import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Deferred } from '@common/deferred/deferred';
import {
  BiometricPermissionState,
  Device,
  DeviceSecurityType,
  IdentityVaultConfig,
  Vault,
  VaultType,
} from '@ionic-enterprise/identity-vault';
import { Observable, Subject } from 'rxjs';

export type UnlockMode = 'Biometrics' | 'BiometricsWithPasscode' | 'SystemPasscode' | 'SecureStorage';

let config: IdentityVaultConfig = {
  key: 'de.itlabs.alberta',
  //secure storage is the default and means no lock
  type: VaultType.SecureStorage,
  deviceSecurityType: DeviceSecurityType.None,
  lockAfterBackgrounded: 2000,
  shouldClearVaultAfterTooManyFailedAttempts: true,
  unlockVaultOnLoad: false,
};

const sessionKey = 'auth-session';

/*
 * This service is a wrapper around the Identity Vault plugin.
 * Its main use is to enable a lockscreen after a certain timeout if configured in config
 * It is genereally inialized in an app initalizer
 * Config settings are applied in app.component
 * During login in auth service a boolean is stored in the session vault
 * It is locked when the app is in background and tenant config is set
 * The lockscreen check is triggered when the vault is locked
 */
@Injectable({
  providedIn: 'root',
})
export class SessionVaultService {
  private _lockedSubject: Subject<boolean>;
  private _vault: Vault;
  private _platform: string;
  private _ready = new Deferred<void>();
  private _contentHiddenInBackground = false;
  private _unlockMode: UnlockMode = 'SecureStorage';
  public vaultInitialized = false;

  get ready(): Promise<void> {
    return this._ready.promise;
  }

  constructor() {
    this._platform = Capacitor.getPlatform();
    this._vault = new Vault();
    this._lockedSubject = new Subject();
  }

  get locked(): Observable<boolean> {
    return this._lockedSubject.asObservable();
  }

  async initialize(): Promise<void> {
    const isWeb = this._platform === 'web';
    if (isWeb) {
      this._ready.resolve();
      return;
    }
    // Potential edge cases where init fails if secure storage is not available
    // -> Should be catched and logged
    try {
      // Init with default config. Lock and Timeout is later set in app.component from tenant config.
      await this._vault.initialize(config);
      await this.resetVaultAndUnlockMode();
      this._ready.resolve();
      this.vaultInitialized = true;
    } catch (exception) {
      this._ready.resolve();
      window.logger.captureErrorWithExtras('Critical Error during vault initalize', exception);
    }
    this._vault.onLock(() => {
      this._lockedSubject.next(true);
    });
    this._vault.onUnlock(() => {
      this._lockedSubject.next(false);
    });
    this._vault.onError(error => {
      // this is triggered if the user cancels the biometric prompt
      console.error(error);
    });
  }

  private async resetVaultAndUnlockMode(): Promise<void> {
    try {
      await this._vault.clear();
      let type = VaultType.SecureStorage;
      let deviceSecurityType = DeviceSecurityType.None;
      const result = await this._vault.updateConfig({
        ...(this._vault.config as IdentityVaultConfig),
        type,
        deviceSecurityType,
      });
      this._unlockMode = type;
      this._lockedSubject.next(false);
    } catch (exception) {
      window.logger.captureErrorWithExtras('Critical Error during vault reset', exception);
    }
  }

  // We save a boolean in the vault which when received triggers the lockscreen if vault is locked
  async saveSessionInVault(isAuthenticated: boolean): Promise<void> {
    await this._ready.promise;
    if (!this.vaultInitialized) {
      return;
    }
    try {
      await this._vault.setValue(sessionKey, isAuthenticated);
    } catch (exception) {
      window.logger.captureErrorWithExtras('Critical Error during saveSessionInVault', exception);
    }
  }
  // this triggers the lockscreen if biometrics or passcode is set and vault is locked after X time
  async getSessionFromVault(): Promise<boolean | null> {
    await this._ready.promise;
    if (!this.vaultInitialized) {
      return;
    }
    try {
      return await this._vault.getValue(sessionKey);
    } catch (exception) {
      window.logger.captureErrorWithExtras('Critical Error during getSessionFromVault', exception);
    }
  }

  async lockVault() {
    await this._ready.promise;
    if (!this.vaultInitialized) {
      return;
    }
    await this._vault.lock();
  }

  async unlockVault() {
    await this._ready.promise;
    if (!this.vaultInitialized) {
      return;
    }
    await this._vault.unlock();
  }

  async isUnlocked(): Promise<boolean> {
    if (!this.vaultInitialized) {
      return true;
    }
    return !this._vault.isLocked();
  }

  // If the vault is unlocked emits again
  async refreshUnlockStatus() {
    if (await this.isUnlocked) {
      this._lockedSubject.next(false);
    }
  }

  // you can only unlock if the vault is locked and not empty
  async canUnlock() {
    if (!this.vaultInitialized) {
      return false;
    }
    let value = this._unlockMode;
    let vaultInitialized = this.vaultInitialized;
    return (
      (value || 'SecureStorage') !== 'SecureStorage' &&
      !(await this._vault.isEmpty()) &&
      (await this._vault.isLocked()) &&
      vaultInitialized
    );
  }

  canHideContentsInBackground(): boolean {
    return this._platform !== 'web';
  }

  async canUseBiometrics(): Promise<boolean> {
    return this._platform !== 'web' && (await Device.isBiometricsEnabled());
  }

  async canUseSystemPasscode(): Promise<boolean> {
    return this._platform !== 'web' && (await Device.isSystemPasscodeSet());
  }

  async hideContentsInBackground(value: boolean): Promise<void> {
    await Device.setHideScreenOnBackground(value, true);
    this._contentHiddenInBackground = value;
  }

  isHidingContentsInBackground(): boolean {
    return this._contentHiddenInBackground;
  }

  public async clearVaultAndResetLockMode(): Promise<void> {
    await this._ready.promise;
    if (!this.vaultInitialized) {
      return;
    }
    try {
      await this._vault.clear();
      await this.setUnlockMode('SecureStorage');
      this._lockedSubject.next(false);
    } catch (exception) {
      window.logger.captureErrorWithExtras('Critical Error during clear vault', exception);
    }
  }

  async getUnlockMode(): Promise<UnlockMode> {
    let value = this._unlockMode;
    return (value as UnlockMode | null) || 'SecureStorage';
  }

  private async provision(): Promise<void> {
    if ((await Device.isBiometricsAllowed()) === BiometricPermissionState.Prompt) {
      try {
        await Device.showBiometricPrompt({ iosBiometricsLocalizedReason: 'Please authenticate to continue' });
      } catch (error) {
        null;
      }
    }
  }

  async setUnlockMode(unlockMode: UnlockMode): Promise<void> {
    let type: VaultType;
    let deviceSecurityType: DeviceSecurityType;

    await this._ready.promise;
    if (!this.vaultInitialized) {
      return;
    }

    switch (unlockMode) {
      case 'Biometrics':
        await this.provision();
        type = VaultType.DeviceSecurity;
        deviceSecurityType = DeviceSecurityType.Biometrics;
        break;

      case 'BiometricsWithPasscode':
        await this.provision();
        type = VaultType.DeviceSecurity;
        deviceSecurityType = DeviceSecurityType.Both;
        break;

      case 'SystemPasscode':
        type = VaultType.DeviceSecurity;
        deviceSecurityType = DeviceSecurityType.SystemPasscode;
        break;

      case 'SecureStorage':
        type = VaultType.SecureStorage;
        deviceSecurityType = DeviceSecurityType.None;
        break;

      default:
        type = VaultType.SecureStorage;
        deviceSecurityType = DeviceSecurityType.None;
    }
    try {
      const result = await this._vault.updateConfig({
        ...(this._vault.config as IdentityVaultConfig),
        type,
        deviceSecurityType,
      });
      this._unlockMode = unlockMode;

      if (type !== VaultType.SecureStorage) {
        await this._vault.lock();
      }
    } catch (exception) {
      window.logger.captureErrorWithExtras('Critical Error during UnlockMode ', exception);
    }
  }

  async setLockscreenTimeout(timeOutinMs: number): Promise<void> {
    await this._ready.promise;
    if (!this.vaultInitialized) {
      return;
    }
    try {
      await this._vault.updateConfig({
        ...(this._vault.config as IdentityVaultConfig),
        lockAfterBackgrounded: timeOutinMs,
      });
    } catch (exception) {
      window.logger.captureErrorWithExtras('Critical Error during setLockscreenTimeout ', exception);
    }
  }
}
