import { ArticleStatus } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'articleStatusColorPipe',
  pure: true,
})
export class ArticleStatusColorPipe implements PipeTransform {
  constructor() {}
  transform(item: ArticleStatus): string {
    switch (item) {
      case ArticleStatus.Unknown:
        return getComputedStyle(document.documentElement).getPropertyValue('--alberta-color-status-unknown');
      case ArticleStatus.Available:
        return getComputedStyle(document.documentElement).getPropertyValue('--alberta-color-status-success');
      case ArticleStatus.Locked:
      case ArticleStatus.NotAvailable:
        return getComputedStyle(document.documentElement).getPropertyValue('--alberta-color-status-warning');
      case ArticleStatus.DontUse:
      case ArticleStatus.Delayed:
        return getComputedStyle(document.documentElement).getPropertyValue('--alberta-color-status-info');
      default:
        return getComputedStyle(document.documentElement).getPropertyValue('--alberta-color-status-unknown');
    }
  }
}
