import { IAppointment } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { BaseStateExtension } from '@common/state/state-extension';
import { AppointmentModelName } from 'src/app/shared/models/model-names';

export const PATIENT_LIST_APPOINTMENT_STATE_NAME = 'PatientListAppointment';
@Injectable({ providedIn: 'root' })
export class PatientListAppointmentStateExtension extends BaseStateExtension<IAppointment> {
  name: string = AppointmentModelName;

  private _patientsToObserve: string[] = [];
  public set patientsToObserve(ids: string[]) {
    this._patientsToObserve = ids;
  }

  public async canBeStored(item: IAppointment): Promise<boolean> {
    if (!item || item.archived) {
      return false;
    }
    const matchingPatientIds = item.patientIds.filter(patientId => this._patientsToObserve.includes(patientId));
    return matchingPatientIds.length > 0;
  }
  protected async afterCreateInternal(item: IAppointment) {
    await this.stateRegistry.createBySync(AppointmentModelName, PATIENT_LIST_APPOINTMENT_STATE_NAME, item);
  }
  protected async afterUpdateInternal(item: IAppointment) {
    await this.stateRegistry.updateBySync(AppointmentModelName, PATIENT_LIST_APPOINTMENT_STATE_NAME, [item]);
  }
  protected async removeFromState(item: IAppointment) {
    await this.stateRegistry.removeBySync(AppointmentModelName, PATIENT_LIST_APPOINTMENT_STATE_NAME, [item]);
  }
}
