import { v4 } from 'uuid';

import { IChangeTracker } from '../contracts/tracking/change-tracker';
import { IViewModel } from '../contracts/view-model/view-model';
import { viewmodel } from '../mapper/viewmodel-instance';

@viewmodel()
export class BaseViewModel implements IViewModel {
  _id: string;
  timestamp: Date;
  archived: boolean;
  public changeTracker?: IChangeTracker;

  constructor() {
    this._id = v4();
    this.archived = false;
  }

  suspendChangeTrack(): void {
    if (!this.changeTracker) {
      return;
    }

    this.changeTracker.suspend();
  }

  resumeChangeTrack(): void {
    if (!this.changeTracker) {
      return;
    }

    this.changeTracker.resume();
  }
}
