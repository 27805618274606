export class BodyPart {
  areaId: number;
  half: string;
  view: string;
  part: string;
  constructor(areaId: number, half: string, view: string, part: string) {
    this.areaId = areaId;
    this.half = half;
    this.view = view;
    this.part = part;
  }
}
