import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LoadingIndicatorComponent } from '../loading-indicator/loading-indicator.component';

@Component({
  selector: 'itl-loading-modal',
  templateUrl: 'loading-modal-page.html',
  styleUrls: ['loading-modal-page.scss'],
  standalone: true,
  imports: [LoadingIndicatorComponent, CommonModule, TranslateModule],
})
export class LoadingModalComponent {
  @Input() header: string;
  @Input() message: Observable<string>;
  @Input() showCancelButton = true;

  constructor(private _modalController: ModalController) {}

  public async close() {
    await this._modalController
      .dismiss({
        stopped: true,
      })
      .catch(error => console.log(error));
  }
}
