import { InstitutionType } from '@alberta/konexi-shared';

export function getInstitutionPathFromType(institutionType: InstitutionType): string {
  switch (institutionType) {
    case InstitutionType.Doctor:
      return 'doctor';
    case InstitutionType.Pharmacy:
      return 'pharmacy';
    case InstitutionType.Hospital:
      return 'hospital';
    case InstitutionType.NursingHome:
      return 'nursing-home';
    case InstitutionType.NursingService:
      return 'nursing-service';
  }
}
