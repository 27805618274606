import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { OrderDB } from 'src/app/common/repository/databases';
import { Changes } from 'src/app/common/tracking';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { OrderDto } from 'src/app/shared/models/order/order-dto.model';
import { IArticleLineHistoryService } from 'src/app/shared/services/contracts/history/article-history-service';
import { ArticleLineHistoryService } from 'src/app/shared/services/history/article-line-history.service';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class OrderWorkItem extends WorkItem<OrderDto> {
  constructor(
    @Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory,
    @Inject(ArticleLineHistoryService) private _articleLineHistoryService: IArticleLineHistoryService
  ) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'OrderWorkItem';
  }
  get database(): string {
    return OrderDB;
  }

  get chunkSize(): number {
    return 75;
  }

  public async beforeCreate(order: OrderDto) {
    if (!order || !order.orderedArticleLines || !order.isSeriesOrder) {
      return order;
    }

    order.articleLineHistory = order.articleLineHistory || [];

    for (const orderedArticleLine of order.orderedArticleLines) {
      if ((orderedArticleLine as any).active) {
        await this._articleLineHistoryService.writeAddedArticleHistory(order, orderedArticleLine);
      }
    }
    if (order.annotation) {
      this._articleLineHistoryService.writeHistoryForAnnotation(
        new Changes(order._id, [{ newValue: order.annotation, oldValue: null, path: 'annotation' }]),
        order
      );
    }

    this.removeInactiveOrderLines(order);

    return order;
  }

  public async beforeUpdate(order: OrderDto, changes: Changes): Promise<OrderDto> {
    if (!changes || !order || !order.isSeriesOrder) {
      return order;
    }

    order.articleLineHistory = order.articleLineHistory || [];
    const result = await this._articleLineHistoryService.writeArticleHistory(order, changes);

    this.removeInactiveOrderLines(order);

    return result as OrderDto;
  }

  private removeInactiveOrderLines(order: OrderDto) {
    for (let index = order.orderedArticleLines.length - 1; index >= 0; index--) {
      if (!(order.orderedArticleLines[index] as any).active) {
        order.orderedArticleLines.splice(index, 1);
      }
    }
  }

  protected async afterUpdate(item: OrderDto, model: OrderDto): Promise<OrderDto> {
    if (!item.isSeriesOrder || !model.isSeriesOrder) {
      return;
    }

    this.removeInactiveOrderLines(model);

    model.articleLineHistory = item.articleLineHistory;

    return item;
  }
}
