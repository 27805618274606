import { IDirectOrder } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { BaseStateExtension } from 'src/app/common/state/state-extension';
import { DirectOrderModelName } from 'src/app/shared/models/model-names';
import { DirectOrderViewModel } from './direct-order-view-model';

@Injectable({ providedIn: 'root' })
export class DirectOrderStateExtension extends BaseStateExtension<IDirectOrder> {
  public get name(): string {
    return DirectOrderModelName;
  }

  async canBeStored(item: IDirectOrder): Promise<boolean> {
    return true;
  }

  protected afterCreateInternal(item: IDirectOrder): void {}

  protected async afterUpdateInternal(item: IDirectOrder): Promise<void> {
    if (
      this.stateRegistry.get<IDirectOrder, DirectOrderViewModel>(DirectOrderModelName, 'selectedItem')?._id ===
      item?._id
    ) {
      await this.stateRegistry.update(DirectOrderModelName, 'selectedItem', item);
    }
  }
  protected removeFromState(item: IDirectOrder): void {}
}
