<ion-header>
  <ion-toolbar>
    <ion-title>{{ fileName }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="_modalCtrl.dismiss()">
        <span color="primary" *ngIf="isIos"> {{ 'CANCEL_BUTTON' | translate }} </span>
        <ion-icon name="close" *ngIf="!isIos"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="pdfBox">
  <pdf-viewer
    class="pdfViewer"
    [src]="src"
    [render-text]="true"
    [original-size]="false"
    [(page)]="currentPage"
  ></pdf-viewer>
</div>
