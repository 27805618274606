import { OrderStatus } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderComponentModel } from 'src/app/business/order/order-component-module';
import { getDoctorIdFromUserId } from 'src/app/notifications/config/web-doctor-config';
import { AuthService } from 'src/app/shared/services/auth.service';
import { OrderDto } from '../../models/order/order-dto.model';

@Injectable({ providedIn: 'root' })
export class OrderNotificationFilterService {
  constructor(private _orderComponentModel: OrderComponentModel, private _authService: AuthService) {}

  public filter(): Observable<OrderDto[]> {
    const doctorId = getDoctorIdFromUserId(this._authService.getCurrentUserId());
    return this._orderComponentModel.select<OrderDto[]>('notification').pipe(
      map(orders =>
        orders.filter(
          order => order.doctorId === doctorId && order.status === OrderStatus.Transmitted && !order.archived
        )
      ),
      map(orders => orders.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()))
    );
  }
}
