import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RegionDto } from 'src/app/shared/models/user-management/region-dto.model';

import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'region-list-item',
  templateUrl: 'region-list-item.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegionListItem implements IListView {
  @Input() item: RegionDto;
  @Output() clicked: EventEmitter<RegionDto> = new EventEmitter<RegionDto>();
  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
