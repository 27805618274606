<!--Service-->
<itl-detail-item *ngIf="service" [noIcon]="!hasCheckbox && !icon">
  <itl-detail-item-icon>
    <div *ngIf="hasCheckbox" class="checkbox-wrapper" (click)="this.toggleCheckbox()">
      <ion-checkbox [checked]="isChecked"></ion-checkbox>
    </div>
    <itl-alberta-icon *ngIf="icon" [class]="iconColor" [name]="icon"></itl-alberta-icon>
  </itl-detail-item-icon>
  <div
    class="bold"
    [ngStyle]="{
      'text-decoration': service.archived ? 'line-through' : 'unset',
    }"
    (click)="this.toggleCheckbox()"
  >
    <span class="service-name">{{ service?.name }}</span>
  </div>
  <!--Quantity-->
  <div [ngClass]="isWizardPage == true ? 'secondary-variant' : 'secondary'" class="bold">
    <ng-content select="[slot=dosage]"></ng-content>
  </div>

  <!--Cards-->
  <div class="card-container">
    <itl-card [header]="'serviceEntity.description' | translate">
      {{ service?.description ? service.description : 'k.A.' }}
    </itl-card>
    <itl-card [header]="'serviceEntity.additionalDescription' | translate">
      {{ service.additionalDescription ? service.additionalDescription : 'k.A.' }}
    </itl-card>
    <itl-card *ngIf="service?.taskOptions?.firstTask" [header]="'serviceEntity.firstTask' | translate">
      {{ service.taskOptions.firstTask.duration ? service.taskOptions.firstTask.duration + ' Minuten' : 'k.A.' }}
    </itl-card>
    <itl-card [header]="'serviceEntity.duration' | translate">
      {{ service?.taskOptions?.default.duration ? service.taskOptions.default.duration + ' Minuten' : 'k.A.' }}
    </itl-card>
    <itl-card [header]="'serviceEntity.customerNumber' | translate">
      {{ service?.taskOptions?.default.customerNumber ? service.taskOptions.default.customerNumber : 'k.A.' }}
    </itl-card>
    <itl-card [header]="'serviceEntity.location' | translate">
      {{ service?.location ? (service?.location | serviceLocationPipe) : 'k.A.' }}
    </itl-card>
    <ng-content select="[slot=cards]"></ng-content>
  </div>
</itl-detail-item>
