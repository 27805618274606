<div class="warning-item bold">
  <svg id="Wolke" xmlns="http://www.w3.org/2000/svg" width="130.499" height="93.539" viewBox="0 0 130.499 93.539">
    <path
      id="Pfad_3071"
      data-name="Pfad 3071"
      d="M115.255,77.746c2.4.552,67.732.192,95.667,0,9.448,0,15.138-.084,15.522-.072h.084c2.065,0,5.2-2.809,8.007-7.2,5.066-7.911,8.535-19.856,4.142-27.371-2.8-4.8-8.4-7.2-16.6-7.2h-.444v-.431c-2.536-15.854-22.9-22.456-41.708-20.8-19.772,1.789-39.34,12.7-38.415,33.062v.552h-.588c-9.6-.792-15.906.936-18.584,5.138-3.385,5.318.156,13.289.78,14.238l.492.756h-1.152c-5.858,0-9.364,1.453-9.868,4.106A5.379,5.379,0,0,0,115.255,77.746Z"
      transform="translate(-111.197 -12.523)"
      fill="#f4f4f4"
    />
    <path
      id="Pfad_3071-2"
      data-name="Pfad 3071"
      d="M142.782,79.031c-16.387,0-27.143-.1-27.875-.36h0a6.3,6.3,0,0,1-3.326-6.375c.42-2.185,2.557-4.8,10.24-4.91-1.044-2.269-3.746-9.256-.42-14.5,2.833-4.466,9.208-6.375,18.992-5.666-.516-20.543,19.34-31.671,39.424-33.484,19.208-1.729,39.844,5.018,42.7,21.249,8.4.084,14.118,2.677,17.059,7.7,4.586,7.863,1.056,20.216-4.166,28.368-3.133,4.886-6.543,7.719-9.016,7.647-.36,0-6.7,0-15.462.072C185.351,78.911,160.717,79.031,142.782,79.031Zm-27.527-1.285c2.4.552,67.732.192,95.667,0,9.448,0,15.138-.084,15.522-.072h.084c2.065,0,5.2-2.809,8.007-7.2,5.066-7.911,8.535-19.856,4.142-27.371-2.8-4.8-8.4-7.2-16.6-7.2h-.444v-.431c-2.536-15.854-22.9-22.456-41.708-20.8-19.772,1.789-39.34,12.7-38.415,33.062v.552h-.588c-9.6-.792-15.906.936-18.584,5.138-3.385,5.318.156,13.289.78,14.238l.492.756h-1.152c-5.858,0-9.364,1.453-9.868,4.106A5.379,5.379,0,0,0,115.255,77.746Z"
      transform="translate(-111.197 -12.523)"
      fill="#ababab"
    />
    <path
      id="Vereinigungsmenge_6"
      data-name="Vereinigungsmenge 6"
      d="M111.2,52.991v-.984h3.77v.984Zm2.954-10.2.732-.7,3.373,3.217-.732.7Zm10.144-.347V37.738h1.032v4.706ZM225.244,28.579V27.547h4.706v1.032Zm-87.636-.793V26.8h3.77v.984Zm84.058-7,3.229-3.373.7.72-3.229,3.373Zm-81.1-3.206.732-.7,3.373,3.218-.732.7Zm74.118.649v-3.77h.984v3.77Zm-63.974-1V12.523h1.032v4.706Z"
      transform="translate(-111.197 -12.523)"
      fill="#00b3a2"
    />
    <g id="Gruppe_6368" data-name="Gruppe 6368" transform="translate(-111.197 -12.523)">
      <path
        id="Pfad_3571"
        data-name="Pfad 3571"
        d="M158.065,45.993a1.884,1.884,0,1,0,1.729,2.029A1.883,1.883,0,0,0,158.065,45.993Z"
        fill="#ababab"
      />
      <path
        id="Pfad_3572"
        data-name="Pfad 3572"
        d="M207.141,45.993a1.884,1.884,0,1,0,1.729,2.029A1.883,1.883,0,0,0,207.141,45.993Z"
        fill="#ababab"
      />
      <path
        id="Pfad_3573"
        data-name="Pfad 3573"
        d="M194.736,61.337c-15.156,5.957-24.4.107-24.489.047a.5.5,0,0,0-.548.837c.258.169,4.434,2.829,11.766,2.829A37.187,37.187,0,0,0,195.1,62.268a.5.5,0,1,0-.366-.931Z"
        fill="#ababab"
      />
    </g>
    <path
      id="Pfad_3574"
      data-name="Pfad 3574"
      d="M198.027,98.193a3.631,3.631,0,1,0,3.63,3.631A3.634,3.634,0,0,0,198.027,98.193Zm0,6.261a2.631,2.631,0,1,1,2.63-2.63A2.633,2.633,0,0,1,198.027,104.454Z"
      transform="translate(-111.197 -12.523)"
      fill="#ababab"
    />
    <path
      id="Pfad_3575"
      data-name="Pfad 3575"
      d="M143.08,83.77a3.028,3.028,0,1,0,3.028,3.027A3.031,3.031,0,0,0,143.08,83.77Zm0,5.056a2.028,2.028,0,1,1,2.028-2.029A2.031,2.031,0,0,1,143.08,88.826Z"
      transform="translate(-111.197 -12.523)"
      fill="#ababab"
    />
    <path
      id="Pfad_3576"
      data-name="Pfad 3576"
      d="M208.561,87.512a1.813,1.813,0,1,0,1.812,1.813A1.816,1.816,0,0,0,208.561,87.512Z"
      transform="translate(-111.197 -12.523)"
      fill="#ababab"
    />
    <g id="Gruppe_6369" data-name="Gruppe 6369" transform="translate(-111.197 -12.523)">
      <path
        id="Pfad_3577"
        data-name="Pfad 3577"
        d="M155.344,99.182h-5.487V93.824a.5.5,0,0,0-1,0v5.358h-5.484a.5.5,0,0,0,0,1h5.484v5.38a.5.5,0,0,0,1,0v-5.38h5.487a.5.5,0,0,0,0-1Z"
        fill="#ababab"
      />
      <circle
        id="Ellipse_357"
        data-name="Ellipse 357"
        cx="1.813"
        cy="1.813"
        r="1.813"
        transform="translate(130.556 95.567)"
        fill="#ababab"
      />
      <path
        id="Pfad_3578"
        data-name="Pfad 3578"
        d="M220.026,98.188h-2.692V95.564a.5.5,0,1,0-1,0v2.624h-2.69a.5.5,0,0,0,0,1h2.69v2.636a.5.5,0,0,0,1,0V99.188h2.692a.5.5,0,0,0,0-1Z"
        fill="#ababab"
      />
    </g>
    <path
      id="Pfad_3579"
      data-name="Pfad 3579"
      d="M238.677,43.1c-2.8-4.8-8.4-7.2-16.6-7.2h-.444v-.431c-2.536-15.854-22.9-22.456-41.708-20.8-9.534.862-19.014,3.853-26.178,8.994,19.468-8.279,48.859-6.48,54.533,14.08,30.03,4.624,25.532,31.209,3.108,40,9.164,0,14.679-.083,15.056-.071h.084c2.065,0,5.2-2.809,8.007-7.2C239.6,62.563,243.07,50.618,238.677,43.1Z"
      transform="translate(-111.197 -12.523)"
      fill="#00b3a2"
    />
  </svg>
  <ng-content></ng-content>
</div>
