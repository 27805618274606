import { IModel } from '@alberta/konexi-shared';

import { IStateExtension } from '../contracts/state/state-extension';
import { IStateRegistry } from '../contracts/state/state-registry';

export abstract class BaseStateExtension<T extends IModel> implements IStateExtension<T> {
  protected stateRegistry: IStateRegistry;
  abstract name: string;

  abstract canBeStored(item: T): Promise<boolean>;
  protected abstract afterCreateInternal(item: T): void;
  protected abstract afterUpdateInternal(item: T): void;
  protected abstract removeFromState(item: T): void;

  public setRegistry(stateRegistry: IStateRegistry): void {
    this.stateRegistry = stateRegistry;
  }

  public async afterCreate(items: T[]): Promise<void> {
    if (!items || !items.length) {
      return;
    }

    for (const item of items) {
      if (!(await this.canBeStored(item))) {
        continue;
      }

      this.afterCreateInternal(item);
    }
  }
  public async afterUpdate(items: T[]): Promise<void> {
    if (!items || !items.length) {
      return;
    }

    for (const item of items) {
      if (!(await this.canBeStored(item))) {
        this.removeFromState(item);
        continue;
      }

      this.afterUpdateInternal(item);
    }
  }
}
