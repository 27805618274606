import { Injectable } from '@angular/core';

import { BodyPart } from '../models/body-part.model';

@Injectable({
  providedIn: 'root',
})
export class BodyPartService {
  private _bodyParts: BodyPart[] = [
    new BodyPart(1, 'rechts', '', 'kleine Zehe'),
    new BodyPart(2, 'rechts', '', '4. Zehe'),
    new BodyPart(3, 'rechts', '', '3. Zehe'),
    new BodyPart(4, 'rechts', '', '2. Zehe'),
    new BodyPart(5, 'rechts', '', 'Großzehe'),
    new BodyPart(6, 'links', '', 'kleine Zehe'),
    new BodyPart(7, 'links', '', '4. Zehe'),
    new BodyPart(8, 'links', '', '3. Zehe'),
    new BodyPart(9, 'links', '', '2. Zehe'),
    new BodyPart(10, 'links', '', 'Großzehe'),
    new BodyPart(11, 'rechts', 'unten', 'vorderer Fußballen'),
    new BodyPart(12, 'rechts', 'unten', 'Fußsohle'),
    new BodyPart(13, 'rechts', 'unten', 'Ferse'),
    new BodyPart(14, 'links', 'unten', 'vorderer Fußballen'),
    new BodyPart(15, 'links', 'unten', 'Fußsohle'),
    new BodyPart(16, 'links', 'unten', 'Ferse'),
    new BodyPart(17, 'rechts', 'oben', 'Vorderfuß'),
    new BodyPart(18, 'rechts', 'oben', 'Fußspann'),
    new BodyPart(19, 'links', 'oben', 'Vorderfuß'),
    new BodyPart(20, 'links', 'oben', 'Fußspann'),
    new BodyPart(21, 'rechts', 'außen', 'Knöchel'),
    new BodyPart(22, 'rechts', 'innen', 'Knöchel'),
    /** @deprecated */
    new BodyPart(23, 'rechts', 'außen', 'Unterschenkel'),
    /** @deprecated */
    new BodyPart(24, 'rechts', 'innen', 'Unterschenkel'),
    /** @deprecated */
    new BodyPart(25, 'rechts', 'außen', 'Oberschenkel'),
    /** @deprecated */
    new BodyPart(26, 'rechts', 'innen', 'Oberschenkel'),
    new BodyPart(27, 'rechts', '', 'Trochanter'),
    new BodyPart(28, 'links', 'außen', 'Knöchel'),
    new BodyPart(29, 'links', 'innen', 'Knöchel'),
    /** @deprecated */
    new BodyPart(30, 'links', 'außen', 'Unterschenkel'),
    /** @deprecated */
    new BodyPart(31, 'links', 'innen', 'Unterschenkel'),
    /** @deprecated */
    new BodyPart(32, 'links', 'außen', 'Oberschenkel'),
    /** @deprecated */
    new BodyPart(33, 'links', 'innen', 'Oberschenkel'),

    new BodyPart(34, 'links', '', 'Trochanter'),
    new BodyPart(35, 'rechts', '', 'Gesäß'),
    new BodyPart(36, 'links', '', 'Gesäß'),
    new BodyPart(37, '', '', 'Kreuzbeinregion'),
    new BodyPart(38, '', '', 'Sakralregion'),
    new BodyPart(39, 'rechts', '', 'unterer Rücken'),
    new BodyPart(40, 'links', '', 'unterer Rücken'),
    new BodyPart(41, '', '', 'Wirbelsäule'),
    new BodyPart(42, 'rechts', '', 'Schulterblatt/Schulter'),
    new BodyPart(43, 'links', '', 'Schulterblatt/Schulter'),
    new BodyPart(44, 'rechts', 'außen', 'Oberarm'),
    new BodyPart(45, 'rechts', '', 'Ellenbogen'),
    new BodyPart(46, 'rechts', 'außen', 'Unterarm'),
    new BodyPart(47, 'rechts', '', 'Handrücken'),
    new BodyPart(48, 'links', 'außen', 'Oberarm'),
    new BodyPart(49, 'links', '', 'Ellenbogen'),
    new BodyPart(50, 'links', 'außen', 'Unterarm'),
    new BodyPart(51, 'links', '', 'Handrücken'),
    new BodyPart(52, '', '', 'Nacken'),
    new BodyPart(53, '', 'hinten', 'Kopf'),
    new BodyPart(54, '', '', 'Genitalien/Leiste'),
    new BodyPart(55, '', '', 'Unterbauch'),
    new BodyPart(56, '', '', 'Oberbauch'),
    new BodyPart(57, 'rechts', '', 'Thorax'),
    new BodyPart(58, 'links', '', 'Thorax'),
    new BodyPart(59, 'rechts', 'innen', 'Oberarm'),
    new BodyPart(60, 'rechts', 'innen', 'Unterarm'),
    new BodyPart(61, 'rechts', '', 'Handinnenfläche'),
    new BodyPart(62, 'links', 'innen', 'Oberarm'),
    new BodyPart(63, 'links', 'innen', 'Unterarm'),
    new BodyPart(64, 'links', '', 'Handinnenfläche'),
    new BodyPart(65, '', '', 'Hals'),
    new BodyPart(66, 'rechts', '', 'Ohr'),
    new BodyPart(67, 'links', '', 'Ohr'),
    new BodyPart(68, '', 'vorne', 'Kopf'),
    new BodyPart(69, 'innen', 'rechts', 'Knie'),
    new BodyPart(70, 'mitte', 'rechts', 'Knie'),
    new BodyPart(71, 'außen', 'rechts', 'Knie'),
    new BodyPart(72, 'innen', 'links', 'Knie'),
    new BodyPart(73, 'mitte', 'links', 'Knie'),
    new BodyPart(74, 'außen', 'links', 'Knie'),
    new BodyPart(75, 'rechts', '', 'Auge'),
    new BodyPart(76, 'links', '', 'Auge'),
    new BodyPart(77, '', '', 'Mund'),
    new BodyPart(78, '', '', 'Nase'),
    new BodyPart(79, 'rechts', '', 'Stirn'),
    new BodyPart(80, 'links', '', 'Stirn'),
    new BodyPart(81, 'rechts', '', 'Wange'),
    new BodyPart(82, 'links', '', 'Wange'),
    new BodyPart(83, '', '', 'Kinn'),
    new BodyPart(84, 'rechts', '', 'Daumen'),
    new BodyPart(85, 'rechts', '', 'Zeigefinger'),
    new BodyPart(86, 'rechts', '', 'Mittelfinger'),
    new BodyPart(87, 'rechts', '', 'Ringfinger'),
    new BodyPart(88, 'rechts', '', 'Kleiner Finger'),
    new BodyPart(89, 'links', '', 'Daumen'),
    new BodyPart(90, 'links', '', 'Zeigefinger'),
    new BodyPart(91, 'links', '', 'Mittelfinger'),
    new BodyPart(92, 'links', '', 'Ringfinger'),
    new BodyPart(93, 'links', '', 'Kleiner Finger'),
    new BodyPart(94, 'rechts', '', 'Oberbauch'),
    new BodyPart(95, 'links', '', 'Oberbauch'),
    new BodyPart(96, 'rechts', '', 'Unterbauch'),
    new BodyPart(97, 'links', '', 'Unterbauch'),
    new BodyPart(98, '', '', 'Genitalien'),
    new BodyPart(99, 'rechts', '', 'Leiste'),
    new BodyPart(100, 'links', '', 'Leiste'),
    // parts after 100 are already in use
    /** @deprecated */
    new BodyPart(301, 'rechts', 'mittig', 'Oberschenkel'),
    /** @deprecated */
    new BodyPart(302, 'links', 'mittig', 'Oberschenkel'),
    /** @deprecated */
    new BodyPart(303, 'rechts', 'mittig', 'Unterschenkel'),
    /** @deprecated */
    new BodyPart(304, 'links', 'mittig', 'Unterschenkel'),
    new BodyPart(305, 'rechts', '', 'Achillessehne'),
    new BodyPart(306, 'links', '', 'Achillessehne'),
    new BodyPart(307, 'rechts', '', 'Brust'),
    new BodyPart(308, 'links', '', 'Brust'),
    new BodyPart(309, 'rechts', '', 'Trochanter'),
    new BodyPart(310, 'links', '', 'Trochanter'),
    new BodyPart(311, '', '', 'Analfalte'),
    new BodyPart(312, 'rechts', 'vorne außen', 'Oberschenkel'),
    new BodyPart(313, 'rechts', 'vorne innen', 'Oberschenkel'),
    new BodyPart(314, 'rechts', 'vorne mittig', 'Oberschenkel'),
    new BodyPart(315, 'links', 'vorne außen', 'Oberschenkel'),
    new BodyPart(316, 'links', 'vorne innen', 'Oberschenkel'),
    new BodyPart(317, 'links', 'vorne mittig', 'Oberschenkel'),
    new BodyPart(318, 'rechts', 'hinten außen', 'Oberschenkel'),
    new BodyPart(319, 'rechts', 'hinten innen', 'Oberschenkel'),
    new BodyPart(320, 'rechts', 'hinten mittig', 'Oberschenkel'),
    new BodyPart(321, 'links', 'hinten außen', 'Oberschenkel'),
    new BodyPart(322, 'links', 'hinten innen', 'Oberschenkel'),
    new BodyPart(323, 'links', 'hinten mittig', 'Oberschenkel'),
    new BodyPart(324, 'rechts', 'vorne außen', 'Unterschenkel'),
    new BodyPart(325, 'rechts', 'vorne innen', 'Unterschenkel'),
    new BodyPart(326, 'rechts', '', 'Schienbein'),
    new BodyPart(327, 'links', 'vorne außen', 'Unterschenkel'),
    new BodyPart(328, 'links', 'vorne innen', 'Unterschenkel'),
    new BodyPart(329, 'links', '', 'Schienbein'),
    new BodyPart(330, 'rechts', 'hinten außen', 'Unterschenkel'),
    new BodyPart(331, 'rechts', 'hinten innen', 'Unterschenkel'),
    new BodyPart(332, 'rechts', '', 'Wade'),
    new BodyPart(333, 'links', 'hinten außen', 'Unterschenkel'),
    new BodyPart(334, 'links', 'hinten innen', 'Unterschenkel'),
    new BodyPart(335, 'links', '', 'Wade'),
    // 999 is used for service logic with several locations in an integrated care and not shown in UI
    new BodyPart(999, '', '', 'Sonstiges'),
  ];

  public get bodyParts() {
    return this._bodyParts;
  }

  constructor() {}

  public getFullBodyPartName(bodyPartId: number) {
    const bodyPart = this.bodyParts.find(f => f.areaId === bodyPartId);
    if (!bodyPart) {
      return;
    }
    return ` ${bodyPart.part} ${bodyPart.half ? bodyPart.half : ''} ${bodyPart.view ? bodyPart.view : ''}`.trim();
  }
}
