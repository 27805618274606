import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IBaseInputContract } from '../contracts/base-input';

@Component({
  selector: 'itl-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionComponent implements IBaseInputContract {
  @Input() title = '';
  @Input() required = false;
  @Input() icon = 'alberta-question';
  @Input() description: string;
}
