<div class="page-wrapper">
  <div class="header">{{header}}</div>
  <div class="content">
    <ng-content></ng-content>
    <div class="loading-indicator"><itl-loading-indicator></itl-loading-indicator></div>
    <div class="text">{{message | async}}</div>
  </div>
  <div class="button-wrapper">
    <button [hidden]="showCancelButton === false" (click)="close()">{{'CANCEL_BUTTON' | translate}}</button>
  </div>
</div>
