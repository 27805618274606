import { Injectable } from '@angular/core';
import { Conversation } from '@twilio/conversations';

export interface IPushMarker {
  uid: string;
  isPatientApp: boolean;
}

@Injectable({ providedIn: 'root' })
export class PushMarkerService {
  private readonly _pushMarker = new RegExp(/^(?<uid>[0-9a-fA-F]{9})\|(?<app>P)#/);

  public getPushMarker(message: string): IPushMarker {
    if (message) {
      const matches = message.match(this._pushMarker);

      if (matches) {
        return {
          uid: matches.groups.uid,
          isPatientApp: matches.groups.app === 'P',
        };
      }
    }

    return null;
  }

  /*
  To enable filtering of Push-Notifications, we will add a marker prefix to any message.
  Prefix: <last 9 characters of the agents uuid>|P#
  The "P" marks the PatientApp.
  */
  public enrichMessageWithPushMarkers(conversation: Conversation, message: string) {
    if ((conversation.attributes as any)?.assignedToId) {
      message = `${(conversation.attributes as any).assignedToId.slice(-9)}|P#${message}`;
    }

    return message;
  }

  public removePushMarker(message: string) {
    // remove first 12 characters if they are UUID and PatientApp markers
    if (this._pushMarker.test(message)) {
      message = message.substring(12);
    }

    return message;
  }
}
