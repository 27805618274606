import { IAppointment, IPatient } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { AppointmentDB, PatientDB } from 'src/app/common/repository/databases';

import {
  ActivitiesListSortFilterType,
  ActivitiesListSortService,
} from '../components/activities/list/activities-list-sort.service';
import { PatientDto } from '../models/patient/patient-dto.model';
import { AuthService } from './auth.service';
import { IQuerySearchOptions } from './contracts/query/query-search-options';
import { IQueryService } from './contracts/query/query-service';
import { QueryService } from './query/query.service';
@Injectable({
  providedIn: 'root',
})
export class PatientService {
  private _myPatients: IPatient[];

  constructor(
    @Inject(QueryService) private _queryService: IQueryService,
    private _activitiesListSortService: ActivitiesListSortService,

    private _authenticationService: AuthService
  ) {}

  public async getMyPatients(): Promise<IPatient[]> {
    if (!this._myPatients) {
      await this.updateMyPatients();
    }
    return this._myPatients;
  }
  /**
   * Sets the myPatients array that is used for downloading attachments
   */
  public async updateMyPatients() {
    // We probably dont need to check for the authentication here,
    // as the sync only starts after the authentication is done
    try {
      this._myPatients = await this._queryService.search<IPatient>(
        {
          // tslint:disable-next-line: max-line-length
          query: `fieldNurseId:${this._authenticationService.authentication.account._id} additionalUserId:${this._authenticationService.authentication.account._id} additionalUserIds:${this._authenticationService.authentication.account._id}`,
        },
        PatientDB,
        { isIn: true }
      );
    } catch (error) {
      // to avoid endless loops we set myPatients to an empty array in case of errors
      if (!this._myPatients) {
        this._myPatients = [];
      }
      window.logger.captureErrorWithExtras('Error in updateMyPatients()', error);
    }
  }

  public async find(id: string): Promise<PatientDto> {
    return this._queryService.get<PatientDto>(id, PatientDB);
  }

  public async query(query: string, options?: IQuerySearchOptions): Promise<PatientDto[]> {
    return this._queryService.search<PatientDto>({ query }, PatientDB, options);
  }

  public async getNextAppointment(
    patient: IPatient,
    patientAppointments?: IAppointment[]
  ): Promise<IAppointment | null> {
    if (patientAppointments && !patientAppointments.length) {
      return null;
    }

    if (patientAppointments) {
      patientAppointments = patientAppointments.filter(appointment => appointment.patientIds.includes(patient._id));
    } else {
      patientAppointments = await this._queryService.search<IAppointment>(
        { query: `patientIds:${patient._id}` },
        AppointmentDB
      );
    }

    // nursingHome appointments
    const futureNursingHomeAppointments = patient.nursingHomeId
      ? this._activitiesListSortService.getFutureActivities(
          patientAppointments.filter(
            appointment => appointment.institutionId != null && appointment.institutionId.length > 0
          ),
          patient.nursingHomeId,
          ActivitiesListSortFilterType.nursingHome
        )
      : {};
    // hospital appointments
    const futureHospitalAppointments = patient.hospital?.hospitalId
      ? this._activitiesListSortService.getFutureActivities(
          patientAppointments.filter(
            appointment => appointment.institutionId != null && appointment.institutionId.length > 0
          ),
          patient.hospital.hospitalId,
          ActivitiesListSortFilterType.hospital
        )
      : {};
    const futurePatientAppointments = this._activitiesListSortService.getFutureActivities(
      patientAppointments.filter(appointment => appointment.institutionId == null || !appointment.institutionId),
      patient._id,
      ActivitiesListSortFilterType.patient
    );

    // javascript objects are sorted by keys,
    // when iterated by Object.keys or when stdout by console.log(obj);
    const futureAppointments = {
      ...futureNursingHomeAppointments,
      ...futureHospitalAppointments,
      ...futurePatientAppointments,
    };
    if (futureAppointments && Object.keys(futureAppointments).length) {
      const futureAppointmentKeys = Object.keys(futureAppointments);
      const newestGroupedAppointments = futureAppointments[futureAppointmentKeys[0]];
      return newestGroupedAppointments[0];
    }
    return null;
  }
}
