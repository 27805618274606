import { IServiceOrder } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { BaseStateExtension } from 'src/app/common/state/state-extension';
import { ServiceOrderModelName } from 'src/app/shared/models/model-names';

import { ServiceOrderViewModel } from './view-model/service-order-view-model';

@Injectable({ providedIn: 'root' })
export class ServiceOrderStateExtension extends BaseStateExtension<IServiceOrder> {
  public get name(): string {
    return ServiceOrderModelName;
  }

  async canBeStored(item: IServiceOrder): Promise<boolean> {
    return true;
  }

  protected afterCreateInternal(item: IServiceOrder): void {}

  protected async afterUpdateInternal(item: IServiceOrder): Promise<void> {
    if (
      this.stateRegistry.get<IServiceOrder, ServiceOrderViewModel>(ServiceOrderModelName, 'selectedItem')?._id ===
      item?._id
    ) {
      await this.stateRegistry.update(ServiceOrderModelName, 'selectedItem', item);
    }
  }
  protected removeFromState(item: IServiceOrder): void {}
}
