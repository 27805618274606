import { IAppointment } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { AppointmentChannel } from 'src/app/business/appointment/contracts/appointment-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { AppointmentModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class AppointmentSyncService extends SyncService<IAppointment> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IAppointment>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(AppointmentModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  protected async onCreated(data: IAppointment): Promise<void> {
    this.formatDates(data);
    await super.onCreated(data);

    // Base SyncService does not add new items to state
    // see #17196
    if (this.ignoreState) {
      return;
    }
    await this._dispatcher.createState(this._name, data);
  }

  protected async onPatched(data: IAppointment): Promise<void> {
    this.formatDates(data);
    return super.onPatched(data);
  }

  protected async onRemoved(data: IAppointment): Promise<void> {
    this.formatDates(data);
    return super.onRemoved(data);
  }

  public canSync(channel: string): boolean {
    return (
      channel === AppointmentChannel.CREATE ||
      channel === AppointmentChannel.EDIT ||
      channel === AppointmentChannel.DELETE
    );
  }

  private formatDates(data: IAppointment) {
    if (data.startDate) {
      data.startDate = new Date(data.startDate);
    }
    if (data.endDate) {
      data.endDate = new Date(data.endDate);
    }
  }
}
