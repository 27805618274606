import { Inject, Injectable, NgZone } from '@angular/core';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { LogEntryDto } from '../../models/logging/log-entry-dto.model';
import { LoggingModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class LoggingSyncService extends SyncService<LogEntryDto> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<LogEntryDto>, // dummy dispatcher, doesnt matter which one
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(LoggingModelName, appController, dispatcher, eventService, ngZone, platformSync);
    this.ignoreSync = true;
  }

  public canSync(channel: string): boolean {
    return channel === 'logging::create';
  }
}
