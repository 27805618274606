import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlbertaFormGroup } from 'src/app/business/form/albertaformgroup';
import { WizardStepItem } from 'src/app/shared/models/wizard-step-item';

@Component({
  selector: 'itl-wizard-nav-page',
  templateUrl: './wizard-nav-page.component.html',
  styleUrls: ['./wizard-nav-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardNavPageComponent implements OnInit, OnChanges {
  @Input() public pageID: number;
  @Input() public wizardSteps: WizardStepItem[] = [];
  @Input() public form: AlbertaFormGroup;
  @Input() public isEditView = false;
  public firstInvalidSection = -1;

  public visibleWizardSteps: WizardStepItem[] = [];

  constructor(private _modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.updateWizardSteps();
  }

  ngOnChanges(): void {
    this.updateWizardSteps();
  }

  private updateWizardSteps() {
    if (!this.wizardSteps) {
      this.wizardSteps = [];
    }

    this.visibleWizardSteps = this.wizardSteps.filter(step => step.hiddenInNavigation !== true);

    if (this.form) {
      this.wizardSteps.forEach((_, index) => {
        const sectionStatus = this.form.getSectionStatus(index.toString());
        if (sectionStatus !== undefined && !sectionStatus) {
          if (this.firstInvalidSection === -1) {
            this.firstInvalidSection = index;
          }
        }
      });
    }
  }

  public isDisabled(index: number): boolean {
    if (this.wizardSteps[index].disabled) {
      return true;
    }
    return this.firstInvalidSection < index && this.firstInvalidSection !== -1;
  }

  public async leaveView() {
    await this._modalCtrl.dismiss(-1).catch(error => console.log(error));
  }

  public async changePage(i) {
    await this._modalCtrl.dismiss(i).catch(error => console.log(error));
  }
}
