import { IOrder } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { OrderDB } from 'src/app/common/repository/databases';
import { MessagePayloadOrder } from 'src/app/shared/models/share/message-payload-order';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

import { IRouteParser } from '../contracts/route/route-parser';
import { QueryService } from '../query/query.service';

@Injectable()
export class OrderRouteParser implements IRouteParser {
  type = MessagePayloadType.Order;

  constructor(private _queryService: QueryService) {}
  async parse(payload: MessagePayloadOrder): Promise<string> {
    const order: IOrder = await this._queryService.get(payload.orderId, OrderDB);

    if (!order) {
      throw new Error(`No order found for id ${payload.orderId}`);
    }

    const orderPath = `?orderId=${order._id}&isSeriesOrder=${Boolean(order.isSeriesOrder)}`;

    return `/patient/${order.patientId}/careproposal${orderPath}`;
  }
}
