import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SyncProgressEvent } from 'src/app/shared/models/sync-progress-event';

@Pipe({
  name: 'progressItemSortPipe',
  pure: true,
})
export class ProgressItemSortPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(progressItems: SyncProgressEvent[]): any[] {
    return progressItems.sort((a, b) =>
      this._translate.instant(`entities.${a.title}`).localeCompare(this._translate.instant(`entities.${b.title}`))
    );
  }
}
