import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { QuotesDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';

import { WorkItem } from '../work-item';
import { QuoteDto } from './../../shared/models/quotes/quote-dto.model';

@Injectable({ providedIn: 'root' })
export class QuoteWorkItem extends WorkItem<QuoteDto> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
    this._deletable = false;
  }
  get name(): string {
    return 'QuoteWorkItem';
  }
  get database(): string {
    return QuotesDB;
  }
}
