import { environment } from './environment.default';

delete environment.branch;
environment.channelName = 'Production';
environment.amplitude.apiKey = 'ab65433b479343895b82b89fdf5538ff';
environment.amplitude.patientApp.apiKey = 'af54c1445e360af9da1b98d0aa591b55';
environment.authCodeFlowConfigWeb.showDebugInformation = false;
environment.careboxBaseUrl = 'https://shop.albisana.de/create';

export { environment };
