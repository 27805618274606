import { Inject, Injectable, NgZone } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import makeDebug from '../../../../makeDebug';
import { AuthService } from '../../services/auth.service';
import { ConnectionStateService } from '../../services/connection-state.service';
import { IConnectionStateService } from '../../services/contracts/sync/connection-state-service';
import { IFeathersService } from '../../services/contracts/sync/feathers-service';
import { ISyncService } from '../../services/contracts/sync/service/sync-service';
import { FeathersService } from '../../services/feathers.service';
import { SyncTimeoutRepeaterServiceFactory } from '../../services/sync-timeout-repeater-factory..service';
import { MaintenanceSyncService } from '../../services/sync/maintenance-sync.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement, SyncElementResult } from '../contracts/sync/sync-element';
import { MaintenanceSynchroniser as Synchroniser } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:maintenance-synchroniser');

@Injectable()
export class MaintenanceSynchroniser implements ISyncElement {
  public get name(): string {
    return Synchroniser;
  }
  private _isRunning = false;

  constructor(
    @Inject(MaintenanceSyncService) private _maintenanceSyncService: ISyncService,
    private _ngZone: NgZone,
    @Inject(FeathersService) private _feathersService: IFeathersService,
    private _authenticationService: AuthService,
    private _syncTimeoutRepeaterServiceFactory: SyncTimeoutRepeaterServiceFactory,
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService
  ) {}

  public async execute(context: ISyncContext): Promise<SyncElementResult> {
    debug('entered execute function', context.params.isOnline);
    const result: SyncElementResult = { contextId: context.id, jobDone: false };
    if (!this._connectionStateService.getSocketConnectionState() || this._isRunning) {
      result.connected = false;
      return result;
    }
    result.connected = true;

    this._isRunning = true;

    await new Promise<SyncElementResult>(resolve => {
      this._authenticationService.authenticatedEventPublisher
        .pipe(takeWhile(authEventData => !authEventData.isAuthenticated))
        .subscribe({
          complete: async () => {
            result['authenticatedEventPublisherCompleted'] = true;
            await this._maintenanceSyncService.setup(this._feathersService);
            debug('calling sync of sync-timeout-repeater', context.params.maintenanceToken);

            const syncTimeoutRepeater = this._syncTimeoutRepeaterServiceFactory.create(
              this._maintenanceSyncService,
              this._ngZone,
              null
            );

            context.params.maintenanceToken.promise = syncTimeoutRepeater.sync(
              context.params.maintenanceToken,
              this._authenticationService.authentication.account
            );

            this._isRunning = false;
            result.jobDone = true;
            resolve(result);
          },
        });
    });

    return result;
  }
}
