import { NgModule } from '@angular/core';

import { ArticleFilterArchivedPackingUnitsPipe } from './article-filter-archived-packageunits-pipe';
import { ArticleFilterNonStringPackingUnitsPipe } from './article-filter-non-string-packaging-units.pipe';
import { ArticleInventoryTypePipe } from './article-inventoryType-pipe';
import { ArticleIsOrderablePipe } from './article-isOrderAble-pipe';
import { ArticleQuantityWithUnitPipe } from './article-quantity-with-unit.pipe';
import { ArticleUnitPipe2 } from './article-unit.pipe2';
import { ArticleAssortmentPipe } from './articleassortment-pipe';
import { ArticlePrescriptionTypetPipe } from './articleprescriptiontype-pipe';
import { ArticleStatusPipe } from './articlestatus-pipe';
import { ArticleStatusColorPipe } from './articlestatuscolor-pipe';
import { ArticleUnitPipe } from './articleunit-pipe';
import { AttributeTypePipe } from './attribute-pipe';
import { ProductGroupMngSortPipePipe } from './productgroupsortpipe';
import { ProductGroupTypePipe } from './productgrouptype-pipe';
import { PurchaseRatingPipe } from './purchaserating-pipe';

@NgModule({
  declarations: [
    ProductGroupTypePipe,
    ArticleStatusPipe,
    ArticleStatusColorPipe,
    ArticleAssortmentPipe,
    ArticleUnitPipe,
    ArticlePrescriptionTypetPipe,
    PurchaseRatingPipe,
    ArticleInventoryTypePipe,
    ProductGroupMngSortPipePipe,
    ArticleIsOrderablePipe,
    AttributeTypePipe,
    ArticleFilterNonStringPackingUnitsPipe,
    ArticleFilterArchivedPackingUnitsPipe,
  ],
  exports: [
    ProductGroupTypePipe,
    ArticleStatusPipe,
    ArticleStatusColorPipe,
    ArticleAssortmentPipe,
    ArticleUnitPipe,
    ArticlePrescriptionTypetPipe,
    PurchaseRatingPipe,
    ArticleInventoryTypePipe,
    ProductGroupMngSortPipePipe,
    ArticleIsOrderablePipe,
    AttributeTypePipe,
    ArticleFilterNonStringPackingUnitsPipe,
    ArticleFilterArchivedPackingUnitsPipe,
    ArticleQuantityWithUnitPipe,
    ArticleUnitPipe2,
  ],
  imports: [ArticleQuantityWithUnitPipe, ArticleUnitPipe2],
  providers: [ArticleStatusColorPipe, ArticleUnitPipe],
})
export class ArticlePipesModule {}
