import { Component, ContentChildren, Input, TemplateRef } from '@angular/core';

import { AlbertaTableColumnDirective } from './alberta-table-column.directive';

@Component({
  selector: 'itl-alberta-table',
  templateUrl: './alberta-table.component.html',
  styleUrls: ['./alberta-table.component.scss'],
})
export class AlbertaTableComponent {
  @Input() data?: Array<object>;
  @Input() showHeaders = true;

  @ContentChildren(AlbertaTableColumnDirective) columns: AlbertaTableColumnDirective[] = [];

  rowTemplate?: TemplateRef<any>;
}
