import { IPatient } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { PatientDB } from 'src/app/common/repository/databases';
import { MessagePayloadPatient } from 'src/app/shared/models/share/message-payload-patient';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';
import { QueryService } from 'src/app/shared/services/query/query.service';

import { IRouteParser } from '../contracts/route/route-parser';

@Injectable()
export class PatientRouteParser implements IRouteParser {
  type = MessagePayloadType.Patient;
  constructor(private _queryService: QueryService) {}

  async parse(payload: MessagePayloadPatient): Promise<string> {
    const patient: IPatient = await this._queryService.get(payload.patientId, PatientDB);

    if (!patient) {
      throw new Error(`No patient found for id ${payload.patientId}`);
    }

    return `/patient/${payload.patientId}/${payload.path}`;
  }
}
