import { IMatch } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { MatchWorkItem } from 'src/app/business/swodoc/match-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class MatchCommandHandler extends EntityCommandHandler<IMatch> {
  protected workItem: IWorkItem<IMatch>;

  constructor(matchWorkItem: MatchWorkItem) {
    super();
    this.workItem = matchWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'MatchDto';
  }
}
