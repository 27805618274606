import { CommonModule } from '@angular/common';
import { NgModule, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Requestor, StorageBackend } from '@openid/appauth';
import { AuthService, Browser } from 'ionic-appauth';
import { CapacitorBrowser, CapacitorSecureStorage } from 'ionic-appauth/lib/capacitor';
import { authFactory } from './factories';
import { NgHttpService } from './ng-http.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: StorageBackend,
      useClass: CapacitorSecureStorage,
    },
    {
      provide: Requestor,
      useClass: NgHttpService,
    },
    {
      provide: Browser,
      useClass: CapacitorBrowser,
    },
    {
      provide: AuthService,
      useFactory: authFactory,
      deps: [Platform, NgZone, Requestor, Browser, StorageBackend],
    },
  ],
})
export class MobileOAuthModule {}
