import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldNurse } from 'src/app/shared/models/field-nurse';

import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'field-nurse-list-item',
  templateUrl: 'field-nurse-list-item.html',
  styleUrls: ['./../autocomplete-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldNurseListItem implements IListView {
  @Input() public item;
  @Output() clicked: EventEmitter<FieldNurse> = new EventEmitter<FieldNurse>();

  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
