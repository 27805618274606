import { inject, InjectionToken } from '@angular/core';
import { AlbertaCalendarAppointmentStateExtension } from 'src/app/alberta-calendar/alberta-calendar-appointment-state-extension';
import { AlbertaCalendarResourceStateExtension } from 'src/app/alberta-calendar/alberta-calendar-resources-state-extension';
import { DashboardAppointmentStateExtension } from 'src/app/business/appointment/dashboard-appointment-state-extension';
import { AttachmentStateExtension } from 'src/app/business/attachment/attachment-state-extension';
import { DirectOrderStateExtension } from 'src/app/business/direct-order/direct-order-state-extension';
import { InstitutionNoteStateExtension } from 'src/app/business/institution/institution-note-state-extension';
import { OrderStateExtension } from 'src/app/business/order/order-state-extension';
import { PatientNoteStateExtension } from 'src/app/business/patientNotes/patient-note-state-extension';
import { ReminderStateExtension } from 'src/app/business/reminder/reminder-state-extension';
import { ServiceOrderStateExtension } from 'src/app/business/service-order-entity/service-order-state-extension';
import { SingleOrderStateExtension } from 'src/app/business/single-order/single-order-state-extension';
import { AuditStateExtension } from 'src/app/business/swodoc/audit-state-extension';
import { PatientListAppointmentStateExtension } from 'src/app/patient/components/patient-list/patient-list-appointment-state-extension';
import { PatientListCareProposalStateExtension } from 'src/app/patient/components/patient-list/patient-list-careproposal-state-extension';
import { PatientListIntegratedCareStateExtension } from 'src/app/patient/components/patient-list/patient-list-integrated-care-state-extension';
import { ActivitiesListStateExtension } from 'src/app/shared/components/activities/list/activities-list-state-extension';
import {
  AppointmentModelName,
  AttachmentModelName,
  AuditModelName,
  CalendarResourceModelName,
  CareProposalModelName,
  DirectOrderModelName,
  InstitutionNoteModelName,
  IntegratedCareModelName,
  OrderModelName,
  PatientNoteModelName,
  ReminderModelName,
  ServiceOrderModelName,
  SingleOrderModelName,
} from 'src/app/shared/models/model-names';
import { IntegratedCareStateExtension } from '../../business/integrated-care/integratedcare-state-extension';

export const StateExtensionToken = new InjectionToken('StateExtensionToken', {
  factory: () => {
    const dashboardAppointmentStateExtension = inject(DashboardAppointmentStateExtension);
    const patientNoteStateExtension = inject(PatientNoteStateExtension);
    const institutionNoteStateExtension = inject(InstitutionNoteStateExtension);

    dashboardAppointmentStateExtension.changed.subscribe(async ({ action, payload }) => {
      await patientNoteStateExtension.onChanged(action, payload);
      await institutionNoteStateExtension.onChanged(action, payload);
    });
    const stateExtensions = {
      [AppointmentModelName]: [
        dashboardAppointmentStateExtension,
        inject(AlbertaCalendarAppointmentStateExtension),
        inject(PatientListAppointmentStateExtension),
        inject(ActivitiesListStateExtension),
      ],
      [OrderModelName]: [inject(OrderStateExtension)],
      [PatientNoteModelName]: [patientNoteStateExtension],
      [InstitutionNoteModelName]: [institutionNoteStateExtension],
      [AttachmentModelName]: [inject(AttachmentStateExtension)],
      [AuditModelName]: [inject(AuditStateExtension)],
      [SingleOrderModelName]: [inject(SingleOrderStateExtension)],
      [DirectOrderModelName]: [inject(DirectOrderStateExtension)],
      [IntegratedCareModelName]: [
        inject(IntegratedCareStateExtension),
        inject(PatientListIntegratedCareStateExtension),
      ],
      [ReminderModelName]: [inject(ReminderStateExtension)],
      [ServiceOrderModelName]: [inject(ServiceOrderStateExtension)],
      [CalendarResourceModelName]: [inject(AlbertaCalendarResourceStateExtension)],
      [CareProposalModelName]: [inject(PatientListCareProposalStateExtension)],
    };

    return stateExtensions;
  },
});
