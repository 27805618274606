import { IModel } from '@alberta/konexi-shared/';

import { IWorkItem } from '../common/contracts/work-item/work-item_T';
import { Command } from './command';
import { ICommandHandler } from './contracts/command-handler';
import { ICommandHandler as ICommandHandler_T } from './contracts/command-handler_T';
import { EntityCreateCommand } from './entity-create-command';
import { EntityDeleteCommand } from './entity-delete-command';
import { EntityUpdateCommand } from './entity-update-command';

export abstract class EntityCommandHandler<T extends IModel>
  implements
    ICommandHandler_T<EntityCreateCommand<T>>,
    ICommandHandler_T<EntityDeleteCommand<T>>,
    ICommandHandler_T<EntityUpdateCommand<T>>,
    ICommandHandler {
  protected abstract workItem: IWorkItem<T>;

  abstract canHandle(command: Command): boolean;

  async handle(command: EntityCreateCommand<T> | EntityUpdateCommand<T> | EntityDeleteCommand<T>): Promise<void> {
    if (command instanceof EntityCreateCommand) {
      await this.create(command);
    } else if (command instanceof EntityDeleteCommand) {
      await this.delete(command);
    } else if (command instanceof EntityUpdateCommand) {
      await this.update(command);
    }
  }

  async update(command: EntityUpdateCommand<T>): Promise<void> {
    await this.workItem.update(command.changes, command.entity);
  }

  async delete(command: EntityDeleteCommand<T>): Promise<void> {
    await this.workItem.delete(command.entity);
  }

  async create(command: EntityCreateCommand<T>): Promise<void> {
    await this.workItem.create(command.entity);
  }
}
