import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import moment from 'moment';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, timeout } from 'rxjs/operators';

import { IonicSlides } from '@ionic/angular';
import { Logger } from 'src/app/common/logging/logger';
import * as models from '../shared/models/model-names';
import { SyncProgressEvent } from '../shared/models/sync-progress-event';
import { EventService } from '../shared/services/event.service';
import { GeneralEntityService } from '../shared/services/general-entity.service';
import { TrackerService } from '../shared/services/tracker.service';
import { XmasService } from '../shared/services/xmas.service';
import { QuoteDto } from './../shared/models/quotes/quote-dto.model';
import { BACKGROUND } from './backgrounds';
import { XMASBACKGROUND } from './xmasBackground';

@Component({
  selector: 'app-sync-indicator',
  templateUrl: './sync-indicator.page.html',
  styleUrls: ['./sync-indicator.page.scss'],
})
export class SyncIndicatorPage implements OnInit, OnDestroy {
  @ViewChild('slider') slider: ElementRef | undefined;
  public progressItems: SyncProgressEvent[] = [];
  private _ngUnsubscribe = new Subject();
  public quote = {
    quoteText: 'Tue es, oder tue es nicht. Es gibt kein Versuchen.',
    author: 'Yoda',
  } as QuoteDto;
  public style = {};
  public background: string;
  public showIndicator = false;
  public showCloseDialogIcon = false;
  private backgrounds = BACKGROUND;
  public swiperModules = [IonicSlides];

  constructor(
    private modalController: ModalController,
    public eventService: EventService<SyncProgressEvent>,
    private _platform: Platform,
    private _xmasService: XmasService,
    private _tracker: TrackerService,
    private _logger: Logger,
    private _generalEntityService: GeneralEntityService
  ) {
    if (this._xmasService.isDuringXmasHolidays) {
      this.backgrounds = XMASBACKGROUND;
    }
    const background = !this._platform.is('hybrid')
      ? this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)].desktop
      : this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)].mobile;
    this.style = {
      'background-image': 'url(' + background + ')',
    };
    const modelNames = Object.keys(models);
    modelNames.forEach(modelName => {
      if (models[modelName] !== 'unknown' && models[modelName] !== 'synctimestamp' && models[modelName] !== 'logging') {
        this.progressItems.push(new SyncProgressEvent(models[modelName], 0, 0));
      }
    });

    eventService.events.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async progress => {
      if (!(progress instanceof SyncProgressEvent)) {
        return;
      }
      if (progress.isError) {
        // If an error during sync occurs we close the sync indicator
        await this.dismiss();
        return;
      }
      const progressItem = this.progressItems.find(f => f.title === progress.title);
      if (!progressItem) {
        this.progressItems.push(progress);
      } else {
        progressItem.progress = progress.progress;
        progressItem.total = progress.total;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.setupCloseDialogIconVisibility();
    await this.setQuote();
  }

  ionViewDidEnter(): void {
    this.showIndicator = true;
    this._tracker.trackSyncOpen();
  }

  private setupCloseDialogIconVisibility() {
    const eventSubscription = this.eventService.events
      .pipe(
        filter(progress => progress.progress > 0),
        timeout(60_000), // fallback: timeout throws error if there is no sync progress for given amount of time
        take(1),
        takeUntil(this._ngUnsubscribe)
      )
      .subscribe(
        (_ /* next */) => {
          this._logger.info(`[SyncIndicatorPage][canCloseSyncIndicator] showing x, reason: progress`);
          this.showCloseDialogIcon = true;
          eventSubscription.unsubscribe();
        },
        (_ /* error */) => {
          this._logger.info(`[SyncIndicatorPage][canCloseSyncIndicator] showing x, reason: timeout`);
          this._logger.captureErrorWithExtras(
            '[SyncIndicatorPage][canCloseSyncIndicator] showing x, reason: timeout',
            new Error('[SyncIndicatorPage][canCloseSyncIndicator] showing x, reason: timeout'),
            new Map([['component', 'sync-indicator-page']]),
            'error'
          );
          this.showCloseDialogIcon = true;
          eventSubscription.unsubscribe();
        }
      );
  }

  private async setQuote(): Promise<void> {
    const thisDay = moment().format('YYYY-MM-DD');
    this._generalEntityService.type = 'quote';
    const quotes = await this._generalEntityService.query(`date:${thisDay}`);
    if (quotes && quotes.length) {
      const quoteOfTheDay = quotes[0] as QuoteDto;
      this.quote = quoteOfTheDay;
    }
  }
  async goToDataPage() {
    this._tracker.trackSyncDetail();
    await this.slider?.nativeElement.swiper.slideNext(500);
  }
  async goToMottoPage() {
    await this.slider?.nativeElement.swiper.slidePrev(500);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next(null);
    this._ngUnsubscribe.complete();
  }

  public async dismiss() {
    this._tracker.trackSyncClose();
    await this.modalController.dismiss().catch(error => console.log(error));
  }
}
