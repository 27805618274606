import moment from 'moment';

import { dateFormat } from '../date/format';
import { ChangeTrack } from './change-track';
import { ChangeTrackCollection } from './change-track-collection';
import { proxyToRaw, rawToProxy } from './internals';

function ownKeys(obj) {
  return Reflect.ownKeys(obj);
}

function deleteProperty(obj, key) {
  if (key in obj) {
    return Reflect.deleteProperty(obj, key);
  }
  return false;
}

function createGet(trackedProps: string[]) {
  return function get(target, key, receiver) {
    const result = Reflect.get(target, key, receiver);

    if (!trackedProps.some(prop => prop === key) || typeof key === 'symbol' || typeof result === 'function') {
      return result;
    }

    return rawToProxy.get(result) || result;
  };
}
function createSet() {
  return function set(obj, key, value, receiver) {
    if (typeof value === 'object' && value !== null) {
      // tslint:disable-next-line:no-parameter-reassignment
      value = proxyToRaw.get(value) || value;
    }

    const forceChange = !obj.hasOwnProperty('key');
    const valueChanged = value !== obj[key];

    if (!valueChanged) {
      return true;
    }

    const result = Reflect.set(obj, key, value, receiver);

    if (valueChanged && obj.changeTracker && !obj.changeTracker.isSuspended) {
      propertyChanged.bind(obj)(key, value, forceChange);
    }

    return result;
  };
}

export function createHandler(trackedProps: string[]) {
  return {
    get: createGet(trackedProps),
    set: createSet(),
    ownKeys,
    deleteProperty,
  };
}

export function propertyChanged(propertyName: string, value: any, forceChange?: boolean) {
  if (this.changeTracker.hasProperty(propertyName)) {
    const changeTrack = this.changeTracker.get(propertyName);
    changeTrack.setValue(value);
  } else {
    if (Array.isArray(value)) {
      const changeTrackCollection = new ChangeTrackCollection(value, propertyName);
      this.changeTracker.set(propertyName, changeTrackCollection);
      this[propertyName] = changeTrackCollection.proxy;
    } else {
      const changeTrack = new ChangeTrack(
        moment(value, dateFormat, true).isValid() ? value.toISOString() : value,
        propertyName
      );
      this.changeTracker.set(propertyName, changeTrack);
      if (forceChange) {
        changeTrack.setValue(value, forceChange);
      }
    }
  }
}
