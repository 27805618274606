import type { Gender, IInstitutionContact, InstitutionType, Title } from '@alberta/konexi-shared';
import { ignore } from 'src/app/common/tracking/tracker';
import { BaseViewModel } from 'src/app/common/viewmodel/base-view-model';
import type { InstitutionContactFunctionService } from 'src/app/shared/services/institution-contact-function.service';
import type { SubunitService } from 'src/app/shared/services/subunit.service';

export class InstitutionContactViewModel extends BaseViewModel implements IInstitutionContact {
  function: string;
  institutionId: string;
  institutionType: InstitutionType;
  gender: Gender;
  title: Title;
  firstName: string;
  lastName: string;
  postalCode: string;
  phone: string;
  fax: string;
  email: string;
  annotation: string;
  @ignore()
  functionName: string;
  _id: string;
  timestamp: Date;
  archived: boolean;

  @ignore()
  subunitNames: string[] = [];

  public async loadFunctionName(institutionContactFuctionService: InstitutionContactFunctionService) {
    if (!this.function || !institutionContactFuctionService) {
      return;
    }
    const contactFunction = await institutionContactFuctionService.find(this.function);
    if (!contactFunction) {
      return;
    }
    this.functionName = contactFunction.translation.de;
  }

  public async loadSubunitNames(subunitService: SubunitService) {
    this.subunitNames = await subunitService.getSubunitNamesForContact(this.institutionId, this._id);
  }
}
