import { IIntegratedCare } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { IntegratedCareDB } from 'src/app/common/repository/databases';
import { MessagePayloadIntegratedCare } from 'src/app/shared/models/share/message-payload-integratedcare';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

import { IRouteParser } from '../contracts/route/route-parser';
import { QueryService } from '../query/query.service';

@Injectable()
export class IntegratedCareRouteParser implements IRouteParser {
  type = MessagePayloadType.IntegratedCare;

  constructor(private _queryService: QueryService) {}
  async parse(payload: MessagePayloadIntegratedCare): Promise<string> {
    const integratedCare: IIntegratedCare = await this._queryService.get(payload.integratedCareId, IntegratedCareDB);

    if (!integratedCare) {
      throw new Error(`No integratedCare found for id ${payload.integratedCareId}`);
    }

    return `/patient/${integratedCare.patientId}/integratedCare/${integratedCare._id}`;
  }
}
