import { Injectable } from '@angular/core';
import { isNumber } from 'lodash';
import { Conversation, Participant, Message } from '@twilio/conversations';

import { PushMarkerService } from '../../push/push-marker.services';
import { ChatChannel, ChatMember, ChatMessage } from '../data/db-schema';

@Injectable({
  providedIn: 'root',
})
export class TwilioToChatDtoHelpersService {
  constructor(private readonly _pushMarkerService: PushMarkerService) {}

  public convertToChatChannel(conversation: Conversation, updateReasons?: string): ChatChannel {
    return {
      sid: conversation.sid,
      dateUpdated: this._convertToIsoString(conversation.dateUpdated),
      dateCreated: this._convertToIsoString(conversation.dateCreated),
      friendlyName: conversation.friendlyName || '',
      uniqueName: conversation.uniqueName || '',
      lastMessageIndex: conversation.lastMessage ? conversation.lastMessage.index : null,
      lastMessageTimestamp: conversation.lastMessage
        ? conversation.lastMessage.dateCreated?.toISOString()
        : conversation.dateCreated.toISOString(),
      lastLocalUpdateAt: undefined,
      lastConsumedMessageIndex: conversation.lastReadMessageIndex,
      removed: false,
      attributes: conversation.attributes,
      updateReasons,
    };
  }

  /*
  Maybe requesting message.author will be enough and even improve performance
  */
  private async _getMemberIdentity(message: Message): Promise<string> {
    try {
      return (await message.getParticipant()).identity;
    } catch (error) {
      return message.author;
    }
  }

  public async convertToChatMessage(message: Message): Promise<ChatMessage> {
    const body = this._pushMarkerService.removePushMarker(message.body);

    return {
      _id: message.attributes['_id'],
      isLocal: undefined,
      channelSid: message.conversation.sid,
      sid: message.sid,
      dateUpdated: message.dateUpdated.toISOString(),
      timestamp: message.dateCreated.toISOString(),
      index: message.index,
      body,
      memberSid: message.participantSid,
      memberIdentity: await this._getMemberIdentity(message),
      status: 'sent',
      retries: 0,
      attributes: message.attributes,
    };
  }

  public convertToChatMember(participant: Participant): ChatMember {
    return {
      sid: participant.sid,
      channelSid: participant.conversation.sid,
      identity: participant.identity,
      lastConsumedMessageIndex: isNumber(participant.lastReadMessageIndex) ? participant.lastReadMessageIndex : 0,
      dateUpdated: participant.dateUpdated.toISOString(),
      isLocal: undefined,
    };
  }

  private _convertToIsoString(date: any) {
    try {
      if (date) {
        return date instanceof Date ? date.toISOString() : new Date(date).toISOString();
      }
    } catch (error) {
      // this does not seem to be a valid date
    }

    return null;
  }
}
