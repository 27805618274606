import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'itl-input-button-template',
  templateUrl: './input-button-template.component.html',
  styleUrls: ['./input-button-template.component.scss'],
})
export class InputButtonTemplateComponent {
  @Input() public value;
  @Input() public control: AbstractControl;
  @Input() public hasErrors = false;
  @Input() public icon: string;
  @Input() public title: string;
  @Input() public disabled = false;
  @Input() public noIcon = false;
  @Input() public placeholder = '';
  @Input() public required = false;
  @Input() public description: string;
  @Input() public cutText = false;

  @Output()
  public buttonPressed: EventEmitter<null> = new EventEmitter<null>();
  public buttonClicked() {
    if (!this.disabled) {
      this.buttonPressed.emit();
    }
  }
}
