import { IInstitutionContactFunction } from '@alberta/konexi-shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

export class InstitutionContactFunctionModel implements IInstitutionContactFunction, ISearchable {
  _id: string;
  key: string;
  translation: { de: string };

  get displayText(): string {
    return this.translation.de;
  }
}
