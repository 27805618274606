import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itl-select-modal',
  templateUrl: './select-modal.component.html',
  styleUrls: ['./select-modal.component.scss'],
})
export class SelectModalComponent implements OnInit, OnDestroy {
  @Input() public header: string;
  @Input() public buttonText: string;
  @Input() private _dontCheckForChange = false;
  @Input() private _content: Type<any>;
  @Input() private _componentProps: {};
  @ViewChild('content', { read: ViewContainerRef, static: true })
  public viewContainerRef: ViewContainerRef;
  public isValid = false;
  private _itemFactory: ComponentFactory<any>;
  private _ngUnsubscribe = new Subject();
  private _currentValue = null;

  constructor(private _modalCtrl: ModalController, private _resolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this._itemFactory = this._resolver.resolveComponentFactory(this._content);
    const component = this.viewContainerRef.createComponent(this._itemFactory);
    component.instance.componentProps = this._componentProps;
    if (!this._dontCheckForChange) {
      component.instance.valueChange.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
        value ? (this.isValid = true) : (this.isValid = false);
        this._currentValue = value;
      });
    } else {
      this.isValid = true;
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next(null);
    this._ngUnsubscribe.complete();
  }

  public async cancel() {
    await this._modalCtrl.dismiss().catch(error => console.log(error));
  }

  async doneClicked() {
    if (!this.isValid) {
      return;
    }
    await this._modalCtrl.dismiss(this._currentValue);
  }
}
