<div [formGroup]="form" id="formGroupDiv">
  <div formArrayName="inputs">
    <div *ngFor="let input of inputs.controls; let i = index" class="input-container">
      <itl-textarea
        [placeholder]="textInputPlaceholder"
        [title]="textInputTitle"
        [icon]="icon"
        [formControlName]="i"
        [hideDelete]="true"
        [required]="false"
      ></itl-textarea>
      <div class="deleteButton">
        <ion-icon name="alberta-delete" (click)="removeInput(i)"></ion-icon>
      </div>
    </div>
  </div>

  <div class="addButtonWrapper">
    <itl-button (click)="addInput()">
      <ion-icon class="addButtonIcon" name="alberta-add"></ion-icon>
      <span class="addButtonText">{{ addButtonText }}</span></itl-button
    >
  </div>
</div>
