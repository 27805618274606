import { IModel } from '@alberta/konexi-shared';
import { clone, map } from 'lodash';

import { IChangeTrack } from '../contracts/tracking/change-track';

export class ChangeTrackCollection implements IChangeTrack {
  private _isDirty;
  public oldValue: any;
  public propertyName: string;
  proxy: any;
  addedItems: IModel[] = [];
  deletedItems: IModel[] = [];
  originTarget: IModel[];

  static handler = (changeTrackCollection: ChangeTrackCollection) => {
    return {
      set(target: any[], property: PropertyKey, value, receiver) {
        target[property] = value;
        changeTrackCollection._isDirty = true;
        changeTrackCollection.originTarget[property] = value;
        return true;
      },
      get(target: any[], propKey, receiver) {
        const origMethod = target[propKey];
        if (propKey === 'constructor' && origMethod.name === 'Array') {
          return origMethod;
        }
        if (propKey === 'forEach' && origMethod.name === 'forEach') {
          return origMethod;
        }
        if (propKey === 'sort' && origMethod.name === 'sort') {
          return origMethod;
        }
        if (propKey === 'filter' && origMethod.name === 'filter') {
          return origMethod;
        }

        if (typeof origMethod !== 'function') {
          return target[propKey];
        }

        return (...args) => {
          const result = origMethod.apply(target, args);
          if (typeof origMethod === 'function') {
            if (propKey === 'push') {
              changeTrackCollection.addedItems.push(...args);
              if (args.length === 1 && changeTrackCollection.originTarget.indexOf(args[0]) === -1) {
                changeTrackCollection.originTarget.push(...args);
              }
              for (let index = changeTrackCollection.deletedItems.length - 1; index >= 0; index--) {
                if (changeTrackCollection.addedItems.indexOf(changeTrackCollection.deletedItems[index]) > -1) {
                  changeTrackCollection.deletedItems.splice(index, 1);
                }
              }
            }
            if (propKey === 'unshift') {
              changeTrackCollection.addedItems.push(...args);
              changeTrackCollection.originTarget.unshift(...args);
            }
            if (propKey === 'shift') {
              changeTrackCollection.deletedItems.push(result);
            }
            if (propKey === 'pop') {
              changeTrackCollection.deletedItems.push(result);
              changeTrackCollection.cleanupAddedItems(changeTrackCollection);
            }
            if (propKey === 'splice') {
              for (const index of Object.keys(result)) {
                changeTrackCollection.deletedItems.push(result[index]);
                changeTrackCollection.cleanupAddedItems(changeTrackCollection);
              }
              if (args.length === 2) {
                changeTrackCollection.originTarget.splice(args[0], args[1]);
              }
            }
          }
          return result;
        };
      },
    };
    // tslint:disable-next-line: semicolon
  };

  constructor(public newValue: any[], propertyName?: string) {
    this.oldValue = JSON.parse(JSON.stringify(this.newValue));
    this.propertyName = propertyName;
    this.originTarget = map(newValue, clone);
    this.proxy = this.interceptArrayMethodCalls(this);
  }

  setValue(value: any): void {
    this.newValue = value || [];
    this.originTarget = value;
    this.proxy = this.interceptArrayMethodCalls(this);
    this.addedItems = [];
    this.deletedItems = [];
    this._isDirty = true;
  }

  get isDirty() {
    return this.addedItems.length > 0 || this.deletedItems.length > 0 || this._isDirty;
  }

  private cleanupAddedItems(trackCollection: ChangeTrackCollection) {
    for (let index = trackCollection.addedItems.length - 1; index >= 0; index--) {
      if (trackCollection.deletedItems.indexOf(trackCollection.addedItems[index]) > -1) {
        trackCollection.addedItems.splice(index, 1);
      }
    }
  }

  interceptArrayMethodCalls(changeTrackCollection: ChangeTrackCollection) {
    return new Proxy<any[]>(this.newValue, ChangeTrackCollection.handler(changeTrackCollection));
  }
}
