<div class="page-wrapper">
  <div class="header">
    <div class="header__titel">
      {{ header }}
    </div>
    <div class="header__closeButton" tappable (click)="cancel()">
      <ion-icon name="alberta-cancel"></ion-icon>
    </div>
  </div>
  <div class="content">
    <div #content></div>
  </div>
  <div class="footer">
    <div
      class="footer__button bold"
      tappable
      (click)="doneClicked()"
      [ngClass]="{ 'footer__button--disabled': !isValid }"
    >
      {{ buttonText }}
    </div>
  </div>
</div>
