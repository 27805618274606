import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'itl-search-modal-input',
  templateUrl: './search-modal-input.component.html',
  styleUrls: ['./search-modal-input.component.scss'],
})
export class SearchModalInputComponent {
  @Input() value = '';
  @Input() placeholder: string;
  @Input() inputDateFormat: string;
  @Input() outputDateFormat: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  clearInput() {
    this.value = '';
    this.valueChange.emit(this.value);
  }

  emitChange() {
    if (
      this.outputDateFormat &&
      this.inputDateFormat &&
      this.value.trim() &&
      moment(this.value, this.inputDateFormat, true).isValid()
    ) {
      this.value = moment(this.value, this.inputDateFormat, true).format(this.outputDateFormat);
    }
    this.valueChange.emit(this.value);
  }
}
