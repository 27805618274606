import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GroupDto } from 'src/app/shared/models/user-management/group-dto.model';

import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'group-list-item',
  templateUrl: 'group-list-item.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupListItem implements IListView {
  @Input() item: GroupDto;
  @Output() clicked: EventEmitter<GroupDto> = new EventEmitter<GroupDto>();

  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
