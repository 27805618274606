import { PrescriptionType } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'articlePrescriptiontypePipe',
  pure: true,
})
export class ArticlePrescriptionTypetPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(item: PrescriptionType): string {
    let prescription = '';
    switch (item) {
      case PrescriptionType.Drug:
        prescription = this._translate.instant('article.prescriptionType.drug');
        break;
      case PrescriptionType.Dressings:
        prescription = this._translate.instant('article.prescriptionType.dressings');
        break;
      case PrescriptionType.NarcoticsRecipe:
        prescription = this._translate.instant('article.prescriptionType.narcoticsRecipe');
        break;
      case PrescriptionType.NursingAids:
        prescription = this._translate.instant('article.prescriptionType.nursingAids');
        break;
      case PrescriptionType.AdisForConsumption:
        prescription = this._translate.instant('article.prescriptionType.adisForConsumption');
        break;
      case PrescriptionType.AidsForUse:
        prescription = this._translate.instant('article.prescriptionType.aidsForUse');
        break;
    }
    return prescription;
  }
}
