import { IDuration, TimePeriod } from '@alberta/konexi-shared';

export function caluclateDurationToDays(item: IDuration): number {
  if (!item || item.timePeriod == null || item.amount == null) {
    return 0;
  }

  switch (item.timePeriod) {
    case TimePeriod.Unknown:
      return 0;
    case TimePeriod.Minute:
      return Math.ceil(item.amount / 60 / 24);
    case TimePeriod.Hour:
      return Math.ceil(item.amount / 24);
    case TimePeriod.Day:
      return item.amount;
    case TimePeriod.Week:
      return Math.ceil(item.amount * 7);
    case TimePeriod.Month:
      return Math.ceil(item.amount * 30);
    case TimePeriod.Quarter:
      return Math.ceil(item.amount * 90);
    case TimePeriod.HalfYear:
      return Math.ceil(item.amount * 180);
    case TimePeriod.Year:
      return Math.ceil(item.amount * 365);
    default:
      return 0;
  }
}
