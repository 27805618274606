import {
  IArticle,
  IDosage,
  IInsuranceContract,
  IOrderedArticleLine,
  IProposedArticleLine,
  IQuantityPerUnit,
  TimePeriod,
} from '@alberta/konexi-shared';
import { ignore } from 'src/app/common/tracking/tracker';
import { ArticleDto } from 'src/app/shared/models/article/article-dto.model';
import { v4 } from 'uuid';

import { DosageViewModel } from '../../general/view-models/dosage-view-model';
import { DurationViewModel } from '../../general/view-models/duration-view-model';
import { IShoppingCartMaxAmountArticleLine } from '../../shopping-cart/shopping-cart-max-amount-article-view-model';
import { IMaxAmountOrderedArticleLine } from '../../single-order/max-amount-ordered-article-line';
import { IOrderMaximumQuantity } from '../contracts/order-maximum-quantity';

export class OrderedArticleLineViewModel
  implements IOrderedArticleLine, IMaxAmountOrderedArticleLine, IShoppingCartMaxAmountArticleLine {
  dosage: IDosage;
  // Konexi Shared Attributes
  _id: string;
  salesLineId: string;
  articleId: string;
  contractID: string;

  articleCustomerNumber: string;

  unit: string;

  isRentalEquipment: boolean;
  isPrivateSale: boolean;
  notAutomaticDelivery: boolean;

  updated: Date;

  // unique attributes
  // Articlelines with an article with prescriptionType aidsForUse (5) are deactivated by default
  // deativated Articlelines won't be transferred to the Dto / Database
  active = true;
  // The quantity thats Alberta recommendends based on the dosage
  @ignore()
  recommendedQuantity: number;
  // The associated Quantity per Unit Item form the packagingId
  @ignore()
  associatedQuantityPerUnit: IQuantityPerUnit;
  // Shows if the Item wasn't in the order before the edit
  @ignore()
  isNew = false;
  // the associated Article
  @ignore()
  associatedArticle: IArticle = new ArticleDto();
  // true if the article wasn't found in Careproposal
  @ignore()
  articleNotFoundInCareProposal = false;
  // the associated Contract if existing
  @ignore()
  associatedContract: IInsuranceContract;
  // the associated Maximum for article
  @ignore()
  maxAmount: IOrderMaximumQuantity;
  // the associated Article
  @ignore()
  associatedProposedArticleLine: IProposedArticleLine;

  constructor() {
    this._id = v4();

    this.dosage = new DosageViewModel();
    this.dosage.packagingId = null;
    this.dosage.quantity = 1;
    this.dosage.duration = new DurationViewModel();
    this.dosage.duration.amount = 1;
    this.dosage.duration.timePeriod = TimePeriod.Month;

    this.notAutomaticDelivery = false;
  }
}
