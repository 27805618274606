import { Component } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'itl-pdf-box',
  templateUrl: './pdf-box.component.html',
  styleUrls: ['./pdf-box.component.scss'],
})
export class PdfBoxComponent {
  public fileName: string;
  public src: ArrayBuffer;
  public currentPage = 1;

  public get isIos() {
    return this._platform.is('ios');
  }

  constructor(public _modalCtrl: ModalController, private _platform: Platform) {}
}
