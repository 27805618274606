import { IInstitutionNote } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { assignIn } from 'lodash';
import { InstitutionNoteViewModel } from 'src/app/business/institution/view-model/institution-note-view-model';
import { InstitutionNoteDB } from 'src/app/common/repository/databases';
import { QueryService } from 'src/app/shared/services/query/query.service';

@Injectable()
export class InstitutionNoteResolver implements Resolve<IInstitutionNote> {
  constructor(private _queryService: QueryService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const institutionNote = await this._queryService.get<IInstitutionNote>(
      route.paramMap.get('noteId'),
      InstitutionNoteDB
    );

    return institutionNote != null ? assignIn(new InstitutionNoteViewModel(), institutionNote) : null;
  }
}
