import { IIntegratedCareReturnDelivery } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { IntegratedCareReturnDeliveryDB } from 'src/app/common/repository/databases';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

import { IRouteParser } from '../contracts/route/route-parser';
import { QueryService } from '../query/query.service';
import {
  MessagePayloadIntegratedCareReturnDelivery,
} from './../../models/share/message-payload-integrated-care-return-delivery';

@Injectable()
export class IntegratedCareReturnDeliveryRouteParser implements IRouteParser {
  type = MessagePayloadType.IntegratedCareReturnDelivery;

  constructor(private _queryService: QueryService) {}
  async parse(payload: MessagePayloadIntegratedCareReturnDelivery): Promise<string> {
    const returnDelivery: IIntegratedCareReturnDelivery = await this._queryService.get(
      payload.returnDeliveryId,
      IntegratedCareReturnDeliveryDB
    );

    if (!returnDelivery) {
      throw new Error(`No return delivery found for id ${payload.returnDeliveryId}`);
    } else {
      return `/patient/${returnDelivery.patientId}/integratedCare/returnDelivery/${returnDelivery._id}`;
    }
  }
}
