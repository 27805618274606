import { IIntegratedCare } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { BaseStateExtension } from '@common/state/state-extension';
import { IntegratedCareModelName } from 'src/app/shared/models/model-names';

export const PATIENT_LIST_INTEGRATED_CARE_STATE_NAME = 'PatientListIntegratedCare';
@Injectable({ providedIn: 'root' })
export class PatientListIntegratedCareStateExtension extends BaseStateExtension<IIntegratedCare> {
  name: string = IntegratedCareModelName;

  private _patientsToObserve: string[] = [];
  public set patientsToObserve(ids: string[]) {
    this._patientsToObserve = ids;
  }

  public async canBeStored(item: IIntegratedCare): Promise<boolean> {
    return this._patientsToObserve.includes(item.patientId);
  }
  protected async afterCreateInternal(item: IIntegratedCare) {
    await this.stateRegistry.createBySync(IntegratedCareModelName, PATIENT_LIST_INTEGRATED_CARE_STATE_NAME, item);
  }
  protected async afterUpdateInternal(item: IIntegratedCare) {
    await this.stateRegistry.updateBySync(IntegratedCareModelName, PATIENT_LIST_INTEGRATED_CARE_STATE_NAME, [item]);
  }
  protected async removeFromState(item: IIntegratedCare) {
    await this.stateRegistry.removeBySync(IntegratedCareModelName, PATIENT_LIST_INTEGRATED_CARE_STATE_NAME, [item]);
  }
}
