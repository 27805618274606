import { IAppointment, IInstitutionNote } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { differenceBy } from 'lodash';
import { map, take } from 'rxjs/operators';
import { InstitutionNoteDB } from 'src/app/common/repository/databases';
import { DashboardStateExtension } from 'src/app/common/state/dashboard-state-extension';
import { InstitutionNoteModelName } from 'src/app/shared/models/model-names';
import { QueryService } from 'src/app/shared/services/query/query.service';

@Injectable({ providedIn: 'root' })
export class InstitutionNoteStateExtension extends DashboardStateExtension<IInstitutionNote> {
  public get name(): string {
    return InstitutionNoteModelName;
  }

  constructor(private _queryService: QueryService) {
    super();
  }
  protected filter(appointments: IAppointment[], institutionNote: IInstitutionNote): boolean {
    if (!appointments || !appointments.length || !institutionNote) {
      return false;
    }
    const filteredAppointments = appointments.filter(
      appointment => appointment.institutionId === institutionNote.institutionId
    );

    return filteredAppointments && !!filteredAppointments.length && !institutionNote.archived;
  }

  protected onCreated(appointment: IAppointment): Promise<void> {
    return new Promise<void>(resolve => {
      // if we reload and the dashboard is not the current page - the stateRegistry is undefined!
      if (!this.stateRegistry) {
        resolve();
      }
      this.stateRegistry
        .select<IInstitutionNote[], IInstitutionNote>(InstitutionNoteModelName, 'dashboard')
        .pipe(
          take(1),
          map(institutionNotes =>
            institutionNotes.filter(institutionNote => institutionNote.institutionId === appointment.institutionId)
          )
        )
        .subscribe(async institutionNotesFromState => {
          const institutionNotesFromDb = await this._queryService.search<IInstitutionNote>(
            { query: `institutionId:${appointment.institutionId}` },
            InstitutionNoteDB
          );

          await this.afterCreate(differenceBy(institutionNotesFromDb, institutionNotesFromState, '_id'));
          resolve();
        });
    });
  }

  protected async afterCreateInternal(institutionNote: IInstitutionNote): Promise<void> {
    await this.stateRegistry.createBySync(InstitutionNoteModelName, 'dashboard', institutionNote);
  }

  protected async afterUpdateInternal(institutionNote: IInstitutionNote): Promise<void> {
    await this.stateRegistry.createBySync(InstitutionNoteModelName, 'dashboard', institutionNote);
    await this.stateRegistry.updateBySync(InstitutionNoteModelName, 'dashboard', [institutionNote]);
  }

  protected async removeFromState(institutionNote: IInstitutionNote): Promise<void> {
    await this.stateRegistry.removeBySync(InstitutionNoteModelName, 'dashboard', [institutionNote]);
  }
}
