import { InstitutionType, ISubunit } from '@alberta/konexi-shared';
import { ignore } from 'src/app/common/tracking/tracker';
import { BaseViewModel } from 'src/app/common/viewmodel/base-view-model';
import { InstitutionContactService } from 'src/app/shared/services/institution-contact.service';
import type { InstitutionContactViewModel } from './institution-contact-view-model';

export class SubunitViewModel extends BaseViewModel implements ISubunit {
  address: string;
  city: string;
  institutionType: InstitutionType;
  postalCode: string;
  email: string;
  fax: string;
  phone: string;
  annotation: string;
  name: string;
  institutionId: string;
  contactIds: string[];
  @ignore()
  contacts: InstitutionContactViewModel[] = [];

  public async loadInstitutionContacts(_institutionContactService: InstitutionContactService) {
    if (this.contacts && this.contacts.length) {
      this.contacts = [];
    }
    if (!this.contactIds || !this.contactIds.length || !_institutionContactService) {
      return;
    }

    for (const contactId of this.contactIds) {
      const institutionContactViewModel = await _institutionContactService.loadInstitutionContact(contactId);
      if (institutionContactViewModel) {
        this.contacts.push(institutionContactViewModel);
      }
    }
  }
}
