import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { IndexRepairStatusModalComponent } from './index-repair-status-modal-page';
import { AlbertaCommonComponentsModule } from '../shared/components/common/common-components.module';

@NgModule({
  imports: [CommonModule, AlbertaCommonComponentsModule, IonicModule, TranslateModule.forChild()],
  declarations: [IndexRepairStatusModalComponent],
  exports: [IndexRepairStatusModalComponent],
})
export class IndexRepairStatusModule {}
