import { IArticle, IInsuranceFixedPrice, InventoryType, IProductGroup, IQuantityPerUnit } from '@alberta/konexi-shared';
import { map } from 'src/app/common/mapper/mapper';
import { AttributeTemplateDB, ProductGroupDB } from 'src/app/common/repository/databases';
import { createObject } from 'src/app/common/tracking/object-creator';
import { BaseViewModel } from 'src/app/common/viewmodel/base-view-model';
import { QueryService } from 'src/app/shared/services/query/query.service';

import { ignore, removeTime, tracked } from '../../../common/tracking/tracker';
import { AttributeViewModel } from './attribute-view-model';

export class ArticleViewModel extends BaseViewModel implements IArticle {
  statusAnnotation: string;
  substituteArticleCustomerNumber: string;
  inventoryType: InventoryType;
  @removeTime()
  unavailableTo: Date;
  @removeTime()
  unavailableFrom: Date;
  @removeTime()
  delayedTo: Date;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  insuranceFixedPrice: IInsuranceFixedPrice[] = [];

  pcn: string;

  name: string;

  description: string;

  manufacturerNumber: string;

  customerNumber: string;

  medicalAidPositionNumber: string;

  ean: string;

  additionalDescription: string;

  manufacturer: string;

  size: string;

  assortmentType: number;

  status: number;

  productGroupId: string;

  therapyIds: number[] = [];

  billableTherapies: number[] = [];

  prescriptionType: number;
  packagingUnits: IQuantityPerUnit[] = [];

  pharmacyPrice: string;

  listPrice: string;

  fixedPrice: string;

  purchaseRating: number;
  stars: number;
  requiresSerialnumber: boolean;
  requiresBatchnumber: boolean;
  requiresValidToDate: boolean;
  resmedTenant: string;
  directOrder?: boolean;
  pharmacyOnly?: boolean;

  @ignore()
  virtualContributionMargin: number;

  @ignore()
  virtualPurchasingPrice: number;

  @ignore()
  ratingInPercent: number;

  @ignore()
  calculatedStars: number;

  @ignore() productGroup: IProductGroup;

  @ignore()
  predefinedArticleAnnotation: string;

  @tracked({ factory: createObject(AttributeViewModel) })
  attributes: AttributeViewModel[] = [];

  @ignore() disabled: boolean;

  async loadAttributesVM(querryService: QueryService) {
    if (this.productGroupId) {
      if (!this.productGroup) {
        this.productGroup = await querryService.get(this.productGroupId, ProductGroupDB);
      }

      if (
        this.productGroup &&
        this.productGroup.attributeTemplateIds &&
        this.productGroup.attributeTemplateIds.length
      ) {
        // map exisiting attributes
        this.attributes.map(attribute => {
          attribute = map(attribute, new AttributeViewModel());
          if (
            !this.productGroup.attributeTemplateIds.find(item => {
              return item === attribute.attributeTemplate._id;
            })
          ) {
            attribute.notFoundInProductGroup = true;
          }
        });

        // add Items from ProductGroup
        for (const productGroupAttributeId of this.productGroup.attributeTemplateIds) {
          if (
            !this.attributes.find(attribute => {
              return attribute.attributeTemplate._id === productGroupAttributeId;
            })
          ) {
            const attributeViewModel = new AttributeViewModel();
            attributeViewModel.attributeTemplate = await querryService.get(
              productGroupAttributeId,
              AttributeTemplateDB
            );
            attributeViewModel.value = null;
            attributeViewModel.new = true;
            this.attributes.push(attributeViewModel);
          }
        }
      }
    } else {
      this.attributes.map(attribute => {
        attribute = map(attribute, new AttributeViewModel());
        attribute.notFoundInProductGroup = true;
      });
    }
  }
}
