import { SQLiteObject } from '@ionic-enterprise/secure-storage/ngx';
import { get, isArray, xor } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { IGenericStorage, IndexHealth } from 'src/app/shared/services/contracts/database/generic-storage';
import { IIndexMetaDataInfo } from 'src/app/shared/services/contracts/database/index-metadata-info';
import { Paginated } from 'src/app/shared/services/contracts/database/paginated';

import { RawOptions } from '../contracts/repository/raw-options';
import { IndexField } from './index-field';

export class SqliteStorage implements IGenericStorage {
  constructor(
    private _dbPromise: Promise<SQLiteObject>,
    private _table: string,
    private _fields: IndexField[],
    private _serialize = item => JSON.stringify(item),
    private _deserialize = item => JSON.parse(item),
    public isDeletable: boolean,
    public canRepairIndex = true
  ) {}

  get(key: string): Promise<any> {
    return this.getItem(key);
  }

  remove(key: string): Promise<any> {
    return this.removeItem(key);
  }

  set(key: string, value: any): Promise<any> {
    return this.setItem(key, value);
  }

  async clear(): Promise<void> {
    const db = await this._dbPromise;
    await db.transaction(tx => {
      tx.executeSql(`DROP TABLE IF EXISTS [${this._table}]`, [], () => {});
      tx.executeSql(`DROP TABLE IF EXISTS [${this._table}_fts]`, [], () => {});
    });
  }
  /**
   * Defragments the whole database file.
   */
  async vacuum(): Promise<void> {
    const db = await this._dbPromise;
    // vacuum doesnt work in a transaction
    await db.executeSql(`VACUUM;`, []);
  }

  // tslint:disable-next-line:ban-types
  async forEach(iteratorCallback: (value: any, key: string, iterationNumber: Number) => any): Promise<void> {
    let index = 0;

    return new Promise(resolve => {
      this.getAll().subscribe(
        item => {
          iteratorCallback(item, item._id, index++);
        },
        error => {
          window.logger.error('Error occured during db iteration', error);
          resolve();
        },
        () => resolve()
      );
    });
  }

  /**
   * IMPORTANT: Max call stack size exceeded errors in iOS if
   * keys() returns more than 115.000 keys --> they need to be pulled in chunks.
   * That's the only reason for this function to exist.
   */
  public async keysChunked(db, chunkSize = 100000, offset = 0): Promise<string[]> {
    let results = [];
    const resultSet = await db.executeSql(`SELECT KEY FROM [${this._table}] LIMIT ${chunkSize} OFFSET ${offset}`, []);
    for (let index = 0; index < resultSet.rows.length; index++) {
      results.push(String(resultSet.rows.item(index).key));
    }
    if (resultSet.rows.length >= chunkSize) {
      results = [...results, ...(await this.keysChunked(db, chunkSize, offset + chunkSize))];
    }
    return results;
  }

  async keys(): Promise<string[]> {
    try {
      const db = await this._dbPromise;
      return await this.keysChunked(db);
    } catch (error) {
      window.logger.error('SQLite SELECT', error);
      throw new Error(`SELECT error: ${error.message}`);
    }
  }

  ready(): Promise<any> {
    return this._dbPromise;
  }

  async removeItem(key: string): Promise<any> {
    const db = await this._dbPromise;
    return db.executeSql(`DELETE FROM [${this._table}] WHERE KEY = ?;`, [key]);
  }

  async removeItems(key: string): Promise<any> {
    const db = await this._dbPromise;
    return db.executeSql(`DELETE FROM [${this._table}] WHERE KEY LIKE ${key}%`);
  }

  /** Finds the number of rows stored in this table on the device
   * @param [ofFts=false] Checks the _fts table instead of the key-value store if true
   * @returns the number of rows or zero if the (fts-)table does not exist
   * @throws SQL error if database cannot be accessed
   */
  async length(ofFts = false): Promise<number> {
    const db = await this._dbPromise;
    let resultSet;
    try {
      resultSet = await db.executeSql(`SELECT COUNT(id) as c FROM [${this._table}${ofFts ? '_fts' : ''}]`, []);
    } catch (error) {
      if (error.message.includes('no such table')) {
        return 0;
      } else {
        window.logger.error('SQLite SELECT', error);
        throw new Error(`SELECT error: ${error.message}`);
      }
    }
    return resultSet.rows.item(0).c;
  }

  async getItem(key: string): Promise<any> {
    let result;

    try {
      const db = await this._dbPromise;
      // TODO - here we die!!!!
      console.log('getItem', key);
      const resultSet = await db.executeSql(`SELECT * FROM [${this._table}] WHERE KEY = ? LIMIT 1`, [key]);
      console.log('getItem', resultSet);
      if (resultSet.rows.length) {
        result = this._deserialize(resultSet.rows.item(0).value);
      }
    } catch (error) {
      window.logger.error('SQLite SELECT', error);
      throw new Error(`SELECT error: ${error.message}`);
    }
    return result;
  }

  async getItems(keys: string[]): Promise<any[]> {
    const results = [];

    if (!keys || !keys.length) {
      return results;
    }

    try {
      const generator = this.buildChunkedStatement(keys);
      let keysTemp = generator.next();
      while (!keysTemp.done && isArray(keysTemp.value)) {
        const orderBy = keysTemp.value.map(key => `KEY="${key}" DESC`).join(',');
        const statement = `SELECT * FROM [${this._table}] WHERE KEY IN (${keysTemp.value
          .map(key => `"${key}"`)
          .join(',')}) ORDER BY ${orderBy}`;
        const db = await this._dbPromise;
        const resultSet = await db.executeSql(statement, []);

        for (let index = 0; index < resultSet.rows.length; index++) {
          results.push(this._deserialize(resultSet.rows.item(index).value));
        }
        keysTemp = generator.next();
      }
    } catch (error) {
      window.logger.error('SQLite SELECT', error);
      throw new Error(`SELECT error: ${error.message}`);
    }
    return results;
  }

  *buildChunkedStatement(keys: string[]) {
    while (keys.length) {
      yield keys.splice(0, 500);
    }
  }

  getAll(chunkSize: number = 250): Observable<any> {
    const subject = new Subject();
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      try {
        const length = await this.length();
        void this.getAllWorkload({ subject, chunkSize, offset: 0, length });
      } catch (error) {
        window.logger.error('SQLite SELECT', error);
        throw new Error(`SELECT error: ${error.message}`);
      }
    })();

    return subject.asObservable();
  }

  async getPaginated(chunkSize: number, offset: number): Promise<Paginated> {
    const subject = new Subject<any[]>();
    let total = 0;
    try {
      total = await this.length();
      void this.getAllWorkload({ subject, chunkSize, offset, length: total });
    } catch (error) {
      throw new Error(`SELECT error: ${error.message}`);
    }
    const data = await subject.toPromise();
    return { data, total };
  }

  async executeBatch(batch: any[]): Promise<any> {
    try {
      return await this._dbPromise.then(db => db.sqlBatch(batch));
    } catch (error) {
      window.logger.error('SQLite Batch', error);
    }
  }

  public async indexHealth(): Promise<IndexHealth> {
    const indexColumnNames = await this.getIndexColumnNames();
    const fieldNames = this._fields.map(field => field.name);

    const hasFieldDifferences = xor(indexColumnNames, fieldNames).length > 0;
    if (hasFieldDifferences) {
      return { healthy: false };
    }

    const ftsLength = await this.length(true);
    const tableLength = await this.length(false);
    return { healthy: ftsLength >= tableLength || fieldNames.length === 0, ftsLength, tableLength };
  }

  public readIndexFieldMetaInfo(item): IIndexMetaDataInfo {
    const result = { entries: [], fieldNames: [], bindings: '' };
    for (const field of this._fields) {
      result.fieldNames.push(field.name);
      const fieldValue = get(item, field.path);
      result.entries.push(
        typeof fieldValue === 'undefined' ? null : Array.isArray(fieldValue) ? fieldValue.join(', ') : fieldValue
      );
    }

    result.bindings = this._fields.map((key, index) => `?${index + 2}`).join(', ');

    return result;
  }

  public async repairIndex(): Promise<void> {
    if (!this._fields.length) {
      return;
    }

    try {
      await this.recreateIndexTable();
      window.logger.info(
        `[sqlite-storage][recreateIndexTable][3] Done recreating table ${this._table}_fts, now filling table`
      );
      await this.refillIndex();
      window.logger.info(`[sqlite-storage][recreateIndexTable] Done filling table ${this._table}_fts`);
    } catch (error) {
      window.logger.error(`[sqlite-storage][repairIndex] Recreate index error in table ${this._table}_fts`, error);
    }
  }

  private async refillIndex() {
    const statement = `INSERT INTO [${this._table}_fts] (id, ${this._fields.map(field => field.name).join(', ')})
        SELECT
        "key" AS id,
          ${this._fields
            .map(
              field => `CASE json_type("value", '$.${field.path}')
                  WHEN 'true' THEN 'true'
                  WHEN 'false' THEN 'false'
                  WHEN 'null' THEN 'null'
                  WHEN  NULL THEN  'null'
                  ELSE IFNULL(LOWER(CAST(json_extract("value", '$.${field.path}') AS TEXT)), 'null')
                END
                AS ${field.name}`
            )
            .join(', ')}
        FROM [${this._table}]`;

    const db = await this._dbPromise;

    window.logger.log(`[sqlite-storage][refillIndex] executing: ${statement}`);
    try {
      await db.sqlBatch([statement]);
    } catch (error) {
      window.logger.error('[sqlite-storage][refillIndex] SQLite statement failed', error);
    }
    window.logger.log(`[sqlite-storage][refillIndex] done refilling ${this._table}_fts`);
  }

  /**
   * Executes a raw SQL statement against the database.
   * Only SELECT statements are allowed.
   *
   * Attention when using FTS:
   * There are tables named e.g. default and default_fts for the entity named 'default'.
   * To get a real document from the database you have to join these tables.
   * default_fts.id => default.key => default.value
   *
   * default_fts ist the Full Text Search table and has columns for all indexed fields.
   * default has the columns key and value. Key is the document id and value is the serialized document (string).
   *
   * Example (gets ids from attachment_fts results and then loads the attachments from attachment table):
   *
   * new SQLiteQueryBuilder()
   *       .select('id')     // select only the id column from the attachment_fts table
   *       .from('attachment_fts')
   *       .where('patientId', '=', this.patient._id)
   *       .mapFtsIdsToValueTable('attachment') // maps the ids from the attachment_fts table to the attachment table
   *       .asString()
   *
   * This extracts the ids from the attachment_fts table and then unwraps the serialized document from the
   * value column of the attachment table.
   *
   *   {
   *       "id": 8266,
   *       "key": "63642ed9e77f87904bb20cfa",
   *       "value": "{\"_id\":\"63642ed9e77f87904bb20cfa\",\"some\":0,\"values\":261120\"}"
   *   } =>
   *  { _id: '63642ed9e77f87904bb20cfa', some: 0, values: 261120 }
   *
   */
  async raw({ sql }: { sql: string; options?: RawOptions }): Promise<any[]> {
    if (!sql) {
      return [];
    }
    if (sql.startsWith('SELECT') === false) {
      throw new Error('Only SELECT statements are allowed.');
    }
    return this._dbPromise.then(async db => {
      const resultSet = await db.executeSql(sql, []);
      const results = [];
      for (let index = 0; index < resultSet.rows.length; index++) {
        const dbValue = resultSet.rows.item(index);
        if (dbValue === undefined) {
          window.logger.info('dbValue undefined');
          continue;
        }
        if (dbValue.value != null && typeof dbValue.value === 'string') {
          // assuming this is the result from a _fts table query
          try {
            // try to deserialize the value column from _fts tables and return the deserialized object
            results.push(this._deserialize(dbValue.value));
          } catch (e) {
            window.logger.error('Error deserializing value', e);
          }
        } else {
          // non-fts tables are pushed to results as found in the result of the query
          results.push(dbValue);
        }
      }
      return results;
    });
  }

  private async recreateIndexTable() {
    const db = await this._dbPromise;
    const fieldNames = this._fields.map(field => field.name).join(', ');
    const dropTableStatement = `DROP TABLE IF EXISTS [${this._table}_fts];`;
    const createTableStatement = `CREATE VIRTUAL TABLE IF NOT EXISTS ${this._table}_fts USING fts4(id, ${fieldNames});`;

    // use transaction for deletion and recreation of the database
    // ohterwise, a process might try to access the database while it is being deleted
    window.logger.info(
      `[sqlite-storage][recreateIndexTable][1] Waiting for sqlite plugin to drop and create ${this._table}_fts`
    );
    await db.sqlBatch([dropTableStatement, createTableStatement]);
    window.logger.info(`[sqlite-storage][recreateIndexTable][2] sqlite plugin done ${this._table}_fts`);
  }

  private async getIndexColumnNames(): Promise<string[]> {
    const db = await this._dbPromise;
    try {
      const resultSet = await db.executeSql(
        `SELECT name, sql FROM sqlite_master WHERE type="table" AND name = "${this._table}_fts";`,
        []
      );
      return resultSet.rows
        .item(0)
        .sql.replace(/^[^\(]+\(([^\)]+)\)/g, '$1')
        .split(',')
        .map(value => value.trim())
        .filter(value => value !== 'id');
    } catch {
      // FTS does not exist
      return [];
    }
  }

  async search(query: string, params?: any[]): Promise<any> {
    const results = [];

    try {
      const db = await this._dbPromise;
      const resultSet = await db.executeSql(query, params);
      for (let index = 0; index < resultSet.rows.length; index++) {
        const dbValue = resultSet.rows.item(index).id;
        results.push(typeof dbValue === 'string' ? dbValue : this._deserialize(dbValue));
      }
    } catch (error) {
      window.logger.error('SQLite Search', error);
    }

    return results;
  }

  async setItems(itemsForDb: { items: any[]; deletable: boolean }, batch: any[]): Promise<any> {
    return this.executeBatch(batch);
  }

  async setItem(key: string, value: any): Promise<any> {
    try {
      const db = await this._dbPromise;
      await db.executeSql(`INSERT OR REPLACE INTO [${this._table}] (key, value) VALUES (?1, ?2)`, [
        key,
        this._serialize(value),
      ]);
    } catch (error) {
      window.logger.error('SQLite INSERT', error);
      throw new Error(`INSERT error: ${error.message}`);
    }
    return value;
  }

  async getAllWorkload({ subject, chunkSize, offset, length }) {
    while (length > 0) {
      const db = await this._dbPromise;
      const resultSet = await db.executeSql(`SELECT * FROM [${this._table}] LIMIT (?1) OFFSET (?2)`, [
        chunkSize,
        offset,
      ]);

      for (let index = 0; index < resultSet.rows.length; index++) {
        subject.next(this._deserialize(resultSet.rows.item(index).value));
      }

      // tslint:disable-next-line:no-parameter-reassignment
      length -= chunkSize;
      // tslint:disable-next-line:no-parameter-reassignment
      offset += chunkSize;
      if (length > 0) {
        await this.getAllWorkload({ subject, chunkSize, offset, length });
        return;
      }
    }

    if (length > 0) {
      await this.getAllWorkload({ subject, chunkSize, offset, length });
    } else {
      subject.complete();
    }
  }
}
