import { IUser } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { UsersDB } from 'src/app/common/repository/databases';

import { FieldNurse } from '../models/field-nurse';
import { UserDto } from '../models/user-management/user-dto.model';
import { AuthService } from './auth.service';
import { IGeneralEntityService } from './contracts/general-entity.service';
import { QueryService } from './query/query.service';

@Injectable({
  providedIn: 'root',
})
export class FieldNurseService implements IGeneralEntityService {
  restriction: any;
  responsibleTypes = ['fieldNurse'];
  type = 'fieldNurse';
  fieldNurses: FieldNurse[] = [];

  private loadingFieldnuresComplete: Promise<void>;

  constructor(private _authService: AuthService, private _queryService: QueryService) {
    // tslint:disable-next-line: no-floating-promises
    this.fillFieldNurses();
  }
  public isRestricted(item: any): boolean {
    return item.archived || (typeof item.active === 'boolean' && !item.active);
  }

  public clone(): IGeneralEntityService {
    return new FieldNurseService(this._authService, this._queryService);
  }

  public async find(id: string): Promise<any> {
    await this.loadingFieldnuresComplete;
    return this.fieldNurses.find(fieldNurse => fieldNurse._id === id);
  }

  public async query(query: string, excludeArchived?: boolean): Promise<any> {
    const users = [];
    const words = query.split(' ');
    const regExstring = this.buildRegEx(words);
    this.fieldNurses.forEach(element => {
      if (new RegExp(regExstring, 'i').test(element.displayText)) {
        if (element) {
          users.push(element);
        }
      }
    });
    return excludeArchived ? users.filter(user => user.active || !user.archived) : users;
  }

  public async getAll(): Promise<any[]> {
    await this.fillFieldNurses();
    return this.fieldNurses;
  }

  private async fillFieldNurses(): Promise<void> {
    this.loadingFieldnuresComplete = new Promise(async resolve => {
      const users: IUser[] = await this._queryService.getAll(UsersDB);
      this.fieldNurses = users.map(user => this.generateFieldnurse(user));
      resolve();
    });
    return this.loadingFieldnuresComplete;
  }

  private buildRegEx(words) {
    let regExString = '^';
    words.forEach(word => {
      if (word !== '') {
        regExString += '(?=.*\\bdefault)'.replace('default', word);
      }
    });
    return (regExString += '.*$');
  }

  public updateCache(user: UserDto) {
    const index = this.fieldNurses.findIndex(item => item._id === user._id);
    if (index === -1) {
      this.fieldNurses.push(this.generateFieldnurse(user));
      return;
    }
    this.fieldNurses[index] = this.generateFieldnurse(user);
  }

  private generateFieldnurse(user: UserDto) {
    return new FieldNurse(user._id, user.firstName, user.lastName, user.archived, user.active);
  }
}
