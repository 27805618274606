import { Directive, Input, TemplateRef } from '@angular/core';

import { AlbertaTableColumnMetadata } from './alberta-table-column-metadata';

@Directive({
  selector: '[albertaTableColumn]',
})
export class AlbertaTableColumnDirective {
  constructor(templateRef: TemplateRef<any>) {
    this.templateRef = templateRef;
  }

  templateRef: TemplateRef<any>;
  @Input() public albertaTableColumn: AlbertaTableColumnMetadata = { name: '', width: 0 };
}
