import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PermissionValidator } from './permission-validator.service';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(private _permissionValidator: PermissionValidator, private _router: Router) {}
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.validatePermission(state);
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.validatePermission(state);
  }

  private validatePermission(state: RouterStateSnapshot) {
    return this._permissionValidator.validate(state.url).pipe(
      tap(async hasPermission => {
        if (!hasPermission) {
          await this._router.navigate(['/dashboard']);
        }
      })
    );
  }
}
