<itl-text-input-template
  [control]="ngControl?.control"
  [icon]="icon"
  [title]="title"
  [value]="ngControl?.value"
  [maxlength]="maxlength"
  [description]="description"
  [noIcon]="noIcon"
  [disabled]="disabled"
  [required]="required"
  (focusClicked)="setFocus()"
>
  <div class="input-delete-wrapper">
    <input
      #input
      itlPhonePrefix
      [maskPhoneNumber]="type === 'tel'"
      class="alb-input"
      [ngClass]="{
        'alb-input--disabled': disabled,
        'disabled-ios': isIos && disabled,
        bold: ngControl?.value && ngControl?.value.length,
      }"
      [formControl]="this.ngControl?.control"
      [type]="type"
      [placeholder]="placeholder"
      [maxlength]="maxlength"
      [disabled]="disabled"
      [readonly]="disabled"
      [itl-inputTrimmer]="trimEnabled"
      (focus)="markAsTouched()"
      (change)="onChange($event.target.value)"
      (blur)="onTouched()"
      [attr.data-testid]="dataTestId"
    />
    <div
      class="deleteButton"
      (click)="removeSelected($event)"
      *ngIf="!disabled && ngControl?.control.value && !hideDelete"
    >
      <ion-icon name="alberta-delete"></ion-icon>
    </div>
  </div>
</itl-text-input-template>
