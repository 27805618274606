import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys', pure: false, standalone: true })
export class KeysPipe implements PipeTransform {
  transform(value): any {
    const keys = [];
    if (!value) {
      return keys;
    }
    Object.keys(value).forEach((key: any) => {
      keys.push(key);
    });
    return keys;
  }
}

/*

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, args:string[]) : any {
    let keys = [];
    for (let key in value) {
      keys.push({key: key, value: value[key]});
    }
    return keys;
  }
}

<span *ngFor="let entry of content | keys">
  Key: {{entry.key}}, value: {{entry.value}}
</span>

*/
