export const BACKGROUND = [
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/1.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/1.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/2.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/2.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/3.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/3.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/4.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/4.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/5.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/5.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/6.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/6.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/7.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/7.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/8.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/8.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/9.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/9.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/10.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/10.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/11.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/11.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/12.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/12.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/13.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/13.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/14.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/14.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/15.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/15.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/16.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/16.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/17.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/17.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/18.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/18.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/19.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/19.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/20.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/20.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/21.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/21.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/22.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/22.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/23.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/23.jpg',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/24.jpg',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/24.jpg',
  },
];
