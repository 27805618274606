import { Injectable, NgZone } from '@angular/core';
import { sortBy } from 'lodash';
import { RxDocument } from 'rxdb';
import makeDebug from 'src/makeDebug';
import { ChatSendService } from './chat-send.service';
import { ChatDbService } from './data/chat-db.service';
import { ChatMessage } from './data/db-schema';
import { Chat } from './model/chat-instance';

const debug = makeDebug('services:chat:sendqueue');

@Injectable({ providedIn: 'root' })
export class ChatSendQueueService {
  private bufferedMessages: RxDocument<ChatMessage>[] = [];

  constructor(
    private readonly _chatDb: ChatDbService,
    private readonly _chatSendService: ChatSendService,
    private readonly _ngZone: NgZone
  ) {
    this.createDeliverTimeout().catch(error =>
      window.logger.error('[ChatSendQueueService] createDeliverTimeout failed', error)
    );
  }

  private async addToSendQueue(...messageDocs: RxDocument<ChatMessage>[]) {
    this.bufferedMessages = [...messageDocs];

    await this.sendBufferedMessage();
  }

  private async sendBufferedMessage() {
    // get oldest message
    while (this.bufferedMessages.length) {
      const messageToSend = this.bufferedMessages.shift();

      if (messageToSend !== undefined) {
        let chatMessage: ChatMessage;
        try {
          chatMessage = await this._chatSendService.sendMessage(
            messageToSend.channelSid,
            messageToSend.body,
            {
              ...messageToSend.attributes,
              _id: messageToSend._id,
            },
            Chat.PatientApp
          );

          if (!chatMessage) {
            continue;
          }

          chatMessage.isLocal = true;
          // TODO: Wird die Nachricht unter Umständen mehrmals gesendet,
          /// falls eine der beiden unteren chatDb Operationen fehlschlägt?
          await this._chatDb.upsertMessage(chatMessage);
          await this._chatDb.setConsumptionIndex(chatMessage.channelSid, chatMessage.index);
        } catch (error) {
          if (
            (error.status === 404 && error.message === 'Not Found') ||
            (error.status === 403 && error.message === 'Forbidden')
          ) {
            // the conversation was deleted or I'm not part of it anymore
            // remove message from db, to not block the queue
            await this._chatDb.removeMessageByInternalId(messageToSend._id);
          } else {
            window.logger.error('[ChatSendQueueService] sendBufferedMessage failed', error);
            break;
          }
        }
      }
    }
  }

  private async createDeliverTimeout() {
    debug('starting queue timeout');
    while (true) {
      await this.initSendQueueFromDb();

      await this.sleep(1000);
    }
  }

  private async initSendQueueFromDb() {
    debug('get send queue from db');
    const currentQueueEntries = await this._chatDb.getCurrentSendQueue();

    if (currentQueueEntries?.length) {
      debug('queue', currentQueueEntries);
      const sortedByTimestamp = sortBy(currentQueueEntries, msg => new Date(msg.timestamp));

      // append send queue to buffered messages aray
      await this.addToSendQueue(...sortedByTimestamp);
    }
  }

  private sleep(ms?: number) {
    return new Promise<void>(resolve => this._ngZone.runOutsideAngular(() => setTimeout(resolve, ms || 0)));
  }
}
