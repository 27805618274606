import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resmedlabelcolor',
  pure: false,
})
export class ResmedLabelColor implements PipeTransform {
  transform(company: string): string {
    if (company === 'RMD') {
      return getComputedStyle(document.documentElement).getPropertyValue('--alberta-resmed-tenant-rmd-text').trim();
    } else if (company === 'RMT') {
      return getComputedStyle(document.documentElement).getPropertyValue('--alberta-resmed-tenant-rmt-text').trim();
    }
    return getComputedStyle(document.documentElement).getPropertyValue('--alberta-color-label-text').trim();
  }
}
