import {
  ArticleStatus,
  IArticle,
  ICareProposal,
  IExtendedArticleLine,
  IInsuranceContract,
  IProposedArticleLine,
  IQuantityPerUnit,
  IStandardCareProposal,
  PrescriptionType,
} from '@alberta/konexi-shared';
import moment from 'moment';
import { map } from 'src/app/common/mapper/mapper';
import { ArticleDB, InsuranceContractDB, StandardCareProposalDB } from 'src/app/common/repository/databases';
import { ArticleDto } from 'src/app/shared/models/article/article-dto.model';
import { IQueryService } from 'src/app/shared/services/contracts/query/query-service';
import { QueryService } from 'src/app/shared/services/query/query.service';
import type { DirectOrderViewModel } from '../direct-order/direct-order-view-model';
import type { SingleOrderViewModel } from './../single-order/single-order-view-model';
import { IOrderMaximumQuantity } from './contracts/order-maximum-quantity';
import { caluclateDurationToDays } from './duration2daysCalcuator';
import type { OrderViewModel } from './view-models/order-view-model';
import { OrderedArticleLineViewModel } from './view-models/ordered-article-line-view-model';
import type { SeriesOrderViewModel } from './view-models/series-order-viewmodel';

export interface IArticleLineError {
  articleId: string;
  name: string;
  errorType: number;
}

export function transformProposedArticleToOrderedArticle(
  proposedArticle: IProposedArticleLine,
  article: ArticleDto,
  scope: number,
  associatedContract?: IInsuranceContract,
  maxQuantity?: IOrderMaximumQuantity,
  forAddionalOrder?: boolean
): OrderedArticleLineViewModel {
  // getting Article & PackagingUnit from Article
  if (!article) {
    return null;
  }
  const packagingUnit = article.findPackagingUnit(proposedArticle.dosage.packagingId);
  if (!packagingUnit) {
    return null;
  }
  let quantity;
  if (!forAddionalOrder) {
    quantity = calculateRecommendedQuantity(packagingUnit, proposedArticle, scope);
  }
  const orderedArticleLineViewModel = new OrderedArticleLineViewModel();
  orderedArticleLineViewModel.articleId = proposedArticle.articleId;
  orderedArticleLineViewModel.associatedArticle = article;
  orderedArticleLineViewModel.articleCustomerNumber = article.customerNumber;
  orderedArticleLineViewModel.dosage.packagingId = packagingUnit._id;
  orderedArticleLineViewModel.dosage.quantity = forAddionalOrder ? 1 : quantity;
  if (!forAddionalOrder) {
    orderedArticleLineViewModel.recommendedQuantity = quantity;
  }
  orderedArticleLineViewModel.unit = packagingUnit.unit;
  orderedArticleLineViewModel.associatedQuantityPerUnit = packagingUnit;
  if (associatedContract) {
    orderedArticleLineViewModel.contractID = associatedContract._id;
    orderedArticleLineViewModel.associatedContract = associatedContract;
  }
  if (maxQuantity && !forAddionalOrder) {
    orderedArticleLineViewModel.maxAmount = maxQuantity;
    orderedArticleLineViewModel.maxAmount.currentQuantity +=
      orderedArticleLineViewModel.dosage.quantity * orderedArticleLineViewModel.associatedQuantityPerUnit.quantity;
  }
  orderedArticleLineViewModel.associatedProposedArticleLine = proposedArticle;
  if (article.prescriptionType && article.prescriptionType === PrescriptionType.AidsForUse) {
    orderedArticleLineViewModel.active = false;
  }
  return orderedArticleLineViewModel;
}

export function transformProposedArticleForSeriesOrder(
  proposedArticle: IProposedArticleLine,
  article: ArticleDto
): OrderedArticleLineViewModel {
  if (!article) {
    throw new Error('Article from proposedArticleline in careproposal not found!');
  }

  const packagingUnit = article.findPackagingUnit(proposedArticle.dosage.packagingId);
  if (!packagingUnit) {
    throw new Error('PackagingUnit from careproposal not found!');
  }
  const articleToOrder = new OrderedArticleLineViewModel();
  articleToOrder.articleId = proposedArticle.articleId;
  articleToOrder.associatedArticle = article;
  articleToOrder.associatedQuantityPerUnit = packagingUnit;
  articleToOrder.articleCustomerNumber = article.customerNumber;
  articleToOrder.dosage.packagingId = packagingUnit._id;
  articleToOrder.dosage.quantity = proposedArticle.dosage.quantity;
  articleToOrder.unit = packagingUnit.unit;
  articleToOrder.dosage.duration = proposedArticle.dosage.duration;

  if (article.prescriptionType && article.prescriptionType === PrescriptionType.AidsForUse) {
    articleToOrder.active = false;
    articleToOrder.notAutomaticDelivery = true;
  }
  return articleToOrder;
}

export function calcMaxQuanityBaseOnScope(maxQuantity: number, scope: number): number {
  if (!scope) {
    return;
  }
  return Math.ceil((maxQuantity / 30) * scope);
}

export function calculateRecommendedQuantity(
  packagingUnit: IQuantityPerUnit,
  proposedArticle: IProposedArticleLine,
  scope: number
): number {
  if (!scope) {
    return;
  }
  // calculating units at all (z.B. 5x Karton(20) -> 5x20 Einheiten = 100)
  const units = packagingUnit.quantity * proposedArticle.dosage.quantity;
  // calculating days of dosage (z.B. 2x Monat -> 2x 30.4167 = 61Tage)
  const days = caluclateDurationToDays(proposedArticle.dosage.duration);
  // calculating units per Day (z.B. 100 Einheiten / 61 Tage = 1.639)
  const unitsPerDay = units / days;
  // calculating units for the Scope (z.B. 1.639perDay * 28Tage = 40Einheiten)
  const unitsPerScope = Math.ceil(unitsPerDay * scope);
  // calculation amount of PackageingUnits (z.B. 40Einheiten / Karton(20) = 2)
  return Math.ceil(unitsPerScope / packagingUnit.quantity);
}

export function calculateRecommendedQuantityForOrder(
  packagingUnit: IQuantityPerUnit,
  extendedArticleLine: IExtendedArticleLine,
  scope: number
): number {
  if (!scope) {
    return;
  }
  // calculating units at all (z.B. 5x Karton(20) -> 5x20 Einheiten = 100)
  const units = packagingUnit.quantity * extendedArticleLine.dosage.quantity;
  // calculating days of dosage (z.B. 2x Monat -> 2x 30.4167 = 61Tage)
  const days = caluclateDurationToDays(extendedArticleLine.dosage.duration);
  // calculating units per Day (z.B. 100 Einheiten / 61 Tage = 1.639)
  const unitsPerDay = units / days;
  // calculating units for the Scope (z.B. 1.639perDay * 28Tage = 40Einheiten)
  const unitsPerScope = Math.ceil(unitsPerDay * scope);
  // calculation amount of PackageingUnits (z.B. 40Einheiten / Karton(20) = 2)
  return Math.ceil(unitsPerScope / packagingUnit.quantity);
}

/**
 * finds one proposedArticleline based on the packagingId in it
 * @param careproposal Careproposal
 * @param articleId PackagingId
 */
export function findProprosedArticleLineInCarepropsoal(
  careproposal: ICareProposal,
  articleId: string
): IProposedArticleLine {
  if (!careproposal || !careproposal.proposedArticleLines) {
    return;
  }
  return careproposal.proposedArticleLines.find(proposedArticleLine => proposedArticleLine.articleId === articleId);
}

export function getInvalidOrderLines(
  order: OrderViewModel | SeriesOrderViewModel | SingleOrderViewModel | DirectOrderViewModel,
  checkDate?: Date
): IArticleLineError[] {
  const errors: IArticleLineError[] = [];
  const dateToCheck = checkDate ? checkDate : order.deliveryDate;
  (order.orderedArticleLines || []).forEach(articleLine => {
    if (!checkIfArticleLineisOrderable(articleLine.associatedArticle, dateToCheck)) {
      errors.push({
        articleId: articleLine.articleId,
        name: articleLine.associatedArticle.name,
        errorType: 1,
      });
    }
  });
  return errors;
}

export function getInvalidDirectOrderLines(order: DirectOrderViewModel, checkDate?: Date): IArticleLineError[] {
  const errors: IArticleLineError[] = [];
  const dateToCheck = checkDate ? checkDate : order.deliveryDate;
  (order.orderedArticleLines || []).forEach(articleLine => {
    if (articleLine.quantity > 0 && !checkIfArticleLineisOrderable(articleLine.article, dateToCheck)) {
      errors.push({
        articleId: articleLine.articleId,
        name: articleLine.article.name,
        errorType: 1,
      });
    }
  });
  return errors;
}

export async function loadArticlesOfArticleLines(articleLines: { articleId: string }[], queryService: IQueryService) {
  if (!articleLines || !articleLines.length || articleLines.every(articleLine => articleLine == null)) {
    return [];
  }

  const articleIds = [
    ...new Set(
      articleLines.reduce((ids, articleLine) => {
        if (articleLine?.articleId) {
          ids.push(articleLine.articleId);
        }

        return ids;
      }, new Array<string>())
    ),
  ];
  if (queryService) {
    const articles = await queryService.search<IArticle>(
      { query: articleIds.map(articleId => `_id:${articleId}`).join(' ') },
      ArticleDB,
      { isIn: true }
    );

    return articles.map(article => map(article, new ArticleDto()));
  } else {
    return [];
  }
}

export function checkIfArticleLineisOrderable(article: IArticle, deliveryDate?: Date): boolean {
  if (
    !article ||
    article.status === ArticleStatus.Available ||
    article.status === ArticleStatus.Unknown ||
    article.status === ArticleStatus.Locked ||
    article.status === ArticleStatus.Delayed ||
    article.status == null ||
    article.unavailableFrom == null
  ) {
    return true;
  }

  if (!deliveryDate) {
    deliveryDate = new Date();
  }

  const isStillAvailable = moment(new Date(article.unavailableFrom).toDateString()).isAfter(
    new Date(deliveryDate).toDateString(),
    'days'
  );

  const isBetween = moment(deliveryDate).isBetween(
    moment(new Date(article.unavailableFrom)),
    moment(new Date(article.unavailableTo))
  );
  return !(
    (article.status === ArticleStatus.DontUse && isBetween) ||
    (article.status === ArticleStatus.NotAvailable && !isStillAvailable)
  );
}

export async function getContractAndStandardCareProposal(
  standardCareProposalId: string,
  contractId: string,
  queryService: QueryService
) {
  if (!queryService) {
    return [];
  }
  const standardCareProposalPromise = standardCareProposalId
    ? queryService.get<IStandardCareProposal>(standardCareProposalId, StandardCareProposalDB)
    : Promise.resolve<IStandardCareProposal>(null);

  const contractPromise = contractId
    ? queryService.get<IInsuranceContract>(contractId, InsuranceContractDB)
    : Promise.resolve<IInsuranceContract>(null);

  return Promise.all([contractPromise, standardCareProposalPromise]);
}
