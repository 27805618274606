import { Storage, StorageConfig } from '@ionic/storage-angular';
import { openDB } from 'idb';
import { remove as _remove } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { IGenericStorage, IndexHealth } from 'src/app/shared/services/contracts/database/generic-storage';
import { IIndexMetaDataInfo } from 'src/app/shared/services/contracts/database/index-metadata-info';
import { Paginated } from 'src/app/shared/services/contracts/database/paginated';
import LocalForage from 'localforage';
export class AlbertaStorage extends Storage implements IGenericStorage {
  private _storageConfig: StorageConfig;

  constructor(config: StorageConfig, public isDeletable: boolean, public canRepairIndex = true) {
    super(config);
    this._storageConfig = config;
  }

  public getItem(key: string): Promise<any> {
    return this.get(key);
  }

  public async getItems(keys: string[]): Promise<any[]> {
    if (keys?.length) {
      const values = await Promise.all(keys.map(key => this.get(key)));

      // remove empty values
      return values.filter(value => value !== null);
    }

    return [];
  }

  public setItem(key: string, value: any): Promise<any> {
    return this.set(key, value);
  }

  search(query: string, params?: any[]): Promise<any> {
    return;
  }
  vacuum(): Promise<void> {
    return;
  }
  executeBatch(batch: any[]): Promise<any> {
    return;
  }
  repairIndex(): void {
    return;
  }
  indexHealth(): Promise<IndexHealth> {
    return Promise.resolve({ healthy: true });
  }

  readIndexFieldMetaInfo(item): IIndexMetaDataInfo {
    return null;
  }

  public async setItems(itemsForDb: { items: any[]; deletable: boolean }): Promise<any> {
    const archived = _remove(itemsForDb.items, item => item.archived || (item.metadata && item.metadata.archived));
    if (archived?.length) {
      if (itemsForDb.deletable) {
        await Promise.all(archived.map(item => this.removeItem(item._id)));
      } else {
        itemsForDb.items.push(...archived);
      }
    }

    if (itemsForDb.items.length) {
      return Promise.all(itemsForDb.items.map(item => this.set(item._id, item)));
    }
  }

  public removeItem(key: string): Promise<any> {
    return this.remove(key);
  }

  public async removeItems(key: string): Promise<any> {
    try {
      const keys = await this.getAllKeys();

      await Promise.all(
        keys?.filter(existingKey => existingKey.includes(key)).map(existingKey => this.removeItem(existingKey))
      );
    } catch (error) {
      window.logger.error('[AlbertaStorage]: Failed to get all keys', error);
    }
  }

  public getAll(): Observable<any> {
    const subject = new Subject();
    (async () => {
      await this.forEach(value => {
        subject.next(value);
      });
      subject.complete();
    })();

    return subject.asObservable();
  }

  public ready(): Promise<LocalForage> {
    return null;
  }

  public async getAllKeys(): Promise<string[]> {
    const db = await openDB(this._storageConfig.name, 2);
    const keys = await db.getAllKeys(this._storageConfig.storeName);

    return keys.map(key => key.toString());
  }

  getPaginated(chunkSize: number, offset: number): Promise<Paginated> {
    throw new Error('Method not implemented.');
  }

  public async raw({ sql }: { sql: string }): Promise<any> {
    return;
  }
}
