import 'reflect-metadata';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import LocalForage from 'localforage';
import { extendPrototype as extendWithGetItems } from 'localforage-getitems';
import { extendPrototype as extendWithSetItems } from 'localforage-setitems';

import { AppModule } from './app/app.module';
import { ILogger } from './app/common/contracts/logging/logger';
import { environment } from './environments/environment';

import 'hammerjs';

declare global {
  interface Window {
    logger: ILogger;
  }
}

extendWithSetItems(LocalForage);
extendWithGetItems(LocalForage);

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
bootstrap().catch(err => console.error(err));
