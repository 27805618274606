import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NursingServiceDto } from 'src/app/shared/models/institution/nursing-service-dto.model';

import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'nursing-service-list-item',
  templateUrl: 'nursing-service-list-item.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./../autocomplete-list-item.scss'],
})
export class NursingServiceListItem implements IListView {
  @Input() item: NursingServiceDto;
  @Output() clicked: EventEmitter<NursingServiceDto> = new EventEmitter<NursingServiceDto>();

  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
