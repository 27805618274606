import { Subject } from 'rxjs';

import { IChangeTrack } from '../contracts/tracking/change-track';
import { IChangeTracker } from '../contracts/tracking/change-tracker';
import { INotifyInfo } from './notify-info';

export class ChangeTracker implements IChangeTracker {
  private _changes = new Map<string, IChangeTrack>();
  private _suspend: boolean;
  private _modifyListeners: Subject<INotifyInfo>[] = [];

  get listeners(): Subject<INotifyInfo>[] {
    return this._modifyListeners;
  }

  get isSuspended(): boolean {
    return this._suspend;
  }

  public hasProperty(propertyName: string) {
    return this._changes.has(propertyName);
  }

  // tslint:disable-next-line:function-name
  [Symbol.iterator]() {
    return this._changes.entries();
  }

  public suspend() {
    this._suspend = true;
  }

  public resume() {
    this._suspend = false;
  }

  public set(key: string, changeTrack: IChangeTrack): void {
    if (this._changes.has(key)) {
      return;
    }

    this._changes.set(key, changeTrack);
  }

  public get(key: string): IChangeTrack {
    if (this._changes.has(key)) {
      return this._changes.get(key);
    }
    return undefined;
  }
}
