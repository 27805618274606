<ion-content class="ion-text-center main-content">
  <div class="centered-content">
    <div class="logo-container ion-padding-top">
      <img #logo />
    </div>
    <div class="login-hint-header bold">
      <span> {{ 'unlock.title' | translate }}</span>
    </div>

    <div class="person-wrapper">
      <itl-alberta-icon class="person-icon" name="alberta-person"></itl-alberta-icon>
      <span class="user-mail"> {{ authService?.authentication?.account?.email }}</span>
    </div>
    <div class="buttons">
      <div class="button-wrapper">
        <itl-button-filled class="stretch-button" (click)="unlockClicked()"
          ><itl-alberta-icon class="lock-icon" name="alberta-unlock"></itl-alberta-icon> {{ 'unlock.unlockButton' |
          translate }}</itl-button-filled
        >

        <itl-button-borderless class="stretch-button" (click)="logoutClicked()"
          >{{ 'unlock.logoutButton' | translate }}</itl-button-borderless
        >
      </div>
    </div>
  </div>
</ion-content>
