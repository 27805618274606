import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HospitalDto } from 'src/app/shared/models/institution/hospital-dto.model';

import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'hospital-list-item',
  templateUrl: 'hospital-list-item.html',
  styleUrls: ['./../autocomplete-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HospitalListItem implements IListView {
  @Input() item: HospitalDto;
  @Output() clicked: EventEmitter<HospitalDto> = new EventEmitter<HospitalDto>();

  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
