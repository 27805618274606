import { Inject, Injectable, NgZone } from '@angular/core';
import { PatientAppUserWorkItem } from 'src/app/business/patient-app-user/patient-app-user-work-item';
import {
  AuthenticationToken,
  IAuthenticationAccount,
} from 'src/app/common/contracts/authentication/authentication-account';
import { ICancellationToken } from 'src/app/common/contracts/cancellation/cancellation-token';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { AppController } from 'src/app/common/controller/app-controller';
import { Deferred } from 'src/app/common/deferred/deferred';

import { PatientAppUserModelName } from '../../models/model-names';
import { IPatientAppUser } from '../../models/patient-app/patient-app-user';
import { PatientAppUserSynchronizer } from '../patient-app/patient-app-user-synchronizer';
import { WebSyncService } from './web-sync.service';

@Injectable()
export class WebPatientAppUserSyncService extends WebSyncService {
  private _ready = new Deferred<void>();
  constructor(
    private _patientAppUserWorkItem: PatientAppUserWorkItem,
    @Inject(AppController) appController: IAppController,
    private _patientAppUserSynchronizer: PatientAppUserSynchronizer,
    ngZone: NgZone,
    @Inject(AuthenticationToken) private _authenticationAccount: IAuthenticationAccount
  ) {
    super(PatientAppUserModelName, ngZone, appController);
    this._authenticationAccount.ready
      .then(() => {
        this._ready.resolve();
      })
      .catch(error =>
        window.logger.error('[WebPatientAppUserSyncService] failed to load authentication account', error)
      );
  }

  protected async handleData(patientAppUsers: IPatientAppUser[], token: ICancellationToken): Promise<any> {
    const syncState = await this._patientAppUserWorkItem.sync(patientAppUsers, token);

    await this._patientAppUserSynchronizer.synchronize(patientAppUsers);

    return [...syncState.created, ...syncState.deleted, ...syncState.updated];
  }
}
