import { NgModule } from '@angular/core';

import { AttachmentTypePipe } from './attachmentType-pipe';
import { BooleanYeTureFalsePipe } from './booleanTureFalse-pipe';
import { BooleanYesNoPipe } from './booleanYesNo-pipe';
import { CapitalizationPipe } from './capitalization-pipe';
import { CareTypePipe } from './caretype-pipe';
import { ChangeTypePipe } from './changeType-pipe';
import { DurationPipe } from './duration-pipe';
import { KeysPipe } from './keys-pipe';
import { NotArchivedPipe } from './not-archived-pipe';
import { Number2MonthPipe } from './numberToMonth-pipe';
import { ResmedLabelColor } from './resmed-label-color.pipe';
import { ReversePipe } from './reverse-pipe';
import { TimePeriodPipe } from './timePeriod-pipe';

@NgModule({
  imports: [KeysPipe, AttachmentTypePipe],
  declarations: [
    BooleanYesNoPipe,
    CareTypePipe,
    DurationPipe,
    TimePeriodPipe,
    Number2MonthPipe,
    ReversePipe,
    ChangeTypePipe,
    BooleanYeTureFalsePipe,
    NotArchivedPipe,
    ResmedLabelColor,
    CapitalizationPipe,
  ],
  exports: [
    AttachmentTypePipe,
    BooleanYesNoPipe,
    CareTypePipe,
    DurationPipe,
    TimePeriodPipe,
    Number2MonthPipe,
    KeysPipe,
    ReversePipe,
    ChangeTypePipe,
    BooleanYeTureFalsePipe,
    NotArchivedPipe,
    ResmedLabelColor,
    CapitalizationPipe,
  ],
})
export class GeneralPipesModule {}
