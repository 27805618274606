import { IMaintenance } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { sortBy } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IMaintenanceService } from 'src/app/business/maintenance/contracts/maintenance';
import { MaintenanceEnvironments } from 'src/app/business/maintenance/maintenance-environments';
import { MaintenanceDB } from 'src/app/common/repository/databases';

import makeDebug from '../../../../makeDebug';
import { map as mapper } from '../../../common/mapper/mapper';
import { MaintenanceDto } from '../../models/maintenance/maintenance-dto.model';
import { AuthService } from '../auth.service';
import { IQueryService } from '../contracts/query/query-service';
import { EnvironmentService } from '../environment/environment.service';
import { QueryService } from '../query/query.service';

const debug = makeDebug('maintenance:maintenance-service');

@Injectable({ providedIn: 'root' })
export class MaintenanceService implements IMaintenanceService {
  private _startWatch$ = new Subject();
  private _maintenanceWatch$ = new BehaviorSubject<IMaintenance>(null);
  private _endCountdownTimeout: NodeJS.Timeout;
  private _watchSubscription: Subscription;

  get maintenanceWatch(): Observable<IMaintenance> {
    return this._maintenanceWatch$.asObservable();
  }

  constructor(
    private _ngZone: NgZone,
    @Inject(QueryService) private _queryService: IQueryService,
    private _authService: AuthService,
    private _environmentService: EnvironmentService
  ) {
    this.startWatch();
  }

  public async startWatchingMaintenance() {
    debug('triggering next maintenance watch');
    await this._authService.init;

    this._startWatch$.next(null);
  }

  private isRelevantMaintenance(maintenace: IMaintenance) {
    if (maintenace.archived) {
      return false;
    }

    if (
      this._environmentService.isMdrBranch() === false &&
      maintenace.environment === MaintenanceEnvironments.Alberta
    ) {
      return true;
    } else if (
      this._environmentService.isMdrBranch() === true &&
      maintenace.environment === MaintenanceEnvironments.Mdr
    ) {
      return true;
    } else {
      return false;
    }
  }

  private startWatch() {
    if (this._watchSubscription != null) {
      this._watchSubscription.unsubscribe();
    }
    this._watchSubscription = this._startWatch$
      .pipe(
        switchMap(() => this._queryService.getAll<IMaintenance>(MaintenanceDB)),
        map(items => items.filter(item => this.isRelevantMaintenance(item))),
        map(items => sortBy(items, ['start'])),
        map(items => items[items.length - 1])
      )
      .subscribe((maintenance: IMaintenance) => {
        if (maintenance) {
          debug('next maintenance', maintenance);
          this._maintenanceWatch$.next(mapper(maintenance, new MaintenanceDto()));
          this.startTimeoutByEndOfMaintenance(maintenance);
        } else {
          debug('next null maintenance');
          this._maintenanceWatch$.next(null);
        }
      });
  }

  private startTimeoutByEndOfMaintenance(maintenance: IMaintenance) {
    global.clearTimeout(this._endCountdownTimeout);

    this._ngZone.runOutsideAngular(() => {
      const diff = moment(maintenance.end).diff(moment(), 'ms', true);
      if (diff <= 0) {
        debug('end date before now');
        return;
      }
      this._endCountdownTimeout = global.setTimeout(() => {
        debug('next null maintenance');
        this._maintenanceWatch$.next(null);
      }, diff);
    });
  }
}
