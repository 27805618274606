import {
  IAudit,
  ICareProposal,
  IDevice,
  IErpOrder,
  IIntegratedCare,
  IModel,
  IOrder,
  IPatient,
  IPatientNote,
  IReturnDelivery,
  ISingleOrder,
} from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';
import { AppController } from 'src/app/common/controller/app-controller';
import {
  AttachmentDB,
  AuditDB,
  CareProposalDB,
  DeviceDB,
  ErpOrderDB,
  IntegratedCareDB,
  OrderDB,
  PatientDB,
  PatientNotesDB,
  ReturnDeliveryDB,
  SingleOrderDB,
} from 'src/app/common/repository/databases';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';
import { IDataRemoval } from 'src/app/shared/services/contracts/data/data-removal';
import { QueryService } from 'src/app/shared/services/query/query.service';

@Injectable()
export class PatientRemovalService implements IDataRemoval {
  constructor(private _queryService: QueryService, private _appController: AppController) {}

  public async clean(): Promise<void> {
    const patientsToRemove = localStorage.getItem('patientsToRemove');
    if (!patientsToRemove) {
      return;
    }

    const patientIds = patientsToRemove.split(',');
    await this.removePatientData(patientIds);

    localStorage.setItem('patientsToRemove', patientIds && patientIds.length ? patientIds.join(',') : '');
  }

  private async removePatientData(patientIds: string[]) {
    for (let index = patientIds.length - 1; index >= 0; index--) {
      const patient = await this._queryService.get<IPatient>(patientIds[index], PatientDB);

      if (!patient) {
        patientIds.pop();
        continue;
      }

      if (moment(patient.validToDate).diff(moment(), 'millisecond', true) > 0) {
        continue;
      }

      const workItem: IWorkItem<IPatient> = this._appController.getWorkItemByCtor(
        'PatientWorkItem'
      ) as IWorkItem<IPatient>;
      await workItem.hardDelete(patient._id);
      await this.removePatientRelations(patient._id);

      patientIds.pop();
    }
  }

  private async removePatientRelations(patientId: string): Promise<void> {
    await Promise.all([
      this.removePatientRelation<IOrder>(patientId, 'OrderWorkItem', OrderDB),
      this.removePatientRelation<ICareProposal>(patientId, 'CareProposalWorkItem', CareProposalDB),
      this.removePatientRelation<IReturnDelivery>(patientId, 'ReturnDeliveryWorkItem', ReturnDeliveryDB),
      this.removePatientRelation<IAudit>(patientId, 'AuditWorkItem', AuditDB),
      this.removePatientRelation<AttachmentDto>(patientId, 'AttachmentWorkItem', AttachmentDB),
      this.removePatientRelation<IIntegratedCare>(patientId, 'IntegratedCareWorkItem', IntegratedCareDB),
      this.removePatientRelation<ISingleOrder>(patientId, 'SingleOrderWorkItem', SingleOrderDB),
      this.removePatientRelation<IDevice>(patientId, 'DeviceWorkItem', DeviceDB),
      this.removePatientRelation<IErpOrder>(patientId, 'ErpOrderWorkItem', ErpOrderDB),
    ]);
  }

  private async removePatientRelation<T extends IModel>(
    patientId: string,
    workItemName: string,
    databaseName: string
  ): Promise<void> {
    const relations = await this._queryService.search({ query: `patientId:${patientId}` }, databaseName);
    if (!relations || !relations.length) {
      return;
    }

    const workItem: IWorkItem<T> = this._appController.getWorkItemByCtor(`${workItemName}`) as IWorkItem<T>;

    await Promise.all(relations.map(relation => workItem.hardDelete(relation._id)));
  }
}
