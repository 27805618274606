import { IModel } from '@alberta/konexi-shared';
import { takeWhile } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { IDatabaseService } from 'src/app/shared/services/contracts/database/database-service';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { IFeathersAppProvider } from 'src/app/shared/services/contracts/sync/feathers-app-provider';

import { DBPlugin } from '@common/repository/index-plugins';
import { IDbQueryStrategy } from '../contracts/repository/db-query-strategy';
import { IRepository } from '../contracts/repository/repository';
import { IPlatformSync } from '../contracts/sync/platform-sync';
import { IUnitOfWork } from '../contracts/unit-of-work/unit-of-work';
import { Deferred } from '../deferred/deferred';
import {
  AppointmentDB,
  AttachmentDB,
  AuditDB,
  CareProposalDB,
  DeviceDB,
  DirectOrderDB,
  DoctorDB,
  ErpOrderDB,
  ErpTaskDB,
  HospitalDB,
  IntegratedCareDB,
  IntegratedCareReturnDeliveryDB,
  NursingHomeDB,
  NursingServiceDB,
  OrderDB,
  PatientAppUserChannelDB,
  PatientAppUserDB,
  PatientDB,
  PatientNotesDB,
  PharmacyDB,
  ReturnDeliveryDB,
  ServiceCostEstimateDB,
  ServiceOrderDB,
  ServiceTaskDB,
  SingleOrderDB,
  SyncTimestampDB,
} from '../repository/databases';
import { Repository as DesktopRepository } from '../repository/desktop-repository';
import { LocalQueryStrategy } from '../repository/local-query-strategy';
import { RemoteQueryStrategy } from '../repository/remote-query-strategy';
import { Repository } from '../repository/repository';

const ONLINE_SEARCHABLE_ENTITIES = [
  PatientDB,
  PatientNotesDB,
  OrderDB,
  ErpOrderDB,
  ErpTaskDB,
  CareProposalDB,
  AuditDB,
  AttachmentDB,
  ReturnDeliveryDB,
  DoctorDB,
  HospitalDB,
  NursingServiceDB,
  NursingHomeDB,
  IntegratedCareDB,
  SingleOrderDB,
  DirectOrderDB,
  DeviceDB,
  IntegratedCareReturnDeliveryDB,
  PharmacyDB,
  ServiceTaskDB,
  ServiceOrderDB,
  ServiceCostEstimateDB,
  AppointmentDB,
  ServiceOrderDB,
  ServiceTaskDB,
  // [schematics searchable entities array marker create-entity]
];

export class UnitOfWork implements IUnitOfWork {
  private _ready: Deferred<void> = new Deferred();
  get ready(): Promise<void> {
    return this._ready.promise;
  }

  constructor(
    private _dbProvider: IDatabaseService,
    private _repositories: Record<string, IRepository<any>>,
    private _plugins: Record<string, DBPlugin>,
    private _storageFactory: (storage: IGenericStorage) => Promise<IGenericStorage>,
    private _authenticationService: AuthService,
    private _feathersAppProvider: IFeathersAppProvider,
    private _platformSync: IPlatformSync
  ) {
    this.init();
  }

  private init() {
    this._authenticationService.authenticatedEventPublisher
      .pipe(takeWhile(authEventData => !authEventData.isAuthenticated))
      .subscribe({
        complete: async () => {
          try {
            for (const metadata of await this._dbProvider.databaseNames) {
              this._repositories[metadata.databaseName] = await this.createRepository(metadata.databaseName);
              if (ONLINE_SEARCHABLE_ENTITIES.includes(metadata.databaseName) && this._platformSync.isCordova) {
                this._repositories[`${metadata.databaseName}::online`] = await this.createOnlineRepository(
                  metadata.databaseName
                );
              }
            }
          } catch (error) {
            window.logger.error('UNITOFWORK INIT()', error);
          }
          this._ready.resolve();
        },
      });
  }

  async create<T extends IModel>(databaseName: string, ignoreOnlineSearch?: boolean): Promise<IRepository<T>> {
    await this._ready.promise;

    const isOnlineSearch = databaseName.endsWith('::online');
    const databaseNameCopy = this.removeSuffixWhenRequired(ignoreOnlineSearch, isOnlineSearch, databaseName);
    if (this.canUseRegularExistingRepository(isOnlineSearch, databaseNameCopy)) {
      return this._repositories[databaseNameCopy];
    }

    if (isOnlineSearch && !this._platformSync.isCordova) {
      const repository = await this.createOnlineRepository(databaseName.substring(0, databaseName.indexOf(':')));
      this._repositories[databaseName] = repository;

      return repository as IRepository<T>;
    } else if (!isOnlineSearch && this._platformSync.isCordova) {
      const repository = await this.createRepository(databaseName);
      this._repositories[databaseName] = repository;

      return repository;
    }
  }

  private removeSuffixWhenRequired(ignoreOnlineSearch: boolean, isOnlineSearch: boolean, databaseName: string) {
    // remove suffix ::online from database name
    // for getting default repository when searching
    // on device for (metadata.auditId OR metadata.templateId)
    return ignoreOnlineSearch && isOnlineSearch ? databaseName.substring(0, databaseName.indexOf(':')) : databaseName;
  }

  private canUseRegularExistingRepository(isOnlineSearch: boolean, databaseName: string) {
    return (
      (this._platformSync.isCordova || (!isOnlineSearch && !this._platformSync.isCordova)) &&
      this._repositories[databaseName]
    );
  }

  private async createRepository(databaseName: string): Promise<IRepository<any>> {
    let repository: IRepository<any>;
    if (this._platformSync.isCordova) {
      repository = new Repository(
        await this._storageFactory(await this._dbProvider.getDatabase(databaseName)),
        databaseName,
        this._plugins
      );
    } else {
      repository = await this.createDesktopRepository(databaseName);
    }

    return repository;
  }

  private async createOnlineRepository(databaseName: string): Promise<IRepository<IModel>> {
    const storage = await this._storageFactory(await this._dbProvider.getDatabase(databaseName));
    const remoteQueryStrategy = new RemoteQueryStrategy(
      databaseName,
      storage,
      this._feathersAppProvider,
      this._authenticationService.authentication.account,
      this._plugins
    );

    return new DesktopRepository(remoteQueryStrategy);
  }

  private async createDesktopRepository(databaseName: string) {
    const storage = await this._storageFactory(await this._dbProvider.getDatabase(databaseName));
    const localQueryStrategy = new LocalQueryStrategy(
      databaseName,
      storage,
      this._feathersAppProvider,
      this._authenticationService.authentication.account,
      this._plugins
    );
    const remoteQueryStrategy = new RemoteQueryStrategy(
      databaseName,
      storage,
      this._feathersAppProvider,
      this._authenticationService.authentication.account,
      this._plugins
    );

    this.bindQueryFunctions(databaseName, localQueryStrategy, remoteQueryStrategy);

    return new DesktopRepository(this._platformSync.canBeSynced ? localQueryStrategy : remoteQueryStrategy);
  }

  private bindQueryFunctions(
    databaseName: string,
    localQueryStrategy: IDbQueryStrategy<IModel>,
    remoteQueryStrategy: IDbQueryStrategy<IModel>
  ) {
    if (this._platformSync.canBeSynced) {
      return;
    }

    if (databaseName === SyncTimestampDB) {
      remoteQueryStrategy.getAll = localQueryStrategy.getAll.bind(localQueryStrategy);
      remoteQueryStrategy.get = localQueryStrategy.get.bind(localQueryStrategy);
    }

    if (databaseName === PatientAppUserDB || databaseName === PatientAppUserChannelDB) {
      remoteQueryStrategy.getAll = localQueryStrategy.getAll.bind(localQueryStrategy);
      remoteQueryStrategy.get = localQueryStrategy.get.bind(localQueryStrategy);
      remoteQueryStrategy.getItems = localQueryStrategy.getItems.bind(localQueryStrategy);
      remoteQueryStrategy.search = localQueryStrategy.search.bind(localQueryStrategy);
    }
  }
}
