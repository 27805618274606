<div class="close-button">
  <ion-icon name="alberta-cancel" tappable (click)="dismissSearchView()" *ngIf="showCancel"></ion-icon>
</div>
<div class="search-wrapper">
  <div class="search-content">
    <form (ngSubmit)="startSearch()">
      <div #content></div>
      <input type="submit" value="submit" id="submitButton" />
    </form>
  </div>
  <div class="confirm-button-wrapper">
    <div class="confirm-button" tappable (click)="startSearch()">
      {{ 'component.search-modal.search' | translate }}
    </div>
    <div class="confirm-button-line"></div>
  </div>
</div>
