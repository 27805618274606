import { PackagingUnitType } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'articleUnitPipe',
  pure: true,
})
export class ArticleUnitPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(item: PackagingUnitType | string): string {
    let unit = '';
    switch (item) {
      case PackagingUnitType.piece:
        unit = this._translate.instant('article.edit.units.stk');
        break;
      case PackagingUnitType.carton:
        unit = this._translate.instant('article.edit.units.ktn');
        break;
    }
    return unit;
  }
}
