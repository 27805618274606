import { Inject, Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { FaxDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { FaxDto } from 'src/app/shared/models/fax/fax-dto.model';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class FaxWorkItem extends WorkItem<FaxDto> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }

  unitOfWork: IUnitOfWork;
  get name(): string {
    return 'FaxWorkItem';
  }
  get database(): string {
    return FaxDB;
  }

  public async beforeCreate(fax: FaxDto): Promise<FaxDto> {
    // don´t touch fax
    // otherwise binary data in command is lost
    const clonedFax = cloneDeep(fax);
    delete clonedFax.binaryData;

    return clonedFax;
  }
}
