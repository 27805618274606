import { Therapy, TherapyType } from '@alberta/konexi-shared';

import { TherapyItem } from './therapy-item';
import { TherapyTypeItem } from './therapy-type';

export class Therapies {
  static therapyItems: TherapyItem[] = [
    new TherapyItem(Therapy.NN, 'Unbekannt', 'N.N.', 'alberta-general', '#3B507A', '#B4BED2', []),
    new TherapyItem(Therapy.PE, 'Parenterale Ernährung', 'PE', 'alberta-parenteral-feeding', '#00CF00', '#BEF2BE', [
      new TherapyTypeItem(TherapyType.Schwerkraft_PE, 'Schwerkraft'),
      new TherapyTypeItem(TherapyType.Pumpe_immobil_PE, 'Pumpe immobil'),
      new TherapyTypeItem(TherapyType.Pumpe_mobil_PE, 'Pumpe mobil'),
      new TherapyTypeItem(TherapyType.Compounding, 'Compounding'),
      new TherapyTypeItem(TherapyType.PE_Kinder, 'PE Kinder'),
      new TherapyTypeItem(TherapyType.Pumpe_PE, 'Pumpe'),
      new TherapyTypeItem(TherapyType.Fertig_PE, 'Fertig-PE'),
      new TherapyTypeItem(TherapyType.HPE_Dreikammerbeutel_PE, 'HPE Dreikammerbeutel'),
      new TherapyTypeItem(TherapyType.Sonstiges_PE, 'Sonstiges'),
      new TherapyTypeItem(TherapyType.Pumpe_Sapphire_PE, 'Pumpe Sapphire'),
      new TherapyTypeItem(TherapyType.Pumpe_Bodyguard_PE, 'Pumpe Bodyguard'),
      new TherapyTypeItem(TherapyType.Pumpe_Cadd_Solis_Vip_PE, 'Pumpe CADD Solis VIP'),
      new TherapyTypeItem(TherapyType.Parenterale_Ernaehrung_PE, 'Parenterale Ernährung'),
    ]),
    new TherapyItem(Therapy.EE, 'Enterale Ernährung', 'EE', 'alberta-enteral-nutrition', '#FFD014', '#FFEEAE', [
      new TherapyTypeItem(TherapyType.Schwerkraft_EE, 'Schwerkraft'),
      new TherapyTypeItem(TherapyType.Pumpe_immobil_EE, 'Pumpe immobil'),
      new TherapyTypeItem(TherapyType.Pumpe_mobil_EE, 'Pumpe mobil'),
      new TherapyTypeItem(TherapyType.Bolus, 'Bolus'),
      new TherapyTypeItem(TherapyType.Trinknahrung, 'Trinknahrung'),
      new TherapyTypeItem(TherapyType.EE_Kinder, 'EE Kinder'),
      new TherapyTypeItem(TherapyType.EE_Technik, 'EE Technik'),
      new TherapyTypeItem(TherapyType.EE_Kinder_Sonde, 'EE Kinder Sonde'),
      new TherapyTypeItem(TherapyType.EE_Kinder_Trink, 'EE Kinder Trink'),
      new TherapyTypeItem(TherapyType.Pumpe_EE, 'Pumpe'),
      new TherapyTypeItem(TherapyType.Pumpe_immobil_K4, 'Pumpe immobil K4'),
      new TherapyTypeItem(TherapyType.EE_Verband, 'EE Verband'),
      new TherapyTypeItem(TherapyType.Sonstiges_EE, 'Sonstiges'),
      new TherapyTypeItem(TherapyType.Nahrung_EE, 'Nahrung'),
      new TherapyTypeItem(TherapyType.Sondennahrung_EE, 'Sondennahrung'),
    ]),
    new TherapyItem(Therapy.STO, 'Stoma', 'STO', 'alberta-enterostomy', '#7700FF', '#D2ACFF', [
      new TherapyTypeItem(TherapyType.Kolostoma, 'Kolostoma'),
      new TherapyTypeItem(TherapyType.Urostoma, 'Urostoma'),
      new TherapyTypeItem(TherapyType.Ileostoma, 'Ileostoma'),
      new TherapyTypeItem(TherapyType.Fistel, 'Fistel'),
      new TherapyTypeItem(TherapyType.Transkutane_Harnleiterfistel, 'Transkutane Harnleiterfistel'),
      new TherapyTypeItem(TherapyType.MehrfachStoma, 'Mehrfachstoma'),
      new TherapyTypeItem(TherapyType.Pouch, 'Pouch'),
      new TherapyTypeItem(TherapyType.Stomairrigation, 'Stomairrigation'),
      new TherapyTypeItem(TherapyType.Sonstiges_STO, 'Sonstiges'),
    ]),
    new TherapyItem(Therapy.TS, 'Tracheostoma', 'TS', 'alberta-tracheostomy', '#0080FF', '#A6D2FF', [
      new TherapyTypeItem(TherapyType.Tracheostoma_beatmet, 'Tracheostoma beatmet'),
      new TherapyTypeItem(TherapyType.Laryngektomie_beatmet, 'Laryngektomie beatmet'),
      new TherapyTypeItem(TherapyType.Tracheostoma_unbeatmet, 'Tracheostoma unbeatmet'),
      new TherapyTypeItem(TherapyType.Laryngektomie_unbeatmet, 'Laryngektomie unbeatmet'),
      new TherapyTypeItem(TherapyType.Absaugung_außerhalb_von_TS, 'Absaugung außerhalb von TS'),
      new TherapyTypeItem(TherapyType.Wachkoma, 'Wachkoma'),
      new TherapyTypeItem(TherapyType.Laryngektomie, 'Laryngektomie'),
      new TherapyTypeItem(TherapyType.Inhalation_obere_Atemwege, 'Inhalation obere Atemwege'),
      new TherapyTypeItem(TherapyType.Inhalation_unterer_Atemwege, 'Inhalation unterer Atemwege'),
      new TherapyTypeItem(TherapyType.Absaugung_endotracheal, 'Absaugung endotracheal'),
      new TherapyTypeItem(TherapyType.Absaugung_oral, 'Absaugung oral'),
      new TherapyTypeItem(TherapyType.Tracheostoma, 'Tracheostoma'),
      new TherapyTypeItem(TherapyType.Absaugung, 'Absaugung'),
      new TherapyTypeItem(TherapyType.Inhalation, 'Inhalation'),
      new TherapyTypeItem(TherapyType.Sonstiges_TS, 'Sonstiges'),
      new TherapyTypeItem(TherapyType.Tracheostoma_geblockt_unbeatmet, 'Tracheostoma geblockt unbeatmet'),
      new TherapyTypeItem(TherapyType.Tracheostoma_geblockt_beatmet, 'Tracheostoma geblockt beatmet'),
      new TherapyTypeItem(TherapyType.Tracheostoma_ungeblockt_unbeatmet, 'Tracheostoma ungeblockt unbeatmet'),
      new TherapyTypeItem(TherapyType.Tracheostoma_ungeblockt_beatmet, 'Tracheostoma ungeblockt beatmet'),
    ]),
    new TherapyItem(Therapy.INKO, 'Inkontinenz', 'INKO', 'alberta-incontinence', '#00DFCB', '#C0FAF4', [
      new TherapyTypeItem(TherapyType.Inkontinenz_ableitend, 'Inkontinenz ableitend'),
      new TherapyTypeItem(TherapyType.Inkontinenz_aufsaugend, 'Inkontinenz aufsaugend'),
      new TherapyTypeItem(TherapyType.ISK_FSK, 'ISK / FSK'),
      new TherapyTypeItem(TherapyType.Anale_Irrigation, 'Anale Irrigation'),
      new TherapyTypeItem(TherapyType.Pessarversorgung, 'Pessarversorgung'),
      new TherapyTypeItem(TherapyType.Fistel, 'Fistel'),
      new TherapyTypeItem(TherapyType.Katheter_suprapubisch_INKO, 'Katheter suprapubisch'),
      new TherapyTypeItem(TherapyType.Katheter_transurethral_INKO, 'Katheter transurethral'),
      new TherapyTypeItem(TherapyType.Kondomurinale_INKO, 'Kondomurinale'),
      new TherapyTypeItem(TherapyType.Katheterwechsel_INKO, 'Katheterwechsel'),
      new TherapyTypeItem(TherapyType.Sonstiges_INKO, 'Sonstiges'),
      new TherapyTypeItem(TherapyType.Stuhlinko_transanale_Irrigation_INKO, 'Stuhlinkontinenz/Transanale Irrigation'),
      new TherapyTypeItem(TherapyType.Blasenverweilkatheter_INKO, 'Blasenverweilkatheter'),
      new TherapyTypeItem(TherapyType.Urinalkondomversorgung_INKO, 'Urinalkondomversorgung'),
      new TherapyTypeItem(TherapyType.Nierenfistel_links_INKO, 'Nierenfistel links'),
      new TherapyTypeItem(TherapyType.Nierenfistel_rechts_INKO, 'Nierenfistel rechts'),
      new TherapyTypeItem(TherapyType.Inkontinenz_ableitend_immobil_INKO, 'Inkontinenz ableitend immobil'),
      new TherapyTypeItem(TherapyType.Inkontinenz_ableitend_mobil_INKO, 'Inkontinenz ableitend mobil'),
      new TherapyTypeItem(TherapyType.Biofeedbackgeraete_15_INKO, 'Biofeedbackgeräte 15'),
    ]),
    new TherapyItem(Therapy.WV, 'Wundversorgung', 'WV', 'alberta-wound', '#AE00A2', '#E1A6DF', [
      new TherapyTypeItem(TherapyType.Ulcus_cruris, 'Ulcus cruris'),
      new TherapyTypeItem(TherapyType.Dekubitus, 'Dekubitus'),
      new TherapyTypeItem(TherapyType.Diabetisches_Fußsyndrom, 'Diabetisches Fußsyndrom'),
      new TherapyTypeItem(TherapyType.Ulzerierte_Tumore, 'Ulzerierte Tumore'),
      new TherapyTypeItem(TherapyType.Wundfisteln, 'Wundfisteln'),
      new TherapyTypeItem(TherapyType.sonstige_chronische_Wunden, 'Sonstige chronische Wunden'),
      new TherapyTypeItem(TherapyType.sonstige_Wunden, 'Sonstige Wunden'),
      new TherapyTypeItem(TherapyType.Akute_Wunde, 'Akute Wunde'),
      new TherapyTypeItem(TherapyType.Ulcus, 'Ulcus'),
      new TherapyTypeItem(TherapyType.Chronische_Wunde, 'Chronische Wunde'),
      new TherapyTypeItem(TherapyType.Fixierung_WV, 'Fixierung'),
      new TherapyTypeItem(TherapyType.Desinfektion_WV, 'Desinfektion'),
      new TherapyTypeItem(TherapyType.Hautablederung_WV, 'Hautablederung'),
      new TherapyTypeItem(TherapyType.Wundnaht_pri_WV, 'Wundnaht (primär)'),
      new TherapyTypeItem(TherapyType.Wundnaht_sek_WV, 'Wundnaht (sekundär)'),
      new TherapyTypeItem(TherapyType.Tumor_WV, 'Tumor'),
      new TherapyTypeItem(TherapyType.IAD_WV, 'IAD'),
      new TherapyTypeItem(TherapyType.WV_allgemein, 'Wundversorgung allgemein'),
      new TherapyTypeItem(TherapyType.Vakuum_therapie_WV, 'Vakuum Therapie'),
      new TherapyTypeItem(TherapyType.Wundheilungsstoerung_postop_WV, 'postoperative Wundheilungsstörung'),
      new TherapyTypeItem(TherapyType.Verbrennung_WV, 'Verbrennungswunde'),
      new TherapyTypeItem(TherapyType.Einstichstelle_WV, 'Einstichstelle'),
      new TherapyTypeItem(TherapyType.Hauttransplantat_WV, 'Hauttransplantat'),
      new TherapyTypeItem(TherapyType.OP_Wunde_WV, 'OP Wunde'),
      new TherapyTypeItem(TherapyType.Wunde_traumatisch_WV, 'Traumatische Wunde'),
      new TherapyTypeItem(TherapyType.Wunde_Tumor_WV, 'Tumorwunde'),
      new TherapyTypeItem(TherapyType.Ulcus_cruris_arteriosum_WV, 'Ulcus cruris arteriosum'),
      new TherapyTypeItem(TherapyType.Ulcus_cruris_venosum_WV, 'Ulcus cruris venosum'),
      new TherapyTypeItem(TherapyType.Ulcus_cruris_mixtum_WV, 'Ulcus cruris mixtum'),
      new TherapyTypeItem(TherapyType.Ulcus_unklar, 'Ulcus unklare Diagnose'),
      new TherapyTypeItem(TherapyType.Exulzerierte_Tumore_WV, 'Exulzerierte Tumore'),
      new TherapyTypeItem(TherapyType.Podologische_Versorgung_WV, 'Podologische Versorgung'),
      new TherapyTypeItem(TherapyType.Sekundaer_heilende_Wunde_WV, 'Sekundär heilende Wunden'),
    ]),
    new TherapyItem(Therapy.IV, 'IV-Therapien', 'IV', 'alberta-parenteral-feeding', '#00CF00', '#BEF2BE', [
      new TherapyTypeItem(TherapyType.Compounding, 'Compounding'),
      new TherapyTypeItem(TherapyType.Antibiose, 'Antibiose'),
      new TherapyTypeItem(TherapyType.Schmerztherapie, 'Schmerztherapie'),
      new TherapyTypeItem(TherapyType.Medikamententherapie, 'Medikamententherapie'),
      new TherapyTypeItem(TherapyType.Immunglobulintherapie, 'Immunglobulintherapie'),
      new TherapyTypeItem(TherapyType.Schmerztherapie_Pumpe_mobil, 'Schmerztherapie (Pumpe mobil)'),
      new TherapyTypeItem(TherapyType.HPE_Dreikammerbeutel, 'HPE Dreikammerbeutel'),
      new TherapyTypeItem(TherapyType.Infusiontherapie, 'Infusionstherapie'),
      new TherapyTypeItem(TherapyType.Enzymersatztherapie_IV, 'Enzymersatztherapie'),
      new TherapyTypeItem(TherapyType.Fluessigkeitszufuhr_IV, 'Flüssigkeitszufuhr'),
      new TherapyTypeItem(TherapyType.Subkutan_Infusion_IV, 'Subkutan Infusion'),
      new TherapyTypeItem(TherapyType.Compounding_IV, 'Compounding IV Therapie'),
    ]),
    new TherapyItem(Therapy.BEA, 'Beatmung', 'BEA', 'alberta-tracheostomy', '#0080FF', '#A6D2FF', [
      new TherapyTypeItem(TherapyType.Beatmung_invasiv, 'Invasiv'),
      new TherapyTypeItem(TherapyType.Beatmung_nicht_invasiv, 'Nicht Invasiv'),
      new TherapyTypeItem(TherapyType.Befeuchtung_aktiv, 'Befeuchtung Aktiv'),
      new TherapyTypeItem(TherapyType.Befeuchtung_passiv, 'Befeuchtung Passiv'),
      new TherapyTypeItem(TherapyType.Befeuchtung_integriert, 'Befeuchtung Integriert'),
      new TherapyTypeItem(TherapyType.High_Flow, 'High Flow'),
      new TherapyTypeItem(TherapyType.Beatmung_IV, 'Beatmung IV'),
      new TherapyTypeItem(TherapyType.Beatmung_NIV, 'Beatmung NIV'),
    ]),
    new TherapyItem(Therapy.SON, 'Sonstiges', 'SON', 'alberta-therapy-miscellaneous', '#f84b12', '#fdc8b7', [
      new TherapyTypeItem(TherapyType.Diabetes, 'Diabetes'),
      new TherapyTypeItem(TherapyType.Pflegehilfsmittel, 'Pflegehilfsmittel'),
      new TherapyTypeItem(TherapyType.Fistel, 'Fistel'),
      new TherapyTypeItem(TherapyType.Haemophilie, 'Hämophilie'),
      new TherapyTypeItem(TherapyType.Lungenhochdruck_PAH, 'Lungenhochdruck (PAH)'),
      new TherapyTypeItem(TherapyType.Pouch, 'Pouch'),
      new TherapyTypeItem(TherapyType.Oral_SON, 'Oral'),
      new TherapyTypeItem(TherapyType.Subkutan_SON, 'Subkutan'),
      new TherapyTypeItem(TherapyType.Sonstiges_SON, 'Sonstiges'),
      new TherapyTypeItem(TherapyType.Werbemittel_SON, 'Werbemittel'),
      new TherapyTypeItem(TherapyType.Ableitungssystem_SON, 'Ableitungssystem'),
      new TherapyTypeItem(TherapyType.Podologische_Versorgung_SON, 'Podologische Versorgung'),
    ]),
    new TherapyItem(Therapy.OSA, 'OSA', 'OSA', 'alberta-medical-technology', '#FF0045', '#FFCCDA', [
      new TherapyTypeItem(TherapyType.CPAP, 'CPAP'),
      new TherapyTypeItem(TherapyType.APAP, 'APAP'),
      new TherapyTypeItem(TherapyType.Bilevel, 'Bilevel'),
      new TherapyTypeItem(TherapyType.Bilevel_ST, 'Bilevel-ST'),
      new TherapyTypeItem(TherapyType.Auto_Bilevel, 'Auto Bilevel'),
      new TherapyTypeItem(TherapyType.CS, 'CS'),
      new TherapyTypeItem(TherapyType.ASV, 'ASV'),
      new TherapyTypeItem(TherapyType.Bilevel_Auto, 'Bilevel Auto'),
      new TherapyTypeItem(TherapyType.Bilevel_S, 'Bilevel S'),
      new TherapyTypeItem(TherapyType.AirMini, 'AirMini'),
    ]),
    new TherapyItem(Therapy.HH, 'Hustenhilfen', 'HH', 'alberta-medical-technology', '#6352E9', '#E0DCFB', [
      new TherapyTypeItem(TherapyType.Hustenhilfen, 'Hustenhilfen'),
      new TherapyTypeItem(TherapyType.Sekretmobilisation, 'Sekretmobilisation'),
    ]),
    new TherapyItem(Therapy.ABS, 'Absaugung', 'ABS', 'alberta-medical-technology', '#3123AF', '#D5D3EF', [
      new TherapyTypeItem(TherapyType.Absaugung_oral, 'Oral'),
      new TherapyTypeItem(TherapyType.Absaugung_subglottisch, 'Subglottisch'),
      new TherapyTypeItem(TherapyType.Endotracheal, 'Endotracheal'),
      new TherapyTypeItem(TherapyType.Sonstige_Absaugung_ABS, 'Sonstige Absaugung'),
    ]),
    new TherapyItem(Therapy.PUE, 'Patientenüberwachung', 'PUE', 'alberta-medical-technology', '#235EE8', '#D3DFFB', [
      new TherapyTypeItem(TherapyType.Monitoring, 'Monitoring'),
      new TherapyTypeItem(TherapyType.Pulsoximetrie, 'Pulsoximetrie'),
      new TherapyTypeItem(TherapyType.Fingerpulsoximetrie, 'Fingerpulsoximetrie'),
      new TherapyTypeItem(TherapyType.Kapnometer, 'Kapnometer'),
      new TherapyTypeItem(TherapyType.Blutdruckmessgeraet, 'Blutdruckmessgerät'),
    ]),
    new TherapyItem(Therapy.SS, 'Sauerstoff', 'SS', 'alberta-medical-technology', '#00B1EC', '#BCEEFF', [
      new TherapyTypeItem(TherapyType.Sauerstoff_mobil, 'Mobil'),
      new TherapyTypeItem(TherapyType.Sauerstoff_fluessig, 'Flüssig'),
      new TherapyTypeItem(TherapyType.Sauerstoff_stationaer, 'Stationär'),
      new TherapyTypeItem(TherapyType.GOX, 'GOX'),
      new TherapyTypeItem(TherapyType.Konzentrator_mobil, 'Konzentrator Mobil'),
      new TherapyTypeItem(TherapyType.Konzentrator_stationaer, 'Konzentrator Stationär'),
      new TherapyTypeItem(TherapyType.LOX, 'LOX'),
      new TherapyTypeItem(TherapyType.O2_Flaschenversorgung, 'O² Flaschenversorgung'),
      new TherapyTypeItem(TherapyType.Sparsystem_mit_O2_Flasche, 'Sparsystem mit O² Flasche'),
      new TherapyTypeItem(TherapyType.Kombi_STA_MOB, 'Kombinationsversorgung STA / MOB'),
      new TherapyTypeItem(TherapyType.Konzentrator_SS, 'Konzentrator'),
      new TherapyTypeItem(TherapyType.Sonstiges_SS, 'Sonstiges'),
    ]),
    new TherapyItem(
      Therapy.IUA,
      'Inhalations- und Atemtherapie',
      'IUA',
      'alberta-medical-technology',
      '#00B580',
      '#CCF0E6',
      [
        new TherapyTypeItem(TherapyType.Inhalation, 'Inhalation'),
        new TherapyTypeItem(TherapyType.Medikamentenvernebler, 'Medikamentenvernebler'),
        new TherapyTypeItem(TherapyType.Ultraschallvernebler, 'Ultraschallvernebler'),
        new TherapyTypeItem(TherapyType.PEP_Systeme, 'PEP-Systeme'),
        new TherapyTypeItem(TherapyType.IPPB_Inhalation, 'IPPB-Inhalation'),
        new TherapyTypeItem(TherapyType.Atemtherapie, 'Atemtherapie'),
        new TherapyTypeItem(TherapyType.OSA_IUA, 'OSA'),
        new TherapyTypeItem(TherapyType.Hustenhilfen_IUA, 'Hustenhilfen'),
        new TherapyTypeItem(TherapyType.Sauerstoff_IUA, 'Sauerstoff'),
        new TherapyTypeItem(TherapyType.Beatmung_IUA, 'Beatmung'),
      ]
    ),
    new TherapyItem(Therapy.LHM, 'Lagerungshilfsmittel', 'LHM', 'alberta-storage-aids', '#011770', '#B2B9D4', [
      new TherapyTypeItem(TherapyType.Anti_Dekubitussystem, 'Anti-Dekubitussysteme'),
      new TherapyTypeItem(TherapyType.Rollstuhlsitzkissen_LHM, 'Rollstuhlsitzkissen'),
      new TherapyTypeItem(TherapyType.Sonstiges_LHM, 'Sonstiges'),
    ]),
    new TherapyItem(Therapy.ST, 'Schmerztherapie', 'ST', 'alberta-pain-therapy', '#9E0059', '#E7BFD5', [
      new TherapyTypeItem(TherapyType.Pumpe_mobil_ST, 'Pumpe mobil'),
      new TherapyTypeItem(TherapyType.Pumpe_Sapphire_ST, 'Pumpe Sapphire'),
      new TherapyTypeItem(TherapyType.Pumpe_Cadd_Solis_Vip_ST, 'Pumpe CADD Solis VIP'),
      new TherapyTypeItem(TherapyType.Schwerkraft_ST, 'Schwerkraft'),
      new TherapyTypeItem(TherapyType.Sonstiges_ST, 'Sonstiges'),
      new TherapyTypeItem(TherapyType.Bodyguard_ST, 'Bodyguard'),
      new TherapyTypeItem(TherapyType.Schmerztherapie_ST, 'Schmerztherapie'),
    ]),
    new TherapyItem(Therapy.IT, 'Immuntherapie', 'IT', 'alberta-immunotherapy', '#E328DD', '#F8C9F7', [
      new TherapyTypeItem(TherapyType.Movy, 'Movy'),
      new TherapyTypeItem(TherapyType.Hizentra, 'Hizentra'),
    ]),
    new TherapyItem(Therapy.RH, 'Rehydration', 'RH', 'alberta-rehydration', '#5785EF', '#CCDAFA', [
      new TherapyTypeItem(TherapyType.Schwerkraft_RH, 'Schwerkraft'),
      new TherapyTypeItem(TherapyType.Pumpe_mobil_RH, 'Pumpe mobil'),
      new TherapyTypeItem(TherapyType.Pumpe_RH, 'Pumpe'),
      new TherapyTypeItem(TherapyType.Elastomare_Einmalpumpe_RH, 'Elastomere Einmalpumpe'),
      new TherapyTypeItem(TherapyType.Rehydration_RH, 'Rehydration'),
    ]),
    new TherapyItem(Therapy.Cross, 'Therapieübergreifend', 'T.Ü.', 'alberta-cross-therapy', '#3B507A', '#CEDAF2', []),
    new TherapyItem(Therapy.BEF, 'Befeuchtung', 'BEF', 'alberta-humidification', '#5785EF', '#CCDAFA', [
      new TherapyTypeItem(TherapyType.Befeuchtung, 'Befeuchtung'),
    ]),
    new TherapyItem(Therapy.HF, 'High-Flow', 'HF', 'alberta-high-flow', '#00B1EC', '#BCEEFF', [
      new TherapyTypeItem(TherapyType.High_Flow_HF, 'High-Flow'),
    ]),
    new TherapyItem(Therapy.AT, 'Atemtherapie', 'AT', 'alberta-breath-therapy', '#00B5A3', '#C2F2ED', [
      new TherapyTypeItem(TherapyType.Hustenassistent, 'Hustenassistent'),
      new TherapyTypeItem(TherapyType.Inhalation_AT, 'Inhalation'),
      new TherapyTypeItem(TherapyType.Atemtrainer, 'Atemtrainer'),
    ]),
    new TherapyItem(Therapy.MON, 'Monitoring', 'MON', 'alberta-monitoring', '#FF4B4B', '#FFD2D2', [
      new TherapyTypeItem(TherapyType.Kapnographie, 'Kapnographie'),
      new TherapyTypeItem(TherapyType.Herz_Atem_Monitor, 'Herz-Atem-Monitor'),
      new TherapyTypeItem(TherapyType.Pulsoximeter, 'Pulsoximeter'),
    ]),
    new TherapyItem(Therapy.DIA, 'Diagnostik', 'DIA', 'alberta-diagnostics', '#9EB500', '#EEF3CA', [
      new TherapyTypeItem(TherapyType.Schlafdiagnostik, 'Schlafdiagnostik'),
    ]),
    // Zahnschiene wurde historisch in "Verbrauchsmaterial Mitarbeiter" geändert
    // Es wurde nur die Frontend Uebersetzung angepasst, da viele Bestandsdaten migriert werden müssten
    // und Schnittstellen schon mit "ZS" arbeiten. Items #9542 #16046
    new TherapyItem(Therapy.ZS, 'Verbrauchsmaterial Mitarbeiter', 'ZS', 'alberta-cross-therapy', '#FF0045', '#FFCCDA', [
      new TherapyTypeItem(TherapyType.Zahnschiene, 'Verbrauchsmaterial Mitarbeiter'),
    ]),
    new TherapyItem(Therapy.SSB, 'Sitzschalenbau', 'SSB', 'alberta-seat-shell-construction', '#011770', '#C7CFED', [
      new TherapyTypeItem(TherapyType.ausgelagerte_Fertigung, 'ausgelagerte Fertigung'),
      new TherapyTypeItem(TherapyType.Selbstherstellung, 'Selbstherstellung'),
    ]),
    new TherapyItem(
      Therapy.ORTHO,
      'Orthopädietechnik',
      'ORTHO',
      'alberta-orthopedic-technology',
      '#00B580',
      '#C7EFE3',
      [
        new TherapyTypeItem(TherapyType.Schienen, 'Schienen'),
        new TherapyTypeItem(TherapyType.Kompressionsstruempfe, 'Kompressionsstrümpfe'),
        new TherapyTypeItem(TherapyType.Bandagen, 'Bandagen'),
        new TherapyTypeItem(TherapyType.Oberschenkel_Prothese, 'Oberschenkel Prothese'),
        new TherapyTypeItem(TherapyType.Unterschenkel_Prothese, 'Unterschenkel Prothese'),
        new TherapyTypeItem(TherapyType.Sonstiges_ORTHO, 'Sonstiges'),
      ]
    ),
    new TherapyItem(Therapy.REHA, 'Reha Hilfsmittel', 'REHA', 'alberta-rehab-aids', '#AE00A2', '#F2BDF0', [
      new TherapyTypeItem(TherapyType.Standard_REHA, 'Standard'),
      new TherapyTypeItem(TherapyType.Schwerlast, 'Schwerlast'),
      new TherapyTypeItem(TherapyType.Anti_Dekubitus_REHA, 'Anti Dekubitus'),
      new TherapyTypeItem(TherapyType.Rollstuhl_elektrisch, 'Rollstuhl elektrisch'),
      new TherapyTypeItem(TherapyType.Sonderbau_REHA, 'Sonderbau'),
    ]),
    new TherapyItem(Therapy.ESTI, 'Elektrostimulation', 'ESTI', 'alberta-electrostimulation', '#9E0059', '#EFD4E3', [
      new TherapyTypeItem(TherapyType.TENS, 'TENS'),
      new TherapyTypeItem(TherapyType.EMS, 'EMS'),
      new TherapyTypeItem(TherapyType.TENS_EMS, 'TENS/EMS'),
    ]),
    new TherapyItem(Therapy.DM, 'Diabetes', 'DM', 'alberta-diabetes', '#FF0000', '#EFD4E3', [
      new TherapyTypeItem(TherapyType.Diabetesversorgung, 'Diabetesversorgung'),
    ]),
    new TherapyItem(
      Therapy.APPAIDS,
      'Applikationshilfen (IV, PE, EE)',
      'APPAIDS',
      'alberta-enteral-nutrition',
      '#FFD014',
      '#FFEEAE',
      [
        new TherapyTypeItem(TherapyType.Enterale_Ernaehrung, 'Enterale Ernährung'),
        new TherapyTypeItem(TherapyType.Parenterale_Ernaehrung, 'Parenterale Ernährung'),
        new TherapyTypeItem(TherapyType.IV_Therapie, 'IV-Therapie'),
      ]
    ),
    new TherapyItem(
      Therapy.MEADEV,
      'Messgeräte für Körperzustände/-funktionen',
      'MEADEV',
      'alberta-medical-technology',
      '#235EE8',
      '#D3DFFB',
      [
        new TherapyTypeItem(TherapyType.Monitoring_MEADEV, 'Monitoring'),
        new TherapyTypeItem(TherapyType.Pulsoximetrie_MEADEV, 'Pulsoximetrie'),
        new TherapyTypeItem(TherapyType.Fingerpulsoximetrie_MEADEV, 'Fingerpulsoximetrie'),
        new TherapyTypeItem(TherapyType.Kapnometer_MEADEV, 'Kapnometer'),
        new TherapyTypeItem(TherapyType.Blutdruckmessgeraet_MEADEV, 'Blutdruckmessgerät'),
        new TherapyTypeItem(TherapyType.Babyueberwachung_MEADEV, 'Babyüberwachung'),
        new TherapyTypeItem(TherapyType.Bia_Messgeraet_MEADEV, 'BIA Messgerät'),
      ]
    ),
    new TherapyItem(Therapy.PCA, 'PCA Schmerztherapie', 'PCA', 'alberta-pain-therapy', '#9E0059', '#E7BFD5', [
      new TherapyTypeItem(TherapyType.Iv_PCA, 'i.v.'),
      new TherapyTypeItem(TherapyType.Sc_PCA, 's.c.'),
    ]),
    new TherapyItem(Therapy.AMG, 'Arzneimittelgabe', 'AMG', 'alberta-drug-distribution', '#3123af', '#d5d3ef', [
      new TherapyTypeItem(TherapyType.PAH_AMG, 'PAH'),
      new TherapyTypeItem(TherapyType.Immunglobuline, 'Immunglobuline'),
      new TherapyTypeItem(TherapyType.Antiinfektivagabe, 'Antiinfektivagabe'),
      new TherapyTypeItem(TherapyType.Haemophilie, 'Hämophilie'),
      new TherapyTypeItem(TherapyType.Lecigon, 'Lecigon'),
      new TherapyTypeItem(TherapyType.Sonstiges_AMG, 'Sonstiges'),
    ]),
    new TherapyItem(Therapy.DV, 'Direktverkauf', 'DV', 'alberta-therapy-miscellaneous', '#f84b12', '#fdc8b7', [
      new TherapyTypeItem(TherapyType.Direktverkauf, 'Direktverkauf'),
    ]),
    new TherapyItem(Therapy.SI, 'Subcutane Infusion', 'SI', 'alberta-subcutane-infusion', '#9E0059', '#F1D9E6', [
      new TherapyTypeItem(TherapyType.Schwerkraft_SI, 'Schwerkraft'),
      new TherapyTypeItem(TherapyType.Pumpe_immobil_SI, 'Pumpe immobil'),
      new TherapyTypeItem(TherapyType.Pumpe_mobil_SI, 'Pumpe mobil'),
      new TherapyTypeItem(TherapyType.Sonstiges_SI, 'Sonstiges'),
    ]),
    new TherapyItem(Therapy.TN, 'Trinknahrung', 'TN', 'alberta-sip-feeding', '#00B580', '#D9F4EC', [
      new TherapyTypeItem(TherapyType.Trinknahrung_klassisch_TN, 'Trinknahrung Klassisch'),
      new TherapyTypeItem(TherapyType.Dialyse_TN, 'Dialyse'),
      new TherapyTypeItem(TherapyType.Sonstiges_TN, 'Sonstiges'),
    ]),
    new TherapyItem(Therapy.SL, 'Serviceleistungen', 'SL', 'alberta-services', '#6352E9', '#E0DCFB', [
      new TherapyTypeItem(TherapyType.Serviceleistungen_SL, 'Serviceleistungen'),
    ]),
    new TherapyItem(Therapy.MT, 'Medizintechnik', 'MT', 'alberta-medical-engineering', '#FF017E', '#FFC7E3', [
      new TherapyTypeItem(TherapyType.Fingerpulsoximeter_MT, 'Fingerpulsoximeter'),
      new TherapyTypeItem(TherapyType.Blutdruckmessgeräte_MT, 'Blutdruckmessgeräte'),
      new TherapyTypeItem(TherapyType.Sonstiges_MT, 'Sonstiges'),
    ]),
    new TherapyItem(Therapy.TNO, 'Trinknahrung Oral', 'TNO', 'alberta-sip-feeding', '#00B580', '#D9F4EC', [
      new TherapyTypeItem(TherapyType.Trinknahrung_Erw_TNO, 'Trinknahrung Erwachsene'),
      new TherapyTypeItem(TherapyType.Trinknahrung_Kinder_TNO, 'Trinknahrung Kinder'),
    ]),
    new TherapyItem(Therapy.ASEPT, 'ASEPT', 'ASEPT', 'alberta-asept', '#235EE8', '#D3DFFB', [
      new TherapyTypeItem(TherapyType.Pleural_Drainage_ASEPT, 'Pleural-Drainage'),
      new TherapyTypeItem(TherapyType.Aszites_Drainage_ASEPT, 'Aszites-Drainage'),
    ]),
    new TherapyItem(Therapy.AKT, 'Antikörpertherapie', 'AKT', 'alberta-antibody-therapy', '#3123AF', '#D5D3EF', [
      new TherapyTypeItem(TherapyType.Blinatumonap_AKT, 'Blinatumomab'),
    ]),
  ];

  static getTherapy(id: number): TherapyItem {
    const therapyItem = this.therapyItems.find(therapy => therapy.id === id);

    return therapyItem ? therapyItem : Therapies.therapyItems[0];
  }

  // get therapy by abbreviation
  static getTherapyByAbbreviation(abbreviation: string): TherapyItem {
    const therapyItem = this.therapyItems.find(therapy => therapy.abbreviation === abbreviation);

    return therapyItem ? therapyItem : Therapies.therapyItems[0];
  }

  static getTherapyType(therapyId: number, therapyTypeId: number): TherapyTypeItem {
    const therapyItem: TherapyItem = this.therapyItems.find(therapy => therapy.id === therapyId);
    if (!therapyItem) {
      return;
    }
    return therapyItem.therapyTypes.find(therapyTypeItem => therapyTypeItem.id === therapyTypeId);
  }

  static getTherapyTypeEnumStringById(id: any): string | undefined {
    for (const key in TherapyType) {
      if (TherapyType[key] === id) {
        return key;
      }
    }
    return undefined;
  }

  static getTherapyAndTypeName(therapyId: number, therapyTypeId: number): string {
    const therapyItem: TherapyItem = this.therapyItems.find(therapy => therapy.id === therapyId);
    if (!therapyItem) {
      return;
    }
    const therapyType = therapyItem.therapyTypes.find(therapyTypeItem => therapyTypeItem.id === therapyTypeId);
    if (!therapyType) {
      return therapyItem.displayName;
    }
    return `${therapyItem.displayName} (${therapyType.displayName})`;
  }

  static getNextVisitDate(id: number): Date {
    const nextVisitDate = new Date();

    switch (id) {
      case 2:
        nextVisitDate.setDate(nextVisitDate.getDate() + 14);
        break;
      case 6:
        nextVisitDate.setDate(nextVisitDate.getDate() + 7);
        break;
      case 3:
        nextVisitDate.setMonth(3);
        break;
      default:
        nextVisitDate.setDate(nextVisitDate.getDate() + 28);
        break;
    }

    return nextVisitDate;
  }

  static getDueColor(date: Date): string {
    // TODO: differentiate by therapy
    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() - 7);
    if (new Date(date) < dueDate) {
      return '#FF6E6E';
    }

    return '#127C77';
  }
}
