import { IAudit, IMatch, IReport, ITemplate } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponentModel } from 'src/app/common/component-model/base-component-model';
import { ComponentModelDependencies } from 'src/app/common/component-model/component-model-dependencies';
import { ComponentModelInfo } from 'src/app/common/component-model/component-model-info';
import { AuditDB, MatchDB, ReportDB, TemplateDB } from 'src/app/common/repository/databases';
import { SQLiteQueryBuilder } from 'src/app/common/storage/sqlite-query-builder';
import { AuditModelName } from 'src/app/shared/models/model-names';

@Injectable({ providedIn: 'root' })
export class AuditComponentModel extends BaseComponentModel<IAudit> {
  /**
   * ComponentModel for all auidt relevant actions and dependencies
   */
  constructor(componentModelDependencies: ComponentModelDependencies, componentModelInfo: ComponentModelInfo) {
    super(AuditModelName, componentModelDependencies, componentModelInfo);
  }

  getTemplate(id: string): Observable<ITemplate> {
    return from(this.componentModelDepencies.queryService.get<ITemplate>(id, TemplateDB));
  }

  getReport(id: string): Observable<IReport> {
    return from(this.componentModelDepencies.queryService.get<IReport>(id, ReportDB));
  }

  getMatch(id: string): Observable<IMatch> {
    return from(this.componentModelDepencies.queryService.get<IMatch>(id, MatchDB));
  }

  public count(patientId: string): Observable<number> {
    return from(
      this.componentModelDepencies.queryService.raw(
        {
          sql: new SQLiteQueryBuilder()
            .select('COUNT(*) AS count')
            .fromFts(AuditDB)
            .where('patientId', '=', patientId)
            .asString(),
          aggregation: [
            {
              $match: {
                patientId,
              },
            },
            {
              $count: 'count',
            },
          ],
        },
        AuditDB
      )
    ).pipe(map(count => count[0]?.count ?? 0));
  }
}
