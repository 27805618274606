import { IPrescriptionRequest } from '@alberta/konexi-shared/dist/prescription/prescription-request';
import { Injectable } from '@angular/core';
import { PrescriptionRequestWorkItem } from 'src/app/business/prescription/prescription-request-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class PrescriptionRequestCommandHandler extends EntityCommandHandler<IPrescriptionRequest> {
  protected workItem: IWorkItem<IPrescriptionRequest>;

  constructor(prescriptionRequestWorkItem: PrescriptionRequestWorkItem) {
    super();
    this.workItem = prescriptionRequestWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'PrescriptionRequestDto';
  }
}
