import { Injectable } from '@angular/core';
import { diff, flattenChangeset, IFlatChange } from 'json-diff-ts/lib/jsonDiff';
import { Dictionary } from 'lodash';

declare type FunctionKey = (obj: any) => any;

@Injectable({
  providedIn: 'root',
})
export class JSONDiffService {
  // key decides how to identifiy array objects, e.g. by _id
  public getFlatDiffs(
    originalObject: object,
    changedObject: object,
    key?: Dictionary<string | FunctionKey>
  ): IFlatChange[] {
    const diffs = diff(originalObject, changedObject, key);
    return flattenChangeset(diffs);
  }

  /**
  *  Returns a map of flat changes grouped by path, e.g.
  *
  *    {
  *      "$.seriesData[0].nextDeliveryDate": [
  *          {
  *              "type": "REMOVE",
  *              "key": "nextDeliveryDate",
  *              "value": "2022-08-24",
  *              "path": "$.seriesData[0].nextDeliveryDate",
  *              "valueType": "String"
  *          }
  *      ]
  *    }
  */
  public groupChangesByPath(changes: IFlatChange[]): { [key: string]: IFlatChange[] } {
    const changesByPath: { [key: string]: IFlatChange[] } = {};
    changes.map(change => {
       (changesByPath[change.path] ??= []).push(change);
    });
    return changesByPath;
  }
}
