import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IConnectionStatus } from 'src/app/common/contracts/connection/connection-status';

import { IConnectionStateService } from './contracts/sync/connection-state-service';
import { ISocketConnectionStateReader } from './contracts/sync/service/socket-connection-state-reader';
import { ISocketConnectionStateWriter } from './contracts/sync/service/socket-connection-state-writer';

@Injectable({ providedIn: 'root' })
export class ConnectionStateService
  implements IConnectionStateService, ISocketConnectionStateReader, ISocketConnectionStateWriter {
  private _isSocketConnected: boolean;

  private _isConnected$ = new BehaviorSubject<boolean>(false);
  public get connected(): Observable<boolean> {
    return this._isConnected$.asObservable();
  }

  private _connectionState$ = new Subject<IConnectionStatus>();
  public get connectionState(): Observable<IConnectionStatus> {
    return this._connectionState$.asObservable();
  }

  public get isConnected(): boolean {
    return this._isConnected$.value;
  }

  public setConnectionState(): void {
    this._connectionState$.next({ isOnline: this._isConnected$.value });
  }

  public setConnected(isOnline: boolean): void {
    this._isConnected$.next(isOnline);
  }

  public getSocketConnectionState(): boolean {
    return this._isSocketConnected;
  }

  setSocketConnectionState(connected: boolean): void {
    this._isSocketConnected = connected;
  }
}
