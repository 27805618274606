import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IListView } from '../../../contracts/list-view';
import { IInstitutionContactFunction } from '@alberta/konexi-shared';
import { InstitutionContactFunctionModel } from 'src/app/shared/models/institution/institution-contact-function.model';

@Component({
  selector: 'institution-contact-function-list-item',
  templateUrl: 'institution-contact-function-list-item.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstitutionContactFunctionListItem implements IListView {
  @Input() item: InstitutionContactFunctionModel;
  @Output() clicked: EventEmitter<IInstitutionContactFunction> = new EventEmitter<IInstitutionContactFunction>();

  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
