import { IHospital } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { HospitalChannel } from 'src/app/business/institution/contracts/hospital.channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { HospitalModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class HospitalSyncService extends SyncService<IHospital> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IHospital>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(HospitalModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public canSync(channel: string): boolean {
    return channel === HospitalChannel.CREATE || channel === HospitalChannel.EDIT || channel === HospitalChannel.DELETE;
  }
}
