import {
  ChangeType,
  IArticleLineHistoryContainer,
  IDosage,
  IHistoryArticleLine,
  IOrderedArticleLine,
  IProposedArticleLine,
  OrderStatus,
} from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import moment from 'moment';
// tslint:disable-next-line: max-line-length
import { HistoryArticleLineViewModel } from 'src/app/business/care-proposal/view-models/history-article-line-view-model';
import {
  AuthenticationToken,
  IAuthenticationAccount,
} from 'src/app/common/contracts/authentication/authentication-account';
import { OrderStatusPipe } from 'src/app/common/pipes/order/orderstatus-pipe';
import { Changes } from 'src/app/common/tracking';

import { ArticleService } from '../article.service';
import { IArticleLineHistoryService } from '../contracts/history/article-history-service';
import { GeneralEntityService } from '../general-entity.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleLineHistoryService implements IArticleLineHistoryService {
  constructor(
    private _articleService: ArticleService,
    private _translate: TranslateService,
    @Inject(AuthenticationToken) private _authenticationAccount: IAuthenticationAccount,
    private _generalEntityService: GeneralEntityService
  ) {}

  public async writeArticleHistory(container: IArticleLineHistoryContainer, changes: Changes) {
    await this.writeHistoryForNotAutomaticDelivery(changes, container);
    await this.writeHistoryForActiveArticleLine(changes, container);
    await this.writeHistoryForDosage(changes, container);
    await this.writeHistoryForDoctor(changes, container);
    await this.setAddedAndRemovedArticles(changes, container);
    await this.writeHistoryForOrderLinePrivateSale(changes, container);
    this.writeHistoryForNextDeliveryDate(changes, container);
    this.writeHistoryForDayOfDelivery(changes, container);
    this.writeHistoryForOrderStatus(changes, container);
    this.writeHistoryForAnnotation(changes, container);
    this.writeHistoryForPermanentExceedMaxAmount(changes, container);
    return container;
  }

  private writeHistoryForOrderStatus(changes: Changes, container: IArticleLineHistoryContainer) {
    const orderStatusChanged = changes.changes.filter(
      change =>
        change.path === 'status' &&
        (Number(change.newValue) === OrderStatus.OrderStopped || Number(change.newValue) === OrderStatus.Pending)
    );

    if (!orderStatusChanged || !orderStatusChanged.length) {
      return;
    }

    const history = new HistoryArticleLineViewModel(ChangeType.Status);
    const valueAfter = new OrderStatusPipe(this._translate).transform(orderStatusChanged[0].newValue);
    history.valueBefore = new OrderStatusPipe(this._translate).transform(orderStatusChanged[0].oldValue);
    history.valueAfter = valueAfter === 'Ausstehend' ? 'Übermittelt' : valueAfter;
    this.setUserData(history);

    if (container.articleLineHistory.some(entry => entry._id === history._id)) {
      return;
    }
    container.articleLineHistory.push(history);
  }

  private async writeHistoryForOrderLinePrivateSale(changes: Changes, container: IArticleLineHistoryContainer) {
    const privateSaleChanges = changes.changes.filter(
      change =>
        change.path.startsWith('orderedArticleLines') &&
        change.path.endsWith('isPrivateSale') &&
        change.newValue != null
    );

    if (!privateSaleChanges || !privateSaleChanges.length) {
      return;
    }

    for (const privateSaleChange of privateSaleChanges) {
      const history = new HistoryArticleLineViewModel(ChangeType.Change);
      const articleLine = get(container, privateSaleChange.path.split('.')[0]);
      await history.setPrivateSale(privateSaleChange, articleLine, this._articleService, this._translate);
      this.setUserData(history);

      if (container.articleLineHistory.some(articleLineHistory => articleLineHistory._id === history._id)) {
        return;
      }
      container.articleLineHistory.push(history);
    }
  }

  private async writeHistoryForDoctor(changes: Changes, container: IArticleLineHistoryContainer) {
    const doctorChanged = changes.changes.filter(change => change.path === 'doctorId');
    if (!doctorChanged || doctorChanged.length < 1) {
      return;
    }
    this._generalEntityService.type = 'doctor';
    const history = new HistoryArticleLineViewModel(ChangeType.Doctor);
    if (doctorChanged[0].oldValue) {
      const doctorBefore = await this._generalEntityService.find(doctorChanged[0].oldValue.toString());
      if (doctorBefore) {
        history.valueBefore = `${doctorBefore.titleShort ? doctorBefore.titleShort : ''} ${doctorBefore.firstName} ${
          doctorBefore.lastName
        }, ${doctorBefore.city}`;
      }
    } else {
      history.valueBefore = '';
    }
    if (doctorChanged[0].newValue) {
      const doctorAfter = await this._generalEntityService.find(doctorChanged[0].newValue.toString());
      if (doctorAfter) {
        history.valueAfter = `${doctorAfter.titleShort ? doctorAfter.titleShort : ''} ${doctorAfter.firstName} ${
          doctorAfter.lastName
        }, ${doctorAfter.city}`;
      }
    } else {
      history.valueAfter = '';
    }
    this.setUserData(history);
    if (container.articleLineHistory.some(entry => entry._id === history._id)) {
      return;
    }
    container.articleLineHistory.push(history);
  }

  private writeHistoryForNextDeliveryDate(changes: Changes, container: IArticleLineHistoryContainer) {
    const nextDeliveryChange = changes.changes.filter(change => change.path === 'nextDelivery');
    if (!nextDeliveryChange || !nextDeliveryChange.length) {
      return;
    }
    const history = new HistoryArticleLineViewModel(ChangeType.Date);
    history.valueBefore = nextDeliveryChange[0].oldValue
      ? moment(new Date(nextDeliveryChange[0].oldValue)).format('YYYY-MM-DD')
      : '';
    history.valueAfter = moment(new Date(nextDeliveryChange[0].newValue)).format('YYYY-MM-DD');
    this.setUserData(history);

    if (container.articleLineHistory.some(articleLineHistory => articleLineHistory._id === history._id)) {
      return;
    }
    container.articleLineHistory.push(history);
  }

  private writeHistoryForDayOfDelivery(changes: Changes, container: IArticleLineHistoryContainer) {
    const dayOfDeliveryChange = changes.changes.filter(change => change.path === 'dayOfDelivery');
    if (!dayOfDeliveryChange || !dayOfDeliveryChange.length) {
      return;
    }
    const history = new HistoryArticleLineViewModel(ChangeType.Day);
    history.valueBefore = dayOfDeliveryChange[0].oldValue ? dayOfDeliveryChange[0].oldValue : '';
    history.valueAfter = dayOfDeliveryChange[0].newValue;
    this.setUserData(history);
    if (container.articleLineHistory.some(articleLineHistory => articleLineHistory._id === history._id)) {
      return;
    }
    container.articleLineHistory.push(history);
  }

  public writeHistoryForAnnotation(changes: Changes, container: IArticleLineHistoryContainer) {
    const annotationChange = changes.changes.filter(change => change.path === 'annotation');
    if (!annotationChange || !annotationChange.length) {
      return;
    }
    const history = new HistoryArticleLineViewModel(ChangeType.Annotation);
    history.valueBefore = annotationChange[0].oldValue;
    history.valueAfter = annotationChange[0].newValue;
    this.setUserData(history);
    if (container.articleLineHistory.some(articleLineHistory => articleLineHistory._id === history._id)) {
      return;
    }
    container.articleLineHistory.push(history);
  }

  private writeHistoryForPermanentExceedMaxAmount(changes: Changes, container: IArticleLineHistoryContainer) {
    const valueChange = changes.changes.filter(change => change.path === 'permanentExceedMaxAmount');
    if (!valueChange || !valueChange.length) {
      return;
    }
    const history = new HistoryArticleLineViewModel(ChangeType.PermanentExceedMaxAmount);
    history.valueBefore = valueChange[0].oldValue;
    history.valueAfter = valueChange[0].newValue;
    this.setUserData(history);
    if (container.articleLineHistory.some(articleLineHistory => articleLineHistory._id === history._id)) {
      return;
    }
    container.articleLineHistory.push(history);
  }

  private async writeHistoryForActiveArticleLine(changes: Changes, container: IArticleLineHistoryContainer) {
    const activeArticleLineChanges = changes.changes.filter(
      change =>
        change.path.startsWith('orderedArticleLines') && change.path.endsWith('active') && change.newValue != null
    );

    if (!activeArticleLineChanges) {
      return;
    }

    for (const ativeArticleLineChange of activeArticleLineChanges) {
      const articleLine = get(container, ativeArticleLineChange.path.split('.')[0]);
      articleLine.active
        ? await this.writeAddedArticleHistory(container, articleLine)
        : await this.writeDeletedArticleHistory(container, articleLine);
    }
  }

  private async writeHistoryForNotAutomaticDelivery(changes: Changes, container: IArticleLineHistoryContainer) {
    const automaticDeliveryChanges = changes.changes.filter(
      change => change.path.startsWith('orderedArticleLines') && change.path.endsWith('notAutomaticDelivery')
    );

    if (!automaticDeliveryChanges) {
      return;
    }

    for (const deliveryChange of automaticDeliveryChanges) {
      const history = new HistoryArticleLineViewModel(ChangeType.Change);
      const articleLine = get(container, deliveryChange.path.split('.')[0]);
      await history.setDeliveryInfo(deliveryChange, articleLine, this._articleService, this._translate);
      this.setUserData(history);

      if (container.articleLineHistory.some(articleLineHistory => articleLineHistory._id === history._id)) {
        return;
      }
      container.articleLineHistory.push(history);
    }
  }

  private async writeHistoryForDosage(changes: Changes, container: IArticleLineHistoryContainer) {
    const dosageChanges = changes.changes.filter(
      change =>
        (change.path.startsWith('proposedArticleLines') || change.path.startsWith('orderedArticleLines')) &&
        change.path.endsWith('dosage')
    );

    if (!dosageChanges) {
      return;
    }

    for (const changedDosage of dosageChanges) {
      await this.setChangedArticle(
        changedDosage.path.split('.')[0],
        changedDosage.newValue,
        changedDosage.oldValue,
        container
      );
    }
  }

  private async setAddedAndRemovedArticles(changes: Changes, container: IArticleLineHistoryContainer) {
    const articleLinesChange = changes.changes.find(
      change => change.path === 'proposedArticleLines' || change.path === 'orderedArticleLines'
    );

    if (!articleLinesChange) {
      return;
    }

    for (const newArticleLine of articleLinesChange.newValue) {
      if (!articleLinesChange.oldValue.some(a => a.articleId === newArticleLine.articleId)) {
        await this.writeAddedArticleHistory(container, newArticleLine);
      }
    }

    for (const oldArticleLine of articleLinesChange.oldValue) {
      if (!articleLinesChange.newValue.some(oa => oa.articleId === oldArticleLine.articleId)) {
        await this.writeDeletedArticleHistory(container, oldArticleLine);
      }
    }
  }

  private async setChangedArticle(
    articleLinePath: string,
    newDosage: IDosage,
    oldDosage: IDosage,
    container: IArticleLineHistoryContainer
  ) {
    const articleLine = get(container, articleLinePath);

    await this.writeChangedArticleHistory(container, newDosage, oldDosage, articleLine);
  }

  public async writeAddedArticleHistory(
    container: IArticleLineHistoryContainer,
    newArticleLine: IProposedArticleLine | IOrderedArticleLine
  ) {
    const history = new HistoryArticleLineViewModel(ChangeType.Add);

    await history.setArticleInfo(this._translate, this._articleService, newArticleLine.dosage, null, newArticleLine);

    this.setUserData(history);

    if (container.articleLineHistory.some(articleLineHistory => articleLineHistory._id === history._id)) {
      return;
    }
    container.articleLineHistory.push(history);
  }

  private async writeChangedArticleHistory(
    container: IArticleLineHistoryContainer,
    newDosage: IDosage,
    oldDosage: IDosage,
    articleLine: IProposedArticleLine
  ) {
    const history = new HistoryArticleLineViewModel(ChangeType.Change);
    await history.setArticleInfo(this._translate, this._articleService, newDosage, oldDosage, articleLine);
    this.setUserData(history);

    if (container.articleLineHistory.some(articleLineHistory => articleLineHistory._id === history._id)) {
      return;
    }
    container.articleLineHistory.push(history);
  }

  private async writeDeletedArticleHistory(
    container: IArticleLineHistoryContainer,
    removedArticle: IProposedArticleLine | IOrderedArticleLine
  ) {
    const history = new HistoryArticleLineViewModel(ChangeType.Delete);
    await history.setArticleInfo(this._translate, this._articleService, null, removedArticle.dosage, removedArticle);
    this.setUserData(history);

    if (container.articleLineHistory.some(articleLineHistory => articleLineHistory._id === history._id)) {
      return;
    }
    container.articleLineHistory.push(history);
  }

  private setUserData(historyArticleLine: IHistoryArticleLine) {
    // tslint:disable-next-line: max-line-length
    historyArticleLine.userName = `${this._authenticationAccount.account.firstName} ${this._authenticationAccount.account.lastName} `;
    historyArticleLine.userId = this._authenticationAccount.account._id;
  }
}
