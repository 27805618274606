import { Component } from '@angular/core';

@Component({
  selector: 'itl-slim-button',
  template: `<button class="btn">
    <div class="btn-icon"><ng-content select="ion-icon"></ng-content></div>
    <div class="btn-text"><ng-content></ng-content></div>
  </button> `,
  styleUrls: ['./slim-button.component.scss'],
})
export class SlimButtonComponent {}
