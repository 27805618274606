import { Inject, Injectable } from '@angular/core';

import makeDebug from '../../../../makeDebug';
import { IWebSocketClient } from '../../services/contracts/sync/web-socket-client';
import { WebSocketClient } from '../../services/web-socket-client.service';
import { IPipelineContext } from '../contracts/start/pipeline-context';
import { IPipelineElement, PipelineResult } from '../contracts/start/pipeline-element';
import { WebSocketConnector } from '../contracts/start/pipeline-endpoints';

const debug = makeDebug('sync:server-connector');

@Injectable()
export class ServerConnector implements IPipelineElement {
  public get endpoint(): string {
    return WebSocketConnector;
  }

  public get parent(): string {
    return '';
  }

  constructor(@Inject(WebSocketClient) private _websocketClient: IWebSocketClient) {}

  async execute(context: IPipelineContext): Promise<PipelineResult> {
    debug('calling connect of websocket-client');
    try {
      await this._websocketClient.connect(context.params.uri);
    } catch (e) {
      console.error(e);
      return { contextId: context.id, uri: context.params.uri, connected: false, jobDone: false };
    }
    return { contextId: context.id, uri: context.params.uri, connected: true, jobDone: true };
  }
}
