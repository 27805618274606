import { Therapy } from '@alberta/konexi-shared';
import { InjectionToken } from '@angular/core';

import {
  AppointmentDB,
  ArticleDB,
  AttachmentDB,
  AttachmentTypeDB,
  AttributeTemplateDB,
  AuditDB,
  CalendarResourceDB,
  CareProposalDB,
  ContractArticleGroupDB,
  DeviceDB,
  DirectOrderDB,
  DoctorDB,
  ErpOrderDB,
  ErpTaskDB,
  FaxDB,
  GroupDB,
  HospitalDB,
  InstitutionAttributeDB,
  InstitutionAuditDB,
  InstitutionContactDB,
  InstitutionNoteDB,
  InsuranceContractDB,
  IntegratedCareDB,
  IntegratedCareReturnDeliveryDB,
  MedicalOrderRequestDB,
  NursingHomeDB,
  NursingServiceDB,
  OrderDB,
  PatientDB,
  PatientNotesDB,
  PayerDB,
  PayerInfoDB,
  PharmacyDB,
  PlannedOrderDB,
  PostalCodeDB,
  PrescriptionRequestDB,
  ProductGroupDB,
  QuotesDB,
  RegionDB,
  ReminderDB,
  ReturnDeliveryDB,
  RightsetDB,
  ServiceCostEstimateDB,
  ServiceDB,
  ServiceOrderDB,
  ServiceTaskDB,
  SingleOrderDB,
  StandardCareProposalDB,
  SubunitDB,
  TemplateDB,
  UsersDB,
} from './databases';

export interface DBPlugin {
  /** List of fields searchable in fts table
   *
   * If searchable is defined, Repository.search() will search the fts table with a regex, not
   * the indexed fulltext-search as long as the search term is a single word.
   *
   * Fields with searchable === false will always be ignored by Repository.search()
   *
   * Regex may be preferred because the fts search is unable to do postfix searched like '*test'.
   * It is however significantly slower, as it requires a complete scan.
   */
  fields: string[] | { name: string; searchable: boolean }[];
  modifier?: Record<string, (item: any) => string>;
  db?: string;
}

const patientPlugin = {
  fields: [
    '_id',
    'lastName',
    'firstName',
    'customerId',
    'nursingHomeId',
    'nursingServiceId',
    'fieldNurseId',
    'city',
    'postalCode',
    'additionalAddress',
    'birthday',
    'nursingHome.subunitId',
    'additionalUserId',
    'additionalUserIds',
    'status',
    'deactivationReason',
    'regionId',
    'hospital.hospitalId',
    'payer.numberOfInsured',
    'accountingStatus',
  ],
};

const doctorPlugin = {
  fields: ['lastName', 'firstName', 'city', 'postalCode', 'institution.description'],
  db: 'global',
};

const hospitalPlugin = {
  fields: ['company', 'name', 'city', 'postalCode'],
  db: 'global',
};

const nursingHomePlugin = {
  fields: ['name', 'city', 'postalCode'],
  db: 'global',
};

const nursingServicePlugin = {
  fields: ['name', 'city', 'postalCode'],
  db: 'global',
};

const payerPlugin = {
  fields: ['_id', 'name', 'ikNumber'],
  db: 'global',
};

const payerInfoPlugin = {
  fields: ['payerId', 'header'],
};

const postalCodePlugin = {
  fields: ['city', 'postalCode'],
  db: 'global',
};

const appointmentPlugin = {
  fields: ['patientIds', 'institutionId', 'userId', 'title', 'startDate', 'endDate', 'calendarResourceId', 'archived'],
};

const usersPlugin = {
  fields: ['firstName', 'lastName', 'email'],
  db: 'users',
};

const groupPlugin = {
  fields: ['_id', 'label'],
};

const regionPlugin = {
  fields: ['_id', 'parent', 'label'],
};

const rightsetPlugin = {
  fields: ['_id', 'description'],
  db: 'users',
};

const productGroupPlugin = {
  fields: ['_id', 'number', 'name', 'description', 'parentId'],
};

const articlePlugin: DBPlugin = {
  fields: [
    { name: '_id', searchable: false },
    { name: 'name', searchable: true },
    { name: 'pcn', searchable: true },
    { name: 'customerNumber', searchable: true },
    { name: 'manufacturerNumber', searchable: true },
    { name: 'productGroupId', searchable: false },
    { name: 'manufacturer', searchable: true },
    { name: 'additionalDescription', searchable: true },
    { name: 'description', searchable: true },
  ],
};

const standardCareProposalPlugin = {
  fields: ['_id', 'name'],
};

const insuranceContractPlugin = {
  fields: ['_id', 'name', 'therapyId'],
  modifier: {
    therapyId: value => {
      return Therapy[value];
    },
  },
};

const pharmacyPlugin = {
  fields: ['name', 'city', 'postalCode'],
  db: 'global',
};

const careProposalPlugin = {
  fields: ['patientId', 'status'],
};

const integratedCarePlugin = {
  fields: ['patientId', 'status'],
};

const erpTaskPlugin = {
  fields: ['patientId', 'entityId'],
};

const orderPlugin = {
  fields: ['patientId', 'careProposalId'],
};

const singleOrderPlugin = {
  fields: ['_id', 'deliveryDate', 'patientId', 'integratedCareId', 'createdBy', 'status', 'therapyTypeId'],
};

const directOrderPlugin = { fields: ['patientId'] };

const auditPlugin = {
  fields: [
    '_id',
    'patientId',
    'completed',
    'templateId',
    'therapyId',
    'therapyTypeId',
    'createdAt',
    'archived',
    'createdBy',
    'woundLocation',
    'createdUtc',
    'draft',
    'updatedUtc',
    'additionalInfo',
    'entityType',
  ],
};

const faxPlugin = {
  fields: ['title', 'createdBy'],
};

const patientNotesPlugin = {
  fields: ['patientId', 'archived'],
};

const erpOrderPlugin = {
  fields: ['salesId', 'patientId'],
};

const devicePlugin = {
  fields: ['articleId', 'patientId'],
};

const attributeTemplatePlugin = {
  fields: ['name'],
};

const subunitPlugin = {
  fields: ['institutionId'],
};

const institutionContactPlugin = {
  fields: ['institutionId'],
};

const institutionNotePlugin = {
  fields: ['institutionId'],
};

const institutionAuditPlugin = {
  fields: ['institutionId', 'templateId', 'createdUtc', 'createdBy', 'draft', 'completed', 'archived'],
};

const attachmentPlugin = {
  fields: [
    'metadata.patientId',
    'metadata.auditNodeId',
    'metadata.templateNodeId',
    'metadata.auditId',
    'metadata.templateId',
    'metadata.type',
    'metadata.archived',
    'metadata.createdBy',
    'metadata.createdAt',
    'metadata.serviceOrderId',
    'uploadDate',
  ],
  dbPostfix: 'files',
};

const attachmentTypePlugin = {
  fields: ['_id', 'attachmentTypeKey', 'value'],
};

const returnDeliveryPlugin = {
  fields: ['patientId'],
};

const contractArticleGroupPlugin = {
  fields: ['_id', 'name', 'customerNumber'],
};

const templatePlugin = {
  fields: ['_id', 'templateTag', 'draft', 'archived'],
};

const reminderPlugin = {
  fields: ['patientId', 'entityId', 'date', 'userId'],
};

const plannedOrderPlugin = {
  fields: ['patientId'],
};
const quotesPlugin = {
  fields: ['date'],
  db: 'global',
};
const integratedCareReturnDeliveryPlugin = {
  fields: ['patientId'],
};
const institutionAttributePlugin = {
  fields: ['institutionId'],
};
const prescriptionRequestPlugin = {
  fields: ['patientId'],
};
const medicalOrderRequestPlugin = {
  fields: ['patientId'],
};
const servicePlugin = {
  fields: ['_id', 'name', 'description', 'additionalDescription', 'customerNumber', 'isHidden', 'archived'],
};
const serviceOrderPlugin = {
  fields: ['patientId', 'integratedCareId', 'archived', 'status'],
};
const serviceTaskPlugin = {
  fields: [
    'patientId',
    'status',
    'archived',
    'appointmentId',
    '_id',
    'serviceOrderId',
    'integratedCareId',
    'isFirstTaskInIntegratedCare',
    'documentation.startDate',
    'documentation.endDate',
  ],
};
const calendarResourcePlugin = {
  fields: ['regionId', 'archived', 'name', 'description'],
};

const serviceCostEstimatePlugin = {
  fields: ['patientId', 'regionId', 'serviceOrderId', 'attachmentId', 'archived'],
};

// [schematics plugin marker create-entity]

export const PluginsToken = new InjectionToken('Plugins', { providedIn: 'root', factory: () => DB_PLUGINS });

const DB_PLUGINS: Record<string, DBPlugin> = {};

DB_PLUGINS[PatientDB] = patientPlugin;
DB_PLUGINS[NursingHomeDB] = nursingHomePlugin;
DB_PLUGINS[NursingServiceDB] = nursingServicePlugin;
DB_PLUGINS[HospitalDB] = hospitalPlugin;
DB_PLUGINS[InsuranceContractDB] = insuranceContractPlugin;
DB_PLUGINS[DoctorDB] = doctorPlugin;
DB_PLUGINS[PayerDB] = payerPlugin;
DB_PLUGINS[PayerInfoDB] = payerInfoPlugin;
DB_PLUGINS[PostalCodeDB] = postalCodePlugin;
DB_PLUGINS[UsersDB] = usersPlugin;
DB_PLUGINS[GroupDB] = groupPlugin;
DB_PLUGINS[RegionDB] = regionPlugin;
DB_PLUGINS[RightsetDB] = rightsetPlugin;
DB_PLUGINS[StandardCareProposalDB] = standardCareProposalPlugin;
DB_PLUGINS[CareProposalDB] = careProposalPlugin;
DB_PLUGINS[IntegratedCareDB] = integratedCarePlugin;
DB_PLUGINS[SingleOrderDB] = singleOrderPlugin;
DB_PLUGINS[ProductGroupDB] = productGroupPlugin;
DB_PLUGINS[ArticleDB] = articlePlugin;
DB_PLUGINS[PharmacyDB] = pharmacyPlugin;
DB_PLUGINS[OrderDB] = orderPlugin;
DB_PLUGINS[AuditDB] = auditPlugin;
DB_PLUGINS[PatientNotesDB] = patientNotesPlugin;
DB_PLUGINS[FaxDB] = faxPlugin;
DB_PLUGINS[ErpOrderDB] = erpOrderPlugin;
DB_PLUGINS[DeviceDB] = devicePlugin;
DB_PLUGINS[AttachmentDB] = attachmentPlugin;
DB_PLUGINS[AttachmentTypeDB] = attachmentTypePlugin;
DB_PLUGINS[AttributeTemplateDB] = attributeTemplatePlugin;
DB_PLUGINS[SubunitDB] = subunitPlugin;
DB_PLUGINS[InstitutionContactDB] = institutionContactPlugin;
DB_PLUGINS[InstitutionNoteDB] = institutionNotePlugin;
DB_PLUGINS[InstitutionAuditDB] = institutionAuditPlugin;
DB_PLUGINS[ReturnDeliveryDB] = returnDeliveryPlugin;
DB_PLUGINS[AppointmentDB] = appointmentPlugin;
DB_PLUGINS[TemplateDB] = templatePlugin;
DB_PLUGINS[ErpTaskDB] = erpTaskPlugin;
DB_PLUGINS[DirectOrderDB] = directOrderPlugin;
DB_PLUGINS[ContractArticleGroupDB] = contractArticleGroupPlugin;
DB_PLUGINS[ReminderDB] = reminderPlugin;
DB_PLUGINS[PlannedOrderDB] = plannedOrderPlugin;
DB_PLUGINS[QuotesDB] = quotesPlugin;
DB_PLUGINS[IntegratedCareReturnDeliveryDB] = integratedCareReturnDeliveryPlugin;
DB_PLUGINS[InstitutionAttributeDB] = institutionAttributePlugin;
DB_PLUGINS[PrescriptionRequestDB] = prescriptionRequestPlugin;
DB_PLUGINS[MedicalOrderRequestDB] = medicalOrderRequestPlugin;
DB_PLUGINS[ServiceDB] = servicePlugin;
DB_PLUGINS[ServiceOrderDB] = serviceOrderPlugin;
DB_PLUGINS[ServiceTaskDB] = serviceTaskPlugin;
DB_PLUGINS[CalendarResourceDB] = calendarResourcePlugin;
DB_PLUGINS[ServiceCostEstimateDB] = serviceCostEstimatePlugin;
// [schematics register marker create-entity]

export default DB_PLUGINS;
