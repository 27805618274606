import { IAppointment } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { BaseStateExtension } from '@common/state/state-extension';
import { AppointmentModelName } from 'src/app/shared/models/model-names';
import { ActivitiesListSortFilterType } from './activities-list-sort.service';

export const ACTIVITY_LIST_STATE_NAME = 'ActivityListAppointment';
@Injectable({ providedIn: 'root' })
export class ActivitiesListStateExtension extends BaseStateExtension<IAppointment> {
  name: string = AppointmentModelName;

  public idToObserve: string;
  public typeToObserve: ActivitiesListSortFilterType;

  public async canBeStored(item: IAppointment): Promise<boolean> {
    if (
      !item ||
      item.archived ||
      this.typeToObserve === null ||
      this.typeToObserve === undefined ||
      !this.idToObserve
    ) {
      return false;
    }
    if (this.typeToObserve === ActivitiesListSortFilterType.patient) {
      return item.patientIds && item.patientIds.includes(this.idToObserve);
    }
    return item.institutionId === this.idToObserve;
  }
  protected async afterCreateInternal(item: IAppointment) {
    await this.stateRegistry.createBySync(AppointmentModelName, ACTIVITY_LIST_STATE_NAME, item);
  }
  protected async afterUpdateInternal(item: IAppointment) {
    await this.stateRegistry.updateBySync(AppointmentModelName, ACTIVITY_LIST_STATE_NAME, [item]);
  }
  protected async removeFromState(item: IAppointment) {
    await this.stateRegistry.removeBySync(AppointmentModelName, ACTIVITY_LIST_STATE_NAME, [item]);
  }
}
