import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'itl-initial-icon',
  templateUrl: './initial-icon.component.html',
  styleUrls: ['./initial-icon.component.scss'],
})
export class InitialIconComponent implements OnChanges {
  @Input() public patientId: string;
  public firstLetter: string;
  public secondLetter: string;
  constructor(private _userService: UserService) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const user = await this._userService.find(changes['patientId'].currentValue);
    if (user) {
      this.firstLetter = user.firstName.charAt(0);
      this.secondLetter = user.lastName.charAt(0);
    }
  }
}
