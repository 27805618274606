import { ICalendarResource } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { BaseStateExtension } from '@common/state/state-extension';
import { CalendarResourceModelName } from '../shared/models/model-names';

export const ALBERTA_CALENDAR_STATE_NAME = 'AlbertaCalendarAppointment';
@Injectable({ providedIn: 'root' })
export class AlbertaCalendarResourceStateExtension extends BaseStateExtension<ICalendarResource> {
  name: string;

  public active = false;

  public async canBeStored(item: ICalendarResource): Promise<boolean> {
    if (this.active === false) {
      return false;
    }
    return item.archived == false;
  }
  protected async afterCreateInternal(item: ICalendarResource) {
    await this.stateRegistry.createBySync(CalendarResourceModelName, ALBERTA_CALENDAR_STATE_NAME, item);
  }
  protected async afterUpdateInternal(item: ICalendarResource) {
    await this.stateRegistry.updateBySync(CalendarResourceModelName, ALBERTA_CALENDAR_STATE_NAME, [item]);
  }
  protected async removeFromState(item: ICalendarResource) {
    await this.stateRegistry.removeBySync(CalendarResourceModelName, ALBERTA_CALENDAR_STATE_NAME, [item]);
  }
}
