import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
  selector: 'itl-number-up-down-2',
  template: `
    <div class="wrapper">
      <itl-icon-button
        [disabled]="minValue ? valueControl.value <= minValue : valueControl.value <= 0"
        class="decrease-button"
        icon="alberta-minus-clear"
        (click)="decrease()"
      ></itl-icon-button>
      <input id="value" type="number" [formControl]="valueControl" [readonly]="inputReadonly" />
      <itl-icon-button class="increase-button" icon="alberta-plus-clear" (click)="increase()"></itl-icon-button>
    </div>
  `,
  styles: [
    `
      .wrapper {
        display: flex;
        align-items: center;
        .decrease-button {
          /* top-left | top-right | bottom-right | bottom-left */
          --button-border-radius: 8px 0 0 8px;
          --button-border-right: 0;
        }
        .increase-button {
          --button-border-radius: 0 8px 8px 0;
          --button-border-left: 0;
        }
        #value {
          padding: 0;
          background-color: white;
          border-top: var(--alberta-color-button-border) 1.5px solid;
          border-bottom: var(--alberta-color-button-border) 1.5px solid;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          color: var(--alberta-color-secondary-variant);
          font-size: 14px;
          font-family: 'alberta-mada-semibold';
          outline: none;
          border-left: 0;
          border-right: 0;
          text-align: center;
          width: 30px;
        }
        /* Remove the arrows from number input */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    `,
  ],
  standalone: true,
  imports: [IconButtonComponent, ReactiveFormsModule],
})
export class NumberUpDown2Component implements OnInit, OnDestroy {
  @Input() value: number;
  @Input() minValue: number;
  @Input() inputReadonly = false;
  @Output() valueChange = new EventEmitter<number>();

  valueControl = new FormControl();

  private _subscription = new Subscription();

  ngOnInit(): void {
    this.valueControl.setValue(this.value);
    this._subscription.add(
      this.valueControl.valueChanges.subscribe(value => {
        if (value == null || (this.minValue && value < this.minValue)) {
          this.valueControl.setValue(this.minValue || 0);
          (document.getElementById('value') as HTMLInputElement).select();
          return;
        }
        this.valueChange.emit(value);
      })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  increase() {
    this.valueControl.setValue(this.valueControl.value + 1);
  }

  decrease() {
    if (this.minValue && this.valueControl.value <= this.minValue) {
      return;
    }
    if (this.valueControl.value <= 0) {
      return;
    }
    this.valueControl.setValue(this.valueControl.value - 1);
  }
}
