import { Inject, Injectable } from '@angular/core';

import { CommandQueue } from './command-queue';
import { CommandResult } from './command-result';
import { CommandSender } from './command-sender';
import { ICommand } from './contracts/command';
import { ICommandBroker } from './contracts/command-broker';
import { ICommandHandler } from './contracts/command-handler';
import { ICommandQueue } from './contracts/command-queue';
import { EntityCommandHandler } from './entity-command-handler';

@Injectable({ providedIn: 'root' })
export class CommandBroker implements ICommandBroker {
  constructor(
    @Inject(EntityCommandHandler) private commandHandlers: ICommandHandler[],
    @Inject(CommandQueue) private _commandQueue: ICommandQueue,
    private readonly _commandSender: CommandSender
  ) {}

  public async handle(command: ICommand): Promise<any | void> {
    const commandResult = await this.sendCommand(command);

    if (!commandResult || commandResult.result) {
      const commandHandler = this.getCommandHandler(command);
      await (commandHandler as any).handle(command);
    }

    if (commandResult) {
      return commandResult.get();
    }

    await this._commandQueue.save(command);
  }

  private getCommandHandler(command: ICommand): ICommandHandler {
    for (const handler of this.commandHandlers) {
      if ((handler as any).canHandle(command)) {
        return handler;
      }
    }
    throw new Error('No CommandHandler defined for command');
  }

  private sendCommand(command: ICommand): Promise<CommandResult> {
    if (command.additionalSyncInfo?.ignoreQueue) {
      return this._commandSender.send(command);
    }
  }
}
