import { Pipe, PipeTransform } from '@angular/core';
import { SelectionItem } from 'src/app/shared/models/selectionItem';

@Pipe({
  name: 'checkboxSelectDisplayPipe',
  pure: false,
})
export class CheckboxSelectDisplayPipe implements PipeTransform {
  constructor() {}
  transform(selected: any[], values: SelectionItem[], compareFn: any) {
    let displayString = '';
    if (selected) {
      selected.forEach(id => {
        const result = values.find(value => {
          if (compareFn) {
            return compareFn(id, value.id);
          }
          return id === value.id;
        });
        if (result) {
          displayString += !displayString ? result.value : `, ${result.value}`;
        }
      });
    }
    return displayString;
  }
}
