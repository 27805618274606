import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { AlbertaCommonComponentsModule } from '../../common/common-components.module';
import { MaintenanceInfoModalComponent } from './maintenance-info-modal';

@NgModule({
  declarations: [MaintenanceInfoModalComponent],
  imports: [CommonModule, IonicModule, AlbertaCommonComponentsModule],
  exports: [MaintenanceInfoModalComponent],
})
export class MaintenanceInfoModalModule {}
