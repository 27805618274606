import { IReminder } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import moment from 'moment';
import {
  AuthenticationToken,
  IAuthenticationAccount,
} from 'src/app/common/contracts/authentication/authentication-account';
import { IStateExtension } from 'src/app/common/contracts/state/state-extension';
import { IStateRegistry } from 'src/app/common/contracts/state/state-registry';
import { filterTodayReminders } from 'src/app/common/filter/dashboard-reminder-filter';
import { ReminderModelName } from 'src/app/shared/models/model-names';

@Injectable({ providedIn: 'root' })
export class ReminderStateExtension implements IStateExtension<IReminder> {
  private _stateRegistry: IStateRegistry;
  private _ttl: Record<string, IReminder> = {};
  public get name(): string {
    return ReminderModelName;
  }

  constructor(@Inject(AuthenticationToken) private _authenticationAccount: IAuthenticationAccount, ngZone: NgZone) {
    ngZone.runOutsideAngular(() => {
      setInterval(async () => {
        const keys = Object.keys(this._ttl);
        for (let index = keys.length - 1; index >= 0; index--) {
          const reminder = this._ttl[keys[index]];
          if (this.checkIfReminderWasYesterday(reminder.date)) {
            await this.removeFromState(reminder);
          }
        }
      }, 60000);
    });
  }

  setRegistry(stateRegistry: IStateRegistry): void {
    this._stateRegistry = stateRegistry;
  }

  public async afterCreate(reminders: IReminder[]): Promise<void> {
    if (!reminders || !reminders.length) {
      return;
    }

    await this._authenticationAccount.ready;

    for (const reminder of reminders) {
      if (this._authenticationAccount.account._id !== reminder.userId || !(await this.canBeStored(reminder))) {
        continue;
      }

      await this._stateRegistry.createBySync(ReminderModelName, 'dashboard', reminder);

      this._ttl[reminder._id] = reminder;
    }
  }

  public async afterUpdate(reminders: IReminder[]): Promise<void> {
    if (!reminders || !reminders.length) {
      return;
    }

    await this._authenticationAccount.ready;

    const myReminders = reminders.filter(reminder => this._authenticationAccount.account._id === reminder.userId);

    for (const reminder of myReminders) {
      if (!(await this.canBeStored(reminder))) {
        await this.removeFromState(reminder);
        continue;
      }

      await this._stateRegistry.createBySync(ReminderModelName, 'dashboard', reminder);
      await this._stateRegistry.updateBySync(ReminderModelName, 'dashboard', [reminder]);

      this._ttl[reminder._id] = reminder;
    }
  }

  private async removeFromState(reminder: IReminder): Promise<void> {
    await this._stateRegistry.removeBySync(ReminderModelName, 'dashboard', [reminder]);
    delete this._ttl[reminder._id];
  }

  public async canBeStored(reminder: IReminder): Promise<boolean> {
    const filteredReminders = filterTodayReminders([reminder]);

    return filteredReminders && !!filteredReminders.length;
  }

  private checkIfReminderWasYesterday(day: Date): boolean {
    const yesterday = moment().subtract(1, 'days').startOf('day');

    return moment(day).isSame(yesterday, 'd');
  }
}
