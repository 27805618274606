import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService<T> {
  protected _eventSubject = new Subject<T>();
  public events = this._eventSubject.asObservable();

  dispatchEvent(event: T) {
    this._eventSubject.next(event);
  }
}
