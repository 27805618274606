import { Component, ElementRef, ViewChild } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { AuthService } from '@services/auth.service';
import { EnvironmentService } from '@services/environment/environment.service';
import { SessionVaultService } from '@services/session-vault/session-vault.service';

@Component({
  selector: 'app-unlock',
  templateUrl: './unlock.page.html',
  styleUrls: ['./unlock.page.scss'],
})
export class UnlockPage {
  @ViewChild('logo') logoElement: ElementRef;
  constructor(
    private navController: NavController,
    public authService: AuthService,
    private sessionVaultService: SessionVaultService,
    private _environmentService: EnvironmentService,
    private _loadingCtrl: LoadingController
  ) {}

  ionViewDidEnter(): void {
    this.logoElement.nativeElement.src = this._environmentService.isMdrBranch()
      ? 'assets/imgs/alberta-mdr.svg'
      : 'assets/imgs/alberta-default.svg';
  }

  async unlockClicked() {
    try {
      if (await this.sessionVaultService.canUnlock()) {
        let result = await this.sessionVaultService.getSessionFromVault();
      } else if (await this.sessionVaultService.isUnlocked) {
        await this.sessionVaultService.refreshUnlockStatus();
      } else {
        //Something went very wrong. This should never happen. User is on lock screen but vault is in undefined state
        window.logger.captureErrorWithExtras('Critical Error: Vault cannot be unlocked');
        await this.logoutClicked();
      }
    } catch (err) {
      window.logger.captureErrorWithExtras('Critical Error during Unlock', err);
    }
  }

  async logoutClicked() {
    const loading = await this._loadingCtrl.create({});
    await loading.present();
    await this.sessionVaultService.clearVaultAndResetLockMode();
    await this.authService.logout();
    await this.navController.navigateRoot('/login');
    window.location.reload();
  }
}
