import { IIntegratedCareReturnDelivery } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {
  IntegratedCareReturnDeliveryComponentModel,
} from 'src/app/business/integrated-care-return-delivery/integrated-care-return-delivery-component-model';

@Injectable()
export class IntegratedCareReturnDeliveryResolver implements Resolve<IIntegratedCareReturnDelivery> {
  constructor(private _returnDeliveryComponentModel: IntegratedCareReturnDeliveryComponentModel) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._returnDeliveryComponentModel.get(route.paramMap.get('returnDeliveryId'));
  }
}
