import { inject, InjectionToken } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Client as TwilioClient } from '@twilio/conversations';
import makeDebug from '../../../../../makeDebug';
import { ChatUserService } from '../chat-user.service';
import { Platform } from '@ionic/angular';

const debug = makeDebug('services:chat:twilio');

export const TWILIO_AGENT_TOKEN = new InjectionToken<Promise<TwilioClient>>('Manually constructed Twilio Service', {
  providedIn: 'root',
  factory: () => _initAgentTwilio(inject(ChatUserService), inject(Platform)),
});

export function _initAgentTwilio(_chatUserService: ChatUserService, _platform: Platform): Promise<TwilioClient> {
  let client: TwilioClient = null;
  return new Promise<TwilioClient>(resolve => {
    if (_platform.is('cordova')) {
      // Agent Chat only available in web
      resolve(null);
    } else {
      _chatUserService.localAgentChatToken$
        .pipe(filter(chatToken => chatToken && chatToken.length > 0))
        .subscribe(async (chatToken: string) => {
          debug('initialize twilio agent chat', chatToken);
          try {
            if (client) {
              await client.updateToken(chatToken);
              return;
            }

            if (chatToken === 'NO_AGENT') {
              resolve(undefined);
              return;
            }

            client = new TwilioClient(chatToken, { logLevel: 'error' });
            debug('... initialized agent twilio!');
            resolve(client);
          } catch (err) {
            console.error('agent twilio init error', err);
          }
        });
    }
  });
}
