import {
  IPredefinedArticle,
  IPredefinedService,
  IStandardCareProposal,
  IStandardCareProposalProductGroupQuantity,
} from '@alberta/konexi-shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { AggregateRoot } from '../aggregate-root';

export class StandardCareProposalDto extends AggregateRoot implements IStandardCareProposal, ISearchable {
  name: string;
  therapyId: number;
  therapyTypeIds: number[];
  assignedContracts: string[];
  sortedProductGroupsWithMaxAmount: IStandardCareProposalProductGroupQuantity[];
  predefinedArticles: IPredefinedArticle[];
  predefinedServices: IPredefinedService[];
  requiredDocumentTags: string[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;

  get displayText() {
    return this.name;
  }

  constructor() {
    super();
    this.assignedContracts = [];
    this.sortedProductGroupsWithMaxAmount = [];
  }
}
