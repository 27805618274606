import { ICalendarResource } from '@alberta/konexi-shared';

import { AggregateRoot } from '../aggregate-root';
import { ISearchable } from '@common/contracts/components/searchable';

export class CalendarResourceDto extends AggregateRoot implements ICalendarResource, ISearchable {
  name: string;
  description: string;
  regionId: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  constructor() {
    super();
  }

  get displayText() {
    return this.name;
  }
}
