import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { ICurrentConfig } from 'cordova-plugin-ionic/dist/ngx/IonicCordova';
import { AuthActions, AuthObserver, IAuthAction, AuthService as MobileOAuthService } from 'ionic-appauth';
import { AppflowChannel } from 'src/environments/appflow-channel';

import { AuthService } from '../auth.service';

@Injectable({ providedIn: 'root' })
// TODO: CodePushService is no longer using CodePush from Microsoft. Rename to AppflowService?
export class CodePushService {
  public get currentDeployment(): string {
    const deploymentComponents = this._currentDeployment ? this._currentDeployment.split('_') : [];
    if (deploymentComponents.length > 1) {
      const platform = deploymentComponents[deploymentComponents.length - 1];

      return platform.toLocaleLowerCase();
    }

    return (this._currentDeployment ?? '').toLocaleLowerCase();
  }
  public deployInfo: ICurrentConfig;
  private _oauthObserver: AuthObserver;
  private _currentDeployment: string;
  constructor(
    private _platform: Platform,
    private _deploy: Deploy,
    private _loadingController: LoadingController,
    public authService: AuthService,

    private _mobileOAuthService: MobileOAuthService,
    private readonly _appflowChannel: AppflowChannel
  ) {}

  public async switchDeployment(channel: string): Promise<string> {
    try {
      await this._appflowChannel.ready;

      await this.setChannel(this._appflowChannel.resetChannelName(channel));
      const checkForUpdateResponse = await this._deploy.checkForUpdate();

      if (!checkForUpdateResponse.available) {
        return;
      }

      await this.performManualUpdate();

      return;
    } catch (error) {
      window.logger.error('Channel update failed.', error);
    }
  }

  public async sync() {
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return;
    }

    await this._deploy.sync({ updateMethod: 'background' });
  }

  public async checkChannel(): Promise<string> {
    try {
      await this._platform.ready();

      if (!this._platform.is('hybrid')) {
        return;
      }

      this.deployInfo = await this._deploy.getConfiguration();
      this._currentDeployment = this.deployInfo.channel;

      return this.currentDeployment;
    } catch (err) {}
  }

  public async setChannel(channel?: string): Promise<void> {
    try {
      await this._platform.ready();
      await this._appflowChannel.ready;

      if (!this._platform.is('hybrid')) {
        return;
      }

      await this._deploy.configure({ channel: channel ?? this._appflowChannel.channel });
      await this.checkChannel();
    } catch (error) {
      window.logger.error(`CodePushService.setChannel(${channel}) failed`, error);
    }
  }

  private async performManualUpdate() {
    try {
      const loading = await this._loadingController.create({
        message: 'Update... <br/> 0 / 100 %',
      });

      await loading.present();

      this._oauthObserver = this._mobileOAuthService.addActionListener(action => this.onSignOutSuccess(action));

      await this._deploy.sync({}, progress => {
        loading.textContent = `Update... <br/> ${progress} / 100 %`;
      });

      await loading.dismiss().catch(error => console.log(error));

      await this.authService.logout();
    } catch (error) {
      window.logger.error('Ionic Appflow deployment failed.', error);
    }
  }

  private async onSignOutSuccess(action: IAuthAction) {
    this._mobileOAuthService.removeActionObserver(this._oauthObserver);

    if (action.action === AuthActions.SignOutSuccess) {
      await this._deploy.reloadApp();
    }
  }
}
