import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Platform } from '@ionic/angular';
import { AuthService as OAuthService } from 'ionic-appauth';
import { Observable } from 'rxjs';

import { SessionVaultService } from '@services/session-vault/session-vault.service';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(
    private _authService: AuthService,
    private _oauthService: OAuthService,
    private _router: Router,
    private _platform: Platform,
    private _sessionVault: SessionVaultService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this._platform.is('hybrid')) {
      return this.checkForMobile(state);
    }

    return this.checkForWeb(state);
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this._platform.is('hybrid')) {
      return this.checkForMobile(state);
    }

    return this.checkForWeb(state);
  }

  private async checkForMobile(state: RouterStateSnapshot): Promise<boolean> {
    await this._authService.init;

    if (!this._oauthService.session.isAuthenticated || !this._authService.authentication.isAuth) {
      await this._router.navigate(['/login']);
      return false;
    }
    return this._oauthService.session.isAuthenticated || this._authService.authentication.isAuth;
  }

  private async checkForWeb(state: RouterStateSnapshot): Promise<boolean> {
    await this._authService.init;

    if (this._authService.authentication.isAuth) {
      return true;
    }

    await this._router.navigate(['/login']);

    return false;
  }
}
