import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import moment from 'moment';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'itl-search-filter-bar',
  templateUrl: './search-filter-bar.component.html',
  styleUrls: ['./search-filter-bar.component.scss'],
})
export class SearchFilterBarComponent implements OnInit, OnDestroy {
  @Input() public filterActive: boolean;
  @Input() public showFilter = true;
  @Input() public showBoxShadow = true;
  @Input() public instantSearch = false;
  @Input() public placeholder = 'Suche';
  @Input() public dateField: string;
  @Input() public value: string;
  @Input() public disabled: boolean;
  @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public filterTypePressed: EventEmitter<null> = new EventEmitter<null>();
  @Output() public searchValueChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public searchValueReset: EventEmitter<null> = new EventEmitter<null>();
  @Output() public resetFilter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('searchInput', { static: true }) searchElement: ElementRef;

  private _search$$: Subscription;

  constructor(private _platform: Platform) {}
  ngOnDestroy(): void {
    if (this._search$$) {
      this._search$$.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.instantSearch) {
      return;
    }

    const inputElement = this.searchElement.nativeElement;

    fromEvent(inputElement, 'keyup')
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(() => this.search());
  }

  public search(): void {
    this.valueChange.emit(this.value);
    this.searchValueChanged.emit(this.toSearchTerm(this.value));
  }

  public filterType(): void {
    this.filterTypePressed.emit();
  }

  private toSearchTerm(query: string): string {
    if (query == null) {
      return '';
    }
    return query.split(' ').reduce((term, word) => {
      if (word.trim()) {
        if (moment(word, 'DD.MM.YYYY', true).isValid()) {
          // tslint:disable-next-line:no-parameter-reassignment
          word = moment(word, 'DD.MM.YYYY', true).format('YYYY-MM-DD');
          if (this.dateField != null && this.dateField !== '') {
            word = `${this.dateField}:${word}`;
          }
        }
        // tslint:disable-next-line:no-parameter-reassignment
        term = `${term}${term.length > 0 ? ' ' : ''}${word}`;
      }
      return term;
    }, '');
  }
  resetSearch() {
    this.value = '';
    this.valueChange.emit(this.value);
    this.searchValueReset.emit();
  }

  resetFilterclicked() {
    this.resetFilter.emit();
  }
}
