import { ArticleStatus } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'articleStatusPipe',
  pure: true,
})
export class ArticleStatusPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(item: ArticleStatus): string {
    let articleStatus = '';
    switch (item) {
      case ArticleStatus.Unknown:
        articleStatus = this._translate.instant('article.edit.status.unknown');
        break;
      case ArticleStatus.Available:
        articleStatus = this._translate.instant('article.edit.status.available');
        break;
      case ArticleStatus.Locked:
        articleStatus = this._translate.instant('article.edit.status.locked');
        break;
      case ArticleStatus.DontUse:
        articleStatus = this._translate.instant('article.edit.status.dontUse');
        break;
      case ArticleStatus.NotAvailable:
        articleStatus = this._translate.instant('article.edit.status.notAvailable');
        break;
      case ArticleStatus.Delayed:
        articleStatus = this._translate.instant('article.edit.status.delayed');
        break;
      default:
        articleStatus = this._translate.instant('article.edit.status.unknown');
        break;
    }
    return articleStatus;
  }
}
