import { IModel, IUser } from '@alberta/konexi-shared';
import { NgZone, inject } from '@angular/core';
import { Paginated, Params, Query, Service } from '@feathersjs/feathers';
import { cloneDeep, upperFirst } from 'lodash';
import { SyncTimestampWorkItem } from 'src/app/business/timestamp/sync-timestamp-work-item';
import { ConnectionMode } from 'src/app/common/contracts/connection/connection-mode';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { LogLevel } from 'src/app/common/contracts/logging/log-level';
import { IPlatformSync } from 'src/app/common/contracts/sync/platform-sync';
import { ISyncState } from 'src/app/common/contracts/sync/sync-state';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';
import { paramsForServer } from 'src/app/common/feathers/params-for-server';
import { trace } from 'src/app/common/tracing/function-call-tracer';
import { ObservableModel } from 'src/app/common/viewmodel/observable-model';

import { ICancellationToken } from '../../../common/contracts/cancellation/cancellation-token';
import { MaintenanceModelName, PatientAppUserModelName, UsersModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { IAdditionalSyncData } from '../contracts/sync/additional-sync-data';
import { IDatabaseSynchronizer } from '../contracts/sync/database-synchronizer_T';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { IFullSyncService } from '../contracts/sync/service/full-sync-service';
import { ISyncService } from '../contracts/sync/service/sync-service';
import { EventService } from '../event.service';
import { TrackerService } from '@services/tracker.service';

export abstract class SyncService<T extends IModel> implements ISyncService, IFullSyncService {
  public get name(): string {
    return this._name;
  }

  protected _service: Service<any>;
  private _syncTimestampWorkItem: SyncTimestampWorkItem;
  protected _workItem: IWorkItem<T> | IDatabaseSynchronizer<T>;
  protected limit = 2000;
  protected ignoreSync = false;
  protected ignoreState: boolean;
  protected _trackerService: TrackerService;

  constructor(
    protected _name: string,
    protected _appController: IAppController,
    protected _dispatcher: IDispatcher<T>,
    protected _eventService: EventService<SyncProgressEvent>,
    private _ngZone: NgZone,
    protected platformSync: IPlatformSync
  ) {
    // using inject() instead of constructor to avoid forcing all extenders
    // of SyncService to also inject via constructor
    // because they need to call super() when subclassing
    this._trackerService = inject(TrackerService);
    if (!this._name) {
      throw new Error('Name of service cannot be empty or null/undefined.');
    }

    this._syncTimestampWorkItem = this._appController.getWorkItemByCtor(
      'SyncTimestampWorkItem'
    ) as SyncTimestampWorkItem;

    this._workItem = this._appController.getWorkItemByCtor(`${upperFirst(this._name)}WorkItem`);
  }

  @trace()
  public resolve(): ISyncService[] {
    return [this];
  }

  public abstract canSync(channel: string): boolean;

  @trace()
  protected patchParams(params: Params): Params {
    return params;
  }

  @trace()
  protected afterSync(syncState: ISyncState<T>): Promise<void> {
    return;
  }

  @trace()
  protected async afterPatch(entity: T): Promise<void> {
    return;
  }

  @trace()
  public async write(payload: IModel, additionalData: IAdditionalSyncData, channel: string, user): Promise<T> {
    if (!payload) {
      throw new Error('Payload to sync must be a valid object of type IModel');
    }

    const method = channel.substring(channel.lastIndexOf(':') + 1);

    switch (method) {
      case 'create': {
        const params = this.getParamsWithAuth(user);
        this._trackerService.debugSyncServiceCreate(this._name, payload?._id);
        return this._service.create(payload, params);
      }
      case 'delete': {
        this._trackerService.debugSyncServiceRemove(this._name, payload?._id);
        return this._service.remove(payload._id, this.getParamsWithAuth(user));
      }
      case 'get': {
        this._trackerService.debugSyncServiceGet(this._name, payload?._id);
        return this._service.get(payload._id, this.getParamsWithAuth(user));
      }
      case 'update': {
        this._trackerService.debugSyncServiceUpdate(this._name, payload?._id);
        const params = this.getParamsWithAuth(user);
        return this._service.update(payload._id, payload, params);
      }
      case 'patch': {
        try {
          const params: Params = { query: {} };
          if (additionalData.changes) {
            const { query } = params;
            paramsForServer({
              query,
              syncData: this.createSyncData(user, additionalData),
            });
          }
          this._trackerService.debugSyncServicePatch(this._name, payload?._id);
          const result = await this._service.patch(payload._id, payload, params);
          await this.afterPatch(result);

          return result;
        } catch (error) {
          if (error && error.code && error.code === 403) {
            return null;
          }
          throw error;
        }
      }
    }
  }

  @trace()
  public async setup(feathersAppProvider: IFeathersAppProvider): Promise<void> {
    await feathersAppProvider.ready;

    if (!feathersAppProvider.app) {
      throw new Error('Feathers application must be provided for synchronisation.');
    }

    this._service = feathersAppProvider.app.service(this._name);

    this.removeAllListeners();

    this._service.on('created', item => this.onCreated(item));
    this._service.on('updated', item => this.onPatched(item));
    this._service.on('patched', item => this.onPatched(item));
    this._service.on('removed', item => this.onRemoved(item));
  }

  @trace()
  public removeAllListeners(connectionMode?: ConnectionMode): void {
    if (connectionMode && connectionMode === ConnectionMode.maintenance && this._name === MaintenanceModelName) {
      return;
    }

    this._service.removeAllListeners('created');
    this._service.removeAllListeners('updated');
    this._service.removeAllListeners('patched');
    this._service.removeAllListeners('removed');
  }

  @trace()
  protected async syncInternal(
    token: ICancellationToken,
    user: any,
    timestamp: Date,
    initialSyncStart: Date,
    resetServiceRepeats: (token: ICancellationToken, serviceName: string) => void,
    resetSyncTimestamp: (data) => Promise<void>
  ): Promise<any> {
    if (!this._service) {
      throw new Error(
        `Service with name ${this._name} could not be found. Please specify a correct name for your synchronisation
        service or take care your service instance is running.`
      );
    }

    if (this.ignoreSync) {
      return;
    }

    token.syncCompleted[this._name] = false;

    this._trackerService.debugSyncServiceSyncInternalStart(this._name, timestamp, initialSyncStart);

    try {
      let query: Query = {
        $limit: 0,
        timestamp: { $gt: timestamp },
        $sort: { timestamp: 1 },
      };
      if (this._workItem.deletable && timestamp <= initialSyncStart) {
        // Initial Sync not completed
        // Archived items should not be in the local database.
        // It is needed to sync archived items anyway if they changed after initialSyncStart, as they could have been
        // archived recently and we might have an outdated, non-archived version of the same item in the database
        // that needs to be updated (deleted)
        query.$or = [{ archived: { $ne: true } }, { timestamp: { $gt: initialSyncStart } }];
      }

      let result: any;
      // If the result is not paginated we need to query at least once since the query before limits to 0
      let total = 1;
      let skip = 0;
      let progress = 0;

      if (!token.cancelled.get()) {
        result = await this._service.find(this.patchParams(this.getParamsWithAuth(user, query)));
        resetServiceRepeats(token, this._name);

        total = this.isPaginated(result) ? result.total : total;
        this._eventService.dispatchEvent(
          new SyncProgressEvent(this._name, this.isPaginated(result) ? result.total : result.length, progress)
        );
      }

      while (total > 0 && !token.cancelled.get()) {
        await this.sleep();

        query = { ...query, $limit: this.limit, $skip: skip };

        if (token.cancelled.get()) {
          break;
        }

        result = await this._service.find(this.patchParams(this.getParamsWithAuth(user, query)));
        resetServiceRepeats(token, this._name);

        const data = this.isPaginated(result) ? result.data : result;

        const syncState = await (this._workItem as IDatabaseSynchronizer<T>).sync(data, token);
        await this._dispatcher.sync(this._name, syncState);
        const payload = [...syncState.created, ...syncState.deleted, ...syncState.updated];

        await resetSyncTimestamp({ payload, documentCount: result.documentCount });
        await this.afterSync(syncState);

        try {
          progress += payload.length;
          this._eventService.dispatchEvent(
            new SyncProgressEvent(this._name, this.isPaginated(result) ? result.total : result.length, progress)
          );
        } catch (e) {}
        this.limit = this.isPaginated(result) ? result.limit : this.limit;

        total -= this.limit;
        skip += this.limit;
      }

      token.syncCompleted[this._name] = total <= 0;
      this._trackerService.debugSyncServiceSyncInternalDone(
        this._name,
        timestamp,
        await this._syncTimestampWorkItem.getSyncTimestamp(this._name),
        initialSyncStart,
        token.syncCompleted[this._name]
      );

      if (token.cancelled.get()) {
        delete token.serviceRepeats[this._name];
        throw {
          reason: 'cancelled',
          service: this._name,
          progress: `progress: ${progress}`,
          total: `total: ${total}`,
          level: LogLevel.silent,
        };
      }
    } catch (error) {
      window.logger.error(`SYNCSERVICE SYNC / ${this._name}`, error, error.level);
      console.log(error);
      this._eventService.dispatchEvent(new SyncProgressEvent(this._name, -1, -1, true));
      throw error;
    }
  }

  @trace()
  public async sync(
    token: ICancellationToken,
    user: any,
    resetServiceRepeats: (token: ICancellationToken, serviceName: string) => void
  ): Promise<any> {
    const timestamps = await this.loadSyncTimestamps();
    return this.syncInternal(
      token,
      user,
      timestamps.last,
      timestamps.start,
      resetServiceRepeats,
      async data => await this._syncTimestampWorkItem.setSyncTimestamp(this._name, data.payload, timestamps.start)
    );
  }

  @trace()
  public async reSync(
    token: ICancellationToken,
    user: any,
    userSync: any,
    resetServiceRepeats: (token: ICancellationToken, serviceName: string) => void
  ): Promise<any> {
    if (
      !userSync ||
      !userSync.status ||
      !userSync.status.length ||
      !userSync.status.some(value => value.name === this._name)
    ) {
      return;
    }

    const serviceStatus = userSync.status.find(value => value.name === this._name);

    const userClone = cloneDeep(user);
    userClone.authorization.regions = [...serviceStatus.regions];

    const initialSyncStart = (await this.loadSyncTimestamps()).start;
    await this.syncInternal(
      token,
      userClone,
      serviceStatus.timestamp,
      initialSyncStart,
      resetServiceRepeats,
      async data => {
        const syncMaxTimestamp = await this._syncTimestampWorkItem.getSyncTimestamp(this._name);
        const payloadMaxTimestamp = this._syncTimestampWorkItem.getLatestDate(data.payload);

        serviceStatus.timestamp = new Date(payloadMaxTimestamp || 0);

        if (serviceStatus.timestamp <= syncMaxTimestamp) {
          return;
        }

        await this._syncTimestampWorkItem.setSyncTimestamp(this._name, data.payload, initialSyncStart);
      }
    );

    userSync.status = userSync.status.filter(value => value.name !== this._name);
  }

  @trace()
  public async countDocuments(user: IUser): Promise<number> {
    const query: any = { $limit: 0, timestamp: { $gt: new Date(0) } };
    if (this._workItem.deletable) {
      query.archived = { $ne: true };
    }

    const result = await this._service.find(this.patchParams(this.getParamsWithAuth(user, query)));

    return result.total || result.length;
  }

  @trace()
  protected async onCreated(data: any): Promise<void> {
    if (this.platformSync.canBeSynced) {
      await (this._workItem as IWorkItem<T>).create(data);
    }
  }

  @trace()
  protected async onPatched(data: any): Promise<void> {
    // update database only when sync mode is on or when patch is for users
    if (this.platformSync.canBeSynced || this._name === UsersModelName || this._name === PatientAppUserModelName) {
      await (this._workItem as IDatabaseSynchronizer<T>).sync(data, {
        cancelled: new ObservableModel(false, false),
        promise: undefined,
        cancel: () => undefined,
        reset: () => undefined,
      });
    }

    if (this.ignoreState) {
      return;
    }
    await this._dispatcher.updateState(this._name, [data]);
  }

  @trace()
  protected async onRemoved(data: any): Promise<void> {
    if (this.platformSync.canBeSynced) {
      await (this._workItem as IWorkItem<T>).delete(data);
    }

    if (this.ignoreState) {
      return;
    }
    await this._dispatcher.removeFromState(this._name, data);
  }

  @trace()
  protected sleep(ms?: number) {
    return new Promise(resolve => this._ngZone.runOutsideAngular(() => setTimeout(resolve, ms || 0)));
  }

  @trace()
  protected getParamsWithAuth(user, query = {}): Params {
    const paramsWithAuth = { query: { ...query } };
    if (user && user.authorization) {
      paramsForServer({
        query: paramsWithAuth.query,
        syncData: this.createSyncData(user),
      });
    }

    return paramsWithAuth;
  }

  @trace()
  private createSyncData(user: any, additionalData?: IAdditionalSyncData): any {
    let syncData = {
      authorization: user.authorization,
      emailAPI:
        user.organization.alberta && user.organization.alberta.emailAPI ? user.organization.alberta.emailAPI : false,
      emailTo: user.organization.alberta && user.organization.alberta.emailTo ? user.organization.alberta.emailTo : [],
      returnDeliveryEmailAPI:
        user.organization.alberta && user.organization.alberta.returnDeliveryEmailAPI
          ? user.organization.alberta.returnDeliveryEmailAPI
          : false,
      returnDeliveryEmailTo:
        user.organization.alberta && user.organization.alberta.returnDeliveryEmailTo
          ? user.organization.alberta.returnDeliveryEmailTo
          : [],
      tenantId: user.organization.tenantId,
    };
    if (additionalData) {
      if (additionalData.additionalSyncInfo) {
        syncData = { ...syncData, ...additionalData.additionalSyncInfo };
      }
      if (additionalData.changes) {
        syncData['changes'] = additionalData.changes;
      }
    }

    return syncData;
  }

  @trace()
  protected isPaginated(paginated: any[] | Paginated<any>): paginated is Paginated<any> {
    return paginated && (paginated as Paginated<any>).data !== undefined;
  }

  private async loadSyncTimestamps(): Promise<{ start: Date; last: Date }> {
    const last = await this._syncTimestampWorkItem.getSyncTimestamp(this._name);
    let start = await this._syncTimestampWorkItem.getInitialSyncStart(this._name);
    if (start === null) {
      if (last.valueOf() === 0) {
        // initial sync
        start = new Date();
      } else {
        // Sync did run in the past, without initialSync timestamp getting set.
        // This happens in older versions of Alberta.
        // Setting initialSyncStart to the past.
        start = new Date(0);
      }
    }
    return { start, last };
  }
}
