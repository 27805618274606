<div class="text-input">
  <div class="checkmark">
    <ion-icon *ngIf="value.length" name="alberta-save"></ion-icon>
  </div>
  <div class="input__wrapper" [ngClass]="{ 'input__wrapper--selected': value.length }">
    <input [placeholder]="placeholder" [(ngModel)]="value" (change)="emitChange()" />
    <div class="clear-input" tappable *ngIf="value.length" (click)="clearInput()">
      <ion-icon name="alberta-cancel"></ion-icon>
    </div>
  </div>
</div>
<div class="input-description">
  <span *ngIf="value.length">{{ placeholder }}</span>
</div>
