import { Query } from '@feathersjs/feathers';
import { flattenDeep, remove } from 'lodash';
import { IQuery } from 'src/app/shared/services/contracts/query/query';
import { validate } from 'uuid';

import { AggregateStage } from './aggregate-stage.interface';
import { DBPlugin } from './index-plugins';

export interface ISearchOptions {
  getAll?: boolean;
  operator?: 'AND' | 'OR';
  keyValuePairs?: string;
  table: string;
  parseQuery(user: any, plugin: DBPlugin): { query?: Query; aggregate?: AggregateStage[] };
}

export class SearchOptions implements ISearchOptions {
  getAll?: boolean;
  keyValuePairs?: string;

  constructor(public table: string, public operator: 'AND' | 'OR' = 'AND') {}

  public static create(query: IQuery, table: string, plugin: DBPlugin): ISearchOptions {
    const searchOptions: ISearchOptions = new SearchOptions(table);
    if (query.query === '***') {
      searchOptions.getAll = true;
      return searchOptions;
    }

    if (query.isIn && query.queryList && query.queryList.length) {
      query.query = query.queryList.shift().join(' ');
    }

    query.query = query.query.replace(/\+/g, '').trim();

    searchOptions.keyValuePairs = query.query.split(' ').reduce((searchTerm, word) => {
      const indexOfColon = word.indexOf(':');

      return `${searchTerm}${searchTerm.length > 0 ? ' ' : ''}${
        indexOfColon > -1 ? `${SearchOptions.modifyValue(word, plugin)}*` : `${word.toLocaleLowerCase()}*`
      }`;
    }, '');

    if (query.isIn) {
      searchOptions.operator = 'OR';
    }

    return searchOptions;
  }

  private static modifyValue(query: string, plugin: DBPlugin): string {
    const [key, value] = query.split(':');

    if (key && value) {
      return `${key}:${plugin && plugin.modifier && plugin.modifier[key] ? plugin.modifier[key](value) : value}`;
    }

    return query;
  }

  public parseQuery(user: any, plugin: DBPlugin): { query?: Query; aggregate?: AggregateStage[] } {
    if (this.isValidOrOperator()) {
      // queryService.search({ query: "_id:123 _id:456 _id:789" }, DbName, { isIn: true });
      // queryService.search({ query: "fieldNurseId:9900-xxx additionalUserId:123-yzyz" }, DbName);
      const keyvaluePairs = this.keyValuePairs.split(' ');
      // check how many unique keys are present
      const uniqueKeys = [
        ...new Set(
          keyvaluePairs.reduce((acc, kvp) => {
            const [key] = kvp.split(':');
            if (key) {
              acc.push(key);
            }
            return acc;
          }, [])
        ),
      ];

      // only _id:123 _id:456 _id:789
      if (uniqueKeys.length === 1) {
        const ids = keyvaluePairs.map(keyValuePair => `${keyValuePair.split(':')[1].replace('*', '')}`);
        return {
          query: { [`${uniqueKeys[0]}`]: { $in: ids } },
        };
      } else if (uniqueKeys.length > 1) {
        // fieldNurseId:123-xxx additionalUserId:987-zzz
        const keyValues: Record<string, string> = {};
        this.keyValuePairs.split(' ').forEach(keyValuePair => {
          const [key, value] = keyValuePair.split(':');
          keyValues[key] = value.replace('*', '');
        });

        return {
          query: { $or: Object.entries(keyValues).map(([key, value]) => ({ [key]: value })) },
        };
      }
    } else if (this.isValidAndOperator()) {
      if (this.keyValuePairs.includes(':') && this.keyValuePairs.split(' ').every(kvp => kvp.includes(':'))) {
        // queryService.search({ query: "patientId:1234" }, DbName);
        const keyValues: Record<string, string | number> = {};

        this.keyValuePairs.split(' ').forEach(keyValuePair => {
          const [key, value] = keyValuePair.split(':');
          // edge case search for attachments by attachmenttype
          if (key === 'metadata.type') {
            keyValues[key] = parseInt(value, 10);
          } else {
            keyValues[key] = value.replace('*', '');
          }
        });
        return {
          query: keyValues,
        };
      } else if (this.isSearchTermUUID()) {
        // queryService.search({query: "f0a2c003-452e-4dda-9882-4bdae4bab966"}, DbName);

        return { query: { _id: this.keyValuePairs.replace('*', '') } };
      } else if (this.isSearchTermOneWordWithHyphens()) {
        // queryService.search({query: "Karl-Hinrichs-Stift"}, DbName);
        return {
          aggregate: [
            {
              $search: {
                index: `${plugin.db || user.organization.tenantId}_${this.table}`,
                compound: {
                  must: this.keyValuePairs.split('-').map(value => ({
                    wildcard: { query: [`${value}`], allowAnalyzedField: true, path: { wildcard: '*' } },
                  })),
                },
              },
            },
          ],
        };
      } else {
        // queryService.search({query: "Markus"}, DbName);
        // queryService.search({query: "Mar Mül"}, DbName);
        // queryService.search({query: "Mar Mül fieldNurseId:1234"}, DbName);

        // this.keyValuePairs.split(' ').filter(kvp => !kvp.includes(':') && kvp.includes('-'))
        const searchTerms = this.keyValuePairs.split(' ');
        const termsWithoutColon = searchTerms.filter(kvp => !kvp.includes(':'));
        const termsWithTwoDollarSigns = remove(termsWithoutColon, kvp => kvp.includes('$$')).map(kvp =>
          kvp.replace('*', '')
        );
        const termsWithHyphens = remove(termsWithoutColon, kvp => kvp.includes('-'));

        // Mar* Mül*
        const mustOptions = termsWithoutColon.map(kvp => ({
          wildcard: { query: [`*${kvp}`], allowAnalyzedField: true, path: { wildcard: '*' } },
        }));

        // Exact text in path match: organization$$5b0d3b9z664ac748c486b985
        const mustMatchTextOptions = termsWithTwoDollarSigns.map(term => ({
          text: { query: [`${term.split('$$')[1]}`], path: term.split('$$')[0] },
        }));

        // Karl-Wilhelm-Stift
        mustOptions.push(
          ...flattenDeep(termsWithHyphens.map(kvp => kvp.split('-'))).map(kvp => ({
            wildcard: { query: [`*${kvp}`], allowAnalyzedField: true, path: { wildcard: '*' } },
          }))
        );

        // fieldNurseId:1234-xxx
        const shouldOptions = searchTerms
          .filter(kvp => kvp.includes(':'))
          .map(kvp => ({
            text: {
              query: `${kvp.split(':')[1].replace('*', '')}`,
              path: kvp.split(':')[0],
            },
          }));

        return {
          aggregate: [
            {
              $search: {
                index: `${plugin.db || user.organization.tenantId}_${this.table}`,
                compound: {
                  must: [...mustOptions, ...mustMatchTextOptions],
                  should: shouldOptions && shouldOptions.length ? shouldOptions : undefined,
                },
              },
            },
          ],
        };
      }
    }
  }

  private isValidOrOperator() {
    return this.operator === 'OR' && Boolean(this.keyValuePairs);
  }

  private isValidAndOperator() {
    return this.operator === 'AND' && Boolean(this.keyValuePairs);
  }

  private isSearchTermUUID(): boolean {
    const value = this.keyValuePairs.replace('*', '');
    return (
      !this.keyValuePairs.includes(':') &&
      !this.keyValuePairs.includes(' ') &&
      (validate(value) || new RegExp('^[0-9a-fA-F]{24}$').test(value))
    );
  }

  private isSearchTermOneWordWithHyphens(): boolean {
    return !this.keyValuePairs.includes(':') && !this.keyValuePairs.includes(' ') && this.keyValuePairs.includes('-');
  }
}
