import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { BrMaskerModule } from 'br-mask';
import { GeneralPipesModule } from 'src/app/common/pipes/general-pipes.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';

import { InputTemplatesModule } from '../_templates/input-templates.module';
import { TextComponent } from './text.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    GeneralPipesModule,
    InputTemplatesModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'always' }),
    BrMaskerModule,
    DirectivesModule,
  ],
  declarations: [TextComponent],
  exports: [TextComponent],
})
export class TextComponentModule {}
