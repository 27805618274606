import { ICareProposal } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { CareProposalWorkItem } from 'src/app/business/care-proposal/care-proposal-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class CareProposalCommandHandler extends EntityCommandHandler<ICareProposal> {
  protected workItem: IWorkItem<ICareProposal>;

  constructor(careProposalWorkItem: CareProposalWorkItem) {
    super();
    this.workItem = careProposalWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'CareProposalDto';
  }
}
