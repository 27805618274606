import { NgModule } from '@angular/core';

import { AllSelectedPipe } from './checkAllSelected-pipe';
import { CheckboxSelectDisplayPipe } from './checkboxselectdisplay-pipe';
import { IsSelectedPipe } from './isselected-pipe';
import { SelectionDisplayTextPipe } from './selectiondisplaytext-pipe';

@NgModule({
  declarations: [SelectionDisplayTextPipe, CheckboxSelectDisplayPipe, IsSelectedPipe, AllSelectedPipe],
  exports: [SelectionDisplayTextPipe, CheckboxSelectDisplayPipe, IsSelectedPipe, AllSelectedPipe],
})
export class ComponentsPipesModule {}
