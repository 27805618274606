import { Inject, Injectable } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { CommandQueue } from 'src/app/commands/command-queue';
import { ICommandQueue } from 'src/app/commands/contracts/command-queue';

import makeDebug from '../../../../makeDebug';
import { NetworkInfoService } from '../../services/network/network-info.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement, SyncElementResult } from '../contracts/sync/sync-element';
import { CommandQueueRunner as Runner } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:command-queue-runner');

@Injectable()
export class CommandQueueRunner implements ISyncElement {
  public get name(): string {
    return Runner;
  }

  constructor(
    @Inject(CommandQueue) private _commandQueue: ICommandQueue,
    private readonly _networkInfoService: NetworkInfoService
  ) {}

  public async execute(context: ISyncContext): Promise<SyncElementResult> {
    debug('entered execute function', context);
    if (!context.params.isOnline || this._networkInfoService.isNotConnected) {
      return { contextId: context.id, notConnected: true, jobDone: false };
    }

    debug('calling sync of command-queue');
    await this._commandQueue.sync(context.params.queueToken);

    return new Promise(async resolve => {
      await this._commandQueue.ready;

      this._commandQueue.queue$.pipe(takeWhile(busy => busy)).subscribe({
        complete: () => {
          debug('queue observable completed.');
          resolve({ contextId: context.id, notConnected: false, queue$completed: true, jobDone: true });
        },
      });
    });
  }
}
