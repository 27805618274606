import { IOrder } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import {
  AuthenticationToken,
  IAuthenticationAccount,
} from 'src/app/common/contracts/authentication/authentication-account';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { ISyncState } from 'src/app/common/contracts/sync/sync-state';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';
import {
  DoctorFreudiger,
  DoctorPeter,
  DoctorWohlgemuth,
  isRelevantDoctor,
} from 'src/app/notifications/config/web-doctor-config';
import { OrderModelName } from '../../models/model-names';
import { WebSyncService } from './web-sync.service';

@Injectable()
export class WebOrderSyncService extends WebSyncService {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) private _dispatcher: IDispatcher<IOrder>,
    ngZone: NgZone,
    @Inject(AuthenticationToken) private _authenticationAccount: IAuthenticationAccount
  ) {
    super(OrderModelName, ngZone, appController);
  }

  protected async beforeSync(): Promise<void> {
    await this._authenticationAccount.ready;
    const currentUserId = this._authenticationAccount.account._id;
    switch (currentUserId) {
      case DoctorPeter.userId:
        this.defaultQuery = {
          doctorId: DoctorPeter.doctorId,
          archived: { $ne: true },
          status: 1,
        };
        break;
      case DoctorWohlgemuth.userId:
        this.defaultQuery = {
          doctorId: DoctorWohlgemuth.doctorId,
          archived: { $ne: true },
          status: 1,
        };
        break;
      case DoctorFreudiger.userId:
        this.defaultQuery = {
          doctorId: DoctorFreudiger.doctorId,
          archived: { $ne: true },
          status: 1,
        };
        break;
    }
  }

  protected async canBeSynced(): Promise<boolean> {
    await this._authenticationAccount.ready;
    return isRelevantDoctor(this._authenticationAccount.account._id);
  }

  protected async handleData(data: any): Promise<any> {
    const syncState: ISyncState<IOrder> = { updated: data, created: [], deleted: [] };
    await this._dispatcher.sync(OrderModelName, syncState);

    return [...syncState.created, ...syncState.deleted, ...syncState.updated];
  }
}
