import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { PortalNotificationDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { PortalNotificationDto } from 'src/app/shared/models/portal-notification/portal-notification-dto-model';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class PortalNotificationWorkItem extends WorkItem<PortalNotificationDto> {
  constructor(@Inject(UnitOfWorkFactory) _unitOfWorkFactory: IUnitOfWorkFactory) {
    super(_unitOfWorkFactory);
  }

  get name(): string {
    return 'PortalNotificationWorkItem';
  }
  get database(): string {
    return PortalNotificationDB;
  }
}
