<div
  *ngIf="showMaintenance && countdownInfo"
  class="maintenance-snack-bar"
  [ngClass]="{ 'maintenance-snack-bar--one-hour-left': countdownInfo.countdown.lessThanOneHour }"
>
  <div class="icon-wrapper">
    <ion-icon name="alberta-hammer"></ion-icon>
  </div>
  <div class="maintenance-text">Wartung {{ countdownInfo.startEndText }} in {{ countdownInfo.countdown.text }}</div>
  <div class="closeButton" tappable (click)="closeSnackBar()" *ngIf="countdownInfo.countdown.moreThanOneDay">
    <ion-icon name="alberta-cancel"></ion-icon>
  </div>
</div>
<div class="content" [ngClass]="{ 'content--hasSnackBar': showMaintenance && countdownInfo }">
  <ng-content></ng-content>
</div>
