import { Inject, Injectable, Injector } from '@angular/core';
import { Messaging, getToken, isSupported, onMessage } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { Platform, ToastController } from '@ionic/angular';
import { Client as TwilioClient } from '@twilio/conversations';
import { from } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import makeDebug from 'src/makeDebug';
import { AuthService } from '../auth.service';
import { TWILIO_AGENT_TOKEN } from '../chat/twillio/agent-twilio.factory';

import { IAuthEventData } from '@common/contracts/authentication/auth-event-data';

const debug = makeDebug('services:push');
const pushMarker = new RegExp(/^(?<userId>[0-9a-fA-F]{9})?\|(?<appMarker>[AP])#/);

@Injectable({ providedIn: 'root' })
export class PushService {
  constructor(
    private platform: Platform,
    @Inject(TWILIO_AGENT_TOKEN) private readonly _twilioAgentChatClient: Promise<TwilioClient>,
    private _authService: AuthService,
    private readonly router: Router,
    private readonly _toastController: ToastController,
    private injector: Injector
  ) {}

  public init() {
    from(isSupported())
      .pipe(
        filter(supported => supported === true),
        switchMap(() => this._authService.authenticatedEventPublisher),
        filter((auth: IAuthEventData) => auth.isAuthenticated),
        map(() => this._authService.authentication.account.isAgent && this.platform.is('cordova') === false),
        filter((shouldRegisterPush: boolean) => shouldRegisterPush),
        take(1),
        tap(() => this.registerWeb())
      )
      .subscribe();
  }

  private async registerWeb() {
    debug('registerWeb');
    const messaging = this.injector.get(Messaging);

    const client = await this._twilioAgentChatClient;
    if (!client) {
      return;
    }

    try {
      // getToken also displays permissions dialog
      const fcmToken = await getToken(messaging);

      try {
        const serviceWorkerRegistrations = await navigator?.serviceWorker.getRegistrations();

        for (const registration of serviceWorkerRegistrations) {
          if (registration.active) {
            registration.active.postMessage({ userId: this._authService.getCurrentUserId() });
          }
        }
      } catch (error) {
        console.error('Failed to send current UserId to ServiceWorker');
      }

      await client.setPushRegistrationId('fcm', fcmToken);
      debug(`push notification for  'patient-app' registered`);

      const self = this;

      /*
        Handle push messages when the app is in the foreground - background is handled in firebase-messaging-sw.js
      */
      onMessage(messaging, async function (payload: any) {
        let message = payload.data.twi_body;
        const matches = payload.data.twi_body.match(pushMarker);

        // messages to assigned users have a pushMarker - check if we are assigned, if not dont display the message
        if (matches) {
          message = message.substr(12);
          if (matches.groups?.userId !== self._authService.authentication.account._id.slice(-9)) {
            // this is no message for me
            return client.handlePushNotification(payload);
          }
        }

        if (self.router.url.startsWith('/agent-chat')) {
          return client.handlePushNotification(payload);
        }

        const toastMessage = await self._toastController.create({
          header: `Neue Nachricht von Patient ${payload.data.author}`,
          message: `${message.substring(0, 40)}${message.length > 40 ? '...' : ''}`,
          position: 'bottom',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
            },
          ],
        });
        await toastMessage.present();
        return client.handlePushNotification(payload);
      });
    } catch (e) {
      console.error(e);
    }
  }
}
