<div class="wrapper">
  <div class="checkbox">
    <div class="checkbox-wrapper">
      <ion-checkbox
        data-testid="multi-autocomplete-item-checkbox"
        checked="false"
        *ngIf="!(item | isSelected: selected)"
        (click)="toggled()"
      ></ion-checkbox>
      <ion-checkbox checked="true" *ngIf="item | isSelected: selected" (click)="toggled()"></ion-checkbox>
      <!--
        Why does this doesn't work in Ionic 4?
        <ion-checkbox [checked]="item | isSelected: selected" (click)="toggled()"></ion-checkbox>
       -->
    </div>
  </div>
  <div class="listitem"><ng-content></ng-content></div>
</div>
