import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISearchable } from 'src/app/common/contracts/components/searchable';
import { DoctorDto } from 'src/app/shared/models/institution/doctor-dto.model';

import { IListView } from '../../../contracts/list-view';
import { CalendarResourceDto } from 'src/app/shared/models/calendar-resource/calendar-resource-dto.model';

@Component({
  selector: 'calendar-resource-list-item',
  templateUrl: 'calendar-resource-list-item.html',
  styleUrls: ['./../autocomplete-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarResourceListItem implements IListView {
  @Input() item: CalendarResourceDto;
  @Output() clicked: EventEmitter<ISearchable> = new EventEmitter<ISearchable>();

  triggerEvent() {
    this.clicked.emit(this.item);
  }
}
