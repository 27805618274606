import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ISyncStatus } from '../contracts/sync/sync-status';

@Injectable({ providedIn: 'root' })
export class SyncStatus implements ISyncStatus {
  private _completedSync$ = new BehaviorSubject<boolean>(false);
  get completedSync$(): Observable<boolean> {
    return this._completedSync$.asObservable();
  }

  private _completedData = false;
  get completedData(): boolean {
    return this._completedData;
  }

  set completedData(complete: boolean) {
    this._completedData = complete;
  }

  private _completedSync = false;
  get completedSync(): boolean {
    return this._completedSync;
  }

  set completedSync(complete: boolean) {
    this._completedSync = complete;
    this._completedSync$.next(complete);
  }
}
