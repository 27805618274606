import { NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Requestor, StorageBackend } from '@openid/appauth';
import { AuthService, Browser, ConsoleLogObserver, IAuthConfig } from 'ionic-appauth';
import { environment } from 'src/environments/environment';
import { App, URLOpenListenerEvent } from '@capacitor/app';

export class AuthServiceWrapper extends AuthService {
  async removeToken(): Promise<void> {
    await this.storage.removeItem('token_response');
  }
}

export let authFactory = (
  platform: Platform,
  ngZone: NgZone,
  requestor: Requestor,
  browser: Browser,
  storage: StorageBackend
) => {
  const authService = new AuthServiceWrapper(browser, storage, requestor);
  authService.authConfig = environment.authCodeFlowConfigApp as IAuthConfig;

  if (platform.is('hybrid')) {
    authService.authConfig.redirect_url = 'appauth://callback';
    authService.authConfig.end_session_redirect_url = 'appauth://endsession';

    App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
      if (!data.url) {
        return;
      }
      ngZone.run(() => {
        const callbackUrl = data.url;
        if (callbackUrl.indexOf(authService.authConfig.redirect_url) === 0) {
          authService.authorizationCallback(callbackUrl);
        } else if (callbackUrl.indexOf(authService.authConfig.end_session_redirect_url) === 0) {
          authService.endSessionCallback();
        }
      });
    });
  } else {
    authService.authConfig.redirect_url = window.location.origin + '/callback';
    authService.authConfig.end_session_redirect_url = window.location.origin + '/logout';
  }

  authService.addActionObserver(new ConsoleLogObserver());
  authService.init();
  return authService;
};
