<div class="container">
  <itl-slim-button (click)="checkStock()">
    <ion-icon name="alberta-fab-stock-check"></ion-icon><span>{{ 'fab-action.stock' | translate }}</span>
  </itl-slim-button>
  <itl-label
    class="quantity"
    [baseColor]="stock === 0 ? '#CF0000' : '#2680EB'"
    *ngIf="stock !== null && stock !== undefined"
  >
    {{ stock }} {{ 'article.details.quantity' | translate }}
  </itl-label>
</div>
