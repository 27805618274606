import { IReport } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { ReportDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { ReportDto } from 'src/app/shared/models/swodoc/report-dto.model';

import { IDatabaseSynchronizer as IDatabaseSynchronizer_T } from '../../shared/services/contracts/sync/database-synchronizer_T';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class ReportWorkItem extends WorkItem<ReportDto> implements IDatabaseSynchronizer_T<IReport> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }

  unitOfWork: IUnitOfWork;
  get name(): string {
    return 'ReportWorkItem';
  }
  get database(): string {
    return ReportDB;
  }
}
