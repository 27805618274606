import { IUser } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { differenceBy, unionBy } from 'lodash';
import { UsersChannel } from 'src/app/business/user/contracts/user.channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { ISyncState } from 'src/app/common/contracts/sync/sync-state';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';

import {
  AuditModelName,
  CareProposalModelName,
  DeviceModelName,
  DoctorModelName,
  ErpOrderModelName,
  HospitalModelName,
  NursingHomeModelName,
  NursingServiceModelName,
  OrderModelName,
  PatientModelName,
  PatientNoteModelName,
  UsersModelName,
} from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { AuthService } from '../auth.service';
import { EventService } from '../event.service';
import { RegionService } from '../region.service';
import { SyncService } from './sync.service';

@Injectable()
export class UsersSyncService extends SyncService<IUser> {
  constructor(
    private _storage: Storage,
    private _regionService: RegionService,
    private _authService: AuthService,
    private _environmentService: EnvironmentService,
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IUser>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(UsersModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public canSync(channel: string): boolean {
    return channel === UsersChannel.CREATE || channel === UsersChannel.EDIT || channel === UsersChannel.DELETE;
  }

  protected async afterSync(syncState: ISyncState<IUser>) {
    const myUser = syncState.updated.find(user => this._authService.authentication.account._id === user._id);

    if (!myUser || !myUser.groups || !myUser.groups.length) {
      return;
    }

    const allUserRegions = await this._regionService.getRegions(myUser.groups);
    if (allUserRegions && allUserRegions.length) {
      let regions = differenceBy(allUserRegions, this._authService.authentication.account.authorization.regions, '_id');
      await this._authService.resetCurrentUser(myUser, allUserRegions);
      if (regions.length) {
        const oldUserSync = await this._storage.get(`sync_${this._environmentService.branch}_${myUser._id}`);
        const newUserSync = {
          status: [
            { name: PatientModelName, regions, timestamp: new Date(0) },
            { name: PatientNoteModelName, regions, timestamp: new Date(0) },
            { name: AuditModelName, regions, timestamp: new Date(0) },
            { name: ErpOrderModelName, regions, timestamp: new Date(0) },
            { name: DeviceModelName, regions, timestamp: new Date(0) },
            { name: OrderModelName, regions, timestamp: new Date(0) },
            { name: CareProposalModelName, regions, timestamp: new Date(0) },
            { name: DoctorModelName, regions, timestamp: new Date(0) },
            { name: NursingHomeModelName, regions, timestamp: new Date(0) },
            { name: NursingServiceModelName, regions, timestamp: new Date(0) },
            { name: HospitalModelName, regions, timestamp: new Date(0) },
          ],
          version: oldUserSync && oldUserSync.version ? oldUserSync.version + 1 : 1,
        };
        if (oldUserSync && oldUserSync.status.length) {
          regions.push(...oldUserSync.status[0].regions);
          regions = unionBy(regions, '_id');
        }
        await this._storage.set(`sync_${this._environmentService.branch}_${myUser._id}`, newUserSync);
      }
    }
  }

  protected async onPatched(user: IUser): Promise<void> {
    await super.onPatched(user);
    await this.afterSync({ deleted: [], created: [], updated: [user] });
  }
}
