import { Inject, Injectable } from '@angular/core';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';

import makeDebug from '../../../../makeDebug';
import { WebDataSyncService } from '../../services/web-data-sync.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement, SyncElementResult } from '../contracts/sync/sync-element';
import { WebSyncStarter as Starter } from '../contracts/sync/sync-endpoints';
import { AttachmentService } from '@services/attachment/attachment.service';

const debug = makeDebug('sync:web-sync-starter');

@Injectable()
export class WebSyncStarter implements ISyncElement {
  public get name(): string {
    return Starter;
  }
  private _isRunning = false;

  constructor(
    private readonly _webDataSyncService: WebDataSyncService,
    @Inject(PlatformSyncToken) private readonly _platformSync: IPlatformSync,
    private readonly _attachmentService: AttachmentService
  ) {}

  public async execute(context: ISyncContext): Promise<SyncElementResult> {
    debug('entered execute function', context);
    await this._platformSync.ready;

    const elementResult: SyncElementResult = { contextId: context.id, jobDone: false };

    if (
      this._platformSync.isCordova ||
      !context.params.isOnline ||
      context.params.token.cancelled.get() ||
      this._isRunning
    ) {
      elementResult.jobDone = false;
      return elementResult;
    }

    this._isRunning = true;

    debug('calling startSync of web-data-sync-service');

    await this._webDataSyncService
      .startSync(context.params.token)
      .catch(error => window.logger.error('Web data client sync failed', error));

    await this._attachmentService.init();
    this._attachmentService
      .runAttachmentQueuesProcessor()
      .catch(error => window.logger.error('WebSyncStarter runAttachmentQueuesProcessor', error));

    this._isRunning = false;
    elementResult.jobDone = true;
    return elementResult;
  }
}
