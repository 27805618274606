import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { IListView } from '../../../contracts/list-view';
import { PatientDto } from 'src/app/shared/models/patient/patient-dto.model';

@Component({
  selector: 'patient-template-list-item',
  templateUrl: 'patient-template-list-item.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientTemplateItem implements IListView {
  @Input() item: PatientDto;
  @Output() clicked: EventEmitter<ISearchable> = new EventEmitter<ISearchable>();

  triggerEvent() {
    this.clicked.emit(this.item);
  }
}
