import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import makeDebug from 'src/makeDebug';
import { Chat } from './model/chat-instance';
import { ConnectionState } from '@twilio/conversations';

const debug = makeDebug('services:chat:online-status');

@Injectable({ providedIn: 'root' })
export class ChatConnectionStateService {
  private _agentConnectionState = new BehaviorSubject('disconnected');

  public setConnectionState(state: ConnectionState, chat = Chat.PatientApp) {
    debug('set state to', state);
    if (chat === Chat.PatientApp) {
      this._agentConnectionState.next(state);
    }
  }

  public observeAgentOnlineState(): Observable<boolean> {
    return this._agentConnectionState.pipe(map((state: ConnectionState) => state === 'connected'));
  }

  public get isAgentChatOnline() {
    return this._agentConnectionState.getValue() === 'connected';
  }
}
