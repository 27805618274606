import { ISubunit } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';
import { SubunitWorkItem } from 'src/app/business/institution/subunit-work-item';

@Injectable()
export class SubunitCommandHandler extends EntityCommandHandler<ISubunit> {
  protected workItem: IWorkItem<ISubunit>;

  constructor(subunitWorkItem: SubunitWorkItem) {
    super();
    this.workItem = subunitWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'SubunitDto';
  }
}
