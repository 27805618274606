import { IAppointment } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { Dictionary, groupBy, orderBy } from 'lodash';
import moment from 'moment';

enum FilterDirection {
  Future,
  Past,
}

export enum ActivitiesListSortFilterType {
  patient = 0,
  nursingHome = 1,
  hospital = 2,
}

@Injectable({ providedIn: 'root' })
export class ActivitiesListSortService {
  public getFutureActivities(
    appointments: IAppointment[],
    id: string,
    type: ActivitiesListSortFilterType
  ): Dictionary<IAppointment[]> {
    return this.getActivities(id, appointments, FilterDirection.Future, type);
  }

  public getPastActivities(
    appointments: IAppointment[],
    id: string,
    type: ActivitiesListSortFilterType
  ): Dictionary<IAppointment[]> {
    return this.getActivities(id, appointments, FilterDirection.Past, type);
  }

  private getActivities(
    id: string,
    appointments: IAppointment[],
    direction: FilterDirection,
    type: ActivitiesListSortFilterType
  ): Dictionary<IAppointment[]> {
    const filteredAppointments = this.filterAppointments(id, appointments ?? [], direction, type);
    const startDatesortDirection = direction === FilterDirection.Future ? 'asc' : 'desc';
    const sortedAppointments = orderBy(
      filteredAppointments,
      (appointment: IAppointment) => appointment.startDate,
      startDatesortDirection
    );
    return groupBy(sortedAppointments, (appointment: IAppointment) =>
      moment(appointment.startDate).format('MM-DD-YYYY')
    );
  }

  private filterAppointments(
    id: string,
    appointments: IAppointment[],
    direction: FilterDirection,
    type: ActivitiesListSortFilterType
  ): IAppointment[] {
    const appointmentsFilter = (appointment: IAppointment): boolean => {
      if (type === ActivitiesListSortFilterType.patient) {
        return (
          !appointment.archived &&
          appointment.patientIds.includes(id) &&
          (direction === FilterDirection.Future
            ? this.isDateInFuture(appointment.endDate)
            : this.isDateInPast(appointment.endDate))
        );
      } else {
        return (
          !appointment.archived &&
          appointment.institutionId === id &&
          (direction === FilterDirection.Future
            ? this.isDateInFuture(appointment.endDate)
            : this.isDateInPast(appointment.endDate))
        );
      }
    };
    return appointments.filter(appointmentsFilter);
  }

  private isDateInFuture(date: Date): boolean {
    return moment(date).isAfter(moment());
  }

  private isDateInPast(date: Date): boolean {
    return moment(date).isSameOrBefore(moment());
  }
}
