import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, HostBinding } from '@angular/core';

@Component({
  selector: 'itl-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input() icon: string;
  @Input() showDismiss = true;
  @Input() @HostBinding('class.ripple') showRippleEffect = false;
  @Output() chipClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() dismissClicked: EventEmitter<void> = new EventEmitter<void>();
}
