import { IMedicalOrderRequest, IService } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { MedicalOrderRequestWorkItem } from 'src/app/business/medical-order/medical-order-request-work-item';
import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class MedicalOrderRequestCommandHandler extends EntityCommandHandler<IMedicalOrderRequest> {
  protected workItem: IWorkItem<IService>;

  constructor(medicalOrderRequestWorkItem: MedicalOrderRequestWorkItem) {
    super();
    this.workItem = medicalOrderRequestWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'MedicalOrderRequestDto';
  }
}
