export const PatientDB = 'patient.db';
export const OrderDB = 'order.db';
export const AuditDB = 'audit.db';
export const TemplateDB = 'template.db';
export const MatchDB = 'match.db';
export const ReportDB = 'report.db';
export const NursingHomeDB = 'nursingHome.db';
export const NursingServiceDB = 'nursingService.db';
export const HospitalDB = 'hospital.db';
export const SubunitDB = 'subunit.db';
export const InstitutionContactDB = 'institutionContact.db';
export const InstitutionNoteDB = 'institutionNote.db';
export const InstitutionAuditDB = 'institutionAudit.db';
export const PostalCodeDB = 'postalCode.db';
export const DoctorDB = 'doctor.db';
export const PayerDB = 'payer.db';
export const PayerInfoDB = 'payerInfo.db';
export const SyncTimestampDB = 'syncTimestamp.db';
export const UsersDB = 'users.db';
export const GroupDB = 'group.db';
export const RegionDB = 'region.db';
export const RightsetDB = 'rightset.db';
export const StandardCareProposalDB = 'standardCareProposal.db';
export const ProductGroupDB = 'productGroup.db';
export const ArticleDB = 'article.db';
export const InsuranceContractDB = 'insuranceContract.db';
export const PharmacyDB = 'pharmacy.db';
export const CareProposalDB = 'careProposal.db';
export const AttachmentDB = 'attachment.db';
export const AttachmentTypeDB = 'attachmentType.db';
export const PatientNotesDB = 'patientNote.db';
export const FaxDB = 'fax.db';
export const ErpOrderDB = 'erpOrder.db';
export const DeviceDB = 'device.db';
export const LoggingDB = 'logging.db';
export const AttributeTemplateDB = 'attributeTemplate.db';
export const MaintenanceDB = 'maintenance.db';
export const ReturnDeliveryDB = 'returnDelivery.db';
export const ConfigDB = 'config.db';
export const AppointmentDB = 'appointment.db';
export const ErpTaskDB = 'erpTask.db';
export const PatientAppUserDB = 'patientAppUser.db';
export const PatientAppUserChannelDB = 'patientAppUserChannel.db';
export const IntegratedCareDB = 'integratedCare.db';
export const SingleOrderDB = 'singleOrder.db';
export const DirectOrderDB = 'directOrder.db';
export const ContractArticleGroupDB = 'contractArticleGroup.db';
export const ReminderDB = 'reminder.db';
export const PlannedOrderDB = 'plannedOrder.db';
export const QuotesDB = 'quote.db';
export const IntegratedCareReturnDeliveryDB = 'integratedCareReturnDelivery.db';
export const InstitutionAttributeDB = 'institutionAttribute.db';
export const PrescriptionRequestDB = 'prescriptionRequest.db';
export const MedicalOrderRequestDB = 'medicalOrderRequest.db';
export const PortalNotificationDB = 'portalNotification.db';
export const SavedInstanceStateDB = 'savedInstanceState.db';
export const ServiceDB = 'service.db';
export const ServiceOrderDB = 'serviceOrder.db';
export const ServiceTaskDB = 'serviceTask.db';
export const CalendarResourceDB = 'calendarResource.db';
export const ServiceCostEstimateDB = 'serviceCostEstimate.db';
// [schematics databases marker create-entity]
