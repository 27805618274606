import { IOrder } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { OrderChannel } from 'src/app/business/order/contracts/order-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { OrderModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class OrderSyncService extends SyncService<IOrder> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IOrder>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(OrderModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public async afterPatch(entity: IOrder): Promise<void> {
    return this.onPatched(entity);
  }

  public canSync(channel: string): boolean {
    return channel === OrderChannel.CREATE || channel === OrderChannel.EDIT || channel === OrderChannel.DELETE;
  }
}
