<div class="select-modal-item__icon">
  <ion-icon [name]="icon"></ion-icon>
</div>
<div class="select-modal-item__text">
  <div class="titel">{{ title }}</div>
  {{ subTitle }}
</div>
<div class="select-modal-item__checkbox">
  <ng-content></ng-content>
</div>
