import { ElementRef, Renderer2 } from '@angular/core';

import { AuthService } from '../services/auth.service';

export class BaseAuthorizationDirective {
  constructor(private _authService: AuthService, private _elementRef: ElementRef, private _renderer: Renderer2) {}

  protected setVisibility() {
    const domain = this._elementRef.nativeElement.getAttribute('domain');
    const permission = this._elementRef.nativeElement.getAttribute('permission');

    if (!domain || !permission) {
      return;
    }
    this._authService.init
      .then(() => {
        if (this._authService.userHasPermission(domain, permission)) {
          this._renderer.removeStyle(this._elementRef.nativeElement, 'display');
        } else {
          /*
        Find parent of the current element
        then remove the current element from dom tree
        if user has no right to see it
        */
          const parent = this._elementRef.nativeElement.parentNode;
          if (parent) {
            this._renderer.removeChild(parent, this._elementRef.nativeElement);
          } else {
            // If no parent can be found at least hide the element as it was done in the past
            this._renderer.setStyle(this._elementRef.nativeElement, 'display', 'none');
          }
        }
      })
      .catch(error => window.logger.error('[BaseAuthorizationDirective] authService init failed', error));
  }
}
