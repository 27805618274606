import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'select-all-item',
  templateUrl: 'select-all-item.component.html',
  styleUrls: ['select-all-item.component.scss'],
})
export class SelectAllItemComponent {
  @Input() selected: any[];
  @Input() items: any[];
  @Output() changed: EventEmitter<null> = new EventEmitter<null>();
  toggled() {
    this.changed.emit();
  }
}
