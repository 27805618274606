import { AttributeDataType } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'attributeType',
  pure: true,
})
export class AttributeTypePipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(item: AttributeDataType): string {
    switch (item) {
      case AttributeDataType.number:
        return this._translate.instant('attribute.dataType.number');
      case AttributeDataType.string:
        return this._translate.instant('attribute.dataType.text');
      case AttributeDataType.boolean:
        return this._translate.instant('attribute.dataType.boolean');
      case AttributeDataType.date:
        return this._translate.instant('attribute.dataType.date');
      default:
        return this._translate.instant('attribute.dataType.unkown');
    }
  }
}
