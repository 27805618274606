import { Injectable, NgZone } from '@angular/core';

import { ISyncService } from './contracts/sync/service/sync-service';
import { SyncTimeoutRepeater } from './sync/sync-timeout-repeater.service';

@Injectable({ providedIn: 'root' })
export class SyncTimeoutRepeaterServiceFactory {
  create(syncService: ISyncService, ngZone: NgZone, userSync: any): SyncTimeoutRepeater {
    return new SyncTimeoutRepeater(syncService, ngZone, userSync);
  }
}
