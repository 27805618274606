<div [ngClass]="{ footer: 1, 'footer--sticky': isSticky }" [ngStyle]="{ 'z-index': zIndex }">
  <div class="footer__content" tappable (click)="itemClicked()">
    <ion-icon *ngIf="icon" [name]="icon"></ion-icon>
    <ng-content></ng-content>
  </div>
  <svg width="134" height="58" viewBox="0 0 134 58">
    <g transform="translate(15847 4688.898)">
      <path
        id="footer_curve"
        data-name="Schnittmenge 1"
        d="M227,58V.1c6.014.032,10.619.071,13.431.121,28.455.5,14.576,48.523,55.133,48.523S328.644-.1,351.407.223H361v58Z"
        transform="translate(-16073.999 -4689)"
      />
    </g>
  </svg>
</div>
