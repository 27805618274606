import { IDuration } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TimePeriodPipe } from './timePeriod-pipe';

@Pipe({
  name: 'durationPipe',
  pure: false,
})
export class DurationPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(duration: IDuration, plural: boolean = false): any {
    if (duration == null || duration.timePeriod == null || duration.amount == null) {
      return '';
    }
    const timePeriod = new TimePeriodPipe(this._translate).transform(duration.timePeriod, plural);
    return `${duration.amount} ${timePeriod}`;
  }
}
