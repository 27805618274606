import { ChangeType } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'changetypepipe',
})
export class ChangeTypePipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(value: ChangeType, valueAfter?: any): string {
    switch (value) {
      case ChangeType.Unknown:
        return this._translate.instant('changetypes.unkown');
      case ChangeType.Add:
        return this._translate.instant('changetypes.add');
      case ChangeType.Delete:
        return this._translate.instant('changetypes.delete');
      case ChangeType.Change:
        return this._translate.instant('changetypes.change');
      case ChangeType.Date:
        return this._translate.instant('changetypes.date');
      case ChangeType.Status:
        if (valueAfter === 'Übermittelt') {
          return this._translate.instant('changetypes.statusTransmitted');
        }
        return this._translate.instant('changetypes.status');
      case ChangeType.Doctor:
        return this._translate.instant('changetypes.doctor');
      case ChangeType.Annotation:
        return this._translate.instant('changetypes.annotation');
      case ChangeType.PermanentExceedMaxAmount:
        return this._translate.instant('changetypes.permanentExceedMaxAmount');
      case ChangeType.Day:
        return this._translate.instant('changetypes.day');
    }
    return '';
  }
}
