import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IDatabaseService } from 'src/app/shared/services/contracts/database/database-service';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { DatabaseService } from 'src/app/shared/services/database.service';

import { IStateRegistryPersister } from '../contracts/state/state-registry-persister';
import { Deferred } from '../deferred/deferred';

@Injectable({ providedIn: 'root' })
export class StateRegistryPersister extends Subject<{ key: string; value: any }> implements IStateRegistryPersister {
  private _database: Partial<IGenericStorage>;
  private _ready = new Deferred<void>();

  constructor(@Inject(DatabaseService) private _dbService: IDatabaseService) {
    super();

    // tslint:disable-next-line: no-floating-promises
    (async () => {
      this._database = await this._dbService.getDatabase('statePersister.db');
      this._ready.resolve();
    })();
  }

  public async update(state: string, path: string, models: any): Promise<void> {
    await this._ready.promise;
    await this._database.set(`${state}:${path}`, { _id: `${state}:${path}`, models });
  }

  public async rehydrate(): Promise<void> {
    const items = {};
    await this._ready.promise;

    await this._database.forEach((models, key) => {
      // clear all entities with key :items during rehydrate to avoid uncontrolled state growth
      // dont clear patientAppUsers as they are only written to state once (during websync)
      if (key.endsWith(':items') && !key.includes('patientAppUser')) {
        this._database.set(key, { _id: key, models: [] });
      } else {
        // Write everything else from state persister into state
        // Every custom keys like :dashboard, etc. must be handled and cleared on their own!
        items[key] = models;
      }
    });

    for (const key in items) {
      if (items.hasOwnProperty(key)) {
        this.next({ key, value: items[key].models });
      }
    }
    this.complete();
  }
}
