import { Inject, Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { RxDatabase, RxDocument } from 'rxdb';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import makeDebug from 'src/makeDebug';
import { Chat } from '../model/chat-instance';
import { ChatChannel, ChatChannelConsumptionStatus, ChatCollections, ChatMember, ChatMessage } from './db-schema';
import { RX_DB_SERVICE_TOKEN } from './rx-db-factory';
import { LogLevel } from 'src/app/common/contracts/logging/log-level';

const debug = makeDebug('services:chat:db');

@Injectable({ providedIn: 'root' })
export class ChatDbService {
  constructor(@Inject(RX_DB_SERVICE_TOKEN) private readonly _db: Promise<RxDatabase<ChatCollections>>) {}

  // ### CHANNELS ###
  public async upsertChannel(channel: ChatChannel) {
    debug('upsert channel', channel);
    const db = await this._db;
    await db.channels.atomicUpsert(channel);
  }

  public async removeChannel(channelSid: string) {
    const db = await this._db;

    const channelDoc = await db.channels
      .findOne({
        selector: {
          sid: channelSid,
        },
      })
      .exec();

    await channelDoc.atomicPatch({ removed: true });
  }

  public async getChannels(chat = Chat.PatientApp) {
    debug('return all channels');
    const db = await this._db;
    if (chat === Chat.PatientApp) {
      return db.channels
        .find({
          selector: {
            removed: { $ne: true },
            isAgent: true,
          },
        })
        .$.pipe(map(chatChannelDocs => chatChannelDocs.map(chatChannelDoc => chatChannelDoc.toJSON() as ChatChannel)));
    } else {
      throw new Error(`Unknown chat type: ${chat}`);
    }
  }

  public async getChannel(channelSid: string) {
    debug('get channel', channelSid);
    const db = await this._db;
    const result = await db.channels.findOne({ selector: { sid: channelSid, removed: { $ne: true } } }).exec();
    if (result) {
      return result.toJSON() as ChatChannel;
    } else {
      return null;
    }
  }

  public async setChannelLastLocalUpdate(channelSid: string) {
    debug('set last local update of channel', { channelSid });
    const db = await this._db;
    const channelDoc = await db.channels.findOne({ selector: { sid: channelSid } }).exec();
    await channelDoc.atomicPatch({ lastLocalUpdateAt: new Date().toISOString() });
  }

  // ### CONSUMPTION ###
  public async setConsumptionIndex(channelSid: string, index: number, allowLower = false) {
    debug('set consumption index', { channelSid, index });
    const db = await this._db;
    if (allowLower === false) {
      const consumption = await db.consumptions.findOne({ selector: { _id: channelSid } }).exec();
      if (consumption && consumption.localLastConsumedMessageIndex > index) {
        return;
      }
    }
    await db.consumptions.atomicUpsert({
      _id: channelSid,
      localLastConsumedMessageIndex: index,
      syncLastConsumedMessageIndex: true,
    });
  }

  public async watchAllLocalConsumptions(): Promise<Observable<ChatChannelConsumptionStatus[]>> {
    debug('get all consumptions');
    const db = await this._db;
    return db.consumptions.find().$.pipe(map(e => e.map(el => el.toJSON() as ChatChannelConsumptionStatus)));
  }

  public async getAllLocalConsumptions(): Promise<ChatChannelConsumptionStatus[]> {
    debug('get all consumptions');
    const db = await this._db;
    const consumptions = await db.consumptions.find().exec();
    return consumptions.map(doc => doc.toJSON() as ChatChannelConsumptionStatus);
  }

  public async getLocalConsumptionOfChannel(channelSid: string): Promise<ChatChannelConsumptionStatus> {
    debug('get consumption of channel', channelSid);
    const db = await this._db;
    const result = await db.consumptions.findOne({ selector: { _id: channelSid } }).exec();
    if (!result) {
      return null;
    }
    return result.toJSON() as ChatChannelConsumptionStatus;
  }

  public async getLocalLastConsumedMessageIndexBulk(channelSids: string[]) {
    const db = await this._db;

    if (!channelSids) {
      return;
    }

    const result = await db.consumptions.find({ selector: { _id: { $in: channelSids } } }).exec();

    return result.map(consumption => ({
      sid: consumption._id,
      localLastConsumedMessageIndex: consumption.localLastConsumedMessageIndex,
    }));
  }

  public async deleteLocalConsumptionOfChannel(channelSid: string): Promise<void> {
    debug('get consumption of channel', channelSid);
    const db = await this._db;
    await db.consumptions.findOne({ selector: { _id: channelSid } }).remove();
  }

  // ### Messages ###
  public async insertMessage(message: ChatMessage): Promise<RxDocument<ChatMessage>> {
    debug('upsert message', message);
    const db = await this._db;

    // run atomicUpsert instead of insert to avoid 409 Document update conflict
    return db.messages.atomicUpsert(message);
  }

  public async removeMessageByInternalId(_id: string): Promise<void> {
    const db = await this._db;

    await db.messages.findOne({ selector: { _id } })?.remove();
  }

  public async removeMessage(messageSid: string): Promise<void> {
    const db = await this._db;

    await db.messages.findOne({ selector: { sid: messageSid } })?.remove();
  }

  public async bulkInsertMessages(messages: ChatMessage[]) {
    if (messages?.length) {
      const db = await this._db;
      debug('bulk (up)insert messages', messages);

      /*
      NEEDS REFACTORING
      TODO: after upgrading to rxdb >= 12.0.0 this section should be refactored to use bulkUpsert
      */
      try {
        const dbMessages = await db.messages
          .find({
            selector: {
              _id: { $in: messages.map(msg => msg._id) },
            },
          })
          .exec();

        const newMessages = messages.filter(message => !dbMessages.find(dbMessage => dbMessage._id === message._id));
        const existingMessages = messages.filter(message =>
          dbMessages.find(dbMessage => message._id === dbMessage._id)
        );

        // Only new messages can be inserted using bulkInsert
        // caveat: pouchdb only supports bulk insert of 1000 items at once, but we'll play it safe
        const chunkSize = 400;
        for (let i = 0; i < newMessages.length; i += chunkSize) {
          const chunk = newMessages.slice(i, i + chunkSize);

          if (chunk?.length) {
            const { error: errors } = await db.messages.bulkInsert(chunk);

            // sometimes bulkInsert fails with error 409 "Document update conflict"
            // in this case we need to run atomicUpsert for each conflicted message
            if (errors?.length) {
              window.logger.log(
                `[ChatDbService]: Bulk insert (partcially) failed for ${errors.length}/${chunk.length} messages`,
                LogLevel.warning
              );

              const conflictedMessages = errors
                .filter(error => error.status === 409)
                .map(error => chunk.find(message => message._id === error.id))
                .filter(message => message);
              let failedConflictedMessages = 0;

              for (const message of conflictedMessages) {
                try {
                  await db.messages.atomicUpsert(message);
                } catch (error) {
                  failedConflictedMessages++;
                }
              }
              if (failedConflictedMessages > 0) {
                window.logger.error(
                  '[ChatDbService]: Bulk insert (partcially) failed, second run',
                  new Error(`Failed atomicUpserts: ${failedConflictedMessages}/${conflictedMessages.length}`)
                );
              }

              // maybe there were errors other than 409
              if (conflictedMessages.length !== errors.length) {
                const nonConflictingErrors = errors.filter(error => error.status !== 409);
                const status = [...new Set(nonConflictingErrors.map(error => error.status))];

                window.logger.error(
                  '[ChatDbService]: Bulk insert (partcially) failed, second run',
                  new Error(
                    `Non-conflicting messages found (not 409): ${nonConflictingErrors.length}/${
                      errors.length
                    } messages, status codes: ${status.join(', ')}`
                  )
                );
              }
            }
          }
        }

        // already existing documents need to be updated using atomicUpsert (slow)
        // until bulkUpsert is available in rxdb >= 12
        if (existingMessages.length) {
          existingMessages.forEach(async message => await db.messages.atomicUpsert(message));
        }
        /* END OF NEEDS REFACTORING */
      } catch (error) {
        window.logger.error('[ChatDbService]: Bulk insert failed.', error);
        return false;
      }
      debug('bulk inserted messages', { existingMessagesIds: messages });
      return true;
    }

    return false;
  }

  public async getMessagesOfChannel(channelSid: string): Promise<Observable<ChatMessage[]>> {
    debug('get messages of channel', channelSid);
    const db = await this._db;
    const messages$ = db.messages
      .find({
        selector: {
          channelSid,
        },
        sort: [{ timestamp: 'asc' }],
      })
      .$.pipe(map(e => e.map(el => el.toJSON() as ChatMessage)));
    return messages$;
  }

  public async getLastMessageOfChannels(channelSids: string[]): Promise<ChatMessage[]> {
    const db = await this._db;

    /*
      A more elegant and memory friendly solution would be to concurrently use findOne() on the pouchDb,
      however, tests showed this would be 4-5 times slower than the following approach.
      */
    const allMessages = await db.messages
      .find({
        selector: {
          channelSid: { $in: channelSids },
        },
        /*
          To speed things up, we'd usually sort using pouchDb, but there is a problem within pouchDb-indices
          see https://github.com/pouchdb/pouchdb/issues/6399
          As we are using sorting in-Code anyway, we can simply remove this sort.
          */
        // sort: [{ index: 'desc' }],
      })
      .exec();

    const lastMessages = channelSids
      .map(channelSid =>
        allMessages
          .filter(message => message.channelSid === channelSid)
          .reduce((previous, current) => (previous?.index > current.index ? previous : current), null)
      )
      .filter(message => message)
      .map(message => message.toJSON() as ChatMessage);

    return lastMessages;
  }

  public async getLastMessageOfChannel(channelSid: string): Promise<ChatMessage | null> {
    debug('get last message of channel', channelSid);
    const db = await this._db;
    const messageResult = await db.messages
      .findOne({
        selector: {
          channelSid,
        },
        sort: [{ index: 'desc' }],
      })
      .exec();
    if (messageResult) {
      return messageResult.toJSON();
    } else {
      return null;
    }
  }

  // ### MEMBERS ###
  public async bulkUpdateChannelMembers(channelSid: string, members: ChatMember[]) {
    debug('bulk update channel members', { channelSid, members });
    const db = await this._db;
    /*
    Usually we would use bulkUpsert, but it is available in rxdb 12 only.
    This is a bugfix solution, the original process was:
    1. bulkInsert all members (updates may fail unrecognized, thats why 2.)
    2. sequentially atomicUpsert all inserted members
    3. find and remove unused members
    Unfortunately this procedure was very slow.
    The in-memory processing and bulkRemove and bulkInsert-method resulted
    in a massive performance gain as the IndexDB is kind of slow.
    */
    try {
      const channelMembers = (
        await db.members
          .find({
            selector: {
              channelSid,
            },
          })
          .exec()
      ).map(member => member.toJSON() as ChatMember);

      const removedMembers = channelMembers.filter(dbMember => !members.find(m => m.sid === dbMember.sid));
      const changedMembers = channelMembers.filter(
        dbMember =>
          !removedMembers.find(removed => removed.sid === dbMember.sid) &&
          !isEqual(
            members.find(m => m.sid === dbMember.sid),
            dbMember
          )
      );
      const newMembers = members.filter(member => !channelMembers.find(dbMember => dbMember.sid === member.sid));

      if (removedMembers.length || changedMembers.length) {
        const { error } = await db.members.bulkRemove([
          ...removedMembers.map(member => member.sid),
          ...changedMembers.map(member => member.sid),
        ]);
        if (error?.length) {
          window.logger.error(
            `[ChatDbService]: member bulkRemove failed`,
            new Error(`Failed to remove ${error.length}/${removedMembers.length + changedMembers.length} members.`)
          );
        }
      }

      if (newMembers.length || changedMembers.length) {
        const insertResult = await db.members.bulkInsert([...newMembers, ...changedMembers]);

        if (insertResult.error?.length) {
          throw new Error(
            `Bulk insert failed for ${insertResult.error.length}/${insertResult.success?.length} members.`
          );
        }
      }
      debug('bulk updated channelmembers', { members });
    } catch (error) {
      window.logger.error('[ChatDbService]: member bulk update error', error);
    }
  }

  public async upsertChannelMember(member: ChatMember) {
    debug('upsert channel member');
    const db = await this._db;
    await db.members.atomicUpsert(member);
  }

  public async removeChannelMember(channelSid: string, memberSid: string) {
    debug('remove channel member', { channelSid, memberSid });
    const db = await this._db;
    const members = db.members.find({
      selector: {
        channelSid: channelSid,
        sid: memberSid,
      },
    });
    await members.remove();
  }

  public async getChannelMembers(channelSid: string): Promise<Observable<ChatMember[]>> {
    debug('get channel members', channelSid);
    const db = await this._db;
    return db.members
      .find({
        selector: {
          channelSid,
          _deleted: { $ne: true },
        },
      })
      .$.pipe(map(e => e.map(el => el.toJSON() as ChatMember)));
  }

  public async getCurrentSendQueueBulk(channelSids: string[]) {
    const db = await this._db;

    return db.messages
      .find({
        selector: {
          channelSid: { $in: channelSids },
          status: 'pending',
        },
      })
      .exec();
  }

  public async getCurrentSendQueue(channelSid: string = null): Promise<RxDocument<ChatMessage>[]> {
    debug('get current send queue');
    const db = await this._db;

    return db.messages
      .find({
        selector: channelSid
          ? {
              channelSid,
              status: 'pending',
            }
          : {
              status: 'pending',
            },
      })
      .exec();
  }

  async upsertMessage(chatMessage: ChatMessage): Promise<void> | never {
    debug('upsert message', chatMessage);
    const db = await this._db;
    try {
      await db.messages.atomicUpsert(chatMessage);
    } catch (error) {
      window.logger.error('[ChatDbService]: upsert message failed', error);
      throw error;
    }
  }

  public async getMemberSidForIdentity(channelSid: string, identity: string): Promise<string | null> {
    const db = await this._db;
    const result = await db.members.findOne({ selector: { channelSid, identity } }).exec();
    if (result) {
      return result.sid;
    } else {
      return null;
    }
  }

  public async clearAll(): Promise<void> {
    const db = await this._db;
    await db.channels.remove();
    await db.members.remove();
    await db.consumptions.remove();
    await db.messages.remove();
  }
}
