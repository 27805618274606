import { IServiceOrder } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { ServiceOrderChannel } from 'src/app/business/service-order-entity/contracts/service-order.channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { ServiceOrderModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class ServiceOrderSyncService extends SyncService<IServiceOrder> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IServiceOrder>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(ServiceOrderModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  protected async afterPatch(entity: IServiceOrder): Promise<void> {
    this.onPatched(entity);
  }

  public canSync(channel: string): boolean {
    return (
      channel === ServiceOrderChannel.CREATE ||
      channel === ServiceOrderChannel.EDIT ||
      channel === ServiceOrderChannel.DELETE
    );
  }
}
