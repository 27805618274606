import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'itl-text-dynamic',
  templateUrl: './text-input-dynamic.component.html',
  styleUrls: ['./text-input-dynamic.component.scss'],
  encapsulation: ViewEncapsulation.None, // Needed to override textarea padding
})
export class DynamicTextInputComponent {
  /**
   * With this component you can add multiple text inputs dynamically.
   * The output is an array of strings of the dynamic text inputs.
   * @param textInputs: string[] - The initial values of the text inputs.
   * @param textInputsChanged: EventEmitter<string[]> - Emitted if an input is added or removed.
   */
  @Input() textInputs: string[] = [];
  @Input() textInputTitle: string = '';
  @Input() textInputPlaceholder: string = '';
  @Input() addButtonText: string = '';
  @Input() public icon: string;
  @Output() textInputsChanged = new EventEmitter<string[]>();
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      inputs: this.fb.array([]),
    });
  }

  ngOnInit() {
    this.textInputs.forEach(value => {
      this.inputs.push(this.fb.control(value));
    });

    this.inputs.valueChanges.subscribe(values => {
      this.textInputsChanged.emit(values);
    });
  }

  get inputs() {
    return this.form.get('inputs') as FormArray;
  }

  addInput() {
    this.inputs.push(this.fb.control(''));
  }

  removeInput(index: number) {
    this.inputs.removeAt(index);
  }

  get values(): string[] {
    return this.inputs.value;
  }
}
