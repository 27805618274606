import { IInstitutionNote, InstitutionType, NoteType } from '@alberta/konexi-shared';
import { BaseViewModel } from 'src/app/common/viewmodel/base-view-model';

export class InstitutionNoteViewModel extends BaseViewModel implements IInstitutionNote {
  institutionType: InstitutionType;
  noteText: string;
  noteType: NoteType;
  institutionId: string;
  postalCode: string;
  _id: string;
  timestamp: Date;
  archived: boolean;
  createdAt: Date;
  createdBy: string;
  updatedBy: string;
  updatedAt: Date;
  // TODO: @Chris, was ist das fuer eine property? Wird in institution-note-detail.component.ts
  edited: boolean;
}
