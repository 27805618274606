import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ServicePipesModule } from '@common/pipes/serviceEntity/service-pipes-module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ArticlePipesModule } from 'src/app/common/pipes/article/article-pipes.module';
import { ComponentsPipesModule } from 'src/app/common/pipes/components/components-pipes.module';
import { GeneralPipesModule } from 'src/app/common/pipes/general-pipes.module';
import { LoadingIndicatorComponent } from 'src/app/shared/components/common/loading-indicator/loading-indicator.component';

import { AlbertaIconModule } from '../alberta-icon/alberta-icon.module';
import { SmallIconButtonModule } from '../inputs/small-icon-button/small-icon-button.module';
import { SearchEmptyStateComponent } from '../search-empty-state/search-empty-state.component';
import { AlbertaTableComponent } from './alberta-table/alberta-table.component';
import { CardComponent } from './card/card.component';
import { ChipComponent } from './chip/chip.component';
import { ListitemAdditionalInfoComponent } from './default-listitem/additional-info/listitem-additional-info.component';
import { ListitemBottomSubheaderComponent } from './default-listitem/bottom-subheader/listitem-bottom-subheader.component';
import { ListitemHeaderComponent } from './default-listitem/header/listitem-header.component';
import { ListitemIconComponent } from './default-listitem/icon/listitem-icon.component';
import { ListitemComponent } from './default-listitem/listitem.component';
import { AlbertaListItemModule } from './default-listitem/listitem.module';
import { ListitemStatusComponent } from './default-listitem/status/listitem-status.component';
import { ListitemTopHeaderComponent } from './default-listitem/top-header/listitem-top-header.component';
import { DesktopCardComponent } from './desktop-card/desktop-card.component';
import { DetailItemContainerComponent } from './detail-item-container/detail-item-container.component';
import { DetailItemIconComponent } from './detail-item/detail-item-icon/detail-item-icon.component';
import { DetailItemComponent } from './detail-item/detail-item.component';
import { DetailTitleIconComponent } from './detail-title/detail-title-icon/detail-title-icon.component';
import { DetailTitleTherapyIconComponent } from './detail-title/detail-title-therapy-icon/detail-title-therapy-icon.component';
import { DetailTitleComponent } from './detail-title/detail-title.component';
import { FooterBarComponent } from './footer-bar/footer-bar.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { InitialIconComponent } from './initial-icon/initial-icon.component';
import { CheckStockButtonComponent } from './integratedcare-article/check-stock-button/check-stock-button.component';
import { SlimButtonComponent } from './integratedcare-article/check-stock-button/slim-button/slim-button.component';
import { IntegratedCareArticleComponent } from './integratedcare-article/integratedcare-article';
import { IntegratedCareServiceComponent } from './integratedcare-service/integratedcare-service.component';
import { LabelComponent } from './label/label.component';
import { ListHeaderComponent } from './list-header/list-header.component';
import { LoadingModalComponent } from './loading-modal/loading-modal-page';
import { ModalPageHeaderComponent } from './modal-page/modal-page-header/modal-page-header.component';
import { ModalPageComponent } from './modal-page/modal-page.component';
import { PdfBoxComponent } from './pdf-box/pdf-box.component';
import { RatingStarsComponent } from './rating-stars/rating-stars.component';
import { SearchFilterBarComponent } from './search-filter-bar/search-filter-bar.component';
import { SearchModalInputComponent } from './search-modal/search-modal-input/search-modal-input.component';
import { SearchModalSelectionComponent } from './search-modal/search-modal-selection/search-modal-selection.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { SectionComponent } from './section/section.component';
import { SelectModalItemComponent } from './select-modal/select-modal-item/select-modal-item.component';
import { SelectModalComponent } from './select-modal/select-modal.component';
import { TabControlComponent } from './tab-control/tab-control.component';
import { TextChipBarComponent } from './text-chip-bar/text-chip-bar.component';
import { TileComponent } from './tile/tile.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { WarningItemComponent } from './warning-item/warning-item.component';
import { WarningModalComponent } from './warning-modal/warning-modal-page';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    FormsModule,
    ComponentsPipesModule,
    AlbertaIconModule,
    PdfViewerModule,
    GeneralPipesModule,
    ArticlePipesModule,
    SmallIconButtonModule,
    ServicePipesModule,
    WarningItemComponent,
    LoadingIndicatorComponent,
    AlbertaListItemModule,
    DetailItemComponent,
    DetailItemIconComponent,
    LabelComponent,
    LoadingModalComponent,
    InfoBoxComponent,
  ],
  declarations: [
    FooterBarComponent,
    SearchFilterBarComponent,
    ListHeaderComponent,
    DetailTitleIconComponent,
    DetailTitleComponent,
    InitialIconComponent,
    ModalPageComponent,
    ModalPageHeaderComponent,
    SectionComponent,
    CardComponent,
    SearchModalComponent,
    SearchModalInputComponent,
    SearchModalSelectionComponent,
    DetailTitleTherapyIconComponent,
    DetailItemContainerComponent,
    SelectModalItemComponent,
    SelectModalComponent,
    ChipComponent,
    TextChipBarComponent,
    RatingStarsComponent,
    TabControlComponent,
    TileComponent,
    PdfBoxComponent,
    InfoModalComponent,
    IntegratedCareArticleComponent,
    CheckStockButtonComponent,
    SearchEmptyStateComponent,
    DesktopCardComponent,
    AlbertaTableComponent,
    SlimButtonComponent,
    IntegratedCareServiceComponent,
    ToolbarComponent,
    WarningModalComponent,
  ],
  exports: [
    FooterBarComponent,
    SearchFilterBarComponent,
    ListitemComponent,
    ListitemAdditionalInfoComponent,
    ListitemBottomSubheaderComponent,
    ListitemHeaderComponent,
    ListitemIconComponent,
    ListitemStatusComponent,
    ListitemTopHeaderComponent,
    DetailItemComponent,
    DetailItemIconComponent,
    ListHeaderComponent,
    SectionComponent,
    DetailTitleIconComponent,
    DetailTitleComponent,
    InitialIconComponent,
    WarningItemComponent,
    ModalPageComponent,
    ModalPageHeaderComponent,
    CardComponent,
    LabelComponent,
    SearchModalComponent,
    SearchModalInputComponent,
    SearchModalSelectionComponent,
    DetailTitleTherapyIconComponent,
    DetailItemContainerComponent,
    SelectModalItemComponent,
    SelectModalComponent,
    ChipComponent,
    TextChipBarComponent,
    LoadingIndicatorComponent,
    RatingStarsComponent,
    TabControlComponent,
    TileComponent,
    InfoBoxComponent,
    PdfBoxComponent,
    InfoModalComponent,
    IntegratedCareArticleComponent,
    CheckStockButtonComponent,
    SearchEmptyStateComponent,
    LoadingModalComponent,
    DesktopCardComponent,
    AlbertaTableComponent,
    SlimButtonComponent,
    IntegratedCareServiceComponent,
    ToolbarComponent,
    WarningModalComponent,
  ],
})
export class AlbertaCommonComponentsModule {}
