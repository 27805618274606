import { Injectable } from '@angular/core';
import { Requestor } from '@openid/appauth';
import { XhrSettings } from 'ionic-appauth/lib/cordova';
import { CapacitorHttp } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class NgHttpService implements Requestor {
  constructor() {}

  public async xhr<T>(settings: XhrSettings): Promise<T> {
    return (
      await CapacitorHttp.request({
        method: settings.method ?? 'GET',
        url: settings.url,
        data: settings.data,
        headers: settings.headers,
      })
    ).data as T;
  }
}
