import { inject, InjectionToken } from '@angular/core';
import { Platform } from '@ionic/angular';
import pouchdbDebug from 'pouchdb-debug';
import { addRxPlugin, createRxDatabase, RxDatabase } from 'rxdb';
import { takeWhile } from 'rxjs/operators';
import makeDebug from 'src/makeDebug';
import { AuthService } from '../../auth.service';
import { EnvironmentService } from '../../environment/environment.service';
import {
  channelsMigrationStrategies,
  channelsSchema,
  chatChannelConsumptionSchema,
  ChatCollections,
  membersMigrationStrategies,
  membersSchema,
  messagesMigrationStrategies,
  messagesSchema,
} from './db-schema';

const debug = makeDebug('services:chat:db');

export const RX_DB_SERVICE_TOKEN = new InjectionToken<Promise<RxDatabase<ChatCollections>>>(
  'Manually constructed MyService',
  {
    providedIn: 'root',
    factory: () => _initDb(inject(Platform), inject(AuthService), inject(EnvironmentService)),
  }
);

export async function _initDb(platform: Platform, authService: AuthService, environmentService: EnvironmentService) {
  addRxPlugin(require('pouchdb-adapter-idb'));
  addRxPlugin(pouchdbDebug);
  addRxPlugin(require('rxdb/plugins/watch-for-changes'));

  debug('init db');
  const isCordova = platform.is('hybrid');
  const pouchSettings: any = {
    revs_limit: 1,
  };

  // migration - see rx-db-migration.spec.ts (to migrate schema)
  // https://github.com/pubkey/rxdb/blob/master/docs-src/data-migration.md
  // Use for dev
  // await RxDB.removeDatabase('chatdb', 'idb');

  const db = await createRxDatabase<ChatCollections>({
    name: await createDbName(authService, environmentService),
    adapter: 'idb',
    ignoreDuplicate: true,
    pouchSettings,
  });
  await db.addCollections({
    channels: {
      schema: channelsSchema,
      migrationStrategies: channelsMigrationStrategies,
    },
    consumptions: {
      schema: chatChannelConsumptionSchema,
    },
    messages: {
      schema: messagesSchema,
      migrationStrategies: messagesMigrationStrategies,
    },
    members: {
      schema: membersSchema,
      migrationStrategies: membersMigrationStrategies,
    },
  });
  debug('db init completed');
  // setup watch for changes when writing directly with .pouch
  db.messages.watchForChanges();
  return db;
}

export async function createDbName(authService: AuthService, environmentService: EnvironmentService) {
  return new Promise<string>(resolve => {
    authService.authenticatedEventPublisher.pipe(takeWhile(authEventData => !authEventData.isAuthenticated)).subscribe({
      complete: () => {
        const account = authService.authentication.account;
        const branch = environmentService.isProductionBranch() ? 'master' : environmentService.branch;
        let dbName = `${account.firstName.trim()[0]}${account.lastName.trim()[0]}${account._id.slice(-4)}_${branch}`;

        dbName = dbName.toLowerCase();
        dbName = normalizeToASCII(dbName);

        resolve(dbName);
      },
    });
  });
}

const normalizeToASCII = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // replace diacritics
    .replace(/\u00E6/g, 'a') // replace æ
    .replace(/\u00F8/g, 'o'); // replace ø
};
