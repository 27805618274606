import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'itl-small-icon-button',
  templateUrl: './small-icon-button.component.html',
  styleUrls: ['./small-icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallIconButtonComponent implements OnChanges {
  @Input() disabled = false;
  @Input() public icon;
  @Input() public active = false;
  @HostBinding('class.disabled') disabledStyle = false;
  @HostBinding('class.active') activeStyle = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.disabledStyle = this.disabled;
    this.activeStyle = this.active;
  }
}
