import { NgZone } from '@angular/core';
import { Paginated, Params, Query, Service } from '@feathersjs/feathers';
import { SyncTimestampWorkItem } from 'src/app/business/timestamp/sync-timestamp-work-item';
import { ICancellationToken } from 'src/app/common/contracts/cancellation/cancellation-token';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { LogLevel } from 'src/app/common/contracts/logging/log-level';
import { paramsForServer } from 'src/app/common/feathers/params-for-server';

import { IAdditionalSyncData } from '../contracts/sync/additional-sync-data';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { IWebSyncService } from '../contracts/sync/service/web-sync-service';

export abstract class WebSyncService implements IWebSyncService {
  protected _service: Service<any>;
  protected _syncTimestampWorkItem: SyncTimestampWorkItem;
  protected limit = 2000;
  protected defaultQuery: Query = {};

  constructor(protected _name: string, private _ngZone: NgZone, private _appController: IAppController) {
    if (!this._name) {
      throw new Error('Name of service cannot be empty or null/undefined.');
    }

    this._syncTimestampWorkItem = this._appController.getWorkItemByCtor(
      'SyncTimestampWorkItem'
    ) as SyncTimestampWorkItem;
  }

  protected async canBeSynced(): Promise<boolean> {
    return true;
  }

  protected abstract handleData(data: any, token?: ICancellationToken): Promise<any>;

  protected async beforeSync(): Promise<void> {
    return;
  }

  public async sync(
    token: ICancellationToken,
    user: any,
    resetServiceRepeats: (token: ICancellationToken, serviceName: string) => void
  ): Promise<any> {
    await this.beforeSync();
    const canBeSynced = await this.canBeSynced();
    if (!canBeSynced) {
      token.syncCompleted[this._name] = true;
      return;
    }

    if (!this._service) {
      throw new Error(
        `Service with name ${this._name} could not be found. Please specify a correct name for your synchronisation
          service or take care your service instance is running.`
      );
    }
    const timestamp = await this._syncTimestampWorkItem.getSyncTimestamp(this._name);
    token.syncCompleted[this._name] = false;

    try {
      let query: Query = {
        $limit: 0,
        timestamp: { $gt: timestamp },
        $sort: { timestamp: 1 },
      };

      let result: any;
      // If the result is not paginated we need to query at least once since the query before limits to 0
      let total = 1;
      let skip = 0;
      let progress = 0;

      if (!token.cancelled.get()) {
        result = await this._service.find(this.getParamsWithAuth(user, { query }));
        resetServiceRepeats(token, this._name);

        total = this.isPaginated(result) ? result.total : total;
      }

      while (total > 0 && !token.cancelled.get()) {
        await this.sleep();

        query = { ...this.defaultQuery, ...query, $limit: this.limit, $skip: skip };

        if (token.cancelled.get()) {
          break;
        }

        result = await this._service.find(this.getParamsWithAuth(user, { query }));
        resetServiceRepeats(token, this._name);

        const data = this.isPaginated(result) ? result.data : result;
        const payload = await this.handleData(data, token);
        await this._syncTimestampWorkItem.setSyncTimestamp(this._name, payload, new Date(0));

        progress += payload.length;

        this.limit = this.isPaginated(result) ? result.limit : this.limit;

        total -= this.limit;
        skip += this.limit;
      }

      token.syncCompleted[this._name] = total <= 0;

      if (token.cancelled.get()) {
        throw {
          reason: 'cancelled',
          service: this._name,
          progress: `progress: ${progress}`,
          total: `total: ${total}`,
          level: LogLevel.silent,
        };
      }
    } catch (error) {
      window.logger.error(`SYNCSERVICE SYNC / ${this._name}`, error, error.level);
      throw error;
    }
  }

  public async setup(feathersAppProvider: IFeathersAppProvider): Promise<void> {
    await feathersAppProvider.ready;

    if (!feathersAppProvider.app) {
      throw new Error('Feathers application must be provided for synchronisation.');
    }

    this._service = feathersAppProvider.app.service(this._name);
  }

  protected getParamsWithAuth(user, params: Params = { query: {} }): Params {
    if (user && user.authorization) {
      const { query } = params;

      paramsForServer({
        query,
        syncData: this.createSyncData(user),
      });
    }

    return params;
  }

  protected sleep(ms?: number) {
    return new Promise<void>(resolve => this._ngZone.runOutsideAngular(() => setTimeout(resolve, ms || 0)));
  }

  private createSyncData(user: any, additionalData?: IAdditionalSyncData): any {
    let syncData = {
      authorization: user.authorization,
      emailAPI:
        user.organization.alberta && user.organization.alberta.emailAPI ? user.organization.alberta.emailAPI : false,
      emailTo: user.organization.alberta && user.organization.alberta.emailTo ? user.organization.alberta.emailTo : [],
      returnDeliveryEmailAPI:
        user.organization.alberta && user.organization.alberta.returnDeliveryEmailAPI
          ? user.organization.alberta.returnDeliveryEmailAPI
          : false,
      returnDeliveryEmailTo:
        user.organization.alberta && user.organization.alberta.returnDeliveryEmailTo
          ? user.organization.alberta.returnDeliveryEmailTo
          : [],
      tenantId: user.organization.tenantId,
    };
    if (additionalData) {
      if (additionalData.additionalSyncInfo) {
        syncData = { ...syncData, ...additionalData.additionalSyncInfo };
      }
      if (additionalData.changes) {
        syncData['changes'] = additionalData.changes;
      }
    }

    return syncData;
  }

  protected isPaginated(paginated: any[] | Paginated<any>): paginated is Paginated<any> {
    return paginated && (paginated as Paginated<any>).data !== undefined;
  }
}
