/**
 * *format*: metadata.patientId or patientId
 */
export type IndexFieldPath = string;

export class IndexField {
  constructor(private _fieldPath: IndexFieldPath) {}

  public get name(): string {
    const dividerPosition = this._fieldPath.indexOf('.');
    const hasDivider = dividerPosition !== -1;
    if (hasDivider) {
      return this._fieldPath.substring(dividerPosition + 1);
    }
    return this._fieldPath;
  }

  public get path(): string {
    return this._fieldPath;
  }
}
