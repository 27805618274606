import { Component, Input } from '@angular/core';

@Component({
  selector: 'itl-select-modal-item',
  templateUrl: './select-modal-item.component.html',
  styleUrls: ['./select-modal-item.component.scss'],
})
export class SelectModalItemComponent {
  @Input() icon = 'alberta-dot';
  @Input() title = '';
  @Input() subTitle = '';
}
