import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NursingHomeDto } from 'src/app/shared/models/institution/nursing-home-dto.model';

import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'nursing-home-list-item',
  templateUrl: 'nursing-home-list-item.html',
  styleUrls: ['./../autocomplete-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NursingHomeListItem implements IListView {
  @Input() item: NursingHomeDto;
  @Output() clicked: EventEmitter<NursingHomeDto> = new EventEmitter<NursingHomeDto>();

  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
