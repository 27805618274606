import { Pipe, PipeTransform } from '@angular/core';
import { SelectionItem } from 'src/app/shared/models/selectionItem';

@Pipe({
  name: 'selectionDisplayText',
  pure: false,
})
export class SelectionDisplayTextPipe implements PipeTransform {
  constructor() {}
  transform(id: any, values: SelectionItem[], compareFn?: any) {
    if (values) {
      const result = values.find(element => {
        if (compareFn) {
          return compareFn(id, element.id);
        }
        return id === element.id;
      });
      if (result && result.value) {
        return result.value;
      }
    }
    return null;
  }
}
