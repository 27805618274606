import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'itl-search-empty-state',
  templateUrl: './search-empty-state.component.html',
  styleUrls: ['./search-empty-state.component.scss'],
})
export class SearchEmptyStateComponent implements OnInit {
  @Input() imageSrc: string;
  @Input() title: string;
  @Input() subtitle: string;

  ngOnInit() {}
}
