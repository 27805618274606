import { IServiceOrder } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';
import { ServiceOrderWorkItem } from 'src/app/business/service-order-entity/service-order-work-item';

@Injectable()
export class ServiceOrderCommandHandler extends EntityCommandHandler<IServiceOrder> {
  protected workItem: IWorkItem<IServiceOrder>;

  constructor(serviceOrderWorkItem: ServiceOrderWorkItem) {
    super();
    this.workItem = serviceOrderWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'ServiceOrderDto';
  }
}
