import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { PlannedOrderDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';

import { WorkItem } from '../work-item';
import { PlannedOrderDto } from './../../shared/models/planned-order/planned-order-dto.model';

@Injectable({ providedIn: 'root' })
export class PlannedOrderWorkItem extends WorkItem<PlannedOrderDto> {
  constructor(@Inject(UnitOfWorkFactory) _unitOfWorkFactory: IUnitOfWorkFactory) {
    super(_unitOfWorkFactory);
  }

  get name(): string {
    return 'PlannedOrderWorkItem';
  }
  get database(): string {
    return PlannedOrderDB;
  }
}
