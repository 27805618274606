import { IModel, IUser } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { LogLevel } from '@common/contracts/logging/log-level';
import { IWorkItem } from '@common/contracts/work-item/work-item_T';
import { Application, Paginated, Params, Query } from '@feathersjs/feathers';
import { upperFirst } from 'lodash';
import { SyncTimestampWorkItem } from 'src/app/business/timestamp/sync-timestamp-work-item';
import { ICancellationToken } from 'src/app/common/contracts/cancellation/cancellation-token';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';
import { paramsForServer } from 'src/app/common/feathers/params-for-server';

import {
  AttributeTemplateModelName,
  ContractArticleGroupName,
  MatchModelName,
  PayerInfoModelName,
  PayerModelName,
  PostalCodeModelName,
  RegionModelName,
  ReportModelName,
  RightsetModelName,
  TemplateModelName,
} from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { IDatabaseSynchronizer } from '../contracts/sync/database-synchronizer_T';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { ISyncService } from '../contracts/sync/service/sync-service';
import { EventService } from '../event.service';
import { QuoteModelName } from './../../models/model-names';

@Injectable()
export class GeneralSyncService {
  private _app: Application;
  private _serviceName: string;
  private _syncTimestampWorkItem: SyncTimestampWorkItem;
  private _workItem: IWorkItem<any> | IDatabaseSynchronizer<any>;

  public get name(): string {
    return this._serviceName;
  }

  protected _syncables: string[] = [
    MatchModelName,
    ReportModelName,
    PostalCodeModelName,
    RegionModelName,
    RightsetModelName,
    PayerInfoModelName,
    PayerModelName,
    TemplateModelName,
    AttributeTemplateModelName,
    ContractArticleGroupName,
    QuoteModelName,
  ];

  protected _baseQuery: Query = {
    $sort: {
      timestamp: 1,
    },
  };

  constructor(
    @Inject(AppController) private _appController: IAppController,
    @Inject(Dispatcher) private _dispatcher: IDispatcher<IModel>,
    private _eventService: EventService<SyncProgressEvent>,
    private _ngZone: NgZone
  ) {
    this._syncTimestampWorkItem = this._appController.getWorkItemByCtor(
      'SyncTimestampWorkItem'
    ) as SyncTimestampWorkItem;
  }

  public resolve(): ISyncService[] {
    return this._syncables.map(serviceName => {
      const service = new GeneralSyncService(this._appController, this._dispatcher, this._eventService, this._ngZone);
      service._serviceName = serviceName;
      service._workItem = this._appController.getWorkItemByCtor(`${upperFirst(serviceName)}WorkItem`);

      return service;
    });
  }

  public removeAllListeners(): void {
    return;
  }

  public async setup(feathersAppProvider: IFeathersAppProvider): Promise<void> {
    await feathersAppProvider.ready;

    if (!feathersAppProvider.app) {
      throw new Error('Feathers application must be provided for synchronisation.');
    }

    this._app = feathersAppProvider.app;
  }

  public async reSync(token: ICancellationToken, user: any) {
    return;
  }

  public async sync(
    token: ICancellationToken,
    user: any,
    resetServiceRepeats: (token: ICancellationToken, serviceName: string) => void
  ): Promise<any> {
    const limit = 2000;

    token.syncCompleted[this._serviceName] = false;

    try {
      const timestamp = await this._syncTimestampWorkItem.getSyncTimestamp(this._serviceName);
      let initialSyncStart = await this._syncTimestampWorkItem.getInitialSyncStart(this._serviceName);
      if (initialSyncStart === null) {
        if (timestamp.valueOf() === 0) {
          // initial sync
          initialSyncStart = new Date();
        } else {
          // Sync did run in the past, without initialSync timestamp getting set.
          // This happens in older versions of Alberta.
          // Setting initialSyncStart to the past.
          initialSyncStart = new Date(0);
        }
      }
      const service = this._app.service(this._serviceName);
      let query: Query = {
        ...this._baseQuery,
        $limit: 0,
        timestamp: {
          $gt: timestamp,
        },
      };
      if (this._workItem.deletable && timestamp <= initialSyncStart) {
        // Initial Sync not completed
        // Archived items should not be in the local database.
        // It is needed to sync archived items anyway if they changed after initialSyncStart, as they could have been
        // archived recently and we might have an outdated, non-archived version of the same item in the database
        // that needs to be updated (deleted)
        query.$or = [{ archived: { $ne: true } }, { timestamp: { $gt: initialSyncStart } }];
      }

      let total = 1;
      let progress = 0;
      let result: any;
      let skip = 0;

      if (!token.cancelled.get()) {
        result = await service.find(this.getParamsWithAuth(user, { query }));
        resetServiceRepeats(token, this._serviceName);

        // If the result is not paginated we need to query at least once since the query before limits to 0
        total = this.isPaginated(result) ? result.total : total;

        this._eventService.dispatchEvent(
          new SyncProgressEvent(this._serviceName, this.isPaginated(result) ? result.total : result.length, progress)
        );
      }

      while (total > 0 && !token.cancelled.get()) {
        await this.sleep();

        query = {
          ...query,
          $limit: limit,
          $skip: skip,
        };

        if (token.cancelled.get()) {
          break;
        }

        result = await service.find(this.getParamsWithAuth(user, { query }));
        resetServiceRepeats(token, this._serviceName);

        const payload = await this.updateDatabase(result, token, initialSyncStart);
        try {
          progress += payload.length;
          this._eventService.dispatchEvent(
            new SyncProgressEvent(this._serviceName, this.isPaginated(result) ? result.total : result.length, progress)
          );
        } catch (e) {}
        total -= result.limit || limit;
        skip += result.limit || limit;
      }

      token.syncCompleted[this._serviceName] = total <= 0;

      if (token.cancelled.get()) {
        delete token.serviceRepeats[this._serviceName];
        throw {
          reason: 'cancelled',
          service: this._serviceName,
          progress: `progress: ${progress}`,
          total: `total: ${total}`,
          level: LogLevel.silent,
        };
      }
    } catch (error) {
      window.logger.error(`GENERALSYNCSERVICE SYNC / ${this._serviceName}`, error, error.level);
      throw error;
    }
  }

  public async countDocuments(user: IUser): Promise<number> {
    const query: any = { $limit: 0, timestamp: { $gt: new Date(0) } };
    if (this._workItem.deletable) {
      query.archived = { $ne: true };
    }

    const result = await this._app.service(this._serviceName).find(this.getParamsWithAuth(user, { query }));

    return result.total || result.length;
  }

  private async updateDatabase(result, token: ICancellationToken, initialSyncStart: Date): Promise<any[]> {
    try {
      const data = this.isPaginated(result) ? result.data : result;
      const syncState = await (this._workItem as IDatabaseSynchronizer<any>).sync(data, token);
      await this._dispatcher.sync(this._serviceName, syncState);
      const payload = [...syncState.created, ...syncState.deleted, ...syncState.updated];
      await this._syncTimestampWorkItem.setSyncTimestamp(this._serviceName, payload, initialSyncStart);

      return payload;
    } catch (error) {
      window.logger.error(`GENERALSYNCSERVICE UPDATEDATABASE / ${this._serviceName}`, error);
    }
  }

  private sleep(ms?) {
    return new Promise<void>(resolve => this._ngZone.runOutsideAngular(() => setTimeout(resolve, ms || 0)));
  }

  private getParamsWithAuth(user, params: Params = { query: {} }): Params {
    if (user && user.authorization) {
      const { query } = params;
      paramsForServer({ query, syncData: { authorization: user.authorization } });
    }

    return params;
  }

  private isPaginated(paginated: any[] | Paginated<any>): paginated is Paginated<any> {
    return paginated && (paginated as Paginated<any>).data !== undefined;
  }
}
