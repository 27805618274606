import { IGroup } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { assignIn, isEqual, uniqWith } from 'lodash';
import { GroupDB, RegionDB } from 'src/app/common/repository/databases';

import { RegionDto } from '../models/user-management/region-dto.model';
import { AuthService } from './auth.service';
import { IGeneralEntityService } from './contracts/general-entity.service';
import { IQueryService } from './contracts/query/query-service';
import { GroupService } from './group.service';
import { QueryService } from './query/query.service';

@Injectable({
  providedIn: 'root',
})
export class RegionService implements IGeneralEntityService {
  restriction: any;
  responsibleTypes = ['region'];
  type = 'region';
  private _restrictToUser = false;

  constructor(
    @Inject(QueryService) private _queryService: IQueryService,
    private _auth: AuthService,
    private _groupService: GroupService
  ) {}

  public isRestricted(item: any): boolean {
    return false;
  }

  public clone(): IGeneralEntityService {
    return new RegionService(this._queryService, this._auth, this._groupService);
  }

  public restrictToUser(activate: boolean) {
    this._restrictToUser = activate;
  }

  public async getAll(): Promise<RegionDto[]> {
    if (this._restrictToUser) {
      return this.getUserRegions();
    }
    return this._queryService.getAll<RegionDto>(RegionDB);
  }

  public async getAllIgnoreRestriction(): Promise<RegionDto[]> {
    return this._queryService.getAll<RegionDto>(RegionDB);
  }

  public async find(id: string): Promise<RegionDto> {
    return this._queryService.get<RegionDto>(id, RegionDB);
  }

  public async query(query: string, options?: { isIn?: boolean }): Promise<RegionDto[]> {
    if (this.restrictToUser) {
      const allRegions = await this.getAll();
      const results: RegionDto[] = [];
      if (query === '*' || query === '**' || query === '***') {
        return allRegions;
      }
      allRegions.forEach(region => {
        if (region.label.toLowerCase().includes(query.toLowerCase())) {
          results.push(region);
        }
      });
      return results;
    }
    return this._queryService.search<RegionDto>({ query }, RegionDB, options);
  }

  public async loadRegionWithChildren(id: string): Promise<RegionDto[]> {
    const regions = [];
    if (id) {
      let region = assignIn(new RegionDto(), await this.find(id));
      if (region) {
        regions.push(region);
        while (region.parent) {
          region = assignIn(new RegionDto(), await this.find(region.parent));
          if (region) {
            regions.push(region);
          } else {
            break;
          }
        }
      }
    }
    return regions;
  }

  private async getUserRegions(): Promise<RegionDto[]> {
    if (
      this._auth.authentication &&
      this._auth.authentication.account &&
      this._auth.authentication.account.authorization &&
      this._auth.authentication.account.authorization.regions
    ) {
      return this._auth.authentication.account.authorization.regions;
    }
  }

  public async getRegions(userGroups: string[]) {
    const regions = [];
    const groups = await this._queryService.search<IGroup>(
      { query: userGroups.map(groupId => `_id:${groupId}`).join(' ') },
      GroupDB,
      { isIn: true }
    );

    for (const userGroup of groups) {
      if (userGroup.regionId) {
        const region = await this._queryService.get(userGroup.regionId, RegionDB);
        if (region) {
          regions.push(region);

          await findRegion(region._id, this._queryService);
        }
      }
    }

    async function findRegion(parentRegion: string, queryService: IQueryService) {
      const foundRegions = await queryService.search({ query: `parent:${parentRegion}` }, RegionDB);
      if (!foundRegions || !Array.isArray(foundRegions) || foundRegions.length === 0) {
        return;
      }
      for (const region of foundRegions) {
        regions.push(region);
        await findRegion(region._id, queryService);
      }
    }

    return uniqWith(regions, isEqual);
  }
}
