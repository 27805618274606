import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { CareProposalDB } from 'src/app/common/repository/databases';
import { Changes } from 'src/app/common/tracking';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { CareProposalDto } from 'src/app/shared/models/care-proposal/care-proposal-dto.model';
import { IArticleLineHistoryService } from 'src/app/shared/services/contracts/history/article-history-service';
import { ArticleLineHistoryService } from 'src/app/shared/services/history/article-line-history.service';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class CareProposalWorkItem extends WorkItem<CareProposalDto> {
  constructor(
    @Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory,
    @Inject(ArticleLineHistoryService) private _articleLineHistoryService: IArticleLineHistoryService
  ) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'CareProposalWorkItem';
  }
  get database(): string {
    return CareProposalDB;
  }

  get chunkSize(): number {
    return 250;
  }

  public async beforeCreate(careProposal: CareProposalDto) {
    if (!careProposal || !careProposal.proposedArticleLines) {
      return careProposal;
    }

    careProposal.articleLineHistory = careProposal.articleLineHistory || [];

    for (const proposedArticleLine of careProposal.proposedArticleLines) {
      await this._articleLineHistoryService.writeAddedArticleHistory(careProposal, proposedArticleLine);
    }

    return careProposal;
  }

  public async beforeUpdate(careProposal: CareProposalDto, changes: Changes): Promise<CareProposalDto> {
    if (!changes) {
      return careProposal;
    }

    careProposal.articleLineHistory = careProposal.articleLineHistory || [];
    return this._articleLineHistoryService.writeArticleHistory(careProposal, changes) as Promise<CareProposalDto>;
  }

  protected async afterUpdate(item: CareProposalDto, model: CareProposalDto): Promise<CareProposalDto> {
    model.articleLineHistory = item.articleLineHistory;

    return item;
  }
}
