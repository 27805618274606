import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { assignIn } from 'lodash';
import { ISearchable } from 'src/app/common/contracts/components/searchable';
import { LogLevel } from 'src/app/common/contracts/logging/log-level';
import { IGeneralEntityService, ProviderToken } from 'src/app/shared/services/contracts/general-entity.service';
import { IProviderConfig, ProviderConfigToken } from 'src/app/shared/services/provider-config';

import { IBaseInputContract } from '../contracts/base-input';
import { AutocompleteMobileSearchViewComponent } from './mobile-search-view/autocomplete-mobile-search-view.component';

@Component({
  selector: 'itl-autocomplete',
  templateUrl: 'autocomplete.component.html',
  styleUrls: ['autocomplete.component.scss'],
})
// TODO: Refactor to use ControlValueAccessor
export class AutocompleteComponent implements OnInit, OnChanges, IBaseInputContract {
  @Input() public type:
    | 'doctor'
    | 'hospital'
    | 'insuranceContract'
    | 'nursingHome'
    | 'nursingService'
    | 'payer'
    | 'postalCode'
    | 'group'
    | 'region'
    | 'productGroup'
    | 'pharmacy'
    | 'standardCareProposal'
    | 'fieldNurse'
    | 'patient'
    | 'templateTag'
    | 'calendarResource'
    | 'institutionContactFunction'
    | 'article';

  @Input() public placeholder: string;
  @Input() public title: string;
  @Input() public useLogicalAnd: boolean;
  @Input() public selected: string;
  @Input() public icon: string;
  @Input() public required: boolean;
  @Input() public restriction: any;
  @Input() public control: AbstractControl;
  @Input() public showDeleteButton = true;
  @Input() public description: string;
  @Input() public disabled: boolean;
  @Input() public customService: IGeneralEntityService;
  @Input() public showAllOnStart = false;
  @Input() public noResultsErrorMessage = this._translate.instant('validation.errors.autocompleteNoResults');
  @Input() public fullPageModal = false;
  @Input() public articleFromDocument = false;
  @Output() public controlChange: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();
  @Output() public selectedChange: EventEmitter<string> = new EventEmitter<string>();

  public selectedItem: ISearchable;
  public itemsProvider: IGeneralEntityService;

  constructor(
    @Inject(ProviderToken) private _itemsProviders: IGeneralEntityService[],
    @Inject(ProviderConfigToken) private _providerConfig: IProviderConfig,
    private _modalController: ModalController,
    private _translate: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.toSearchTerm = this.toSearchTerm.bind(this);
    this.filterItems = this.filterItems.bind(this);
  }

  public async ngOnInit(): Promise<void> {
    if (this.type && !this.customService) {
      this.itemsProvider = this._itemsProviders.find(item => item.responsibleTypes.some(name => name === this.type));
      if (!this.itemsProvider) {
        window.logger.log(`No provider found for autocomplete of type ${this.type}`, LogLevel.warning);
      }
      if (this.itemsProvider.type) {
        this.itemsProvider = this.itemsProvider.clone();
      }
      this.itemsProvider.type = this.type;
    }
    if (this.customService) {
      this.itemsProvider = this.customService;
    }
    if (this.selected) {
      await this.setSelected();
    }
    this._changeDetectorRef.markForCheck();
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if ('restriction' in changes) {
      if (changes.restriction.currentValue !== changes.restriction.previousValue) {
        this.restriction = changes.restriction.currentValue;
      }
    }
    if ('type' in changes) {
      if (this.itemsProvider && changes.type.currentValue !== changes.type.previousValue) {
        this.itemsProvider.type = changes.type.currentValue;
        if (!('selected' in changes)) {
          this.selected = null;
        }
      }
    }
    await this.setSelected();
    this._changeDetectorRef.markForCheck();
  }

  public async openSearchView(): Promise<void> {
    const autocompleteModal = await this._modalController.create({
      component: AutocompleteMobileSearchViewComponent,
      componentProps: {
        itemsProvider: this.itemsProvider,
        placeholder: this.placeholder,
        type: this.type,
        errorMessage: this.noResultsErrorMessage,
        toSearchTerm: this.toSearchTerm,
        filterItems: this.filterItems,
        showAllOnStart: this.showAllOnStart,
        articleFromDocument: this.articleFromDocument,
      },
      cssClass: this.fullPageModal === true ? 'fullPageModal' : '',
      backdropDismiss: false,
    });

    await autocompleteModal.present();
    const returnValue = await autocompleteModal.onDidDismiss();
    const item = returnValue.data;
    if (item) {
      if (this.control) {
        this.control.setValue(item._id);
        this.control.markAsDirty();
      }
      this.selected = item._id;
      this.selectedItem = item;
      this.selectedChange.emit(this.selected);
    }
    if (this.control) {
      this.control.markAsTouched();
      this.controlChange.emit(this.control);
    }
  }

  private async setSelected(): Promise<void> {
    if (this.itemsProvider && this.selected) {
      const result = await this.itemsProvider.find(this.selected);
      let item;
      if (!result) {
        item = {
          _id: this.selected,
          displayText: this._translate.instant('validation.warning.cantMatchId'),
        };
      } else {
        item = this.assignObjectToDto(result);
      }
      this.selectedItem = item;
    } else {
      this.clear();
    }
  }

  private assignObjectToDto(item: any): ISearchable {
    const config = this._providerConfig[this.type];
    return assignIn(config.dtoFactory(), item);
  }

  public toSearchTerm(query: string): string {
    return query.split(' ').reduce((term, word) => {
      if (word.trim()) {
        // tslint:disable-next-line:no-parameter-reassignment
        term = `${term}${term.length > 0 ? ' ' : ''}${this.useLogicalAnd ? `+${word}` : word}`;
      }
      return term;
    }, '');
  }

  public filterItems(results: any[]): any[] {
    const filteredItems = [];
    if (results) {
      this.itemsProvider.restriction = this.restriction;
      results.forEach(element => {
        const object = this.assignObjectToDto(element);
        if (this.itemsProvider.isRestricted(object)) {
          return;
        }
        filteredItems.push(object);
      });
    }
    return filteredItems;
  }

  public clear() {
    if (this.selectedItem) {
      this.selectedItem = null;
    }
  }

  public removeSelected($event: any): void {
    if ($event) {
      $event.stopPropagation();
    }
    if (this.selectedItem) {
      this.selectedItem = null;
    }
    if (this.selected) {
      this.selected = null;
      this.selectedChange.emit(this.selected);
    }
    if (this.control) {
      this.control.setValue(null);
      this.control.markAsDirty();
      this.controlChange.emit(this.control);
    }
  }
}
