import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allSelected',
  pure: false,
})
export class AllSelectedPipe implements PipeTransform {
  constructor() {}
  transform(items: any[], values: any[]): boolean {
    for (const element of items) {
      const res = values.find(item => item._id === element._id);
      if (res === undefined) {
        return false;
      }
    }
    return true;
  }
}
