import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AlertDialogService {
  constructor(private alertController: AlertController, private translate: TranslateService) {}

  /**
   * Any texts will be passed to the TranslateService
   */
  showYesNoAlert(
    header: string,
    subHeader: string,
    buttonText: {
      yesButtonText: string;
      noButtonText: string;
    } = { yesButtonText: 'YES', noButtonText: 'NO' }
  ): Promise<boolean> {
    return new Promise(async resolve => {
      const alert = await this.alertController.create({
        header: this.translate.instant(header),
        subHeader: this.translate.instant(subHeader),
        buttons: [
          {
            text: this.translate.instant(buttonText.noButtonText),
            role: 'cancel',
            handler: () => {
              resolve(false);
            },
          },
          {
            text: this.translate.instant(buttonText.yesButtonText),
            handler: async () => {
              resolve(true);
            },
          },
        ],
      });
      await alert.present();
    });
  }
}
