import { Inject, Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IMaintenanceModeChecker } from 'src/app/business/maintenance/contracts/mainenance-mode-checker';
import { IMaintenanceService } from 'src/app/business/maintenance/contracts/maintenance';
import { IShutdownBroker } from 'src/app/business/maintenance/contracts/shutdown-broker';
import { IShutdownInfo } from 'src/app/business/maintenance/contracts/shutdown-info';
import { IShutdownMetadata } from 'src/app/business/maintenance/contracts/shutdown-metadata';
import { ShutdownTimeoutType } from 'src/app/business/maintenance/contracts/shutdown-timeout-type';

import makeDebug from '../../../../makeDebug';
import { MaintenanceDto } from '../../models/maintenance/maintenance-dto.model';
import { MaintenanceModeChecker } from './maintenance-mode-checker.service';
import { MaintenanceService } from './maintenance.service';

const debug = makeDebug('maintenance:shutdown-broker');

@Injectable({ providedIn: 'root' })
export class ShutdownBroker implements IShutdownBroker {
  private _shutdownSubject$ = new Subject<IShutdownInfo>();
  public get shutdownWatch(): Observable<IShutdownInfo> {
    return this._shutdownSubject$.asObservable();
  }
  private _endCountdownTimeout: NodeJS.Timeout;
  private _startCountdownTimeout: NodeJS.Timeout;

  constructor(
    private _ngZone: NgZone,
    @Inject(MaintenanceModeChecker) private _maintenanceModeChecker: IMaintenanceModeChecker,
    @Inject(MaintenanceService) private _maintenanceService: IMaintenanceService
  ) {
    this._maintenanceService.maintenanceWatch.subscribe(maintenance =>
      this.handleMaintenance(maintenance as MaintenanceDto)
    );
  }

  private setShutdown(shutdownInfo: IShutdownInfo) {
    debug('next shutdownInfo', shutdownInfo);
    this._shutdownSubject$.next(shutdownInfo);
  }

  private handleMaintenance(maintenance: MaintenanceDto) {
    global.clearTimeout(this._endCountdownTimeout);
    global.clearTimeout(this._startCountdownTimeout);

    debug('checking maintenance for shutdown metadata', maintenance);
    const shutdownMetadatas = this._maintenanceModeChecker.check(maintenance);

    for (const shutdownMetadata of shutdownMetadatas) {
      if (shutdownMetadata.timeout) {
        this.handleTimeout(shutdownMetadata);
      } else {
        this.setShutdown(shutdownMetadata.shutdownInfo);
      }
    }
  }

  private handleTimeout(shutdownMetadata: IShutdownMetadata) {
    this._ngZone.runOutsideAngular(() => {
      if (shutdownMetadata.timeout.type === ShutdownTimeoutType.end) {
        this._endCountdownTimeout = global.setTimeout(() => {
          debug('timeout for end expired', shutdownMetadata.shutdownInfo);
          this.setShutdown(shutdownMetadata.shutdownInfo);
        }, shutdownMetadata.timeout.timeInMs);
      } else {
        this._startCountdownTimeout = global.setTimeout(() => {
          debug('timeout for start expired', shutdownMetadata.shutdownInfo);
          this.setShutdown(shutdownMetadata.shutdownInfo);
        }, shutdownMetadata.timeout.timeInMs);
      }
    });
  }
}
