import { IMaintenance } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { IMaintenanceModeChecker } from 'src/app/business/maintenance/contracts/mainenance-mode-checker';
import { IShutdownMetadata } from 'src/app/business/maintenance/contracts/shutdown-metadata';
import { ShutdownTimeout, ShutdownTimeoutType } from 'src/app/business/maintenance/contracts/shutdown-timeout-type';

import makeDebug from '../../../../makeDebug';
import { AuthService } from '../auth.service';

const debug = makeDebug('maintenance:maintenance-mode-checker');

@Injectable({ providedIn: 'root' })
export class MaintenanceModeChecker implements IMaintenanceModeChecker {
  private _diffByStart = -1;
  private _diffByEnd = -1;
  private _shutdownMetadata: IShutdownMetadata[];

  constructor(private _authenticationService: AuthService) {}

  public check(maintenance: IMaintenance): IShutdownMetadata[] {
    this._shutdownMetadata = [];

    this._diffByStart = maintenance && moment(maintenance.start).diff(moment(), 'ms', true);
    this._diffByEnd = maintenance && moment(maintenance.end).diff(moment(), 'ms', true);
    debug('Calculated diff by start and end', this._diffByStart, this._diffByEnd);

    if (
      !maintenance ||
      this._authenticationService.userIsItLabs ||
      (maintenance && (this.isLessOrEqualZero(this._diffByEnd) || maintenance.stopped))
    ) {
      debug('Maintenance null || user is IT-Labs || maintenance stopped || end is before now');
      this.pushShutdownMetadata({ shutdown: false, maintenance });
    }

    this.evaluateStartAndEnd(maintenance);

    if (maintenance && !maintenance.stopped && moment().isBefore(moment(maintenance.start))) {
      debug('Pushing shutdown metadata while start is after now && maintenance not stopped');
      this.pushShutdownMetadata({ shutdown: false, maintenance });
    }

    return this._shutdownMetadata;
  }

  private evaluateStartAndEnd(maintenance: IMaintenance): void {
    if (
      !maintenance ||
      maintenance.stopped ||
      this._diffByEnd <= this._diffByStart ||
      this.isLessOrEqualZero(this._diffByEnd)
    ) {
      debug('maintenance null || maintenance stopped || start/end not valid || end is before now');
      return;
    }

    this.evaluateStart(maintenance);

    this.evaluateEnd(maintenance);
  }
  private evaluateEnd(maintenance: IMaintenance): void {
    this.pushShutdownMetadata(
      { shutdown: false, maintenance },
      { timeInMs: this._diffByEnd, type: ShutdownTimeoutType.end }
    );
  }

  private evaluateStart(maintenance: IMaintenance): void {
    if (this.isLessOrEqualZero(this._diffByStart) && this.isBiggerZero(this._diffByEnd)) {
      debug('start date is past behind and end after now');
      this.pushShutdownMetadata({ shutdown: true, maintenance, showPopUp: true });
      return;
    }

    if (this.isAllBiggerZero()) {
      debug('start and end are before now');
      this.pushShutdownMetadata({ shutdown: false, maintenance, showPopUp: true });
    }

    if (this.isBiggerZero(this._diffByStart)) {
      debug('start is before now');
      this.pushShutdownMetadata(
        { shutdown: true, maintenance, showPopUp: true },
        { timeInMs: this._diffByStart, type: ShutdownTimeoutType.start }
      );
    }
  }

  private pushShutdownMetadata(
    shutdownInfo: { shutdown: boolean; maintenance: IMaintenance; showPopUp?: boolean },
    shutdownTimeout?: ShutdownTimeout
  ): void {
    this._shutdownMetadata.push({
      timeout: shutdownTimeout,
      shutdownInfo: { ...shutdownInfo, userIsItLabs: this._authenticationService.userIsItLabs },
    });
  }

  private isBiggerZero(timeInMs: number): boolean {
    return timeInMs > 0;
  }

  private isLessOrEqualZero(timeInMs: number): boolean {
    return timeInMs <= 0;
  }

  private isAllBiggerZero(): boolean {
    return [this._diffByEnd, this._diffByStart].every((timeInMs: number) => this.isBiggerZero(timeInMs));
  }
}
