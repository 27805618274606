import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { ReminderDto } from 'src/app/shared/models/reminder/reminder-dto.model';

import { ReminderDB } from '../../common/repository/databases';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class ReminderWorkItem extends WorkItem<ReminderDto> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }

  get name(): string {
    return 'ReminderWorkItem';
  }
  get database(): string {
    return ReminderDB;
  }
}
