import { Injectable } from '@angular/core';
import { PatientService } from '@services/patient.service';
import { IStateExtension } from 'src/app/common/contracts/state/state-extension';
import { IStateRegistry } from 'src/app/common/contracts/state/state-registry';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';
import { AttachmentModelName } from 'src/app/shared/models/model-names';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class AttachmentStateExtension implements IStateExtension<AttachmentDto> {
  private _stateRegistry: IStateRegistry;

  public get name(): string {
    return AttachmentModelName;
  }

  constructor(private _authenticationService: AuthService, private _patientService: PatientService) {}

  setRegistry(stateRegistry: IStateRegistry): void {
    this._stateRegistry = stateRegistry;
  }
  async canBeStored(attachment: AttachmentDto): Promise<boolean> {
    const isMyAttachment = attachment.patient != null || (await this.isMyAttachment(attachment));

    return isMyAttachment && attachment.metadata.createdBy !== this._authenticationService.authentication.account._id;
  }

  public async afterCreate(attachments: AttachmentDto[]): Promise<void> {
    if (!attachments || !attachments.length) {
      return;
    }

    const myPatients = await this._patientService.getMyPatients();

    if (!myPatients.length) {
      return;
    }

    const filteredAttachments = attachments.filter(
      attachment =>
        attachment.metadata.type === 7 || // CareProposal
        attachment.metadata.type === 3 || // PatientAgreement
        attachment.metadata.type === 8 // DoctorDelegation
    );

    for (const attachment of filteredAttachments) {
      const canBeDispatched = await this.canBeStored(attachment);
      if (!canBeDispatched) {
        continue;
      }

      this._stateRegistry.createBySync(AttachmentModelName, 'notification', attachment);
    }
  }

  afterUpdate(attachments: AttachmentDto[]): Promise<void> {
    return;
  }

  /**
   * This also sets the patient property of the attachment!
   */
  private async isMyAttachment(attachment: AttachmentDto): Promise<boolean> {
    const myPatients = await this._patientService.getMyPatients();
    const patient = myPatients
      .filter(myPatient => !myPatient.deactivationReason && myPatient.accountingStatus)
      .find(
        myPatient =>
          attachment.metadata.patientId &&
          myPatient._id === attachment.metadata.patientId &&
          attachment.metadata.auditId == null
      );
    // If you find out why this necesarry please document it
    if (patient) {
      (attachment as any).patient = patient;
    }

    return patient != null;
  }
}
