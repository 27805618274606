import { IQuantityPerUnit, PackagingUnitType } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'articleUnit',
  pure: true,
  standalone: true,
})
export class ArticleUnitPipe2 implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(packagingUnit: IQuantityPerUnit): string {
    switch (packagingUnit.unit) {
      case PackagingUnitType.piece:
        return `${this._translate.instant('article.edit.units.stk')} (${packagingUnit.quantity} Stk.)`;
      case PackagingUnitType.carton:
        return `${this._translate.instant('article.edit.units.ktn')} (${packagingUnit.quantity} Stk.)`;
    }
  }
}
