import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PharmacyDto } from 'src/app/shared/models/institution/pharmacy-dto.model';

import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'pharmacy-list-item',
  templateUrl: 'pharmacy-list-item.html',
  styleUrls: ['./../autocomplete-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PharmacyListItem implements IListView {
  @Input() item: PharmacyDto;
  @Output() clicked: EventEmitter<PharmacyDto> = new EventEmitter<PharmacyDto>();

  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
