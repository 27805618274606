import { IReturnDelivery } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { ReturnDeliveryWorkItem } from 'src/app/business/return-delivery/return-delivery-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class ReturnDeliveryCommandHandler extends EntityCommandHandler<IReturnDelivery> {
  protected workItem: IWorkItem<IReturnDelivery>;

  constructor(returnDeliveryWorkItem: ReturnDeliveryWorkItem) {
    super();
    this.workItem = returnDeliveryWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'ReturnDeliveryDto';
  }
}
