<div class="page-wrapper" [style]="infoStyle">
  <div class="content">
    <ion-icon class="info-icon" [style]="iconStyle" name="alberta-info"></ion-icon>
    <div class="information">
      <div class="header">{{ header }}</div>
      <div class="message">{{ message }}</div>
    </div>
    <ion-icon class="cancel-icon" name="alberta-cancel" (click)="close()"></ion-icon>
  </div>
</div>
