import { Injectable } from '@angular/core';

import { ICancellationToken } from '../contracts/cancellation/cancellation-token';
import { ICancellationTokenHandler, source } from '../contracts/cancellation/cancellationtoken-handler';
import { CancellationToken } from './cancellation-token';

@Injectable({ providedIn: 'root' })
export class CancellationTokenHandler implements ICancellationTokenHandler {
  private _tokens: Record<string, ICancellationToken> = {
    app: new CancellationToken(),
    login: new CancellationToken(),
    maintenance: new CancellationToken(),
    commandQueue: new CancellationToken(),
  };

  public getToken(name: source): ICancellationToken {
    return this._tokens[name];
  }

  public setupToken(name: source): ICancellationToken {
    this._tokens[name] = new CancellationToken();

    return this._tokens[name];
  }

  public async cancellAll(): Promise<void> {
    for (const token of Object.values(this._tokens)) {
      await token.cancel();
    }
  }
}
