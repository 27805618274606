import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ColorService } from '@services/color.service';
import { AlbertaIconModule } from '../../alberta-icon/alberta-icon.module';

export enum InfoBoxType {
  Information,
  Warning,
  Error,
  Success,
}
@Component({
  selector: 'itl-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  standalone: true,
  imports: [CommonModule, AlbertaIconModule],
})
export class InfoBoxComponent implements OnInit {
  @Input() type: InfoBoxType = InfoBoxType.Information;
  @Input() icon = '';
  @Input() baseColor;

  public class = 'info';
  public infoStyle: string;
  public boxColor: string;

  ngOnInit(): void {
    if (this.type === InfoBoxType.Information) {
      this.class = 'info';
      if (!this.icon) {
        this.icon = 'alberta-info';
      }
    } else if (this.type === InfoBoxType.Warning) {
      this.class = 'warning';
      if (!this.icon) {
        this.icon = 'alberta-warning';
      }
    } else if (this.type === InfoBoxType.Error) {
      this.class = 'error';
      if (!this.icon) {
        this.icon = 'alberta-search-clear';
      }
      this.icon = 'alberta-search-clear';
    } else if (this.type === InfoBoxType.Success) {
      this.class = 'success';
      if (!this.icon) {
        this.icon = 'alberta-product-group-checkmark';
      }
    }
    if (this.baseColor) {
      this.setInfoBoxColor();
    }
  }

  /**
   * Set the color of the box based on the base color
   */
  private setInfoBoxColor(): void {
    if (!this.baseColor) {
      return;
    }
    const hsl = ColorService.hexToHSL(this.baseColor.trim());
    const hslPrefix = 'hsl(' + hsl.h + ',' + hsl.s * 100 + '%,';
    this.infoStyle = `color: ${hslPrefix}${hsl.l * 100}%); z-index: 10`;
    this.boxColor = `
      background-color: ${hslPrefix}99%);
      border-color: ${hslPrefix}${hsl.l * 100}%);
      color: ${hslPrefix}${hsl.l * 100}%);
    `;
  }
}
