import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

// Note: it is not possible to use onPush!
@Component({
  selector: 'multi-autocomplete-list-item',
  templateUrl: 'multi-autocomplete-list-item.component.html',
  styleUrls: ['multi-autocomplete-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MultiAutocompleteListItemComponent {
  @Input() selected: any[];
  @Input() item: any;
  @Output() changed: EventEmitter<null> = new EventEmitter<null>();
  toggled() {
    this.changed.emit();
  }
}
