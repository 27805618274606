import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AlbertaIconModule } from '../../alberta-icon/alberta-icon.module';

@Component({
  selector: 'itl-icon-button',
  template: `<itl-alberta-icon [name]="icon" [style.color]="color"></itl-alberta-icon>`,
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AlbertaIconModule],
})
export class IconButtonComponent implements OnChanges {
  @Input() disabled = false;
  @Input() public icon: 'alberta-dot';
  @Input() public color: '#000';

  @HostBinding('class.disabled') disabledStyle = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.disabledStyle = this.disabled;
  }
}
