import { Injectable } from '@angular/core';
import { clamp, isNumber } from 'lodash';
import makeDebug from '../../../../makeDebug';
import { ChatDbService } from './data/chat-db.service';
import { ChatChannel } from './data/db-schema';

const debug = makeDebug('services:chat:chat-messages-data');

@Injectable({
  providedIn: 'root',
})
export class ChatMessageDataService {
  constructor(private readonly _chatDb: ChatDbService) {}
  public async getUnreadMessagesOfChannels(channels: ChatChannel[]) {
    const consumptions = await this._chatDb.getLocalLastConsumedMessageIndexBulk(channels.map(channel => channel.sid));

    return channels.map(channel => ({
      sid: channel.sid,
      unreadMessages: this.calculateConsumptionIndex(
        channel,
        consumptions.find(consumption => consumption.sid === channel.sid)?.localLastConsumedMessageIndex
      ),
    }));
  }

  private calculateConsumptionIndex(channel: ChatChannel, localLastConsumedMessageIndex: number) {
    if (channel.lastMessageIndex == null) {
      // there is no message in the channel
      return 0;
    } else if (channel.lastConsumedMessageIndex == null && localLastConsumedMessageIndex == null) {
      // there are messages, but none of them is consumed
      return channel.lastMessageIndex + 1;
    } else {
      return (
        channel.lastMessageIndex - Math.max(channel.lastConsumedMessageIndex ?? 0, localLastConsumedMessageIndex ?? 0)
      );
    }
  }

  public async updateChannelConsumptionStatus(channelSid: string, index: number) {
    const localChannelConsumption = await this._chatDb.getLocalConsumptionOfChannel(channelSid);
    if (!localChannelConsumption || localChannelConsumption.localLastConsumedMessageIndex < index) {
      debug('update channel consumption', channelSid, index);
      await this._chatDb.setConsumptionIndex(channelSid, index);
      await this._chatDb.setChannelLastLocalUpdate(channelSid);
    } else {
      debug('no consumption update needed', { channelSid, index, localChannelConsumption });
    }
  }
}
