import { IOrder, OrderStatus } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { map, take } from 'rxjs/operators';
import { IStateExtension } from 'src/app/common/contracts/state/state-extension';
import { IStateRegistry } from 'src/app/common/contracts/state/state-registry';
import { getDoctorIdFromUserId } from 'src/app/notifications/config/web-doctor-config';
import { OrderModelName } from 'src/app/shared/models/model-names';
import { OrderDto } from 'src/app/shared/models/order/order-dto.model';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class OrderStateExtension implements IStateExtension<IOrder> {
  private _stateRegistry: IStateRegistry;

  public get name(): string {
    return OrderModelName;
  }

  constructor(private _authenticationService: AuthService, private _platform: Platform) {}

  setRegistry(stateRegistry: IStateRegistry): void {
    this._stateRegistry = stateRegistry;
  }

  async canBeStored(order: IOrder): Promise<boolean> {
    await this._authenticationService.init;

    const doctorId = getDoctorIdFromUserId(this._authenticationService.getCurrentUserId());
    return (
      this._authenticationService.userIsRelevantDoctor &&
      order.status === OrderStatus.Transmitted &&
      order.doctorId &&
      order.doctorId === doctorId
    );
  }

  afterCreate(orders: IOrder[]): Promise<void> {
    return;
  }

  async afterUpdate(orders: OrderDto[]): Promise<void> {
    if (!orders || this._platform.is('hybrid')) {
      return;
    }

    for (const order of orders) {
      const canBeDispatched = await this.canBeStored(order);
      if (!canBeDispatched) {
        continue;
      }

      await this._stateRegistry.createBySync(OrderModelName, 'notification', order);

      if (order.isSeriesOrder && order.alreadyTransmitted) {
        const canBeUpdated = await this.canBeUpdated(order);
        if (canBeUpdated) {
          order.hasBeenSeen = false;
          await this._stateRegistry.updateBySync(OrderModelName, 'notification', [order]);
        }
      }
    }
  }

  private canBeUpdated(order: OrderDto): Promise<boolean> {
    let result = true;

    return new Promise<boolean>((resolve, reject) => {
      this._stateRegistry
        .select<OrderDto[], OrderDto>(OrderModelName, 'notification')
        .pipe(
          take(1),
          map(orders => orders.find(stateOrder => stateOrder._id === order._id))
        )
        .subscribe({
          next: stateOrder => (result = stateOrder != null && order.updatedAt !== stateOrder.updatedAt),
          complete: () => resolve(result),
          error: () => reject(result),
        });
    });
  }
}
