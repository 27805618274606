import { IStock } from '@alberta/konexi-shared';
import { Injectable, OnDestroy } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, from, timer } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { LoadingModalComponent } from 'src/app/shared/components/common/loading-modal/loading-modal-page';
import { ConnectionStateService } from 'src/app/shared/services/connection-state.service';
import { StockService } from 'src/app/shared/services/stock/stock.service';
import { TrackerService } from 'src/app/shared/services/tracker.service';

/**
 *  Holds and updates stock information.
 *  Use init() to create stock observable.
 */
@Injectable()
export class IntegratedCareStockService implements OnDestroy {
  private _ngUnsubscribe = new Subject();
  public stock$: BehaviorSubject<{ [key: string]: IStock }>;

  constructor(
    private _stockService: StockService,
    private _connectionState: ConnectionStateService,
    private _tracker: TrackerService,
    private _modalController: ModalController,
    public _translate: TranslateService,
    private _alertController: AlertController
  ) {}

  init() {
    this.stock$ = new BehaviorSubject({});
  }

  public stock(): Observable<{ [key: string]: IStock }> {
    return this.stock$;
  }

  private async updateStock(articleIds: string[]): Promise<IStock[]> {
    const stockResult = await this._stockService.getStock(articleIds);
    const newStock = {};
    stockResult.map(stock => {
      newStock[stock.customerNumber] = stock;
    });
    this.stock$.next(newStock);
    return stockResult;
  }

  public async checkStock(customerNumbers: string[], integratedCareId = null): Promise<void> {
    if (!this._connectionState.isConnected) {
      this._showOfflineModal(customerNumbers);
      return;
    }
    this._tracker.trackIntegratedCareStockCheck(integratedCareId, 'started');
    const loadingModal = await this._modalController.create({
      cssClass: 'loading-modal',
      component: LoadingModalComponent,
      showBackdrop: true,
      backdropDismiss: false,
      componentProps: {
        message: from(Promise.resolve(this._translate.instant('integratedCare.details.modalStockCheckMessage'))),
      },
    });
    loadingModal.present();
    if (customerNumbers.length > 0) {
      from(this.updateStock(customerNumbers))
        .pipe(delay(300))
        .subscribe(
          _ => {
            loadingModal.dismiss();
            this._tracker.trackIntegratedCareStockCheck(integratedCareId, 'success');
          },
          async _ => {
            loadingModal.dismiss();
            this._tracker.trackIntegratedCareStockCheck(integratedCareId, 'failed');
            this._showStockCheckFailedModal(customerNumbers);
          }
        );
    } else {
      // show dialog at least 300ms to avoid UI flicker
      timer(300)
        .pipe(takeUntil(this._ngUnsubscribe))
        .subscribe(_ => {
          this._tracker.trackIntegratedCareStockCheck(integratedCareId, 'success');
          loadingModal.dismiss();
        });
    }
  }

  private async _showOfflineModal(customerNumbers: string[]) {
    const alert = await this._alertController.create({
      header: this._translate.instant('integratedCare.details.modalStockOfflineHeader'),
      message: this._translate.instant('integratedCare.details.modalStockOfflineMessage'),
      buttons: [
        {
          text: this._translate.instant('integratedCare.details.modalStockButtonCheckAgain'),
          role: 'cancel',
          handler: () => {
            this.checkStock(customerNumbers);
          },
        },
        {
          text: this._translate.instant('integratedCare.details.modalStockButtonClose'),
        },
      ],
    });
    await alert.present();
  }

  private async _showStockCheckFailedModal(customerNumbers: string[]) {
    const alert = await this._alertController.create({
      header: this._translate.instant('integratedCare.details.modalStockErrorHeader'),
      message: this._translate.instant('integratedCare.details.modalStockErrorMessage'),
      buttons: [
        {
          text: this._translate.instant('integratedCare.details.modalStockButtonCheckAgain'),
          role: 'cancel',
          handler: () => {
            this.checkStock(customerNumbers);
          },
        },
        {
          text: this._translate.instant('integratedCare.details.modalStockButtonClose'),
        },
      ],
    });
    await alert.present();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next(null);
    this._ngUnsubscribe.complete();
  }
}
