import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ListitemAdditionalInfoComponent } from './additional-info/listitem-additional-info.component';
import { ListitemBottomSubheaderComponent } from './bottom-subheader/listitem-bottom-subheader.component';
import { ListitemHeaderComponent } from './header/listitem-header.component';
import { ListitemIconComponent } from './icon/listitem-icon.component';
import { ListitemComponent } from './listitem.component';
import { ListitemStatusComponent } from './status/listitem-status.component';
import { ListitemTopHeaderComponent } from './top-header/listitem-top-header.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ListitemComponent,
    ListitemAdditionalInfoComponent,
    ListitemBottomSubheaderComponent,
    ListitemHeaderComponent,
    ListitemIconComponent,
    ListitemStatusComponent,
    ListitemTopHeaderComponent,
  ],
  exports: [
    ListitemComponent,
    ListitemAdditionalInfoComponent,
    ListitemBottomSubheaderComponent,
    ListitemHeaderComponent,
    ListitemIconComponent,
    ListitemStatusComponent,
    ListitemTopHeaderComponent,
  ],
})
export class AlbertaListItemModule {}
