import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsPipesModule } from 'src/app/common/pipes/components/components-pipes.module';
import { GeneralPipesModule } from 'src/app/common/pipes/general-pipes.module';
import { AlbertaIconModule } from '../../alberta-icon/alberta-icon.module';
import { AutocompleteListItemComponent } from './autocomplete-list-item/autocomplete-list-item.component';
import { ErrorDisplayComponent } from './error-display/error-display.component';
import { InputButtonTemplateComponent } from './input-button-template/input-button-template.component';
import { MultiAutocompleteListItemComponent } from './multi-autocomplete-list-item/multi-autocomplete-list-item.component';
import { TextInputTemplateComponent } from './text-input-template/text-input-template.component';

@NgModule({
  declarations: [
    AutocompleteListItemComponent,
    InputButtonTemplateComponent,
    ErrorDisplayComponent,
    MultiAutocompleteListItemComponent,
    TextInputTemplateComponent,
  ],
  imports: [
    AlbertaIconModule,
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    GeneralPipesModule,
    ComponentsPipesModule,
  ],
  exports: [
    AutocompleteListItemComponent,
    InputButtonTemplateComponent,
    ErrorDisplayComponent,
    MultiAutocompleteListItemComponent,
    TextInputTemplateComponent,
  ],
})
export class InputTemplatesModule {}
