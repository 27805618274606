import { Component, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IntegratedCareStockService } from 'src/app/patient/components/patient-detail/sections/integratedcare/integratedcare-detail/integrated-care-stock/integrated-care-stock.service';

@Component({
  selector: 'itl-check-stock-button',
  templateUrl: './check-stock-button.component.html',
  styleUrls: ['./check-stock-button.component.scss'],
  providers: [IntegratedCareStockService],
})
export class CheckStockButtonComponent implements OnDestroy {
  @Input() articleCustomerNumber: string;
  public stock: number;

  private _ngUnsubscribe = new Subject();

  constructor(public _translate: TranslateService, private _integratedcareStockService: IntegratedCareStockService) {}

  ngOnDestroy(): void {
    this._ngUnsubscribe.next(null);
    this._ngUnsubscribe.complete();
  }

  public async checkStock(): Promise<void> {
    this._integratedcareStockService.init();
    this._integratedcareStockService.stock$
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(stock => (this.stock = stock[this.articleCustomerNumber]?.quantity));
    await this._integratedcareStockService.checkStock([this.articleCustomerNumber]);
  }
}
