import { IPatient } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { PatientChannel } from 'src/app/business/patient/contracts/patient-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { ISyncState } from '@common/contracts/sync/sync-state';
import { PatientService } from '@services/patient.service';
import { PatientModelName } from '../../models/model-names';
import { PatientDto } from '../../models/patient/patient-dto.model';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class PatientSyncService extends SyncService<IPatient> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IPatient>,
    eventService: EventService<SyncProgressEvent>,
    private _patientService: PatientService,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(PatientModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  // On websocketevents we update the myPatients Array
  protected async onCreated(data: any): Promise<void> {
    super.onCreated(data);
    await this._patientService.updateMyPatients();
  }

  protected async onPatched(data: any): Promise<void> {
    super.onPatched(data);
    await this._patientService.updateMyPatients();
  }

  protected async onRemoved(data: any): Promise<void> {
    super.onRemoved(data);
    await this._patientService.updateMyPatients();
  }

  public canSync(channel: string): boolean {
    return channel === PatientChannel.CREATE || channel === PatientChannel.EDIT || channel === PatientChannel.DELETE;
  }

  // This gets called after the sync of a batch of patients
  protected async afterSync(syncState: ISyncState<PatientDto>) {
    await this._patientService.updateMyPatients();
  }
}
