import { IIntegratedCare } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { IntegratedCareWorkItem } from 'src/app/business/integrated-care/integrated-care-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class IntegratedCareCommandHandler extends EntityCommandHandler<IIntegratedCare> {
  protected workItem: IWorkItem<IIntegratedCare>;

  constructor(integratedCareWorkItem: IntegratedCareWorkItem) {
    super();
    this.workItem = integratedCareWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'IntegratedCareDto';
  }
}
