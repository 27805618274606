import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
@Component({
  selector: 'itl-text-input-template',
  templateUrl: './text-input-template.component.html',
  styleUrls: ['./text-input-template.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextInputTemplateComponent {
  @Input() public control: AbstractControl;
  @Input() public icon: string;
  @Input() public title: string;
  @Input() public value: string;
  @Input() public maxlength: number;
  @Input() public description: string;
  @Input() public noIcon: boolean;
  @Input() public required: boolean;
  @Input() public disabled: boolean;
  @Output() public focusClicked: EventEmitter<null> = new EventEmitter<null>();

  public setFocus() {
    this.focusClicked.emit();
  }
}
