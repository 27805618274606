import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { ArticleDto } from '../../../../../models/article/article-dto.model';
import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'article-list-item',
  templateUrl: 'article-list-item.html',
  styleUrls: ['./../autocomplete-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleListItem implements IListView {
  @Input() item: ArticleDto;
  @Output() clicked: EventEmitter<ISearchable> = new EventEmitter<ISearchable>();

  triggerEvent() {
    this.clicked.emit(this.item);
  }
}
