import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { LogLevel } from 'src/app/common/contracts/logging/log-level';
import { IGeneralEntityService } from 'src/app/shared/services/contracts/general-entity.service';
import { IProviderConfig, ProviderConfigToken } from 'src/app/shared/services/provider-config';
import { IListView } from '../../contracts/list-view';
import { SelectAllItemComponent } from '../select-all-item/select-all-item.component';

@Component({
  selector: 'multi-autocomplete-desktop-search-view',
  templateUrl: 'multi-autocomplete-desktop-search-view.component.html',
  styleUrls: ['multi-autocomplete-desktop-search-view.component.scss'],
})
export class MulitAutocompleteDesktopSearchView implements OnInit, OnDestroy {
  @Input() public titel: string;
  @Input() public itemsProvider: IGeneralEntityService;
  @Input() public selectedItems: any[] = [];
  @Input() public toSearchTerm: (query: string) => string;
  @Input() public filterItems: (results: any[]) => any[];
  @Input() public type:
    | 'doctor'
    | 'hospital'
    | 'insuranceContract'
    | 'nursingHome'
    | 'nursingService'
    | 'payer'
    | 'postalCode'
    | 'group'
    | 'region'
    | 'productGroup'
    | 'pharmacy';
  @Input() public control: AbstractControl;
  @Input() public desktopResultsAreaHeight: number;
  @Input() public showChips: boolean;
  @Input() public showAllOnStart: boolean;
  @Input() public icon: string;
  @Input() public required: boolean;
  @Input() public placeholder: string;
  @Input() public disabled = false;
  @Output() public selectedItemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild('autocompleteWrapper', { static: true }) public autocompleteWrapper: ElementRef;
  @ViewChild('resultsArea', { static: true }) public resultsArea: ElementRef;
  @ViewChild('pickContainer', { read: ViewContainerRef, static: true })
  private _pickContainer;
  @ViewChild('searchbar', { static: true }) private _searchBar: ElementRef;

  public searchViewOpen = false;
  public directionBottom = true;
  public busy = false;
  public hasNotSearchYet = true;
  public hasAnyItems = false;
  public fadingOut = false;
  public searchValue = '';
  private _searchChange$$ = new Subject<string>();
  private _listItemFactory: ComponentFactory<IListView>;
  private _selectAllFactory: ComponentFactory<SelectAllItemComponent>;
  private _listItemsSubscriptions: Subscription[] = [];
  private _selectAllSubscription: Subscription = new Subscription();
  private _ngUnsubscribe = new Subject();

  constructor(
    @Inject(ProviderConfigToken) private _providerConfig: IProviderConfig,
    private _resolver: ComponentFactoryResolver
  ) {
    this._searchChange$$
      .pipe(
        tap(() => (this.busy = true)),
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this._ngUnsubscribe)
      )
      .subscribe(async value => {
        if (value == null) {
          return;
        }
        this._pickContainer.clear();
        this.hasNotSearchYet = false;
        let items;
        value.length === 0
          ? (items = this.showAllOnStart ? this.filterItems(await this.itemsProvider.getAll()) : [])
          : (items = this.filterItems(await this.itemsProvider.query(this.toSearchTerm(`${value}`))));
        this.hasAnyItems = value.length === 0 && !this.showAllOnStart ? true : !!items.length;
        this.createListViewItemComponents(items);
        this.busy = false;
      });
  }

  ngOnInit(): void {
    this._selectAllFactory = this._resolver.resolveComponentFactory(SelectAllItemComponent);
    if (!this._providerConfig[this.type].multiSearchListitem) {
      window.logger.log(`no multiplesearchlistItem found for type: ${this.type} please add one`, LogLevel.warning);
    } else {
      this._listItemFactory = this._resolver.resolveComponentFactory(
        this._providerConfig[this.type].multiSearchListitem
      );
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next(null);
    this._ngUnsubscribe.complete();
  }

  private createListViewItemComponents(results: any[]) {
    if (this._selectAllSubscription && !this._selectAllSubscription.closed) {
      this._selectAllSubscription.unsubscribe();
    }
    this._listItemsSubscriptions.forEach(item => item && !item.closed && item.unsubscribe());
    this._listItemsSubscriptions = [];
    if (results.length) {
      const selectAll = this._pickContainer.createComponent(this._selectAllFactory);
      selectAll.instance.selected = this.selectedItems;
      selectAll.instance.items = results;
      this._selectAllSubscription = selectAll.instance.changed.subscribe(() => {
        if (this.checkAllSelected(this.selectedItems, results)) {
          for (const item of results) {
            this.selectedItems.splice(
              this.selectedItems.findIndex(findItem => findItem._id === item._id),
              1
            );
          }
        } else {
          for (const item of results) {
            const res = this.selectedItems.find(value => item._id === value._id);
            if (res === undefined) {
              this.selectedItems.push(item);
            }
          }
          this.selectedItemsChange.emit(this.selectedItems);
        }
      });
    }
    for (const item of results) {
      const listViewItem = this._pickContainer.createComponent(this._listItemFactory);
      if (!listViewItem) {
        continue;
      }
      listViewItem.instance.item = item;
      listViewItem.instance.selected = this.selectedItems;
      this._listItemsSubscriptions.push(
        listViewItem.instance.checkedChange.subscribe(value => {
          if (this.selectedItems.find(findItem => findItem._id === value._id)) {
            this.selectedItems.splice(
              this.selectedItems.findIndex(findItem => findItem._id === value._id),
              1
            );
          } else {
            this.selectedItems.push(value);
          }
          this.selectedItemsChange.emit(this.selectedItems);
        })
      );
    }
  }

  public removeChip(item) {
    if (this.selectedItems.find(value => value._id === item._id)) {
      this.selectedItems.splice(
        this.selectedItems.findIndex(findItem => findItem._id === item._id),
        1
      );
      this.selectedItemsChange.emit(this.selectedItems);
    }
  }

  focus() {
    if (this.disabled) {
      return;
    }
    if (this.control) {
      this.control.markAsTouched();
    }
    if (!this.searchViewOpen) {
      this.searchValue = '';
      this._searchChange$$.next('');
    }
    this.searchViewOpen = true;
    const ancestorContainer = this.findAncestorContainer(this.autocompleteWrapper.nativeElement);
    const viewStart = ancestorContainer.scrollTop;
    const viewEnd = ancestorContainer.scrollTop + ancestorContainer.clientHeight;
    const viewmiddel = viewStart + (viewEnd - viewStart) / 2;

    if (this.autocompleteWrapper.nativeElement.offsetTop < viewmiddel) {
      this.directionBottom = true;
      if (this.desktopResultsAreaHeight) {
        this.resultsArea.nativeElement.style.height = `${this.desktopResultsAreaHeight}px`;
      } else {
        this.resultsArea.nativeElement.style.height = `${
          viewEnd - this.autocompleteWrapper.nativeElement.offsetTop - 80
        }px`;
      }
    } else {
      this.directionBottom = false;
      if (this.desktopResultsAreaHeight) {
        this.resultsArea.nativeElement.style.height = `${this.desktopResultsAreaHeight}px`;
      } else {
        this.resultsArea.nativeElement.style.height = `${
          this.autocompleteWrapper.nativeElement.offsetTop - viewStart - 80
        }px`;
      }
    }
    this.searchViewOpen = true;
  }

  private findAncestorContainer(autocompleteWrapperElement: HTMLElement): HTMLElement {
    let currentAncestor = autocompleteWrapperElement.parentElement;
    while (currentAncestor.localName !== 'itl-multiautocomplete') {
      currentAncestor = currentAncestor.parentElement;
    }
    return currentAncestor.parentElement;
  }

  checkAllSelected(selected, results) {
    for (const element of results) {
      const res = selected.find(item => item._id === element._id);
      if (res === undefined) {
        return false;
      }
    }
    return true;
  }

  closeSearchArea() {
    if (this._listItemsSubscriptions) {
      this._listItemsSubscriptions.forEach(item => item && !item.closed && item.unsubscribe());
      this._listItemsSubscriptions = [];
    }
    if (this._selectAllSubscription && !this._selectAllSubscription.closed) {
      this._selectAllSubscription.unsubscribe();
    }
    this._pickContainer.clear();
    this.searchValue = '';
    this.resultsArea.nativeElement.style.height = `0px`;
    this.fadingOut = true;
    setTimeout(() => {
      this.fadingOut = false;
      this.searchViewOpen = false;
    }, 500);
  }

  search() {
    this.searchValue = this._searchBar.nativeElement.value;
    this._searchChange$$.next(this._searchBar.nativeElement.value);
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }
}
