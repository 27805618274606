import { Inject, Injectable } from '@angular/core';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

import { MessagePayloadPatientAppAttachment } from '../../models/share/message-payload-patient-app-attachment';
import { IRouteParser } from '../contracts/route/route-parser';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { FeathersService } from '../feathers.service';

@Injectable()
export class PatientAppAttachmentRouteParser implements IRouteParser {
  type = MessagePayloadType.PatientAppAttachment;

  constructor(@Inject(FeathersService) private _feathersAppProvider: IFeathersAppProvider) {}
  async parse(payload: MessagePayloadPatientAppAttachment): Promise<string> {
    const attachmentService = this._feathersAppProvider.app.service('attachment');
    const res = await attachmentService.find({
      query: {
        'metadata.patientId': payload.patientId,
        'metadata.type': 200, // PatientAppUpload
        'metadata.archived': { $ne: true },
        $ignoreRegionIds: true,
      },
    });
    let patientAppAttachment;
    if (res?.data) {
      patientAppAttachment = res.data.find(attachment => attachment._id === payload.attachmentId);
    }
    if (!patientAppAttachment) {
      throw new Error(`No attachment found for id ${payload.attachmentId}`);
    }
    // tslint:disable-next-line:max-line-length
    return `/patient/${patientAppAttachment.metadata.patientId}/attachments/patientapp/detail/${patientAppAttachment._id}`;
  }
}
