import { Injectable } from '@angular/core';
import { BaseComponentModel } from 'src/app/common/component-model/base-component-model';
import { ComponentModelDependencies } from 'src/app/common/component-model/component-model-dependencies';
import { ComponentModelInfo } from 'src/app/common/component-model/component-model-info';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';
import { AttachmentModelName } from 'src/app/shared/models/model-names';

@Injectable({ providedIn: 'root' })
export class AttachmentComponentModel extends BaseComponentModel<AttachmentDto> {
  constructor(componentModelDependencies: ComponentModelDependencies, componentModelInfo: ComponentModelInfo) {
    super(AttachmentModelName, componentModelDependencies, componentModelInfo);
  }
}
