import {
  ChangeOperation,
  HistoryItemType,
  IDeliveryAddress,
  IExtendedArticleLine,
  IIntegratedCare,
} from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { IFlatChange } from 'json-diff-ts/lib/jsonDiff';
import { IntegratedCareHistoryLineViewModel } from 'src/app/business/integrated-care/integrated-care-history-view-model';

import { IntegratedCareDto } from '../../models/integrated-care/integrated-care-dto.model';

@Injectable({ providedIn: 'root' })
export class IntegratedCareHistoryService {
  public async writeHistoryEntries(
    integratedCare: IntegratedCareDto,
    flatDiffs: IFlatChange[],
    user: { userName: string; userId: string },
    currentIntegratedCare: IntegratedCareDto
  ): Promise<IntegratedCareDto> {
    // remove seriesData changes if seriesData is toggled on and off without creating a new seriesData
    flatDiffs = this.removeUnnecessarySeriesDataChanges(flatDiffs, currentIntegratedCare, integratedCare);

    // create history entry for added or removed seriesData
    this.createSeriesDataLine(flatDiffs, integratedCare, user);

    // check each defined history item type
    const typeArray = Object.values(HistoryItemType);
    for (let i = 0; i < typeArray.length; i++) {
      await this.createHistoryItemTypeLines(integratedCare, flatDiffs, user, typeArray[i], currentIntegratedCare);
    }
    return integratedCare;
  }

  public createInitialArticleHistoryLine(
    integratedCare: IntegratedCareDto,
    articleLine: IExtendedArticleLine,
    user: { userName: string; userId: string }
  ): void {
    const addArticleHistoryLine = new IntegratedCareHistoryLineViewModel(HistoryItemType.ProposedArticleLines);
    this.setArticleLineData(addArticleHistoryLine, articleLine, user);
    addArticleHistoryLine.changeOperation = ChangeOperation.ADD;
    addArticleHistoryLine.key = articleLine._id;
    integratedCare.history.push(addArticleHistoryLine);
  }

  private setArticleLineData(
    historyLine: IntegratedCareHistoryLineViewModel,
    articleLine: IExtendedArticleLine,
    user: { userName: string; userId: string }
  ): IntegratedCareHistoryLineViewModel {
    historyLine.valueAfter = {
      _id: articleLine._id,
      dosage: articleLine.dosage,
      articleId: articleLine.articleId,
    } as any;
    if (articleLine.seriesDataId) {
      (historyLine.valueAfter as any).seriesDataId = articleLine.seriesDataId;
    }
    historyLine.userName = user.userName;
    historyLine.userId = user.userId;
    historyLine.articleId = articleLine.articleId;
    return historyLine;
  }

  private async createHistoryItemTypeLines(
    integratedCare: IntegratedCareDto,
    flatDiffs: IFlatChange[],
    user: { userName: string; userId: string },
    itemType: HistoryItemType,
    currentIntegratedCare: IntegratedCareDto
  ): Promise<IntegratedCareDto> {
    let itemDiffs = [];
    switch (itemType) {
      case HistoryItemType.ProposedArticleLines:
        this.createProposedArticleLinesHistory(integratedCare, flatDiffs, user, currentIntegratedCare);
        break;
      case HistoryItemType.DayOfDelivery:
        itemDiffs = flatDiffs.filter(flatDiff => /\$\.seriesData\[\d{1,}\]\.dayOfDelivery$/.test(flatDiff.path));
        break;
      case HistoryItemType.NextDeliveryDate:
        itemDiffs = flatDiffs.filter(flatDiff => /\$\.seriesData\[\d{1,}\]\.nextDeliveryDate$/.test(flatDiff.path));
        break;
      case HistoryItemType.DeliveryInformation:
        itemDiffs = flatDiffs.filter(flatDiff => /\$\.seriesData\[\d{1,}\]\.deliveryInformation$/.test(flatDiff.path));
        break;
      case HistoryItemType.DeliveryStartTime:
        itemDiffs = flatDiffs.filter(flatDiff => /\$\.seriesData\[\d{1,}\]\.deliveryStartTime$/.test(flatDiff.path));
        break;
      case HistoryItemType.DeliveryNote:
        itemDiffs = flatDiffs.filter(flatDiff => /\$\.seriesData\[\d{1,}\]\.deliveryNote$/.test(flatDiff.path));
        break;
      case HistoryItemType.DeliveryAddress:
        itemDiffs = this.createDeliveryAddressChange(flatDiffs);
        break;
      default:
        // properties of tier 1
        itemDiffs = flatDiffs.filter(flatDiff => flatDiff.path === `$.${itemType}`);
        break;
    }
    if (itemDiffs) {
      // change 'remove' and 'add' of same property to 'update' for fields with valueType changes e.g. dates
      if (itemDiffs.length === 2) {
        itemDiffs = this.createUpdateChange(itemDiffs);
      }
      itemDiffs.forEach(itemDiff => {
        // change type to add or remove if null values
        itemDiff = this.changeTypeForNullValues(itemDiff);
        // create history entry
        this.createHistoryEntry(itemDiff, integratedCare, itemType, user);
      });
    }
    return integratedCare;
  }

  private createHistoryEntry(
    itemDiff: IFlatChange,
    integratedCare: IIntegratedCare,
    itemType: HistoryItemType,
    user: { userName: string; userId: string },
    articleId?: string
  ) {
    const history = new IntegratedCareHistoryLineViewModel(itemType);
    history.valueBefore = itemDiff.oldValue;
    history.valueAfter = itemDiff.value;
    history.changeOperation = itemDiff.type as any;
    history.userName = user.userName;
    history.userId = user.userId;
    if (articleId) {
      history.articleId = articleId;
    }
    history.key = itemDiff.key;
    if (!integratedCare.history) {
      integratedCare.history = [];
    }
    integratedCare.history.push(history);
  }

  private createUpdateChange(itemDiffs: IFlatChange[]): IFlatChange[] {
    const removed = itemDiffs.find(itemDiff => itemDiff.type === 'REMOVE');
    const added = itemDiffs.find(itemDiff => itemDiff.type === 'ADD');
    if (removed && added) {
      const newDiff = {
        type: ChangeOperation.UPDATE as any,
        key: removed.key,
        path: removed.path,
        valueType: removed.valueType,
        value: added.value,
        oldValue: removed.value,
      };
      return [newDiff];
    } else {
      return itemDiffs;
    }
  }

  private changeTypeForNullValues(itemDiff: IFlatChange): IFlatChange {
    if (itemDiff.value == null) {
      itemDiff.type = ChangeOperation.REMOVE as any;
    }
    if (itemDiff.oldValue == null) {
      itemDiff.type = ChangeOperation.ADD as any;
    }
    return itemDiff;
  }

  private createSeriesDataLine(
    flatDiffs: IFlatChange[],
    integratedCare: IIntegratedCare,
    user: { userName: string; userId: string }
  ) {
    const seriesDataArchived = flatDiffs.find(flatDiff => /\$\.seriesData\[\d{1,}\]\.archived$/.test(flatDiff.path));
    if (seriesDataArchived) {
      seriesDataArchived.type = ChangeOperation.REMOVE as any;
      // find seriesData
      const index = parseInt(seriesDataArchived.path.match(/\d+/).toString(), 10);
      if (index) {
        seriesDataArchived.value = {
          dayOfDelivery: integratedCare.seriesData[index].dayOfDelivery,
          deliveryAddress: integratedCare.seriesData[index].deliveryAddress,
          nextDeliveryDate: integratedCare.seriesData[index].nextDeliveryDate,
        };
      }
      this.createHistoryEntry(seriesDataArchived, integratedCare, HistoryItemType.SeriesData, user);
    }
    const seriesDatasAdded = flatDiffs.filter(flatDiff => /\$\.seriesData\[\d{1,}\]$/.test(flatDiff.path));
    if (seriesDatasAdded?.length) {
      const activeSeriesData = seriesDatasAdded.find(seriesData => !seriesData.value?.archived);
      if (activeSeriesData) {
        this.createHistoryEntry(activeSeriesData, integratedCare, HistoryItemType.SeriesData, user);
      }
    }
  }

  private removeUnnecessarySeriesDataChanges(
    flatDiffs: IFlatChange[],
    oldIntegratedCare: IntegratedCareDto,
    newIntegratedCare: IntegratedCareDto
  ): IFlatChange[] {
    // only archived seriesData was added -> remove all seriesData changes
    if (!this.hasActiveAbos(oldIntegratedCare) && !this.hasActiveAbos(newIntegratedCare)) {
      flatDiffs = flatDiffs.filter(flatDiff => !flatDiff.path.startsWith('$.seriesData'));
    }
    return flatDiffs;
  }

  private hasActiveAbos(integratedCare: IntegratedCareDto) {
    if (!integratedCare) {
      return false;
    }
    return !!integratedCare.seriesData?.length && integratedCare.seriesData.some(seriesData => !seriesData.archived);
  }

  private createDeliveryAddressChange(flatDiffs: IFlatChange[]) {
    const addressChanges = flatDiffs.filter(flatDiff =>
      /\$\.seriesData\[\d{1,}\]\.deliveryAddress/.test(flatDiff.path)
    );
    const valueBefore = {} as IDeliveryAddress;
    const valueAfter = {} as IDeliveryAddress;

    if (addressChanges?.length) {
      const relevantChanges = addressChanges.filter(
        change =>
          change.key === 'name' ||
          change.key === 'address' ||
          change.key === 'postalCode' ||
          change.key === 'city' ||
          change.key === 'additionalAddress' ||
          change.key === 'additionalAddress2'
      );
      if (relevantChanges?.length) {
        relevantChanges.forEach(relevantChange => {
          valueBefore[relevantChange.key] = relevantChange.oldValue;
          valueAfter[relevantChange.key] = relevantChange.value;
        });
        return [
          {
            type: ChangeOperation.UPDATE as any,
            path: '$.seriesData.deliveryAddress',
            value: valueAfter,
            oldValue: valueBefore,
          },
        ];
      }
    }
  }

  private createProposedArticleLinesHistory(
    integratedCare: IntegratedCareDto,
    flatDiffs: IFlatChange[],
    user: { userName: string; userId: string },
    currentIntegratedCare: IntegratedCareDto
  ) {
    const articleLineChanges = flatDiffs.filter(flatDiff => flatDiff.path.startsWith('$.proposedArticleLines'));
    if (articleLineChanges) {
      // find added articles
      this.createAddArticleChanges(integratedCare, currentIntegratedCare, articleLineChanges, user);
      // get relevant changes for already existing article lines
      currentIntegratedCare.proposedArticleLines.forEach(articleLine => {
        const correspondingChanges = this.getCorrespondingArticleLineChange(
          articleLine._id,
          articleLineChanges,
          integratedCare,
          currentIntegratedCare
        );
        if (correspondingChanges) {
          correspondingChanges.forEach(articleLineChange => {
            // corresponding changes can only be update and remove
            (articleLineChange.type as any) === ChangeOperation.ADD
              ? (articleLineChange.type = ChangeOperation.UPDATE as any)
              : (articleLineChange.type = articleLineChange.type);

            if (articleLineChange.type === ChangeOperation.UPDATE && articleLine.seriesDataId) {
              if (typeof articleLineChange.value === 'string' || typeof articleLineChange.value === 'boolean') {
                articleLineChange.value = { value: articleLineChange.value, seriesDataId: articleLine.seriesDataId };
              } else {
                articleLineChange.value = { ...articleLineChange.value, seriesDataId: articleLine.seriesDataId };
              }
            }

            this.createHistoryEntry(
              articleLineChange,
              integratedCare,
              HistoryItemType.ProposedArticleLines,
              user,
              articleLine.articleId
            );
          });
        }
      });
    }
  }

  private getCorrespondingArticleLineChange(
    articleLineId: string,
    flatDiffs: IFlatChange[],
    integratedCare: IntegratedCareDto,
    currentIntegratedCare: IntegratedCareDto
  ) {
    const articleLineChanges = flatDiffs.filter(flatDiff =>
      // tslint:disable-next-line:quotemark
      flatDiff.path.startsWith(`$.proposedArticleLines[?(@._id='${articleLineId}')]`)
    );
    if (articleLineChanges) {
      const relevantChanges = [];
      const dosageChange = this.getDosageChange(
        articleLineChanges,
        integratedCare,
        currentIntegratedCare,
        articleLineId
      );
      if (dosageChange) {
        relevantChanges.push(dosageChange);
      }
      const seriesDataChanges = this.getArticleLineSeriesDataChange(articleLineChanges);
      if (seriesDataChanges) {
        seriesDataChanges.forEach(seriesDataChange => {
          relevantChanges.push(seriesDataChange);
        });
      }
      const additionalInformationChanges = this.getArticleAdditionalInformationChanges(
        articleLineChanges,
        currentIntegratedCare
      );
      if (additionalInformationChanges) {
        additionalInformationChanges.forEach(additionalInformationChange => {
          relevantChanges.push(additionalInformationChange);
        });
      }

      const removeArticleChange = this.getRemoveArticleChange(articleLineChanges, articleLineId);
      if (removeArticleChange) {
        relevantChanges.push(removeArticleChange);
      }
      return relevantChanges;
    }
  }

  private getDosageChange(
    articleLineChanges: IFlatChange[],
    integratedCare: IntegratedCareDto,
    currentIntegratedCare: IntegratedCareDto,
    articleLineId: string
  ): IFlatChange {
    const dosageChanges = articleLineChanges.filter(
      change =>
        change.key === 'packagingId' ||
        change.key === 'quantity' ||
        change.key === 'amount' ||
        change.key === 'timePeriod' ||
        change.key === 'unit'
    );

    if (dosageChanges?.length) {
      const oldArticleLine = currentIntegratedCare.proposedArticleLines.find(line => line._id === articleLineId);
      const newArticleLine = integratedCare.proposedArticleLines.find(line => line._id === articleLineId);
      const dosageChange = {
        type: ChangeOperation.UPDATE as any,
        key: 'dosage',
        value: newArticleLine?.dosage,
        oldValue: oldArticleLine?.dosage,
      };
      return dosageChange as IFlatChange;
    }
  }

  private getArticleLineSeriesDataChange(articleLineChanges: IFlatChange[]): IFlatChange[] {
    const seriesDataChanges = articleLineChanges.filter(
      change => change.key === 'seriesDataId' && change.value != null
    );
    if (seriesDataChanges) {
      return seriesDataChanges;
    }
  }

  private getArticleAdditionalInformationChanges(
    articleLineChanges: IFlatChange[],
    currentIntegratedCare: IIntegratedCare
  ): IFlatChange[] {
    const additionalInformationChanges = articleLineChanges.filter(change => {
      if (change.key === 'isPrivateSale' || change.key === 'notAutomaticDelivery') {
        if (change.value === false) {
          const correspondingArticleLine = currentIntegratedCare.proposedArticleLines?.find(articleLine =>
            change.path.includes(articleLine._id)
          );
          if (correspondingArticleLine) {
            if (correspondingArticleLine[change.key] === undefined) {
              return false;
            }
          }
        }
        return change;
      }
    });
    if (additionalInformationChanges) {
      return additionalInformationChanges;
    }
  }

  private getRemoveArticleChange(articleLineChanges: IFlatChange[], articleLineId: string): IFlatChange {
    const removeArticleChange = articleLineChanges.find(
      change => change.key === articleLineId && (change.type as any) === ChangeOperation.REMOVE
    );
    if (removeArticleChange) {
      return removeArticleChange;
    }
  }

  private createAddArticleChanges(
    newIntegratedCare: IntegratedCareDto,
    oldIntegratedCare: IntegratedCareDto,
    articleLineChanges: IFlatChange[],
    user: { userName: string; userId: string }
  ) {
    const newArticleLines = newIntegratedCare.proposedArticleLines.filter(
      articleLine => !oldIntegratedCare.proposedArticleLines.find(line => line._id === articleLine._id)
    );
    if (newArticleLines.length) {
      newArticleLines.forEach(articleLine => {
        const addArticleChange = articleLineChanges.find(change => change.key === articleLine._id);
        if (addArticleChange) {
          this.createHistoryEntry(
            addArticleChange,
            newIntegratedCare,
            HistoryItemType.ProposedArticleLines,
            user,
            articleLine.articleId
          );
        }
      });
    }
  }
}
