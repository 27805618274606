import { Inject, Injectable, NgZone } from '@angular/core';
import { sortBy } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 } from 'uuid';

import makeDebug from '../../makeDebug';
import { ICancellationToken } from '../common/contracts/cancellation/cancellation-token';
import { IPlatformSync, PlatformSyncToken } from '../common/contracts/sync/platform-sync';
import { Deferred } from '../common/deferred/deferred';
import { ObservableModel } from '../common/viewmodel/observable-model';
import { IGenericStorage } from '../shared/services/contracts/database/generic-storage';
import { DatabaseService } from '../shared/services/database.service';
import { CommandSender } from './command-sender';
import { ICommand } from './contracts/command';
import { ICommandQueue } from './contracts/command-queue';
import { ICommandQueueCount } from './contracts/command-queue-count';

const debug = makeDebug('command:command-queue');

@Injectable({ providedIn: 'root' })
export class CommandQueue implements ICommandQueue, ICommandQueueCount {
  private _deletingCommands = false;
  private _isRunning = false;
  private commandQueue: IGenericStorage;
  private _ready = new Deferred<void>();
  public queue$: Observable<boolean>;
  private _queueModel: ObservableModel<boolean>;
  private _count$ = new BehaviorSubject<number>(0);
  private _daysSinceSynchronisation$ = new BehaviorSubject<number>(0);

  get ready(): Promise<void> {
    return this._ready.promise;
  }

  public get count(): Observable<number> {
    return this._count$.asObservable();
  }

  public get daysSinceSynchronisation(): Observable<number> {
    return this._daysSinceSynchronisation$.asObservable();
  }

  constructor(
    private _commandSender: CommandSender,
    private _databaseService: DatabaseService,
    private _ngZone: NgZone,
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync
  ) {
    // tslint:disable-next-line: no-floating-promises
    this.init();
  }

  public async save(command: ICommand): Promise<void> {
    await this._ready.promise;
    await this.commandQueue.ready();

    command._id = v4();
    command.timestamp = new Date();

    await this.commandQueue.set(command._id, command);
    this._count$.next(this._count$.value + 1);

    if (this._count$.value === 1) {
      this._daysSinceSynchronisation$.next(this.calculateDays(command?.timestamp));
    }

    if (!this._platformSync.canBeSynced) {
      await this.syncInternal({
        cancelled: new ObservableModel(false, false),
        promise: undefined,
        cancel: () => undefined,
        reset: () => undefined,
      });
    }
  }

  public async sync(cancellationToken: ICancellationToken): Promise<void> {
    if (this._isRunning) {
      return;
    }

    this._isRunning = true;

    await this.ready;

    await this.syncInternal(cancellationToken);

    if (this._platformSync.canBeSynced) {
      cancellationToken.promise = this.createTimeout(cancellationToken);
    } else {
      cancellationToken.promise = Promise.resolve();
    }
  }

  private async init() {
    this.commandQueue = await this._databaseService.getDatabase('commandQueue.db');

    const queueCount = await this.commandQueue.length();
    this._count$.next(queueCount);
    if (queueCount > 0) {
      const firstCommand = await this.getFirstCommand();
      this._daysSinceSynchronisation$.next(this.calculateDays(firstCommand?.timestamp));
    }
    this._queueModel = new ObservableModel<boolean>(queueCount !== 0, false);
    if (!this._queueModel.get()) {
      this._queueModel.set(true);
    }
    this.queue$ = this._queueModel.data$;

    this._ready.resolve();
  }

  private async syncInternal(cancellationToken: ICancellationToken): Promise<any> {
    await this.commandQueue.ready();

    let commands = [];
    await this.commandQueue.forEach(command => {
      commands.push(command);
    });
    commands = sortBy(commands, ['timestamp']);

    debug(`finished loading of commands from db`, commands.length);

    while (commands.length && !this._deletingCommands) {
      const command = commands.shift();
      if (cancellationToken.cancelled.get()) {
        debug('break sending commands of queue - cancelled by token');
        break;
      }

      const { successful } = await this._commandSender.send(command);
      if (!successful) {
        debug('break sending commands of queue - cancelled by error');
        break;
      }
      await this.commandQueue.remove(command._id);
      debug(`${this._count$.value - 1} open commands`);
      const count = this._count$.value - 1;
      this._count$.next(count >= 0 ? count : 0);
      this._daysSinceSynchronisation$.next(this.calculateDays(commands[0]?.timestamp));
    }

    this._queueModel.set((await this.commandQueue.length()) !== 0);
  }

  public async getAllCommands() {
    await this.commandQueue.ready();

    const keys = await this.getKeys();

    const commands = [];

    for (const key of keys) {
      try {
        const command = await this.commandQueue.get(key);
        commands.push(command);
      } catch (error) {
        window.logger.error(`failed to load command from queue`, error);
      }
    }

    return sortBy(commands, ['timestamp']);
  }

  public async deleteFirstCommand() {
    this._deletingCommands = true;

    await this.commandQueue.ready();

    const keys = await this.getKeys();

    let commands = [];

    for (let index = 0; index <= keys.length - 1; index++) {
      try {
        const command = await this.commandQueue.get(keys[index]);
        commands.push(command);
      } catch (error) {
        window.logger.error('failed to read command from queue.', error);
        await this.commandQueue.remove(keys[index]);
        return;
      }
    }

    commands = sortBy(commands, ['timestamp']);

    if (!commands.length) {
      this._deletingCommands = false;
      return;
    }

    await this.commandQueue.remove(commands[0]._id);
    this._count$.next(this._count$.value - 1);
    this._daysSinceSynchronisation$.next(this.calculateDays(commands[0]?.timestamp));

    this._queueModel.set((await this.commandQueue.length()) !== 0);

    this._deletingCommands = false;

    if (window && window.logger) {
      window.logger.error(`First command removed: ${JSON.stringify(commands[0])}`, null);
    }
  }

  public async getFirstCommand(): Promise<ICommand> {
    await this.commandQueue.ready();

    const keys = await this.getKeys();

    let commands = [];

    for (let index = 0; index <= keys.length - 1; index++) {
      try {
        const command = await this.commandQueue.get(keys[index]);
        commands.push(command);
      } catch (error) {
        window.logger.error('failed to read command from queue.', error);
        await this.commandQueue.remove(keys[index]);
        return;
      }
    }
    if (!commands.length) {
      return;
    }
    commands = sortBy(commands, ['timestamp']);
    return commands[0];
  }

  public async deleteAllCommands() {
    this._deletingCommands = true;

    await this.commandQueue.ready();

    const keys = await this.getKeys();

    for (const key of keys) {
      await this.commandQueue.remove(key);
      this._count$.next(this._count$.value - 1);

      const firstCommand = await this.getFirstCommand();
      this._daysSinceSynchronisation$.next(this.calculateDays(firstCommand?.timestamp));
    }
    this._queueModel.set(false);

    this._deletingCommands = false;
  }

  private async createTimeout(cancellationToken: ICancellationToken): Promise<void> {
    debug('starting queue timeout');
    while (true) {
      await this.sleep(2000);

      await this.syncInternal(cancellationToken);

      if (cancellationToken.cancelled.get()) {
        debug('breaking queue timeout');
        this._isRunning = false;
        break;
      }
    }
  }

  private sleep(ms?: number) {
    return new Promise<void>(resolve =>
      this._ngZone.runOutsideAngular(() => {
        const timeout = global.setTimeout(() => {
          global.clearTimeout(timeout);
          resolve();
        }, ms || 0);
      })
    );
  }

  private async getKeys() {
    return (this.commandQueue as any).getAllKeys
      ? await new Promise<string[]>((resolve, reject) =>
          (this.commandQueue as any).getAllKeys((error, keys: string[]) => (error ? reject(error) : resolve(keys)))
        )
      : await this.commandQueue.keys();
  }

  private calculateDays(timestamp: Date | string) {
    try {
      if (timestamp) {
        if (typeof timestamp === 'string') {
          timestamp = new Date(timestamp);
        }

        const dayOfCommand = moment(timestamp.setHours(0, 0, 0, 0));
        const today = moment(new Date().setHours(0, 0, 0, 0));
        const diff = today.diff(dayOfCommand, 'days');
        return diff;
      }
    } catch (error) {
      window.logger.error('[CommandQueue:calculateDays] failed to calculate days', error);
    }

    return 0;
  }
}
