import { Inject, Injectable, NgZone } from '@angular/core';
import { PatientAppUserChannel } from 'src/app/business/patient-app-user/contracts/patient-app-user-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { ISyncState } from '@common/contracts/sync/sync-state';
import { PatientAppUserSynchronizer } from '@services/patient-app/patient-app-user-synchronizer';
import { PatientAppUserModelName } from '../../models/model-names';
import { IPatientAppUser } from '../../models/patient-app/patient-app-user';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class PatientAppUserSyncService extends SyncService<IPatientAppUser> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IPatientAppUser>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync,
    private _patientAppUserSynchronizer: PatientAppUserSynchronizer
  ) {
    super(PatientAppUserModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  protected async afterSync(syncState: ISyncState<IPatientAppUser>): Promise<void> {
    this._patientAppUserSynchronizer.synchronize([...syncState.created, ...syncState.updated]);
    return;
  }

  public canSync(channel: string): boolean {
    return (
      channel === PatientAppUserChannel.CREATE ||
      channel === PatientAppUserChannel.EDIT ||
      channel === PatientAppUserChannel.DELETE
    );
  }

  async onCreated(data: IPatientAppUser): Promise<void> {
    await super.onCreated(data);

    await this._patientAppUserSynchronizer.synchronize([data]);
  }

  async onPatched(data: any): Promise<void> {
    await super.onPatched(data);

    await this._patientAppUserSynchronizer.synchronize([data]);
  }
}
