import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Deferred } from 'src/app/common/deferred/deferred';

import { environment } from './environment';

@Injectable({ providedIn: 'root' })
export class AppflowChannel {
  private _channelName: string;
  private _ready = new Deferred<void>();
  public get ready(): Promise<void> {
    return this._ready.promise;
  }

  public get channel(): string {
    return this._channelName;
  }

  constructor(private _platform: Platform) {}

  public async init() {
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      this._ready.resolve();
      return;
    }

    if ((environment as any).channelName) {
      const channelNameByEnvironment: string = (environment as any).channelName;

      this._channelName = this._platform.is('ios')
        ? `iOS_${channelNameByEnvironment}`
        : `Android_${channelNameByEnvironment}`;
    }

    this._ready.resolve();
  }

  public resetChannelName(channel: string): string {
    if (!this._channelName.includes('_')) {
      return channel;
    }

    const lastIndexOfUnderscore = this._channelName.lastIndexOf('_');

    return `${this._channelName.substring(0, lastIndexOfUnderscore)}_${channel}`;
  }
}
