import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { MedicalOrderRequestDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';

import { MedicalOrderRequestDto } from 'src/app/shared/models/medical-order/medical-order-request-dto.model';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class MedicalOrderRequestWorkItem extends WorkItem<MedicalOrderRequestDto> {
  constructor(@Inject(UnitOfWorkFactory) _unitOfWorkFactory: IUnitOfWorkFactory) {
    super(_unitOfWorkFactory);
  }

  get name(): string {
    return 'MedicalOrderRequestWorkItem';
  }
  get database(): string {
    return MedicalOrderRequestDB;
  }
}
