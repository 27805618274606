import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'itl-detail-item',
  templateUrl: './detail-item.component.html',
  styleUrls: ['./detail-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class DetailItemComponent {
  @Input() noIcon = false;
  @Input() clickAble = false;
  @Output() clicked: EventEmitter<null> = new EventEmitter<null>();

  triggerevent() {
    this.clicked.emit();
  }
}
