import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from '@common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from '@common/contracts/unit-of-work/unit-of-work-factory';
import { CalendarResourceDB } from '@common/repository/databases';
import { UnitOfWorkFactory } from '@common/unit-of-work/unit-of-work-factory';
import { CalendarResourceDto } from 'src/app/shared/models/calendar-resource/calendar-resource-dto.model';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class CalendarResourceWorkItem extends WorkItem<CalendarResourceDto> {
  unitOfWork: IUnitOfWork;
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'CalendarResourceWorkItem';
  }
  get database(): string {
    return CalendarResourceDB;
  }
}
