import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'itl-detail-item-icon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./detail-item-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DetailItemIconComponent {}
