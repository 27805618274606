import { Injectable } from '@angular/core';
import { ICancellationToken } from 'src/app/common/contracts/cancellation/cancellation-token';
import { ISyncState } from 'src/app/common/contracts/sync/sync-state';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';
import { Changes } from 'src/app/common/tracking';
import { GroupDto } from 'src/app/shared/models/user-management/group-dto.model';
import { AuthorizationResolver } from 'src/app/shared/services/authorization-resolver.service';
import { IDatabaseSynchronizer } from 'src/app/shared/services/contracts/sync/database-synchronizer_T';

import { WorkItem } from '../work-item';
import { GroupWorkItem as DecoratedGroupWorkItem } from './group-work-item';

@Injectable({ providedIn: 'root' })
export class GroupWorkItem extends WorkItem<GroupDto> implements IWorkItem<GroupDto>, IDatabaseSynchronizer<GroupDto> {
  constructor(private _groupWorkItem: DecoratedGroupWorkItem, private _authorizationResolver: AuthorizationResolver) {
    super(null);
  }
  get name(): string {
    return 'GroupWorkItem';
  }
  getAll(): Promise<GroupDto[]> {
    return this._groupWorkItem.getAll();
  }

  async sync(state: {}, token: ICancellationToken): Promise<ISyncState<GroupDto>> {
    const result = await this._groupWorkItem.sync(state, token);

    return result;
  }

  get database(): string {
    return this._groupWorkItem.database;
  }

  public async beforeUpdate(item: GroupDto, changes: Changes): Promise<GroupDto> {
    return this._groupWorkItem.beforeUpdate(item, changes);
  }

  async create(model: GroupDto): Promise<void> {
    await this._groupWorkItem.create(model);
    await this._authorizationResolver.resolve();

    return;
  }

  update(changes: Changes, groupDto: GroupDto): Promise<void> {
    return this._groupWorkItem.update(changes, groupDto);
  }

  async delete(model: GroupDto): Promise<void> {
    await this._groupWorkItem.delete(model);
    await this._authorizationResolver.resolve();

    return;
  }
}
