import { IndexRebuilder } from 'src/app/shared/pipeline/sync/index-rebuilder';
import { IPlatformSync } from '../contracts/sync/platform-sync';
import { Deferred } from '../deferred/deferred';

export class PlatformSync implements IPlatformSync {
  private _isCordova: boolean;
  private _canBeSynced: boolean;
  private _ready = new Deferred<void>();

  public get canBeSynced(): boolean {
    return this._canBeSynced && !IndexRebuilder.isRepairing;
  }

  public set canBeSynced(canSync: boolean) {
    this._canBeSynced = canSync;
    this._ready.resolve();
  }

  public get isCordova(): boolean {
    return this._isCordova;
  }

  public set isCordova(cordova: boolean) {
    this._isCordova = cordova;
  }

  public get ready(): Promise<void> {
    return this._ready.promise;
  }
}

const PLATFORMSYNC: IPlatformSync = new PlatformSync();

export default PLATFORMSYNC;
