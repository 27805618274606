import { Inject, Injectable } from '@angular/core';
import { map } from 'src/app/common/mapper/mapper';
import { ArticleDB } from 'src/app/common/repository/databases';

import { ArticleDto } from '../models/article/article-dto.model';
import { IQueryService } from './contracts/query/query-service';
import { QueryService } from './query/query.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  constructor(@Inject(QueryService) private _queryService: IQueryService) {}

  public async find(id: string): Promise<ArticleDto> {
    const article = await this._queryService.get<ArticleDto>(id, ArticleDB);

    return article ? map(article, new ArticleDto()) : undefined;
  }
}
