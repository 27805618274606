import { Injectable } from '@angular/core';
import authentication from '@feathersjs/authentication-client';
import feathers, { Application, Service } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConnectionMode } from 'src/app/common/contracts/connection/connection-mode';
import { Deferred } from 'src/app/common/deferred/deferred';

import { IFeathersService } from './contracts/sync/feathers-service';

@Injectable({ providedIn: 'root' })
export class FeathersService implements IFeathersService {
  private _ready = new Deferred<void>();
  public get ready(): Promise<void> {
    return this._ready.promise;
  }

  private _app: Application;
  private _connnectionMode = ConnectionMode.default;
  public get connectionMode(): ConnectionMode {
    return this._connnectionMode;
  }

  public set connectionMode(connectionMode: ConnectionMode) {
    this._connnectionMode = connectionMode;
    this._connectionMode$.next(this._connnectionMode);
  }

  private _connectionMode$ = new BehaviorSubject<ConnectionMode>(ConnectionMode.default);
  public get connectionModeChange(): Observable<ConnectionMode> {
    return this._connectionMode$.asObservable();
  }

  public get app(): Application {
    return this._app;
  }

  public getService<T>(serviceName: string): Service<T> {
    return this._app.service(serviceName);
  }

  public setup(socket: SocketIOClient.Socket) {
    this._app = this.setupFeathersApp(socket);
    if ((window as any).Cypress) {
      window['feathers'] = this._app;
    }
    this._ready.resolve();
  }

  private setupFeathersApp(socket: SocketIOClient.Socket) {
    const app = feathers();
    /* timeout (default: 5000ms) - should be same on websocket proxy on alberta.api.gateway
    The time after which a method call fails and times out.
    This usually happens when calling a service or service method that does not exist.
    https://docs.feathersjs.com/api/client/socketio.html#socketiosocket-options
  */
    app.configure(socketio(socket, { timeout: 60000 }));
    app.configure(
      authentication({
        header: 'Authorization',
        path: '/authentication',
        jwtStrategy: 'fusionAuthJwt',
        entity: 'user',
        service: 'users',
        cookie: 'feathers-jwt',
        storageKey: 'authorizationData',
        storage: window.localStorage,
        timeout: 15000,
      } as any)
    );

    return app;
  }
}
