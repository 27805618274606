import { Inject, Injectable } from '@angular/core';
import { ChatDbService } from 'src/app/shared/services/chat/data/chat-db.service';
import makeDebug from 'src/makeDebug';
import { Conversation as TwilioConversation, Client as TwilioClient } from '@twilio/conversations';
import { PushMarkerService } from '../../push/push-marker.services';
import type { ChatSendService } from '../chat-send.service';
import { ChatChannel, ChatMessage } from '../data/db-schema';
import { Chat } from '../model/chat-instance';
import { TWILIO_AGENT_TOKEN } from './agent-twilio.factory';
import { TwilioToChatDtoHelpersService } from './twilio-to-chat-dto-helpers';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';

const debug = makeDebug('services:chat:twilio-sendService');
const sleep = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

@Injectable({
  providedIn: 'root',
})
export class TwilioChatSendService implements ChatSendService {
  constructor(
    @Inject(TWILIO_AGENT_TOKEN) private readonly _twilioAgentChatClient: Promise<TwilioClient>,
    private readonly _twilioDtoHelper: TwilioToChatDtoHelpersService,
    private readonly _chatDbService: ChatDbService,
    private readonly _pushMarkerService: PushMarkerService
  ) {}

  public async fetchMessagesOfConversation(
    conversationSid: string,
    anchor: number,
    count: number
  ): Promise<ChatMessage[]> {
    debug('fetch messages of channel', { conversationSid, anchor, count });
    const client = await this._twilioAgentChatClient;
    if (!client) {
      return [];
    }
    const conversation = await client.getConversationBySid(conversationSid);
    const messages = await conversation.getMessages(count, anchor);
    const convertedMessages = [];

    for (const message of messages.items) {
      const convertedMessage = await this._twilioDtoHelper.convertToChatMessage(message);
      convertedMessages.push(convertedMessage);
    }
    debug('got messages from twlio', { conversationSid, anchor, count, convertedMessages });
    return convertedMessages;
  }

  public async sendMessage(
    conversationId: string,
    bodyText: string,
    attributes: {},
    chat = Chat.PatientApp
  ): Promise<ChatMessage> {
    debug('send message in queue', bodyText);
    const twilioClient: TwilioClient = await this._twilioAgentChatClient;
    if (!twilioClient) {
      return null;
    }

    // in some rare cases, twilio will not be fully connected already
    // this will buy it some time
    for (let i = 0; twilioClient.connectionState === 'connecting' && i < 10; i++) {
      await sleep(1000);
    }

    const conversation: TwilioConversation = await twilioClient.getConversationBySid(conversationId);
    if (!conversation) {
      throw new Error('conversation not found:' + conversationId);
    }
    debug('sending message...', bodyText);

    if (chat === Chat.PatientApp) {
      bodyText = this._pushMarkerService.enrichMessageWithPushMarkers(conversation, bodyText);
    }

    const result = await conversation.sendMessage(bodyText, attributes);
    if (!Number.isInteger(result)) {
      throw new Error('send-failed-with:' + result);
    }
    const message = await conversation.getMessages(1, result);
    debug('got send result for', bodyText, 'result:', result);
    return this._twilioDtoHelper.convertToChatMessage(message.items[0]);
  }

  public async setAgentConsumptionIndex(conversationId: string, index: number): Promise<void> {
    debug('set consumption index', { conversationId, index });
    const twilioClient: TwilioClient = await this._twilioAgentChatClient;
    if (!twilioClient) {
      return;
    }
    const converstation: TwilioConversation = await twilioClient.getConversationBySid(conversationId);
    if (!converstation) {
      throw new Error('channel not found:' + conversationId);
    }
    await converstation.advanceLastReadMessageIndex(index);
  }

  public async updateConversationAttributes(conversationSid: string, attributes = {}): Promise<void> {
    const twilioClient: TwilioClient = await this._twilioAgentChatClient;
    if (!twilioClient) {
      return;
    }

    const conversation: TwilioConversation = await twilioClient.getConversationBySid(conversationSid);

    if (!conversation) {
      throw new Error('converstaion not found:' + conversationSid);
    }

    await conversation.updateAttributes({ ...(conversation.attributes as any), ...attributes }).catch(error => {
      console.error(JSON.stringify(error));
    });
  }

  /**
   * Requests all locally stored Twilio channels, and removes deleted ones.
   *
   * Reason:
   * If a conversation is beeing deleted, while the client was offline,
   * it will not receive the delete event. Thus the conversation would never be deleted locally.
   */
  public async removeDeletedChannels(): Promise<void> {
    const agentChatChannels$ = await this._chatDbService.getChannels(Chat.PatientApp);

    const deleteChannelsIfMissing = async (
      channels$: Observable<ChatChannel[]>,
      twilioClient: TwilioClient
    ): Promise<void> => {
      return new Promise(resolve => {
        channels$.pipe(first()).subscribe(async channels => {
          await Promise.all(
            channels.map(async channel => {
              try {
                await twilioClient.getConversationBySid(channel.sid);
              } catch (error) {
                if (error.status === 404 || error.status === 403) {
                  // conversation was removed completely, or I've been removed from it
                  await this._chatDbService.removeChannel(channel.sid);
                }
              }
            })
          );

          resolve();
        });
      });
    };

    await deleteChannelsIfMissing(agentChatChannels$, await this._twilioAgentChatClient);
  }
}
