<div class="stars-wrapper">
    <ion-icon
      name="star"
      [class]="
        rating > 0
          ? 'rating-star positive icon icon-md ion-md-star'
          : 'rating-star deactivated icon icon-md ion-md-star'
      "
    ></ion-icon>
    <ion-icon
      name="star"
      [class]="
        rating > 1
          ? 'rating-star positive icon icon-md ion-md-star'
          : 'rating-star deactivated icon icon-md ion-md-star'
      "
    ></ion-icon>
    <ion-icon
      name="star"
      [class]="
        rating > 2
          ? 'rating-star positive icon icon-md ion-md-star'
          : 'rating-star deactivated icon icon-md ion-md-star'
      "
    ></ion-icon>
    <ion-icon
      name="star"
      [class]="
        rating > 3
          ? 'rating-star positive icon icon-md ion-md-star'
          : 'rating-star deactivated icon icon-md ion-md-star'
      "
    ></ion-icon>
    <ion-icon
      name="star"
      [class]="
        rating > 4 && assortmentType === 1
          ? 'rating-star superior icon icon-md ion-md-star'
          : (rating > 4 ? 'rating-star positive icon icon-md ion-md-star'
            : 'rating-star deactivated icon icon-md ion-md-star')
      "
    ></ion-icon>
    <div class="assortmentType superiorExtension bold" *ngIf="assortmentType === 1">F</div>
    <div class="assortmentType inferiorExtension bold" *ngIf="assortmentType === 3">R</div>
  </div>