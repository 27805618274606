import 'zone.js/plugins/zone-error';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const baseUrl = 'halloalberta.de';

export interface ICodePushDeploymentKeys {
  deploymentKey: string;
  platform: 'android' | 'ios';
  deployment: 'dev' | 'staging' | 'production';
}

export interface IEncryptionSettings {
  emails: string[];
  platforms: string[];
}

export interface IEnvironmentUrls {
  dev: string;
  production: string;
  staging: string;
  uat: string;
  mdr: string;
}

export interface IAlbertaEnvironment {
  production: boolean;
  disableDeploy: boolean;
  baseUrl: string;
  branch: 'dev' | 'mdr' | 'uat' | 'production' | 'staging';
  channelName: 'MDR' | 'MDR_UAT' | 'Dev' | 'Staging' | 'Production';
  o365Scopes: string[];
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  amplitude: {
    apiKey: string;
    patientApp: {
      enabled: boolean;
      apiKey: string;
    };
  };
  sentry: {
    dsn: string;
  };
  authCodeFlowConfigWeb: {
    issuer: string;
    redirectUri: string;
    clientId: string;
    responseType: string;
    scope: string;
    clearHashAfterLogin: boolean;
    showDebugInformation: boolean;
  };
  authCodeFlowConfigApp: {
    client_id: string;
    server_host: string;
    scopes: string;
    pkce: boolean;
  };
  codePushDeploymentKeys: ICodePushDeploymentKeys[];
  stimulsoftLicense: string;
  calendar: {
    settingsPath: string;
    apiPath: string;
    helpLink: {
      url: string;
      label: string;
    };
    appLinkLabel: string;
  };
  encryption: {
    regex: RegExp;
    settings: IEncryptionSettings[];
  };
  sendAllCommandsToEmail: string;
  environmentSettings: {
    albertaUrl: IEnvironmentUrls;
    swodocUrls: IEnvironmentUrls;
    signalingServer: IEnvironmentUrls;
  };
  createTenant: {
    defaultUserSuffix: string;
  };
  validITLabsUser: string[];
  markerjs2Key: string;
  careboxBaseUrl: string;
}

export const environment: IAlbertaEnvironment = {
  production: false,
  disableDeploy: true,
  baseUrl,
  branch: 'dev',
  channelName: 'Dev',
  o365Scopes: ['user.read'],
  firebase: {
    apiKey: 'AIzaSyCFI8FjgCF9O8jQWmeNIadi-64M-nyCxu8',
    authDomain: 'alberta-e5259.firebaseapp.com',
    databaseURL: 'https://alberta-e5259.firebaseio.com',
    projectId: 'alberta-e5259',
    storageBucket: 'alberta-e5259.appspot.com',
    messagingSenderId: '32777537853',
    appId: '1:32777537853:web:f06357dc49bfc6d64f9d54',
  },
  amplitude: {
    apiKey: 'cda380991a824d9b9728a1192f58f50b',
    patientApp: {
      enabled: true,
      apiKey: '80322ee5cb842862a199ec9919b986ca',
    },
  },
  sentry: {
    dsn: 'https://fec3ae89cf75472c97023f4609a0b6c1@sentry.io/1774312',
  },
  authCodeFlowConfigWeb: {
    issuer: `https://auth.${baseUrl}`,
    redirectUri: window.location.origin,
    clientId: '5e9e8812-b866-48d2-9b15-8a96fdb92473',
    responseType: 'code',
    scope: 'openid offline_access',
    clearHashAfterLogin: true,
    showDebugInformation: true,
  },
  authCodeFlowConfigApp: {
    client_id: '5e9e8812-b866-48d2-9b15-8a96fdb92473',
    server_host: `https://auth.${baseUrl}`,
    scopes: 'openid offline_access',
    pkce: true,
  },
  codePushDeploymentKeys: [
    { deploymentKey: 'BrSgolTnL1XL_kH0qCXbeVuZ_gfHFKqWu2jui', platform: 'android', deployment: 'dev' },
    { deploymentKey: 'vSQ7WCiqJANrtezBF3akiPkOjLHUtwuXRxriu', platform: 'android', deployment: 'staging' },
    { deploymentKey: 'X_qhUznDXTBB0TXNn61eJgSGUK2xZH1lYGuXU', platform: 'android', deployment: 'production' },
    { deploymentKey: 'g5SL8aaCEgZGZfHDNC7UYcqiPALJ-9pex36S5', platform: 'ios', deployment: 'dev' },
    { deploymentKey: 'tSoS-gGre8t0uN8hHPSEL98c7zqxHZhXDhMrZ', platform: 'ios', deployment: 'staging' },
    { deploymentKey: 'ZBfMW9NSubm7WqvVnwfULZh0_0XAZorDMguyw', platform: 'ios', deployment: 'production' },
  ],
  stimulsoftLicense:
    '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkJ2or/7Baexggyb/CoJTWw5tbdDhaAFoQFSrso+HywMU0omd' +
    '9t+u8H2eWI0jjl79hJUyhwKtQwD4mfoKgB1BhaL9nuTZOyJKe1imrCTa/Y1jeAKJhiwyD7tZJv9VpRKqmOCriy76jE' +
    '0bf9ZYHgF7/XLfbropeJtTNxgj9q0BLLhFX/dsGTSnehzBPBEdwerwPOYkJz1MyFLBrbr+VrrUFAG9L8wOqjjbJS3D' +
    'kYRth7hfuyHjnJXylg4PTIUar8CU7jT0JzJNpM+JT33NbFpcbM82/ZUf6/WLAeb2GqiocZmXKhtTNFxIUBhvkHAk3v' +
    'mMVZcOVA//qEK5pAXO2U8iGdwhHsigfOS/neR+Te6HA0SzVSTSKorE+tUrriGcIQvjqGR3QhHzDp2iwK7Eo8J14lxO' +
    'ADXBZoKlEW8DFaoRgJqOPseYQZ5V5zd6TSx8bR4i7chp4nyFUYauvbhGDLsOjLP1r6ddu0CBNxwma1LNIGTWIwZZ1B' +
    '7MiZoAag2iLgl3N6JYXJmgka5DEnbaWXWwyc',
  calendar: {
    settingsPath: `app.${baseUrl}/setting/calendar`,
    apiPath: `api.${baseUrl}/calendar`,
    helpLink: {
      url: `https://hilfe.${baseUrl}/portal/kb/articles/kalender`,
      label: `hilfe.${baseUrl}`,
    },
    appLinkLabel: `app.${baseUrl}`,
  },
  encryption: {
    regex: /^\w+([-+.']\w+)*@?(resmed\.com|resmed\.de|it-labs\.de|halloalberta\.de)$/,
    settings: [
      { emails: ['@resmed.com', '@resmed.de'], platforms: ['ios', 'iphone', 'ipad'] },
      { emails: ['@it-labs.de', `@${baseUrl}`], platforms: ['cordova', 'hybrid'] },
    ],
  },
  sendAllCommandsToEmail: `hilfe@${baseUrl}`,
  environmentSettings: {
    albertaUrl: {
      dev: `https://dev.k8s.api.${baseUrl}`,
      staging: `https://staging.api.${baseUrl}`,
      production: `https://api.${baseUrl}`,
      uat: `https://uat-mdr.k8s.api.${baseUrl}`,
      mdr: `https://mdr.k8s.api.${baseUrl}`,
    },
    swodocUrls: {
      dev: `https://dev.formulare.${baseUrl}`,
      staging: `https://staging.formulare.${baseUrl}`,
      production: `https://formulare.${baseUrl}`,
      uat: `https://mdr-uat.formulare.${baseUrl}`,
      mdr: `https://mdr.formulare.${baseUrl}`,
    },
    signalingServer: {
      dev: 'https://signaling.dev.k8s.api.halloalberta.de',
      production: 'https://signaling.k8s.api.halloalberta.de',
      staging: 'https://signaling.staging.k8s.api.halloalberta.de/',
      uat: '',
      mdr: '',
    },
  },
  createTenant: {
    defaultUserSuffix: 'halloalberta.de',
  },
  validITLabsUser: ['it-labs.de', 'fiedlefl@gmail.com'],
  markerjs2Key: 'MJS2-P947-M765-5857',
  careboxBaseUrl: `https://dev.emu.apps.${baseUrl}/create`,
};
