import { ProductGroupType } from '@alberta/konexi-shared';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductGroupDto } from 'src/app/shared/models/article/product-group-dto.model';

import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'product-group-list-item',
  templateUrl: 'product-group-list-item.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductGroupListItem implements IListView {
  @Input() item: ProductGroupDto;
  @Output() clicked: EventEmitter<ProductGroupDto> = new EventEmitter<ProductGroupDto>();

  public triggerEvent() {
    this.clicked.emit(this.item);
  }
  public itemName(item) {
    return `(${ProductGroupType[item.type]}) ${item.name}`;
  }
}
