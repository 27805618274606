import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { DirectivesModule } from '../../directives/directives.module';
import { SplitPaneComponent } from './split-pane.component';
import { AlbertaIconModule } from '../alberta-icon/alberta-icon.module';

@NgModule({
  declarations: [SplitPaneComponent],
  imports: [CommonModule, IonicModule, DirectivesModule, AlbertaIconModule],
  exports: [SplitPaneComponent],
})
export class SplitPaneModule {}
