<itl-text-input-template
  [control]="ngControl?.control"
  [icon]="icon"
  [title]="title"
  [value]="value"
  [maxlength]="maxlength"
  [description]="description"
  [noIcon]="noIcon"
  [disabled]="disabled"
  [required]="required"
  (focusClicked)="setFocus()"
>
  <input
    #input
    class="alb-input"
    [ngClass]="{
      bold: value && value.length,
      'alb-input--disabled': disabled
    }"
    [formControl]="this.ngControl?.control"
    type="currency"
    [placeholder]="placeholder"
    [maxlength]="maxlength"
    [disabled]="disabled"
    [readonly]="disabled"
    [itl-inputTrimmer]="trimEnabled"
    [brmasker]="{ money: true, decimal: 2 }"
    (focus)="markAsTouched()"
    (change)="onChange($event.target.value)"
    (blur)="onTouched()"
  />
</itl-text-input-template>
