import { DoctorConfig } from './doctor-config';

export const DoctorPeter: DoctorConfig = new DoctorConfig(
  '53a2b9d0-a622-4137-9ae8-eab56b9a677f',
  '5ab23ba59d69c74b68cfc198'
);

export const DoctorFreudiger: DoctorConfig = new DoctorConfig(
  '622697fd-8e44-44fc-b863-68ea81c07277',
  '5ab23bbe9d69c74b68cfc94d'
);

export const DoctorWohlgemuth: DoctorConfig = new DoctorConfig(
  '4baccb35-8196-4115-8636-f9419f17c4e8',
  '5ab23bbe9d69c74b68cfc95c'
);

export function isRelevantDoctor(userId: string): boolean {
  return userId === DoctorPeter.userId || userId === DoctorWohlgemuth.userId || userId === DoctorFreudiger.userId;
}

export function getDoctorIdFromUserId(userId: string): string {
  switch (userId) {
    case DoctorPeter.userId:
      return DoctorPeter.doctorId;
    case DoctorWohlgemuth.userId:
      return DoctorWohlgemuth.doctorId;
    case DoctorFreudiger.userId:
      return DoctorFreudiger.doctorId;
    default:
      return null;
  }
}
