import { NgModule } from '@angular/core';
import { SmallIconButtonComponent } from './small-icon-button.component';
import { IonicModule } from '@ionic/angular';
import { AlbertaIconModule } from '../../alberta-icon/alberta-icon.module';

@NgModule({
  declarations: [SmallIconButtonComponent],
  imports: [AlbertaIconModule, IonicModule],
  exports: [SmallIconButtonComponent],
})
export class SmallIconButtonModule {}
