import { IAppointment } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { AppointmentDB } from 'src/app/common/repository/databases';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

import { MessagePayloadAppointment } from '../../models/share/message-payload-appointment';
import { IRouteParser } from '../contracts/route/route-parser';
import { InstitutionActivityHelperService } from '../institution-activity-helper.service';
import { QueryService } from '../query/query.service';

@Injectable()
export class AppointmentRouteParser implements IRouteParser {
  type = MessagePayloadType.Appointment;

  constructor(
    private _queryService: QueryService,
    private _institutionActivityHelperService: InstitutionActivityHelperService
  ) {}
  async parse(payload: MessagePayloadAppointment): Promise<string> {
    const appointment = await this._queryService.get<IAppointment>(payload.appointmentId, AppointmentDB);

    if (!appointment) {
      throw new Error(`No appointment found for id ${payload.appointmentId}`);
    }

    if (payload.patientId && !appointment.patientIds.includes(payload.patientId)) {
      throw new Error(`Patient with id ${payload.patientId} is no longer part of appointment.`);
    }

    if (payload.patientId) {
      return `/patient/${payload.patientId}/activities/${appointment._id}`;
    } else if (payload.institutionId) {
      const institutionRoute = this._institutionActivityHelperService.getRouteForInstitutionType(
        payload.appointmentType
      );
      return `/${institutionRoute}/${payload.institutionId}/activities/${appointment._id}`;
    }
  }
}
