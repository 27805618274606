import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { AlbertaIconComponent } from './alberta-icon.component';

@NgModule({
  declarations: [AlbertaIconComponent],
  exports: [AlbertaIconComponent],
  imports: [CommonModule, IonicModule],
})
export class AlbertaIconModule {}
