import { IModel } from '@alberta/konexi-shared';
import { chunk as createChunks, set } from 'lodash';

import { ICancellationToken } from '../common/contracts/cancellation/cancellation-token';
import { IRepository } from '../common/contracts/repository/repository';
import { ISyncState } from '../common/contracts/sync/sync-state';
import { IUnitOfWork } from '../common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from '../common/contracts/unit-of-work/unit-of-work-factory';
import { IWorkItem } from '../common/contracts/work-item/work-item_T';
import { Deferred } from '../common/deferred/deferred';
import { Changes } from '../common/tracking';
import { IDatabaseSynchronizer as IDatabaseSynchronizer_T } from '../shared/services/contracts/sync/database-synchronizer_T';

export abstract class WorkItem<T extends IModel> implements IWorkItem<T>, IDatabaseSynchronizer_T<T> {
  protected ready = new Deferred<void>();
  protected _deletable = true;

  constructor(protected unitOfWorkFactory: IUnitOfWorkFactory) {
    // tslint:disable-next-line: no-floating-promises
    this.init();
  }

  abstract get database(): string;
  abstract get name(): string;

  protected get chunkSize(): number {
    return 250;
  }

  private get _table(): string {
    return this.database.substring(0, this.database.length - 3);
  }

  protected unitOfWork: IUnitOfWork;

  protected async afterCreate(model: T): Promise<T> {
    return model;
  }

  protected async beforeCreate(model: T): Promise<T> {
    return model;
  }

  protected async afterUpdate(item: T, model: T): Promise<T> {
    return item;
  }

  public get deletable(): boolean {
    return this._deletable;
  }

  public async beforeUpdate(model: T, changes: Changes): Promise<T> {
    return model;
  }

  protected async afterDelete(model: T): Promise<T> {
    return model;
  }

  protected async beforeDelete(model: T): Promise<T> {
    return model;
  }

  async getAll(): Promise<T[]> {
    await this.ready.promise;

    const repository = await this.unitOfWork.create<T>(this.database);
    return repository.getAll();
  }

  async get(id: string): Promise<T> {
    await this.ready.promise;

    const repository = await this.unitOfWork.create<T>(this.database);
    return repository.get(id);
  }

  async sync(state: any, token: ICancellationToken): Promise<ISyncState<T>> {
    await this.ready.promise;

    const result: ISyncState<T> = { updated: [], created: [], deleted: [] };

    if (!state) {
      return result;
    }

    const stateData = Array.isArray(state)
      ? Array.from(state)
      : state.data && state.total && state.limit
        ? state.data
        : this.createArrayByState(state);

    if (!stateData.length) {
      return result;
    }

    const repository = await this.unitOfWork.create<T>(this.database);

    const items: Record<string, IModel> = {};
    await repository
      .getItems(stateData.map(item => item._id))
      .then(itemsFromDb => itemsFromDb.forEach(item => (items[item._id] = item)));

    const chunks: any[] = createChunks(stateData, this.chunkSize);
    for (const chunk of chunks) {
      if (token.cancelled.get()) {
        break;
      }
      const batches = [];
      const itemsForDb = [];

      for (const item of chunk) {
        if (token.cancelled.get()) {
          break;
        }

        if (items[item._id]) {
          if (item.archived || (item.metadata && item.metadata.archived)) {
            if (this.deletable) {
              batches.push([`DELETE FROM [${this._table}] WHERE KEY = (?1);`, [item._id]]);
              result.deleted.push(item);
            } else {
              batches.push([
                `INSERT OR REPLACE INTO [${this._table}] (key, value) VALUES (?1, ?2)`,
                [String(item._id), JSON.stringify(item)],
              ]);
              result.updated.push(item);
            }
            itemsForDb.push(item);
          } else {
            batches.push([
              `UPDATE [${this._table}] SET Value = (?1) WHERE Key = (?2)`,
              [JSON.stringify(item), item._id],
            ]);
            result.updated.push(item);
            itemsForDb.push(item);
          }
        } else {
          if (
            !item.archived ||
            (item.metadata && !item.metadata.archived) ||
            ((item.archived || (item.metadata && item.metadata.archived)) && !this.deletable)
          ) {
            batches.push([
              `INSERT OR REPLACE INTO [${this._table}] (key, value) VALUES (?1, ?2)`,
              [String(item._id), JSON.stringify(item)],
            ]);
            itemsForDb.push(item);
          }
          result.created.push(item);
        }

        const indexedValuesForThisItem = await repository.addOrUpdateIndex(item);
        if (typeof indexedValuesForThisItem === 'object' && indexedValuesForThisItem != null) {
          batches.push([
            `DELETE FROM [${this._table}_fts] WHERE rowid = (SELECT docid FROM [${this._table}_fts] WHERE ${
              this._table
            }_fts MATCH 'id:${String(item._id)}')`,
            [],
          ]);
          if (!this.deletable || (!item.archived && !item.metadata?.archived)) {
            batches.push([
              `INSERT INTO [${this._table}_fts] (id, ${indexedValuesForThisItem.fieldNames.join(', ')}) VALUES (?1, ${
                indexedValuesForThisItem.bindings
              })
        `,
              // extract values from the object and convert them to String to insert them into FTS/Index Table
              // dates need to be converted to ISOString to be stored in the FTS/Index Table
              [
                String(item._id),
                ...indexedValuesForThisItem.entries.map(entry =>
                  entry instanceof Date ? entry.toISOString().toLocaleLowerCase() : String(entry).toLocaleLowerCase()
                ),
              ],
            ]);
          }
        }
      }

      if (itemsForDb.length) {
        await repository.execBatch(batches);
      }
    }

    return result;
  }

  async create(model: T): Promise<void> {
    await this.ready.promise;

    const repository = await this.unitOfWork.create<T>(this.database);

    const modelToSave = await this.beforeCreate(model);

    await repository.createOrUpdate(modelToSave);

    await this.afterCreate(model);
  }

  async update(changes: Changes, model: T): Promise<void> {
    await this.ready.promise;

    const repository = await this.unitOfWork.create<T>(this.database);

    const dbItem = await this.updateValues(model._id, changes, repository);

    if (dbItem.archived && !this.deletable) {
      return;
    }

    await this.beforeUpdate(dbItem, changes);

    await repository.createOrUpdate(dbItem);

    await this.afterUpdate(dbItem, model);
  }

  async delete(model: T): Promise<void> {
    await this.ready.promise;

    const repository = await this.unitOfWork.create<T>(this.database);
    const item = await repository.get(model._id);
    item.archived = true;

    await this.beforeDelete(item);

    await repository.createOrUpdate(item);

    await this.afterDelete(item);
  }

  public async hardDelete(id: string): Promise<void> {
    await this.ready.promise;

    const repository = await this.unitOfWork.create<T>(this.database);
    await repository.delete(id);
  }

  private async updateValues(entityId: string, changes: Changes, repository: IRepository<T>): Promise<T> {
    const dbItem = await repository.get(changes.rootId || entityId);

    if (dbItem == null) {
      throw new Error(
        `No item defined for ID: ${changes.rootId} - changes: ${JSON.stringify(changes)} - entity: ${this.database}`
      );
    }

    for (const change of changes.changes) {
      change.path = change.path.replace('#.', '');
      set(dbItem, change.path, change.newValue);
    }

    return dbItem;
  }

  private createArrayByState(state) {
    return state._id ? [state] : Object.keys(state).map(key => state[key]);
  }

  private async init() {
    if (this.unitOfWorkFactory) {
      this.unitOfWork = await this.unitOfWorkFactory.create();
    }
    this.ready.resolve();
  }
}
