<itl-text-input-template
  [control]="ngControl"
  [icon]="icon"
  [title]="title"
  [value]="value"
  [description]="description"
  [noIcon]="noIcon"
  [disabled]="disabled"
  [required]="required"
  (focusClicked)="setFocus()"
>
  <input
    [(ngModel)]="value"
    type="number"
    [readonly]="disabled"
    [placeholder]="placeholder"
    [disabled]="disabled"
    class="alb-input"
    [ngClass]="{
      bold: value && value.length,
      'alb-input--disabled': disabled
    }"
    (focus)="markAsTouched()"
    (ngModelChange)="updateChanges()"
    #input
    [min]="min"
  />
</itl-text-input-template>
