import { Directive, TemplateRef } from '@angular/core';

import { AlbertaTableComponent } from './alberta-table.component';

@Directive({
  selector: '[albertaTableRowTemplate]',
})
export class AlbertaTableRowTemplateDirective {
  constructor(private tableComponent: AlbertaTableComponent, templateRef: TemplateRef<any>) {
    this.tableComponent.rowTemplate = templateRef;
  }
}
