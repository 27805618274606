import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { ErpOrderDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { ErpOrderDto } from 'src/app/shared/models/order/erp-order-dto.model';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class ErpOrderWorkItem extends WorkItem<ErpOrderDto> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'ErpOrderWorkItem';
  }
  get database(): string {
    return ErpOrderDB;
  }

  get chunkSize(): number {
    return 75;
  }
}
