import {
  IInsuranceFixedPrice,
  IService,
  IServiceQuantityPerUnit,
  ServiceLocation,
  ServiceType,
} from '@alberta/konexi-shared';
import IServiceUnit from '@alberta/konexi-shared/dist/product/service-unit';
import { ignore } from '@common/tracking/tracker';
import { BaseViewModel } from '@common/viewmodel/base-view-model';

export class ServiceViewModel extends BaseViewModel implements IService {
  linkedServiceIds: string[];
  /** hidden services cannot be added to integratedCares / serviceOrders
   * they are are only used as linkedServices for other services
   */
  isHidden: boolean;
  taskOptions: { firstTask?: IServiceUnit; default: IServiceUnit };
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  name: string;
  description: string;
  additionalDescription: string;
  therapyIds: number[] = [];
  serviceType: ServiceType;
  insuranceFixedPrice: IInsuranceFixedPrice[] = [];
  billingUnits: IServiceQuantityPerUnit[];
  location: ServiceLocation;

  @ignore()
  checked: boolean;
}
