import { NgZone } from '@angular/core';
import decode from 'jwt-decode';
import moment from 'moment';
import { Observable } from 'rxjs';

import makeDebug from '../../../makeDebug';
import { TokenExpiredError } from './token-expired-error';
import { TokenMalformedError } from './token-malformed-error';

const TOKEN_EXPIRATION_THRESHOLD_IN_MS = 2 * 60 * 1000;

const debug = makeDebug('auth:auth');

export function verifyJWT(accessToken) {
  if (typeof accessToken !== 'string') {
    throw new Error('Token provided to verifyJWT is missing or not a string');
  }
  try {
    const payload = decode(accessToken);

    if (payloadIsValid(payload)) {
      return payload;
    }
  } catch (error) {
    throw new TokenMalformedError('Cannot decode malformed token.');
  }
  throw new TokenExpiredError('Invalid token: expired');
}

function payloadIsValid(payload) {
  return payload && (!payload.exp || payload.exp * 1000 > new Date().getTime());
}

function verifyJWTAboutToExpire(accessToken) {
  try {
    const payload = decode(accessToken);

    return moment(new Date(payload.exp * 1000)).diff(moment(), 'millisecond', true) <= TOKEN_EXPIRATION_THRESHOLD_IN_MS;
  } catch (error) {
    throw new TokenMalformedError('Cannot decode malformed token.');
  }
}

let interval;

export function isTokenAboutToExpire(accessToken, ngZone: NgZone): Observable<boolean> {
  global.clearInterval(interval);

  return new Observable(observer => {
    interval = ngZone.runOutsideAngular(() =>
      setInterval(() => {
        try {
          if (verifyJWTAboutToExpire(accessToken)) {
            global.clearInterval(interval);
            debug('accessToken expired');
            observer.complete();
          }
        } catch (error) {
          observer.error(error);
        }
      }, 2000)
    );
  });
}
