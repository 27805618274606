import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[focus-next-input]',
})
export class FocusNextInputDirective implements OnInit, OnDestroy {
  constructor(private _element: ElementRef) {}
  ngOnInit() {
    this._element.nativeElement.onkeydown = value => {
      if (!isNaN(value.key)) {
        setTimeout(() => {
          this._element.nativeElement.nextElementSibling.focus();
          this._element.nativeElement.nextElementSibling.select();
        }, 100);
      }
    };
  }
  ngOnDestroy(): void {
    this._element.nativeElement.onkeydown = undefined;
  }
}
