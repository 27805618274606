import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IBaseInputContract } from '../contracts/base-input';

@Component({
  selector: 'itl-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements IBaseInputContract {
  @Input() title: string;
  @Input() description: string;
  @Input() buttonText: string;
  @Input() icon: string;
  @Input() required: boolean;
  @Input() showUploadButton = true;
  @Output() addImageButtonClicked: EventEmitter<null> = new EventEmitter<null>();
}
