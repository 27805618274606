import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { LoginFlowState } from '../contracts/authentication/login-flow-state';

@Injectable({ providedIn: 'root' })
export class LoginFlowStateDetector {
  private _loginFlowState$ = new Subject<LoginFlowState>();
  public get loginFlowState(): Observable<LoginFlowState> {
    return this._loginFlowState$.asObservable();
  }

  setState(loginFlowState: LoginFlowState) {
    this._loginFlowState$.next(loginFlowState);
  }
}
