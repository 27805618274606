import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { MaintenanceSnackBarComponent } from './maintenance-snack-bar.component';

@NgModule({
  declarations: [MaintenanceSnackBarComponent],
  imports: [CommonModule, FormsModule, IonicModule],
  exports: [MaintenanceSnackBarComponent],
})
export class MaintenanceSnackBarModule {}
