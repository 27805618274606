import { NgModule } from '@angular/core';

import { PatientRemovalService } from '../business/patient/patient-removal.service';
import { PipelineModule } from './pipeline/pipeline.module';
import { DataRemovalToken } from './services/contracts/data/data-removal';
import { RouteParserModule } from './services/route/route-parser.module';
import { SyncModule } from './services/sync/sync.module';

@NgModule({
  imports: [SyncModule.forRoot(), RouteParserModule.forRoot(), PipelineModule.forRoot()],
  providers: [{ provide: DataRemovalToken, useClass: PatientRemovalService, multi: true }],
})
export class SharedModule {}
