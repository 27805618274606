import { IArticle } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { checkIfArticleLineisOrderable } from 'src/app/business/order/order-functions';

@Pipe({
  name: 'articleIsOrderable',
  pure: true,
})
export class ArticleIsOrderablePipe implements PipeTransform {
  transform(article: IArticle, deliveryDate: Date): any {
    return checkIfArticleLineisOrderable(article, deliveryDate);
  }
}
