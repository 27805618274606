import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AlertDialogService } from 'src/app/business/alert-dialog-service';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { SyncIndicatorPage } from 'src/app/sync-indicator/sync-indicator.page';

import makeDebug from '../../../../makeDebug';
import { AppReadyEvent } from '../../models/app-ready-event';
import { EventService } from '../../services/event.service';
import { NetworkInfoService } from '../../services/network/network-info.service';
import { SavedInstanceStateService } from '../../services/saved-instance-state/saved-instance-state.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement, SyncElementResult } from '../contracts/sync/sync-element';
import { SyncIndicatorOpener as Opener } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:sync-indicator-opener');

@Injectable()
export class SyncIndicatorOpener implements ISyncElement {
  private static _isFirstStart = true;

  public get name(): string {
    return Opener;
  }

  constructor(
    private modalCtrl: ModalController,
    private _appReadyService: EventService<AppReadyEvent>,
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync,
    private readonly _networkInfoService: NetworkInfoService,
    private _router: Router,
    private _alertService: AlertDialogService,
    private _savedInstanceStateService: SavedInstanceStateService
  ) {}

  public async execute(context: ISyncContext): Promise<SyncElementResult> {
    const result: SyncElementResult = {
      contextId: context.id,
      jobDone: false,
      restoredLastInstanceState: false,
      showedSyncIndicator: false,
      isFirstStart: SyncIndicatorOpener._isFirstStart,
    };
    await this._platformSync.ready;

    if (!this._platformSync.canBeSynced) {
      this._appReadyService.dispatchEvent(new AppReadyEvent(true));
      return;
    }

    debug('entered execute function', context);
    if (
      !context.params.isOnline ||
      !context.params.isStartUpSync ||
      context.syncModal ||
      this._networkInfoService.isNotConnected
    ) {
      // usually we would restore the lastInstanceState after the SyncIndicator has enden,
      // as we do not open a SyncIndicator, we have to do it here
      await this.restoreLastInstanceState();
      result.restoredLastInstanceState = true;
      result.jobDone = true;
      return result;
    }

    debug('opening sync indicator');
    await this.initializeSyncIndicator(context).catch(error => {
      window.logger.error('initializing sync-indicator crashed', error);
      result.jobDone = false;
    });
    result.showedSyncIndicator = true;
    result.jobDone = true;
    return result;
  }

  public async initializeSyncIndicator(context: ISyncContext) {
    debug('initializing sync modal', context);
    context.syncModal = await this.modalCtrl.create({
      component: SyncIndicatorPage,
      backdropDismiss: false,
      cssClass: 'syncModal',
    });

    await context.syncModal.present();

    // tslint:disable-next-line: no-floating-promises
    context.syncModal
      .onDidDismiss()
      .then(() => {
        context.syncModal = null;
      })
      .finally(() => this.restoreLastInstanceState());
  }

  private async restoreLastInstanceState() {
    if (SyncIndicatorOpener._isFirstStart) {
      SyncIndicatorOpener._isFirstStart = false;

      try {
        const state = await this._savedInstanceStateService.getInstanceState();

        if (state) {
          const answer = await this._alertService.showYesNoAlert(
            'restoreInstanceState.headline',
            'restoreInstanceState.message',
            {
              yesButtonText: 'restoreInstanceState.ok',
              noButtonText: 'restoreInstanceState.cancel',
            }
          );

          if (answer) {
            await this._router.navigateByUrl(state.url, {
              state: { lastInstanceState: state },
            });
          }

          await this._savedInstanceStateService.clearAllStates();
        }
      } catch (error) {
        window.logger.error('[SyncIndicatorOpener] Failed to restore saved instance state', error);
      }
    }
  }
}
