import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[itl-inputTrimmer]',
})
export class InputTextTrimmerDirective {
  @Input('itl-inputTrimmer') isTrimEnabled = false;
  @Output() ngModelChange = new EventEmitter();

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  @HostListener('focusout', ['$event.target.value'])
  handleInput(value: any): void {
    if (this.isTrimEnabled) {
      const valueToProcess = value.trim();
      this._renderer.setProperty(this._elementRef.nativeElement, 'value', valueToProcess);
      this.ngModelChange.emit(valueToProcess);
    }
  }
}
