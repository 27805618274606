import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { MaintenanceDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { MaintenanceDto } from 'src/app/shared/models/maintenance/maintenance-dto.model';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class MaintenanceWorkItem extends WorkItem<MaintenanceDto> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'MaintenanceWorkItem';
  }
  get database(): string {
    return MaintenanceDB;
  }
}
