import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'itl-listitem',
  templateUrl: './listitem.component.html',
  styleUrls: ['./listitem.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListitemComponent {
  @Output()
  clicked: EventEmitter<null> = new EventEmitter<null>();
  @Input()
  disabled = false;
  @Input()
  noHover = false;
  @Input()
  minHeight = 'unset';
  @Input()
  paddingLeft = 'unset';

  itemClicked() {
    // if (!this.disabled) {
    this.clicked.emit();
    // }
  }
}
