import { NgZone } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { ICancellationToken } from 'src/app/common/contracts/cancellation/cancellation-token';

import { ISyncService } from '../contracts/sync/service/sync-service';
import { IWebSyncService } from '../contracts/sync/service/web-sync-service';

export class SyncTimeoutRepeater {
  private _runCount = 0;

  private _delaySchedule = [
    0,
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 5, // After 5 seconds
    1000 * 10, // After 10 seconds
    1000 * 20, // After 20 seconds
  ];

  constructor(private _syncService: ISyncService | IWebSyncService, private _ngZone: NgZone, private _userSync?: any) {}

  public async sync(token: ICancellationToken, user): Promise<any> {
    let timedOut = false;
    let serviceName;

    do {
      await this.sleep(this._delaySchedule[this._runCount++], token);
      const { timedOut: timedOut1, serviceName: serviceName1 } = await this.run(token, user);
      timedOut = timedOut1;
      serviceName = serviceName || serviceName1;
    } while (timedOut);

    if (serviceName && token.serviceRepeats.hasOwnProperty(serviceName)) {
      delete token.serviceRepeats[serviceName];
    }
  }

  private async run(token: ICancellationToken, user): Promise<any> {
    try {
      await this._syncService.sync(token, user, this.resetServiceRepeats);
      if (this.isSyncService(this._syncService)) {
        await this._syncService.reSync(token, user, this._userSync, this.resetServiceRepeats);
      }
    } catch (error) {
      if (!error.data || !error.data.path) {
        return { timedOut: false, serviceName: error.service };
      }

      token.serviceRepeats[error.data.path] = token.serviceRepeats.hasOwnProperty(error.data.path)
        ? token.serviceRepeats[error.data.path] + 1
        : 0;

      if (
        error.code &&
        (error.code === 408 || error.code === 404) &&
        !token.cancelled.get() &&
        token.serviceRepeats[error.data.path] <= this._delaySchedule.length - 1
      ) {
        return { timedOut: true, serviceName: error.data.path };
      }

      if (token.serviceRepeats.hasOwnProperty(error.data.path)) {
        delete token.serviceRepeats[error.data.path];
        return { timedOut: false, serviceName: error.data.path };
      }
    }

    return { timedOut: false };
  }

  private resetServiceRepeats(token: ICancellationToken, serviceName: string) {
    if (token.serviceRepeats.hasOwnProperty(serviceName)) {
      token.serviceRepeats[serviceName] = 0;
    }
  }

  private isSyncService(service: any): service is ISyncService {
    return service && typeof service.reSync === 'function';
  }

  private sleep(milliseconds: number, token: ICancellationToken) {
    return new Promise<void>(resolve => {
      token.cancelled.data$.pipe(takeWhile(cancelled => !cancelled)).subscribe({ complete: () => resolve() });
      this._ngZone.runOutsideAngular(() => setTimeout(resolve, milliseconds || 0));
    });
  }
}
