import { Injectable } from '@angular/core';
import { AttachmentDatabaseService } from 'src/app/shared/services/attachment/attachment-database.service';
import { Database } from 'src/app/shared/services/attachment/Database';

@Injectable({ providedIn: 'root' })
export class AttachmentFeathersCallbacks {
  constructor(private _attachmentDbProvider: AttachmentDatabaseService) {}

  public async onCreated(data: any): Promise<void> {
    if (!(await this._attachmentDbProvider.get(Database.Attachment, data._id))) {
      await this._attachmentDbProvider.writeAttachmentToStorage(data);
    }
  }

  public async onRemoved(data: any): Promise<void> {
    await this._attachmentDbProvider.remove(Database.Blob, data._id);
    await this._attachmentDbProvider.remove(Database.Attachment, data._id);
  }

  public async onArchived(id: string): Promise<void> {
    return this._attachmentDbProvider.remove(Database.Blob, id);
  }
}
