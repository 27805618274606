import { IServiceTask } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { ServiceTaskWorkItem } from 'src/app/business/serviceTask/service-task-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class ServiceTaskCommandHandler extends EntityCommandHandler<IServiceTask> {
  protected workItem: IWorkItem<IServiceTask>;

  constructor(serviceTaskWorkItem: ServiceTaskWorkItem) {
    super();
    this.workItem = serviceTaskWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'ServiceTaskDto';
  }
}
