import { Pipe, PipeTransform } from '@angular/core';
import { AttachmentTypeService } from '@services/attachment/attachment-type.service';

@Pipe({
  name: 'attachmentTypePipe',
  pure: true,
  standalone: true,
})
export class AttachmentTypePipe implements PipeTransform {
  constructor(private _attachmentTypeService: AttachmentTypeService) {}

  transform(attachmentTypeKey: number | string): Promise<string> {
    return this._attachmentTypeService.getTranslation(attachmentTypeKey);
  }
}
