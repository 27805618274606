import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { assignIn } from 'lodash';
import { ISearchable } from 'src/app/common/contracts/components/searchable';
import { LogLevel } from 'src/app/common/contracts/logging/log-level';
import { IGeneralEntityService, ProviderToken } from 'src/app/shared/services/contracts/general-entity.service';
import { IProviderConfig, ProviderConfigToken } from 'src/app/shared/services/provider-config';

import { MulitAutocompleteDesktopSearchView } from './desktop-search-view/multi-autocomplete-desktop-search-view.component';

@Component({
  selector: 'itl-multiautocomplete',
  templateUrl: 'multi-autocomplete.component.html',
  styleUrls: ['multi-autocomplete.component.scss'],
})
export class MultiAutocompleteComponent implements OnInit {
  @Input()
  public type:
    | 'doctor'
    | 'hospital'
    | 'insuranceContract'
    | 'nursingHome'
    | 'nursingService'
    | 'payer'
    | 'postalCode'
    | 'group'
    | 'region'
    | 'productGroup'
    | 'pharmacy'
    | 'standardCareProposal'
    | 'attributeTemplate';
  @Input()
  public placeholder: string;
  @Input()
  public useLogicalAnd: boolean;
  @Input()
  public selected: string[];
  @Input()
  public desktopResultsAreaHeight: number;
  @Input()
  public restriction: any;
  @Input()
  public control: AbstractControl;
  @Input()
  public showChips: boolean;
  @Input()
  public showReorder: boolean;
  @Input()
  public showAllOnStart = false;
  @Input() disabled = false;
  @Input()
  public customService: IGeneralEntityService;
  @Input() public icon: string;
  @Input() public required: boolean;
  @Input() public title: string;
  @Input() public listTemplate: any;
  @Output()
  public controlChange: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();
  @Output()
  public selectedChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  public itemsProvider: IGeneralEntityService;
  public selectedItems: any[] = [];

  public countOfAllItems: number;

  @ViewChild('ionContent', { static: true }) ionContent;
  @ViewChild('selectedList', { static: true }) selectedList;
  @ViewChild('desktopAutocomplete', { static: true }) desktopAutocomplete: MulitAutocompleteDesktopSearchView;

  constructor(
    @Inject(ProviderToken) private _itemsProviders: IGeneralEntityService[],
    @Inject(ProviderConfigToken) private _providerConfig: IProviderConfig
  ) {}

  public async ngOnInit(): Promise<void> {
    if (this.type && !this.customService) {
      this.itemsProvider = this._itemsProviders.find(item => item.responsibleTypes.some(name => name === this.type));
      if (!this.itemsProvider) {
        window.logger.log(`No provider found for autocomplete of type ${this.type}`, LogLevel.warning);
      }
      if (this.itemsProvider.type) {
        this.itemsProvider = this.itemsProvider.clone();
      }
      this.itemsProvider.type = this.type;
      this.countOfAllItems = this.filterItems(await this.itemsProvider.getAll()).length;
    }
    if (this.customService) {
      this.itemsProvider = this.customService;
      this.countOfAllItems = this.filterItems(await this.itemsProvider.getAll()).length;
    }

    if (this.selected && this.selected.length) {
      const items = [];
      for (const item of this.selected) {
        items.push(await this.itemsProvider.find(item));
      }
      this.selectedItems = this.filterItems(items);
      this.updateView();
    }
    this.desktopAutocomplete.selectedItemsChange.subscribe(() => {
      this.updateView();
      this.updateSelected();
    });
  }

  public async selectAll() {
    this.selectedItems = this.filterItems(await this.itemsProvider.getAll());
    this.updateSelected();
    this.updateView();
  }

  public toSearchTerm(query: string): string {
    return query.split(' ').reduce((term, word) => {
      if (word.trim()) {
        // tslint:disable-next-line:no-parameter-reassignment
        term = `${term}${term.length > 0 ? ' ' : ''}${this.useLogicalAnd ? `+${word}` : word}`;
      }
      return term;
    }, '');
  }

  public reorderItems(event): void {
    if (this.disabled) {
      return;
    }
    const element = this.selectedItems[event.detail.from];
    this.selectedItems.splice(event.detail.from, 1);
    this.selectedItems.splice(event.detail.to, 0, element);
    this.updateSelected();
    event.detail.complete();
  }

  public removeItem(item) {
    if (this.disabled) {
      return;
    }
    if (this.selectedItems.find(value => value._id === item._id)) {
      this.selectedItems.splice(
        this.selectedItems.findIndex(findItem => findItem._id === item._id),
        1
      );
      this.updateView();
      this.updateSelected();
    }
  }

  public filterItems(results: any[]): any[] {
    const filteredItems = [];
    if (results) {
      this.itemsProvider.restriction = this.restriction;
      results.forEach(element => {
        const object = this.assignObjectToDto(element);
        if (this.itemsProvider.isRestricted(object)) {
          return;
        }
        filteredItems.push(object);
      });
    }
    return filteredItems;
  }
  private assignObjectToDto(item: any): ISearchable {
    const config = this._providerConfig[this.type];
    return assignIn(config.dtoFactory(), item);
  }

  private updateView() {
    setTimeout(() => {
      this.ionContent.el.style.height = `${this.selectedList.el.clientHeight + 20}px`;
    });
  }
  private updateSelected() {
    this.selected = [];
    this.selectedItems.forEach(item => this.selected.push(item._id));
    this.selectedChange.emit(this.selected);
    if (this.control) {
      this.control.setValue(this.selected);
      this.control.markAsTouched();
      this.controlChange.emit(this.control);
    }
  }
}
