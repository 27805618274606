import { isEqual } from 'lodash';

import { IChangeTrack } from '../contracts/tracking/change-track';

export class ChangeTrack implements IChangeTrack {
  constructor(value: any, propertyName?: string) {
    this._newValue = value;
    this._oldValue = value == null ? undefined : JSON.parse(JSON.stringify(value));
    this._comparableOldValue = value;
    this._propertyName = propertyName;
  }

  private _propertyName: string;
  private _oldValue: any;
  private _comparableOldValue: any;
  private _newValue: any;

  get isDirty() {
    return !isEqual(this.newValue != null ? JSON.parse(JSON.stringify(this.newValue)) : undefined, this._oldValue);
  }

  get propertyName(): string {
    return this._propertyName;
  }

  get newValue() {
    return this._newValue;
  }

  get oldValue() {
    return this._oldValue;
  }

  setValue(newValue: any, forceChange?: boolean): void {
    if (!forceChange && isEqual(newValue, this.newValue)) {
      return;
    }

    this._oldValue = forceChange ? undefined : this._oldValue;
    this._comparableOldValue = forceChange ? undefined : this._comparableOldValue;
    this._newValue = newValue;
  }
}
