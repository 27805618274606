import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

// TODO: playbook entry
@Component({
  selector: 'itl-warning-modal',
  templateUrl: 'warning-modal-page.html',
  styleUrls: ['warning-modal-page.scss'],
})
export class WarningModalComponent {
  @Input() headerTranslationString: string;
  @Input() messageParagraphTranslationStrings: string[];
  @Input() continueButtonTranslationString: string;
  @Input() cancelButtonTranslationString = 'CANCEL_BUTTON';

  constructor(private _modalController: ModalController) {}

  public async cancel() {
    await this._modalController
      .dismiss({
        stopped: true,
      })
      .catch(error => console.log(error));
  }

  public async continue() {
    await this._modalController
      .dismiss({
        stopped: false,
      })
      .catch(error => console.log(error));
  }
}
