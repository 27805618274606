import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'autocomplete-list-item',
  templateUrl: 'autocomplete-list-item.component.html',
  styleUrls: ['autocomplete-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteListItemComponent {
  @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();
  buttonpressed() {
    this.clicked.emit();
  }
}
