<ion-split-pane contentId="main">
  <ion-menu
    menuId="mainNav"
    class="nav"
    [ngClass]="{ open: isMenuExpanded, hide: !authService.authentication.isAuth }"
    [type]="'overlay'"
    (ionDidOpen)="onOpen()"
    (ionDidClose)="onClose()"
    contentId="main"
  >
    <ion-content>
      <div class="col-wrapper">
        <div class="first-col">
          <div>
            <div class="branch-name" *ngIf="!isMasterBranch">
              {{ branchName }}
            </div>
            <div
              #logo
              *ngIf="!isDuringXmasHolidays"
              [ngClass]="{ 'logo-mdr': isMdrBranch, 'logo-default': !isMdrBranch }"
            ></div>
            <div *ngIf="isDuringXmasHolidays" class="logoXmas"></div>
          </div>
          <div class="menu-items">
            <ion-button
              fill="clear"
              size="large"
              class="nav-button"
              color="light"
              (click)="isMenuExpanded = !isMenuExpanded"
            >
              <ion-icon [name]="!isMenuExpanded ? 'alberta-burger-menu' : 'alberta-burger-close'" class="menu-icon">
              </ion-icon>
            </ion-button>
            <ion-button fill="clear" size="large" class="nav-button" color="light" (click)="openPage('/notifications')">
              <div class="icon-wrapper">
                <div class="count-disturber bold" *ngIf="showNewNotifications">{{ numberOfNewNotifications }}</div>
                <ion-icon name="alberta-notification" class="menu-icon"></ion-icon>
              </div>
            </ion-button>
            <!-- ngIf is used to wait for the permissions -->
            <ion-button
              fill="clear"
              size="large"
              class="nav-button"
              color="light"
              domain="chat"
              (click)="openPage('/agent-chat')"
              *ngIf="userIsAuthenticated && userIsChatAgent && !isMobile"
            >
              <div class="icon-wrapper">
                <div class="count-disturber count-disturber--chat bold" *ngIf="unreadAgentMessages > 0">
                  {{ unreadAgentMessages }}
                </div>
                <itl-alberta-icon name="alberta-support" class="menu-icon"></itl-alberta-icon>
              </div>
            </ion-button>
            <!-- calendar -->
            <ion-button
              itl-authorize
              permission="2"
              domain="activities"
              fill="clear"
              size="large"
              class="nav-button"
              color="light"
              (click)="openPage('/alberta-calendar')"
              *ngIf="showCalendar"
            >
              <div class="icon-wrapper">
                <itl-alberta-icon name="alberta-calendar" class="menu-icon"></itl-alberta-icon>
              </div>
            </ion-button>
            <ion-button
              fill="clear"
              size="large"
              class="nav-button"
              class="nav-help"
              color="light"
              (click)="openPage('/help')"
            >
              <div class="icon-wrapper">
                <ion-icon name="alberta-new" class="disturber" *ngIf="showHelpPageDisturber"> </ion-icon>
                <ion-icon name="alberta-help" class="menu-icon"></ion-icon>
              </div>
            </ion-button>
          </div>
          <div class="menu-footer">
            <ion-button
              [disabled]="isLoggingOut"
              menuClose
              fill="clear"
              size="large"
              class="nav-button"
              class="nav-button"
              color="light"
              (click)="logout()"
            >
              <ion-icon name="alberta-logout" class="menu-icon"></ion-icon>
            </ion-button>
            <div class="version">Version: {{ version }}</div>
          </div>
        </div>

        <div class="second-col">
          <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: menuItems }"></ng-container>
        </div>
      </div>
    </ion-content>
  </ion-menu>

  <ng-template #menuItem let-items>
    <div
      *ngFor="let item of items; let i = index"
      [ngClass]="{
        'active-nav': selectedCategory == -1 || selectedCategory == i
      }"
    >
      <!-- without the children -->
      <ion-button
        *ngIf="!item.children"
        fill="clear"
        menuClose
        strong="true"
        class="nav-button ion-text-capitalize"
        (click)="openPage(item.page)"
      >
        <ion-icon name="{{ item.icon }}" slot="start"></ion-icon>
        <span>{{ item.name }}</span>
      </ion-button>

      <!-- with children -->
      <div *ngIf="item.children">
        <!-- parent -->
        <ion-button
          strong="true"
          fill="clear"
          class="nav-button ion-text-capitalize"
          (click)="this.selectedCategory == i ? (this.selectedCategory = -1) : (this.selectedCategory = i)"
        >
          <ion-icon name="{{ item.icon }}" slot="start"></ion-icon>
          {{ item.name }}
        </ion-button>
        <!-- children -->
        <!-- *ngIf selected -->
        <ion-list [ngClass]="{ dropped: selectedCategory == i }">
          <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: item.children }"></ng-container>
        </ion-list>
      </div>
    </div>
  </ng-template>
  <ion-router-outlet id="main"> </ion-router-outlet>
</ion-split-pane>
