import { Inject, Injectable } from '@angular/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { TrackerService } from '@services/tracker.service';
import hash from 'object-hash';
import randomToken from 'random-token';
import {
  AuthenticationToken,
  IAuthenticationAccount,
} from 'src/app/common/contracts/authentication/authentication-account';
import { environment } from 'src/environments/environment';
import { SecureStorageEcho, SecureStorageEchoObject } from '@awesome-cordova-plugins/secure-storage-echo/ngx';
import { EnvironmentService } from './environment/environment.service';

@Injectable({ providedIn: 'root' })
export class SqlCipherConfigService {
  private _encryptionSettings = environment.encryption.settings;
  private _regex = environment.encryption.regex;
  private _keySalt: string;
  private _secureStorageObject: SecureStorageEchoObject;
  constructor(
    private _platform: Platform,
    private _secureStorage: SecureStorageEcho,
    private _trackerService: TrackerService,
    @Inject(AuthenticationToken) private _authenticationAccount: IAuthenticationAccount,
    private _environmentService: EnvironmentService
  ) {}

  public async mustEncryptStorage(): Promise<boolean> {
    await this._authenticationAccount.ready;

    const settings = this._encryptionSettings.find(setting =>
      setting.emails.some(email => this._authenticationAccount.account.email.includes(email))
    );

    return (
      this._regex.test(this._authenticationAccount.account.email) &&
      settings.platforms.some(platform => this._platform.platforms().includes(platform))
    );
  }

  public async getKey(): Promise<string> {
    if (!this._keySalt) {
      await this.createKeySalt();
    }

    await this._authenticationAccount.ready;

    const branch = this._environmentService.isProductionBranch() ? 'master' : this._environmentService.branch;

    const secureStorageKey = `sqlite_${this._authenticationAccount.account._id.slice(-4)}_${branch}`;

    let key: string = null;

    /*
    The new capacitor secure storage plugin cannot read from the old plugin
    Thats why we need to do a migration here to read the database key from
    the old plugin
    */

    // Step 1: check in new plugin for key. Exception if nothing is found!
    try {
      key = (await SecureStoragePlugin.get({ key: secureStorageKey })).value;
      return key;
    } catch {}

    // Step 2: try to read from old plugin
    this._secureStorageObject = await this._secureStorage.create('alberta');
    const oldKeys = await this._secureStorageObject.keys();
    if (oldKeys && oldKeys.includes(secureStorageKey)) {
      this._trackerService.trackMigrateKeyToCapacitorStart();
      key = await this._secureStorageObject.get(secureStorageKey);
      // save key in new plugin
      await SecureStoragePlugin.set({ key: secureStorageKey, value: key });
      this._trackerService.trackMigrateKeyToCapacitorEnd();
    } else {
      // else we have no key stored. There should be no database and we create new key
      key = randomToken.create(this._keySalt)(64);
      await SecureStoragePlugin.set({ key: secureStorageKey, value: key });
    }
    return key;
  }

  private async createKeySalt(): Promise<void> {
    await this._authenticationAccount.ready;
    // UserId vom angemeldeten Benutzer wird mit zur Generierung des Salts verwendet
    // Ich weiß nicht, wie jetzt irgend jemand den Key dann noch rausfinden möchte?!
    // https://discuss.zetetic.net/t/sqlcipher-database-key-material-and-selection/25

    const uuid = (await Device.getId()).identifier;
    const uuidHashValue = hash(`${uuid}${this._authenticationAccount.account._id}`);

    const set = new Set([
      ...Array.from(uuidHashValue).map((char: string) => char.toLocaleLowerCase()),
      ...Array.from(uuidHashValue).map((char: string) => char.toLocaleUpperCase()),
      // SqlCipher supports all UTF-8 encoded strings/characters
      // https://discuss.zetetic.net/t/technical-guidance-using-random-values-as-sqlcipher-keys/3715
      ...['#', '$', '%', '?', '!', '*', '=', '&', '@', '<', '>', '/', '-', '^', '_'],
    ]);

    this._keySalt = [...set].join('');
  }
}
