import { IQuantityPerUnit } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterNonStringPackingUnits',
  pure: true,
})
export class ArticleFilterNonStringPackingUnitsPipe implements PipeTransform {
  transform(packagingUnits: IQuantityPerUnit[]): IQuantityPerUnit[] {
    return packagingUnits.filter(packagingUnit => typeof packagingUnit.pcn === 'string');
  }
}
