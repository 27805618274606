import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { BaseAuthorizationDirective } from './base-authorization.directive';

@Directive({
  selector: '[itl-authorize]',
})
export class AuthorizationDirective extends BaseAuthorizationDirective implements AfterViewInit {
  constructor(elementRef: ElementRef, renderer: Renderer2, authService: AuthService) {
    super(authService, elementRef, renderer);
  }

  ngAfterViewInit() {
    super.setVisibility();
  }
}
