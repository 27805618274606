export const DEFAULTINSTITUTIONCONTACTFUNCTIONS = [
  {
    key: 'apotheker',
    translation: { de: 'Apotheker / -in' },
  },
  {
    key: 'arzt',
    translation: { de: 'Arzt / Ärztin' },
  },
  {
    key: 'arzthelfer',
    translation: { de: 'Arzthelfer / -in' },
  },
  {
    key: 'casemanager',
    translation: { de: 'Case Manager / -in' },
  },
  {
    key: 'chefarzt',
    translation: { de: 'Chefarzt / -ärztin' },
  },
  {
    key: 'diabetologe',
    translation: { de: 'Diabetologe / -in' },
  },
  {
    key: 'dialysefachschwester',
    translation: { de: 'Dialysefachschwester / Pfleger' },
  },
  {
    key: 'diaetassistent',
    translation: { de: 'Diätassistent / -assistentin' },
  },
  {
    key: 'einrichtungsleitung',
    translation: { de: 'Einrichtungsleitung / Institutionsleitung' },
  },
  {
    key: 'ernaehrungsberater',
    translation: { de: 'Ernährungsberater / -in' },
  },
  {
    key: 'examinierte_pflegefachkraft',
    translation: { de: 'Examinierte Pflegefachkraft' },
  },
  {
    key: 'funktionsoberarzt',
    translation: { de: 'Funktionsoberarzt/- ärztin' },
  },
  {
    key: 'gastroenterologe',
    translation: { de: 'Gastroenterologe/ -in' },
  },
  {
    key: 'geschaeftsfuehrer',
    translation: { de: 'Geschäftsführer / - in' },
  },
  {
    key: 'gynaekologe',
    translation: { de: 'Gynäkologe / -in' },
  },
  {
    key: 'heimleiter',
    translation: { de: 'Heimleiter / -in' },
  },
  {
    key: 'hno_arzt',
    translation: { de: 'HNO Arzt / Ärztin' },
  },
  {
    key: 'hygienebeaufragte',
    translation: { de: 'Hygienebeauftragte/ -r' },
  },
  {
    key: 'intensivpflegekraft',
    translation: { de: 'Intensivpflegekraft' },
  },
  {
    key: 'kardiologe',
    translation: { de: 'Kardiologe / -in' },
  },
  {
    key: 'leitender_oberarzt',
    translation: { de: 'Leitender Oberarzt / -ärztin' },
  },
  {
    key: 'leitung_casemanagement',
    translation: { de: 'Leitung Case Management' },
  },
  {
    key: 'logopaede',
    translation: { de: 'Logopäde / -in' },
  },
  {
    key: 'med_fachangestellte',
    translation: { de: 'Medizinische Fachangestellte' },
  },
  {
    key: 'mta',
    translation: { de: 'MTA' },
  },
  {
    key: 'mtra',
    translation: { de: 'MTRA' },
  },
  {
    key: 'oberarzt',
    translation: { de: 'Oberarzt / ärztin' },
  },
  {
    key: 'oecotrophologe',
    translation: { de: 'Oecotrophologe / -in' },
  },
  {
    key: 'onkologische_fachschwester',
    translation: { de: 'Onkologische Fachschwester / -pfleger' },
  },
  {
    key: 'pain_nurse',
    translation: { de: 'Pain Nurse' },
  },
  {
    key: 'palliativmediziner',
    translation: { de: 'Palliativmediziner/ -in' },
  },
  {
    key: 'palliativpfleger',
    translation: { de: 'Palliativpfleger/ -in' },
  },
  {
    key: 'pflegedienstleitung',
    translation: { de: 'Pflegedienstleitung' },
  },
  {
    key: 'pflegekraft',
    translation: { de: 'Pflegekraft' },
  },
  {
    key: 'pflegeueberleitung',
    translation: { de: 'Pflegeüberleitung / Entlassmanagement' },
  },
  {
    key: 'pharmakologe',
    translation: { de: 'Pharmakologe / -in' },
  },
  {
    key: 'pta',
    translation: { de: 'PTA' },
  },
  {
    key: 'qm_beauftragter',
    translation: { de: 'QM Beauftragter' },
  },
  {
    key: 'qm_einrichtung',
    translation: { de: 'QM Einrichtungen / Kette' },
  },
  {
    key: 'qm_leitung',
    translation: { de: 'QM Leitung' },
  },
  {
    key: 'sachbearbeiter',
    translation: { de: 'Sachbearbeiter /-in' },
  },
  {
    key: 'sozialdienst',
    translation: { de: 'Sozialdienst' },
  },
  {
    key: 'stationsarzt',
    translation: { de: 'Stationsarzt / -ärztin' },
  },
  {
    key: 'stationsleitung',
    translation: { de: 'Stationsleitung' },
  },
  {
    key: 'stellv_pflegedienstleitung',
    translation: { de: 'Stellvertretende Pflegedienstleitung' },
  },
  {
    key: 'stomatherapeut',
    translation: { de: 'Stomatherapeut/ -in' },
  },
  {
    key: 'urologe',
    translation: { de: 'Urologe' },
  },
  {
    key: 'wohnbereichsleitung',
    translation: { de: 'Wohnbereichsleitung' },
  },
  {
    key: 'wundassistent',
    translation: { de: 'Wundassistent/ - in' },
  },
  {
    key: 'wundexperte',
    translation: { de: 'Wundexperte' },
  },
  {
    key: 'zentrales_qm',
    translation: { de: 'Zentrales QM' },
  },
];
