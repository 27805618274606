import { IPharmacy } from '@alberta/konexi-shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { AggregateRoot } from '../aggregate-root';

export class PharmacyDto extends AggregateRoot
  implements IPharmacy, ISearchable {
  public website: string;
  public name: string;
  public address: string;
  public phone: string;
  public fax: string;
  public email: string;
  public city: string;
  public postalCode: string;
  public homepage: string;

  get displayText(): string {
    return this.name;
  }
}
