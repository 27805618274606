import { ISingleOrder } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { SingleOrderDB } from 'src/app/common/repository/databases';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';
import { MessagePayloadSingleOrder } from '../../models/share/message-payload-single-order';

import { IRouteParser } from '../contracts/route/route-parser';
import { QueryService } from '../query/query.service';

@Injectable()
export class SingleOrderRouteParser implements IRouteParser {
  type = MessagePayloadType.SingleOrder;

  constructor(private _queryService: QueryService) {}
  async parse(payload: MessagePayloadSingleOrder): Promise<string> {
    const order: ISingleOrder = await this._queryService.get(payload.singleOrderId, SingleOrderDB);

    if (!order) {
      throw new Error(`No single order found for id ${payload.singleOrderId}`);
    }

    return `/patient/${order.patientId}/integratedCare/${order.integratedCareId}/singleOrder/${order._id}`;
  }
}
