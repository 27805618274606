import { environment } from 'src/environments/environment';

export function trace() {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    // environment.production check during target class instantiation
    // (may be removed if tracing should be toggleable)
    if (environment.production === true) {
      return;
    } else {
      try {
        const originalFn = target[propertyKey];
        descriptor.value = function (...params: any[]) {
          // environment.production check on function call
          if (environment.production === true) {
            return originalFn.call(this, ...params);
          } else {
            const debugInfo = {
              functionName: propertyKey,
              className: this.constructor.name,
              // parameters: params,
            };
            console.log('trace()', debugInfo);
          }
          return originalFn.call(this, ...params);
        };
      } catch (e) {
        console.error('trace()', e);
      }
    }
  };
}
