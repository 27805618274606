import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { InsuranceContractDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { InsuranceContractDto } from 'src/app/shared/models/insurance-contract/insurance-contract-dto.model';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class InsuranceContractWorkItem extends WorkItem<InsuranceContractDto> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'InsuranceContractWorkItem';
  }
  get database(): string {
    return InsuranceContractDB;
  }
}
