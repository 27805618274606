import { NgModule } from '@angular/core';

import { AlbertaTableColumnDirective } from '../components/common/alberta-table/alberta-table-column.directive';
import { AlbertaTableRowTemplateDirective } from '../components/common/alberta-table/alberta-table-row.directive';
import { AuthorizationWithSelectionDirective } from './authorization-selection.directive';
import { AuthorizationDirective } from './authorization.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { FocusNextInputDirective } from './focus-next-input.directive';
import { HideWhenDirective } from './hideWhen.directive';
import { InputTextTrimmerDirective } from './input-trimmer.directive';
import { PhonePrefixDirective } from './phone-prefix.directive';
import { ShowWhenDirective } from './showWhen.directive';
import { RemoveHostDirective } from './remove-host';

@NgModule({
  declarations: [
    AuthorizationDirective,
    AuthorizationWithSelectionDirective,
    FocusNextInputDirective,
    HideWhenDirective,
    ShowWhenDirective,
    PhonePrefixDirective,
    InputTextTrimmerDirective,
    ClickOutsideDirective,
    AlbertaTableRowTemplateDirective,
    AlbertaTableColumnDirective,
    RemoveHostDirective,
  ],
  imports: [],
  exports: [
    AuthorizationDirective,
    AuthorizationWithSelectionDirective,
    FocusNextInputDirective,
    HideWhenDirective,
    ShowWhenDirective,
    InputTextTrimmerDirective,
    PhonePrefixDirective,
    ClickOutsideDirective,
    AlbertaTableRowTemplateDirective,
    AlbertaTableColumnDirective,
    RemoveHostDirective,
  ],
})
export class DirectivesModule {}
