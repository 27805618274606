import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import {
  IntegratedCareReturnDeliveryDto,
} from 'src/app/shared/models/integrated-care-return-delivery/integrated-care-return-delivery-dto.model';

import { WorkItem } from '../work-item';
import { IntegratedCareReturnDeliveryDB } from './../../common/repository/databases';

@Injectable({ providedIn: 'root' })
export class IntegratedCareReturnDeliveryWorkItem extends WorkItem<IntegratedCareReturnDeliveryDto> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'IntegratedCareReturnDeliveryWorkItem';
  }
  get database(): string {
    return IntegratedCareReturnDeliveryDB;
  }
}
