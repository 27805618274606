import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Logger } from 'src/app/common/logging/logger';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';

import { AttachmentDatabaseService } from './attachment-database.service';
import { Database } from './Database';
import { arrayBufferToBlob } from './attachment-helper';
import { AttachmentTransferService } from './attachment-transfer.service';
import { ConnectionStateService } from '@services/connection-state.service';
import { TrackerService } from '@services/tracker.service';

@Injectable({ providedIn: 'root' })
export class AttachmentQueuesProcessorService {
  constructor(
    private _attachmentDbProvider: AttachmentDatabaseService,
    private _attachmentTransferService: AttachmentTransferService,
    private _connectionStateService: ConnectionStateService,
    private _trackerService: TrackerService,
    private _logger: Logger
  ) {}

  public async processQueues(
    deleteFn: (id: string) => Promise<any>,
    uploadFn: (key: string) => Promise<any>,
    updateFn: (attachment: AttachmentDto) => Promise<void>
  ) {
    this._logger.info('process local queues');
    this._trackerService.debugSyncAttachmentQueueProcessorStart();
    const removeCount = await this.processAttachmentToRemoveQueue(deleteFn);
    const saveCount = await this.processAttachmentToSaveQueue();
    const updateCount = await this.processAttachmentToUpdateQueue(updateFn);
    const localAttachmentsCount = await this.processLocalAttachmentsQueue(uploadFn);
    this._trackerService.debugSyncAttachmentQueueProcessorDone({
      removeCount,
      saveCount,
      updateCount,
      localAttachmentsCount,
    });
    this._logger.info('finished processing local queues');
  }

  private async processAttachmentToRemoveQueue(deleteFn: (id: string) => Promise<number>) {
    this._logger.info('starting processing attachment to remove queue');
    await this._attachmentDbProvider.ready(Database.AttachmentToRemove);
    const attachmentsToRemove = await this._attachmentDbProvider.keys(Database.AttachmentToRemove);
    const count = attachmentsToRemove.length;
    while (this._connectionStateService.isConnected && attachmentsToRemove.length) {
      this._logger.info(`While loop in processAttachmentToRemoveQueue entered`);
      const id = attachmentsToRemove.pop();
      this._logger.info(`While loop in processAttachmentToRemoveQueue popped id: ${id}`);
      try {
        await this._attachmentDbProvider.remove(Database.AttachmentToRemove, id);
        if (id != null) {
          await deleteFn(id);
        }
      } catch (err) {
        this._trackerService.debugSyncAttachmentQueueProcessorError('toRemove', id);
        this._logger.info(`While loop in processAttachmentToRemoveQueue caught error: ${err}`);
        await this._attachmentDbProvider.set(Database.AttachmentToRemove, id, null);
        throw err;
      }
    }
    this._logger.info(`While loop in processAttachmentToRemoveQueue finished`);
    return count;
  }

  private async processAttachmentToSaveQueue(): Promise<number> {
    this._logger.info('starting processing attachment to save queue');
    await this._attachmentDbProvider.ready(Database.AttachmentToSave);
    const attachmentsToSave = await this._attachmentDbProvider.keys(Database.AttachmentToSave);
    const count = attachmentsToSave.length;
    while (this._connectionStateService.isConnected && attachmentsToSave.length) {
      this._logger.info(`While loop in processAttachmentToSaveQueue entered`);
      const localId = attachmentsToSave.pop();
      this._logger.info(`While loop in processAttachmentToSaveQueue popped localId: ${localId}`);
      const attachment = await this._attachmentDbProvider.get(Database.AttachmentToSave, localId);
      await this._attachmentDbProvider.remove(Database.AttachmentToSave, localId);
      if (attachment != null && attachment.data) {
        const attachmentWithBlob = cloneDeep(attachment);
        attachmentWithBlob.data.blob = arrayBufferToBlob(attachmentWithBlob.data.blob, attachmentWithBlob.data.mime);
        const uploadResult = await this._attachmentTransferService.upload(
          attachmentWithBlob.data,
          attachmentWithBlob.localId,
          this._connectionStateService.isConnected,
          true
        );
        if (uploadResult.error && uploadResult.needRetry) {
          this._trackerService.debugSyncAttachmentQueueProcessorError('toSave', localId);
          await this._attachmentDbProvider.set(Database.AttachmentToSave, localId, attachment);
        }
      }
    }
    this._logger.info(`While loop in processAttachmentToSaveQueue finished`);
    return count;
  }

  private async processAttachmentToUpdateQueue(
    updateFn: (attachmentDto: AttachmentDto) => Promise<void>
  ): Promise<number> {
    this._logger.info('starting processing attachment to update queue');
    await this._attachmentDbProvider.ready(Database.AttachmentToUpdate);
    const attachmentsToUpdate = await this._attachmentDbProvider.keys(Database.AttachmentToUpdate);
    const count = attachmentsToUpdate.length;
    while (this._connectionStateService.isConnected && attachmentsToUpdate.length) {
      this._logger.info(`While loop in processAttachmentToUpdateQueue entered`);
      const attachmentId = attachmentsToUpdate.pop();
      this._logger.info(`While loop in processAttachmentToUpdateQueue popped attachmentId: ${attachmentId}`);
      const attachment = await this._attachmentDbProvider.get(Database.AttachmentToUpdate, attachmentId);
      if (attachment && attachment._id) {
        try {
          await updateFn(attachment);
        } catch (err) {
          this._trackerService.debugSyncAttachmentQueueProcessorError('toUpdate', attachment._id);
          this._logger.info(`While loop in processAttachmentToUpdateQueue caught error: ${err}`);
          await this._attachmentDbProvider.set(Database.AttachmentToUpdate, attachment._id, attachment);
          throw err;
        }
      }
    }
    return count;
  }

  private async processLocalAttachmentsQueue(uploadFn: (key: string) => Promise<void>): Promise<number> {
    this._logger.info('starting processing local attachments queue');
    const localAttachmentKeys = await this._attachmentDbProvider.getLocalAttachments();
    const count = localAttachmentKeys.length;
    if (this._connectionStateService.isConnected && localAttachmentKeys.length > 0) {
      for (const localAttachmentKey of localAttachmentKeys) {
        this._logger.info(`For loop in processLocalAttachmentsQueue localAttachmentKey: ${localAttachmentKey}`);
        await uploadFn(localAttachmentKey);
      }
    }
    this._logger.info(`For loop in processLocalAttachmentsQueue finished`);
    return count;
  }
}
