import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'booleanTrueFalse',
  pure: true,
})
export class BooleanYeTureFalsePipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(value: boolean): string {
    if (value === true) {
      return 'wahr';
    }
    if (value === false) {
      return 'falsch';
    }
    return 'unbekannt';
  }
}
