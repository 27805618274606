import { ProductGroupType } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productGroupTypePipe',
  pure: true,
})
export class ProductGroupTypePipe implements PipeTransform {
  constructor() {}
  transform(item: ProductGroupType): string {
    let prouctGroup = '';
    switch (item) {
      case ProductGroupType.OWG:
        prouctGroup = 'Oberwarengruppe';
        break;
      case ProductGroupType.WG:
        prouctGroup = 'Warengruppe';
        break;
      case ProductGroupType.TWG:
        prouctGroup = 'Therapiewarengruppe';
    }
    return prouctGroup;
  }
}
