import { IInstitutionNote } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';
import { InstitutionNoteWorkItem } from 'src/app/business/institution/institution-note-work-item';

@Injectable()
export class InstitutionNoteCommandHandler extends EntityCommandHandler<IInstitutionNote> {
  protected workItem: IWorkItem<IInstitutionNote>;

  constructor(institutionNoteWorkItem: InstitutionNoteWorkItem) {
    super();
    this.workItem = institutionNoteWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'InstitutionNoteDto';
  }
}
