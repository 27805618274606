import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from '@common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { ServiceOrderDto } from 'src/app/shared/models/service-order-entity/service-order-dto.model';

import { ServiceOrderDB } from '../../common/repository/databases';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class ServiceOrderWorkItem extends WorkItem<ServiceOrderDto> {
  unitOfWork: IUnitOfWork;
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }

  get name(): string {
    return 'ServiceOrderWorkItem';
  }
  get database(): string {
    return ServiceOrderDB;
  }
}
