import { IServiceCostEstimate } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { IAppController } from '@common/contracts/controller/app-controller';
import { IDispatcher } from '@common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from '@common/contracts/sync/platform-sync';
import { AppController } from '@common/controller/app-controller';
import { Dispatcher } from '@common/dispatch/dispatcher';
import { EventService } from '@services/event.service';
import { ServiceCostEstimateChannel } from 'src/app/business/service-cost-estimate/contracts/service-cost-estimate.channel';
import { ServiceCostEstimateModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { SyncService } from './sync.service';

@Injectable()
export class ServiceCostEstimateSyncService extends SyncService<IServiceCostEstimate> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IServiceCostEstimate>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(ServiceCostEstimateModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }
  public canSync(channel: string): boolean {
    return (
      channel === ServiceCostEstimateChannel.CREATE ||
      channel === ServiceCostEstimateChannel.EDIT ||
      channel === ServiceCostEstimateChannel.DELETE
    );
  }
}
