import { Inject, Injectable } from '@angular/core';
import { SQLite, SQLiteDatabaseConfig, SQLiteObject } from '@ionic-enterprise/secure-storage/ngx';
import { Platform } from '@ionic/angular';
import { TrackerService } from '@services/tracker.service';
import { SqlCipherConfigService } from 'src/app/shared/services/sql-cipher-config.service';

import { Logger } from '@common/logging/logger';
import { AuthService } from '@services/auth.service';
import DB_PLUGINS, { PluginsToken } from '../repository/index-plugins';
import { IndexField } from './index-field';
import { SqliteStorage } from './sqlite-storage';

@Injectable({ providedIn: 'root' })
export class SqliteStorageFactory {
  private _dbPromise: Promise<SQLiteObject>;
  private _deserialize;
  private _serialize;

  constructor(
    private _sqlite: SQLite,
    @Inject(PluginsToken) private _plugins: typeof DB_PLUGINS,
    private _sqlCipherConfig: SqlCipherConfigService,
    private _platform: Platform,
    private _trackerService: TrackerService,
    private _logger: Logger,
    private _authService: AuthService
  ) {}

  public async configure(
    databaseName: string,
    serialize = item => JSON.stringify(item),
    deserialize = item => JSON.parse(item)
  ): Promise<void> {
    this._serialize = serialize;
    this._deserialize = deserialize;

    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return;
    }

    this._dbPromise = new Promise(async (resolve, reject) => {
      const mustEncryptStorage = await this._sqlCipherConfig.mustEncryptStorage();
      let key: string;
      if (mustEncryptStorage) {
        key = await this._sqlCipherConfig.getKey();
      }
      try {
        const createdDb = await this.createDatabase(databaseName, key);
        resolve(createdDb);
      } catch (error) {
        const userId = this._authService.getCurrentUserId();
        this._trackerService.trackDatabaseOpenError(userId);
        this._logger.error(
          `User ${userId} Could not open database - dbName: ${databaseName}, mustEncrypt: ${mustEncryptStorage}, key: ${key ? 'exists' : 'falsy'}, error: ${error.message}`,
          error
        );
        reject(error);
      }
    });
  }

  private async createDatabase(databaseName: string, key: string = null) {
    let db: SQLiteObject;
    const sqliteDatabaseConfig = this.getSqliteDatabaseConfig(databaseName, key);
    db = await this._sqlite.create(sqliteDatabaseConfig);
    return db;
  }

  private getSqliteDatabaseConfig(databaseName: string, key: string = null): SQLiteDatabaseConfig {
    const config: SQLiteDatabaseConfig = {
      name: databaseName,
    };

    config.location = 'default';
    if (key) {
      config.key = key;
    }
    return config;
  }

  public async create(storageName: string, isDeletable = true, canRepairIndex = true): Promise<SqliteStorage> {
    const table = storageName.substring(0, storageName.length - 3);

    const db = await this._dbPromise;

    const sqlStatement = `CREATE TABLE IF NOT EXISTS [${table}] (id INTEGER PRIMARY KEY, key unique, value)`;
    await db.executeSql(sqlStatement, []);
    const indexStatement = `CREATE UNIQUE INDEX IF NOT EXISTS idx_${table}_key ON [${table}] (key);`;
    await db.executeSql(indexStatement, []);

    const plugin = this._plugins[storageName];
    const fields = plugin
      ? plugin.fields.map(field => (typeof field === 'string' ? new IndexField(field) : new IndexField(field.name)))
      : [];
    const fieldNames = fields.map(field => field.name).join(', ');
    if (fields.length > 0) {
      const searchTableStatement = `CREATE VIRTUAL TABLE IF NOT EXISTS ${table}_fts USING fts4(id, ${fieldNames})`;
      await db.executeSql(searchTableStatement, []);
    }
    return new SqliteStorage(
      this._dbPromise,
      table,
      fields,
      this._serialize,
      this._deserialize,
      isDeletable,
      canRepairIndex
    );
  }
}
