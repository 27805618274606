import { IModel } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';

import { IDispatchFilter } from '../contracts/dispatch/dispatch-filter';

@Injectable({ providedIn: 'root' })
export class DispatchFilterService {
  private _dispatchFilters: IDispatchFilter[] = [];

  public register(dispatchFilter: IDispatchFilter) {
    this._dispatchFilters.push(dispatchFilter);
  }

  public filter(payload: IModel[], modelName: string): IModel[] {
    const filteredPayload = [...payload];
    const dispatchFilter = this._dispatchFilters.find(filter => filter.modelName === modelName);

    return dispatchFilter ? dispatchFilter.filter(filteredPayload) : filteredPayload;
  }
}
