import { Injectable } from '@angular/core';
import { ICancellationToken } from 'src/app/common/contracts/cancellation/cancellation-token';
import { ISyncState } from 'src/app/common/contracts/sync/sync-state';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';
import { Changes } from 'src/app/common/tracking';
import { RegionDto } from 'src/app/shared/models/user-management/region-dto.model';
import { AuthorizationResolver } from 'src/app/shared/services/authorization-resolver.service';
import { IDatabaseSynchronizer } from 'src/app/shared/services/contracts/sync/database-synchronizer_T';

import { WorkItem } from '../work-item';
import { RegionWorkItem as DecoratedRegionWorkItem } from './region-work-item';

@Injectable({ providedIn: 'root' })
export class RegionWorkItem extends WorkItem<RegionDto>
  implements IWorkItem<RegionDto>, IDatabaseSynchronizer<RegionDto> {
  constructor(private _regionWorkItem: DecoratedRegionWorkItem, private _authorizationResolver: AuthorizationResolver) {
    super(null);
  }
  get name(): string {
    return 'RegionWorkItem';
  }
  getAll(): Promise<RegionDto[]> {
    return this._regionWorkItem.getAll();
  }

  async sync(state: {}, token: ICancellationToken): Promise<ISyncState<RegionDto>> {
    const result = await this._regionWorkItem.sync(state, token);

    return result;
  }

  get database(): string {
    return this._regionWorkItem.database;
  }

  public async beforeUpdate(item: RegionDto, changes: Changes): Promise<RegionDto> {
    return this._regionWorkItem.beforeUpdate(item, changes);
  }

  async create(model: RegionDto): Promise<void> {
    await this._regionWorkItem.create(model);
    await this._authorizationResolver.resolveRegion();

    return;
  }

  update(changes: Changes, regionDto: RegionDto): Promise<void> {
    return this._regionWorkItem.update(changes, regionDto);
  }

  async delete(model: RegionDto): Promise<void> {
    await this._regionWorkItem.delete(model);
    await this._authorizationResolver.resolveRegion();

    return;
  }
}
