import { ICareProposal } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { BaseStateExtension } from '@common/state/state-extension';
import { CareProposalModelName } from 'src/app/shared/models/model-names';

export const PATIENT_LIST_CARE_PROPOSAL_STATE_NAME = 'PatientListCareProposal';
@Injectable({ providedIn: 'root' })
export class PatientListCareProposalStateExtension extends BaseStateExtension<ICareProposal> {
  name: string = CareProposalModelName;

  private _patientsToObserve: string[] = [];
  public set patientsToObserve(ids: string[]) {
    this._patientsToObserve = ids;
  }

  public async canBeStored(item: ICareProposal): Promise<boolean> {
    return this._patientsToObserve.includes(item.patientId);
  }
  protected async afterCreateInternal(item: ICareProposal) {
    await this.stateRegistry.createBySync(CareProposalModelName, PATIENT_LIST_CARE_PROPOSAL_STATE_NAME, item);
  }
  protected async afterUpdateInternal(item: ICareProposal) {
    await this.stateRegistry.updateBySync(CareProposalModelName, PATIENT_LIST_CARE_PROPOSAL_STATE_NAME, [item]);
  }
  protected async removeFromState(item: ICareProposal) {
    await this.stateRegistry.removeBySync(CareProposalModelName, PATIENT_LIST_CARE_PROPOSAL_STATE_NAME, [item]);
  }
}
