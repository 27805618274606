import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'number2MonthPipe',
  pure: true,
})
export class Number2MonthPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(item: number): string {
    return this._translate.instant(`months.${item}`);
  }
}
