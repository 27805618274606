import { Injectable } from '@angular/core';
import { SavedInstanceStateDB } from 'src/app/common/repository/databases';
import { DatabaseService } from '../database.service';
import { IGenericStorage } from '../contracts/database/generic-storage';
import { Deferred } from 'src/app/common/deferred/deferred';
import { ILastInstanceState } from './saved-instance-state';
import { Platform } from '@ionic/angular';
import { map } from '@common/mapper/mapper';

/**
 * MOBILE ONLY (cordova|capacitor)
 *
 * This service is used to save the last state of the app.
 * As mobile apps could be terminated by the system at any time, or crash due to unexpected errors,
 * we need to save the last kown state of the app.
 * When restarting, this last kown state is loaded and restored, resulting in less data loss.
 */
@Injectable({ providedIn: 'root' })
export class SavedInstanceStateService {
  private readonly TAG = 'lastState';
  private _ready = new Deferred<void>();
  private _database: Partial<IGenericStorage>;
  private _isMobile = false;

  constructor(private _platform: Platform, private _dbService: DatabaseService) {
    if (this._platform.is('hybrid')) {
      this._isMobile = true;

      this._dbService
        .getDatabase(SavedInstanceStateDB)
        .then(db => {
          this._database = db;
          this._ready.resolve();
        })
        .catch(err => window.logger.error('[SavedInstanceStateService] failed to get database', err));
    } else {
      this._ready.resolve();
    }
  }

  /**
   * Returns the last known state of the app or null.
   */
  public async getInstanceState(): Promise<ILastInstanceState> {
    if (this._isMobile) {
      await this._ready.promise;

      const state: ILastInstanceState = await this._database.get(this.TAG);

      if (state) {
        return {
          url: state.url,
          payload: JSON.parse(state.payload),
          source: state.source,
          timestamp: state.timestamp,
        };
      }
    }

    return null;
  }

  /**
   * Save state as last known state.
   *
   * @param url last known url
   * @param payload data to be recovered
   * @param source (optional) source/wizard of the state
   */
  public async setInstanceState(url: string, payload: any, source?: string): Promise<void> {
    // as dashoard is the the default page, we must enforce the navigator
    // to execute a route evaluation. Otherwise no routing would occur and the state never be passed.
    if (url.startsWith('/dashboard')) {
      url = null;
    }

    if (this._isMobile) {
      await this._ready.promise;

      // "map" will remove all changeTracker references
      // those entries would cause errors when restoring the payload
      const serializedPayload = JSON.stringify(map(payload, {}));

      // internally this will INSERT OR REPLACE the current state
      // thus we do not need to clear the state before
      await this._database.set(this.TAG, {
        url,
        payload: serializedPayload,
        source,
        timestamp: new Date().toISOString(),
      });
    }
  }

  /**
   * Remove all saved states.
   */
  public async clearAllStates(): Promise<void> {
    if (this._isMobile) {
      await this._ready.promise;
      await this._database.remove(this.TAG);
    }
  }
}
