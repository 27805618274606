import { Inject, Injectable } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { AuthenticationToken, IAuthenticationAccount } from 'src/app/common/contracts/authentication/authentication-account';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { IntegratedCareDB } from 'src/app/common/repository/databases';
import { Changes } from 'src/app/common/tracking';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { IntegratedCareDto } from 'src/app/shared/models/integrated-care/integrated-care-dto.model';
import { IntegratedCareHistoryService } from 'src/app/shared/services/history/integrated-care-history.service';
import { JSONDiffService } from 'src/app/shared/services/history/json-diff.service';
import { QueryService } from 'src/app/shared/services/query/query.service';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class IntegratedCareWorkItem extends WorkItem<IntegratedCareDto> {
  constructor(
    @Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory,
    @Inject(AuthenticationToken) private _authenticationAccount: IAuthenticationAccount,
    private _queryService: QueryService,
    private _jsonDiffService: JSONDiffService,
    private _integratedCareHistoryService: IntegratedCareHistoryService
  ) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'IntegratedCareWorkItem';
  }
  get database(): string {
    return IntegratedCareDB;
  }

  get chunkSize(): number {
    return 250;
  }

  public async beforeUpdate(integratedCare: IntegratedCareDto, changes: Changes): Promise<IntegratedCareDto> {
    const currentIntegratedCare = await this._queryService.get<IntegratedCareDto>(integratedCare._id, IntegratedCareDB);
    if (currentIntegratedCare) {
      const diffs = this._jsonDiffService.getFlatDiffs(currentIntegratedCare, integratedCare, {
        proposedArticleLines: '_id',
      });
      if (diffs) {
        // tslint:disable-next-line:max-line-length
        const userName = `${this._authenticationAccount.account.firstName} ${this._authenticationAccount.account.lastName}`;
        const userId = this._authenticationAccount.account._id;
        integratedCare = await this._integratedCareHistoryService.writeHistoryEntries(
          integratedCare,
          diffs,
          {
            userName,
            userId,
          },
          currentIntegratedCare
        );
      }
    }
    return integratedCare;
  }

  public async beforeCreate(integratedCare: IntegratedCareDto): Promise<IntegratedCareDto> {
    if (!integratedCare || !integratedCare.proposedArticleLines) {
      return integratedCare;
    }
    integratedCare.history = integratedCare.history || [];
    const userName = `${this._authenticationAccount.account.firstName} ${this._authenticationAccount.account.lastName}`;
    const userId = this._authenticationAccount.account._id;
    for (const proposedArticleLine of integratedCare.proposedArticleLines) {
      this._integratedCareHistoryService.createInitialArticleHistoryLine(integratedCare, proposedArticleLine, {
        userName,
        userId,
      });
    }
    return integratedCare;
  }

  protected async afterUpdate(item: IntegratedCareDto, model: IntegratedCareDto): Promise<IntegratedCareDto> {
    model.history = item.history;
    return item;
  }
}
