import { ICalendarResource } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { CalendarResourceWorkItem } from 'src/app/business/calendar-resource/calendar-resource-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class CalendarResourceCommandHandler extends EntityCommandHandler<ICalendarResource> {
  protected workItem: IWorkItem<ICalendarResource>;
  constructor(calendarResourceWorkItem: CalendarResourceWorkItem) {
    super();
    this.workItem = calendarResourceWorkItem;
  }
  canHandle(command: Command): boolean {
    return command.dtoName === 'CalendarResourceDto';
  }
}
