import { IAttachmentType } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { IWorkItem } from '@common/contracts/work-item/work-item_T';
import { AttachmentTypeWorkItem } from 'src/app/business/attachmentType/attachment-type-work-item';
import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class AttachmentTypeCommandHandler extends EntityCommandHandler<IAttachmentType> {
  protected workItem: IWorkItem<IAttachmentType>;

  constructor(attachmentWorkItem: AttachmentTypeWorkItem) {
    super();
    this.workItem = attachmentWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'AttachmentTypeDto';
  }
}
