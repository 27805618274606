import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AttachmentDB } from 'src/app/common/repository/databases';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';
import { MessagePayloadAttachment } from 'src/app/shared/models/share/message-payload-attachment';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

import { IRouteParser } from '../contracts/route/route-parser';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { FeathersService } from '../feathers.service';
import { QueryService } from '../query/query.service';

@Injectable()
export class AttachmentRouteParser implements IRouteParser {
  type = MessagePayloadType.Attachment;

  constructor(
    @Inject(FeathersService) private _feathersAppProvider: IFeathersAppProvider,
    private _queryService: QueryService,
    private _platform: Platform
  ) {}
  async parse(payload: MessagePayloadAttachment): Promise<string> {
    let attachment: AttachmentDto = await this._queryService.get(payload.attachmentId, AttachmentDB);
    if (!attachment) {
      // attachments could not be loaded into indexeddb in browser, go to server
      if (!this._platform.is('hybrid')) {
        const feathersAttachmentService = this._feathersAppProvider.app.service('attachment');
        try {
          const result = await feathersAttachmentService.find({
            query: {
              _id: { $in: [payload.attachmentId] },
            },
          });
          if (result?.data?.length) {
            attachment = result.data[0];
          }
        } catch (error) {
          window.logger.error(`Failed to load attachment ${payload.attachmentId} from server, result empty`, error);
        }
      }
    }
    if (!attachment) {
      throw new Error(`No attachment found for id ${payload.attachmentId}`);
    }

    return `/patient/${attachment.metadata.patientId}/attachments/detail/${attachment._id}`;
  }
}
