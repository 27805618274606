import { InventoryType } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'articleInventoryTypePipe',
  pure: true,
})
export class ArticleInventoryTypePipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}

  transform(item: number): string {
    let inventoryType = '';
    switch (item) {
      case InventoryType.Unknown:
        inventoryType = this._translate.instant('article.edit.inventoryType.unknown');
        break;
      case InventoryType.Store:
        inventoryType = this._translate.instant('article.edit.inventoryType.store');
        break;
      case InventoryType.Order:
        inventoryType = this._translate.instant('article.edit.inventoryType.order');
        break;
      default:
        inventoryType = this._translate.instant('article.edit.inventoryType.unknown');
        break;
    }
    return inventoryType;
  }
}
