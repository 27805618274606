export const UnknownModelName = 'unknown';
export const PatientModelName = 'patient';
export const OrderModelName = 'order';
export const SingleOrderModelName = 'singleOrder';
export const AuditModelName = 'audit';
export const TemplateModelName = 'template';
export const MatchModelName = 'match';
export const ReportModelName = 'report';
export const NursingHomeModelName = 'nursingHome';
export const NursingServiceModelName = 'nursingService';
export const HospitalModelName = 'hospital';
export const DoctorModelName = 'doctor';
export const SubunitModelName = 'subunit';
export const InstitutionModelName = 'institution';
export const InstitutionNoteModelName = 'institutionNote';
export const InstitutionAttributeModelName = 'institutionAttribute';
export const InstitutionAuditModelName = 'institutionAudit';
export const InstitutionContactModelName = 'institutionContact';
export const PayerModelName = 'payer';
export const PayerInfoModelName = 'payerInfo';
export const UsersModelName = 'users';
export const GroupModelName = 'group';
export const RegionModelName = 'region';
export const RightsetModelName = 'rightset';
export const ProductGroupModelName = 'productGroup';
export const PostalCodeModelName = 'postalCode';
export const ArticleModelName = 'article';
export const StandardCareProposalModelName = 'standardCareProposal';
export const CareProposalModelName = 'careProposal';
export const IntegratedCareModelName = 'integratedCare';
export const InsuranceContractModelName = 'insuranceContract';
export const PharmacyModelName = 'pharmacy';
export const PrescriptionModelName = 'prescription';
export const SyncTimestampModelName = 'synctimestamp';
export const AttachmentModelName = 'attachment';
export const AttachmentTypeModelName = 'attachmentType';
export const PatientNoteModelName = 'patientNote';
export const FaxModelName = 'fax';
export const ErpOrderModelName = 'erpOrder';
export const DeviceModelName = 'device';
export const LoggingModelName = 'logging';
export const AttributeTemplateModelName = 'attributeTemplate';
export const MaintenanceModelName = 'maintenance';
export const ReturnDeliveryModelName = 'returnDelivery';
export const ConfigModelName = 'config';
export const AppointmentModelName = 'appointment';
export const ErpTaskModelName = 'erpTask';
export const PatientAppUserModelName = 'patientAppUser';
export const DirectOrderModelName = 'directOrder';
export const ContractArticleGroupName = 'contractArticleGroup';
export const ReminderModelName = 'reminder';
export const PlannedOrderModelName = 'plannedOrder';
export const QuoteModelName = 'quote';
export const IntegratedCareReturnDeliveryModelName = 'integratedCareReturnDelivery';
export const PrescriptionRequestModelName = 'prescriptionRequest';
export const MedicalOrderRequestModelName = 'medicalOrderRequest';
export const PortalNotificationModelName = 'portalNotification';
export const ServiceModelName = 'service';
export const ServiceOrderModelName = 'serviceOrder';
export const ServiceTaskModelName = 'serviceTask';
export const CalendarResourceModelName = 'calendarResource';
export const ServiceCostEstimateModelName = 'serviceCostEstimate';
// [schematics model-name marker create-entity]
