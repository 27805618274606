import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { ArticleDto } from 'src/app/shared/models/article/article-dto.model';

import { ArticleDB } from '../../common/repository/databases';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class ArticleWorkItem extends WorkItem<ArticleDto> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
    this._deletable = false;
  }

  get name(): string {
    return 'ArticleWorkItem';
  }
  get database(): string {
    return ArticleDB;
  }
}
