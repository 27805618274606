import { Inject, Injectable, NgZone } from '@angular/core';
import makeDebug from 'src/makeDebug';
import {
  ConnectionState,
  Conversation,
  ConversationUpdateReason,
  ConversationUpdatedEventArgs,
  Client as TwilioClient,
  Participant,
  Message,
} from '@twilio/conversations';
import { ChatConnectionStateService } from '../chat-connection-state.service';
import { ChatEventService } from '../chat-event.service';
import { Chat } from '../model/chat-instance';
import { TWILIO_AGENT_TOKEN } from './agent-twilio.factory';
import { TwilioToChatDtoHelpersService } from './twilio-to-chat-dto-helpers';
import { Platform } from '@ionic/angular';

const debug = makeDebug('services:chat:twilioSync');

@Injectable({ providedIn: 'root' })
export class TwilioChatEventSourceService {
  constructor(
    @Inject(TWILIO_AGENT_TOKEN) private readonly _twilioAgentChatClient: Promise<TwilioClient>,
    private readonly _twilioToChatDtoHelpers: TwilioToChatDtoHelpersService,
    private readonly _chatEventService: ChatEventService,
    private readonly _chatConnectionStateService: ChatConnectionStateService,
    private readonly _ngZone: NgZone,
    private readonly _platform: Platform
  ) {
    if (!this._platform.is('cordova')) {
      // Agent Chat only available in web
      this.initialize();
    }
  }

  private initialize() {
    debug('init');
    this._ngZone.runOutsideAngular(() => {
      const timeout = setTimeout(async () => {
        clearTimeout(timeout);
        await this.initializeEvents(this._twilioAgentChatClient, Chat.PatientApp);
      });
    });
  }

  private async initializeEvents(twilioClientPromise: Promise<TwilioClient>, chate = Chat.PatientApp) {
    const twilioClient = await twilioClientPromise;

    this.setupChannelEvents(twilioClient, chate);
    this.setupMessageEvents(twilioClient, chate);
    this.setupTokenEvents(twilioClient, chate);
    this.setupConnectionStateEvents(twilioClient, chate);
  }

  private setupChannelEvents(twilioClient: TwilioClient, chat = Chat.PatientApp) {
    if (twilioClient) {
      debug('setup channel events');
      twilioClient.on('conversationAdded', async conversation => await this.addConversation(conversation, chat));
      twilioClient.on(
        'conversationUpdated',
        async ({ conversation, updateReasons }: ConversationUpdatedEventArgs) =>
          await this.updateConversation(conversation, updateReasons, chat)
      );
      twilioClient.on(
        'conversationRemoved',
        async (conversation: Conversation) => await this.removeConversation(conversation)
      );
    }
  }

  private setupMessageEvents(twilioClient: TwilioClient, chat = Chat.PatientApp) {
    if (twilioClient) {
      debug('setup message events');
      twilioClient.on('messageAdded', async (message: Message) => await this.addMessage(message, chat));
      twilioClient.on('messageRemoved', async (message: Message) => await this.removeMessage(message));
      twilioClient.on(
        'participantJoined',
        async (member: Participant) => await this.addParticipantToConversation(member)
      );
      twilioClient.on(
        'participantLeft',
        async (member: Participant) => await this.removeParticipantFromConversation(member)
      );
    }
  }

  private setupTokenEvents(twilioClient: TwilioClient, chat = Chat.PatientApp) {
    if (twilioClient) {
      debug('setup token events');
      twilioClient.on('tokenAboutToExpire', async () => await this.reactToTokenAboutToExpire(chat));
      twilioClient.on('tokenExpired', async () => await this.reactToTokenExpired(chat));
    }
  }

  private async addConversation(conversation: Conversation, chat = Chat.PatientApp): Promise<void> {
    debug('twilio: conversation added', conversation);
    const chatConversation = this._twilioToChatDtoHelpers.convertToChatChannel(conversation);
    chatConversation.isAgent = chat === Chat.PatientApp ? true : undefined;
    await this._chatEventService.addChatChannel(chatConversation);

    try {
      /*
      Conversations created by alberta, will emit this event before the conversation is joined.
      Thus conversation.getParticipants() would cause twilio to crash.
      Also, participants will already be added to the conversation within the TwilioChatSendService.
      */
      if (conversation.status === 'joined') {
        const participants = await conversation.getParticipants();
        const chatMembers = participants.map(member => this._twilioToChatDtoHelpers.convertToChatMember(member));
        await this._chatEventService.updateChannelMembers(conversation.sid, chatMembers);
      }
    } catch (error) {
      window.logger.error('[TwilioChatEventSourceService]: Failed to add conversation', error);
    }
  }

  private async updateConversation(
    conversation: Conversation,
    updateReasons: ConversationUpdateReason[],
    chat = Chat.PatientApp
  ) {
    const chatChannel = this._twilioToChatDtoHelpers.convertToChatChannel(
      conversation,
      chat === Chat.PatientApp ? updateReasons.join('#') : undefined
    );
    chatChannel.isAgent = chat === Chat.PatientApp ? true : undefined;

    return this._chatEventService.updateChatChannel(chatChannel);
  }

  private async removeConversation(conversation: Conversation) {
    debug('twilio: conversation removed', conversation);
    return this._chatEventService.removeChatChannelById(conversation.sid);
  }

  private async addParticipantToConversation(participant: Participant) {
    debug('twilio: participant joined', participant);
    const conversationParticipant = this._twilioToChatDtoHelpers.convertToChatMember(participant);
    return this._chatEventService.addMemberToChannel(conversationParticipant);
  }

  private async removeParticipantFromConversation(participant: Participant) {
    debug('twilio: participant left', participant);
    return this._chatEventService.removeMemberFromChannel(participant.conversation.sid, participant.sid);
  }

  private async addMessage(message: Message, chat = Chat.PatientApp) {
    debug('twilio: message added', message);
    const chatMessage = await this._twilioToChatDtoHelpers.convertToChatMessage(message);
    const attributes = chatMessage.attributes || {};
    chatMessage.attributes = chat === Chat.PatientApp ? { ...attributes, isAgent: true } : chatMessage.attributes;

    return this._chatEventService.addMessage(chatMessage);
  }

  private async removeMessage(message: Message) {
    debug('twilio: message removed', message);
    return this._chatEventService.removeMessageById(message.sid);
  }

  private async reactToTokenAboutToExpire(chat: Chat) {
    debug('twilio: token about to expire');
    await this._chatEventService.reactToTokenAboutToExpire(chat);
  }

  private async reactToTokenExpired(chat: Chat) {
    debug('twilio: token expired');
    await this._chatEventService.reactToTokenExpired(chat);
  }

  private setupConnectionStateEvents(twilioClient: TwilioClient, chat = Chat.PatientApp) {
    if (twilioClient) {
      twilioClient.on('connectionStateChanged', (state: ConnectionState) =>
        this.handleConnectionStateChanged(state, chat)
      );
      if (twilioClient.connectionState === 'connected') {
        this._chatConnectionStateService.setConnectionState('connected');
      }
    }
  }

  private handleConnectionStateChanged(connectionState: ConnectionState, chat = Chat.PatientApp) {
    debug('connection state changed', connectionState);
    this._chatConnectionStateService.setConnectionState(connectionState, chat);

    if (connectionState === 'disconnected') {
      throw new Error('Twilio disconnected');
    }
  }
}
