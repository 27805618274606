import { IDirectOrder } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { DirectOrderDB } from 'src/app/common/repository/databases';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

import { MessagePayloadDirectOrder } from '../../models/share/message-payload-direct-order';
import { IRouteParser } from '../contracts/route/route-parser';
import { QueryService } from '../query/query.service';

@Injectable()
export class DirectOrderRouteParser implements IRouteParser {
  type = MessagePayloadType.DirectOrder;

  constructor(private _queryService: QueryService) {}
  async parse(payload: MessagePayloadDirectOrder): Promise<string> {
    const order: IDirectOrder = await this._queryService.get(payload.directOrderId, DirectOrderDB);

    if (!order) {
      throw new Error(`No direct order found for id ${payload.directOrderId}`);
    }

    return `/patient/${order.patientId}/directOrder/${order._id}`;
  }
}
