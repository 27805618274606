import { IModel } from '@alberta/konexi-shared';
import { Params } from '@angular/router';
import { Query } from '@feathersjs/feathers';
import {
  ArticleModelName,
  GroupModelName,
  PostalCodeModelName,
  RegionModelName,
  TemplateModelName,
  UsersModelName,
} from 'src/app/shared/models/model-names';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { IQuery } from 'src/app/shared/services/contracts/query/query';
import { IFeathersAppProvider } from 'src/app/shared/services/contracts/sync/feathers-app-provider';

import { IDbQueryStrategy } from '../contracts/repository/db-query-strategy';
import { Deferred } from '../deferred/deferred';
import { paramsForServer } from '../feathers/params-for-server';
import { DBPlugin } from './index-plugins';
import { SearchOptions } from './search-options';
import type { AggregateStage } from './aggregate-stage.interface';

export abstract class DbQueryStrategy<T extends IModel & {}> implements IDbQueryStrategy<T> {
  private _ready = new Deferred<void>();
  protected table: string;
  protected plugin: DBPlugin;

  protected withArchive = [
    ArticleModelName,
    UsersModelName,
    GroupModelName,
    RegionModelName,
    PostalCodeModelName,
    TemplateModelName,
  ];

  public get storage(): Readonly<IGenericStorage> {
    return this._storage;
  }

  constructor(
    private _databaseName: string,
    private _storage: IGenericStorage,
    protected feathersAppProvider: IFeathersAppProvider,
    protected user,
    protected plugins?: Record<string, DBPlugin>
  ) {
    this.table = this._databaseName.substring(0, this._databaseName.length - 3);
    if (this.plugins) {
      this.plugin = this.plugins[`${this.table}.db`];
    }
    this._ready.resolve();
  }

  public abstract getAll(): Promise<T[]>;
  public abstract getItems(keys: string[]): Promise<any[]>;
  public abstract get(id: string): Promise<T>;
  public abstract searchInternal(ids: string[], query?: IQuery): Promise<T[]>;
  public abstract searchInternalByQuery(
    query: Query,
    aggregate: AggregateStage[],
    ignoreRegionIds: boolean,
    ignoreMetadataType?: boolean,
    sort?: { [key: string]: number }
  ): Promise<T[]>;
  public abstract raw({ aggregation, query }: { aggregation?: any[]; query?: any }): Promise<any>;

  get ready(): Promise<void> {
    return this._ready.promise;
  }

  public async search(query: IQuery): Promise<T[]> {
    await this.ready;

    if (!query || !query.query || query.query.length === 0) {
      return [];
    }

    this.splitTooLongQuery(query);

    const searchResults = [];

    do {
      const searchOptions = SearchOptions.create(query, this.table, this.plugin);
      if (searchOptions.getAll) {
        return this.getAll();
      }

      const { query: searchQuery, aggregate } = searchOptions.parseQuery(this.user, this.plugin);

      const results = await this.searchInternalByQuery(
        searchQuery,
        aggregate,
        query.ignoreRegionIds,
        query.ignoreMetadataType,
        query.sort
      );
      searchResults.push(...(results || []));
    } while (query.isIn && query.queryList && query.queryList.length);

    return searchResults && searchResults.length ? searchResults : [];
  }

  protected getParamsWithAuth(user, params: Params = { query: {} }): Params {
    if (user && user.authorization) {
      const { query = {} } = params;

      paramsForServer({
        query,
        syncData: {
          authorization: user.authorization,
          tenantId: user.organization.tenantId,
        },
      });
    }

    return params;
  }

  protected splitTooLongQuery(query: IQuery) {
    if (!query.isIn) {
      return;
    }

    const terms = query.query.split(' ');
    if ((terms || []).length > 200) {
      query.queryList = [];
      while (terms.length > 0) {
        query.queryList.push([...terms.splice(0, terms.length > 200 ? 200 : terms.length)]);
      }
    }
  }
}
