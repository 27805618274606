import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ServiceViewModel } from 'src/app/business/service-entity/view-model/service-view-model';
import { ArticleStatusPipe } from 'src/app/common/pipes/article/articlestatus-pipe';

@Component({
  selector: 'itl-integratedcare-service',
  templateUrl: './integratedcare-service.component.html',
  styleUrls: ['./integratedcare-service.component.scss'],
  providers: [DatePipe, ArticleStatusPipe],
})
export class IntegratedCareServiceComponent {
  @Input()
  service: ServiceViewModel;
  @Input()
  icon = '';
  @Input()
  iconColor: string;
  @Input()
  hasCheckbox = false;
  @Input()
  isChecked = false;
  @Input()
  isWizardPage = true;
  @Output()
  checked = new EventEmitter();

  public toggleCheckbox() {
    if (this.hasCheckbox) {
      this.checked.emit();
    }
  }
}
