import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'purchaseRatingPipe',
  pure: true,
})
export class PurchaseRatingPipe implements PipeTransform {
  constructor() {}
  transform(item: number): string {
    let rating = '';
    switch (item) {
      case 0:
        rating = 'A';
        break;
      case 1:
        rating = 'B';
        break;
      case 2:
        rating = 'C';
        break;
      case 3:
        rating = 'D';
        break;
      case 4:
        rating = 'E';
        break;
      case 5:
        rating = 'F';
        break;
    }
    return rating;
  }
}
