import { IUser } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { UsersWorkItem } from 'src/app/business/user/user-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class UsersCommandHandler extends EntityCommandHandler<IUser> {
  protected workItem: IWorkItem<IUser>;

  constructor(userWorkItem: UsersWorkItem) {
    super();
    this.workItem = userWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'UserDto';
  }
}
