import { Injectable } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Platform } from '@ionic/angular';
import { ObservableModel } from 'src/app/common/viewmodel/observable-model';

@Injectable({ providedIn: 'root' })
export class NetworkInfoService {
  private _noConnectionModel = new ObservableModel<boolean>(false, false);

  get isNotConnected(): boolean {
    return this._noConnectionModel.get();
  }

  constructor(private readonly _platform: Platform) {}

  public async init() {
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return;
    }

    const currentStatus = await Network.getStatus();
    this.setConnectionMode(currentStatus);

    // networkStatusChange will automatically be invoked on platform.resume
    await Network.addListener('networkStatusChange', status => this.setConnectionMode(status));
  }

  private setConnectionMode(status: ConnectionStatus) {
    const noConnection = status.connected === false || status.connectionType === 'none';
    // switching between wifi and cellular invokes multiple events
    // we signal it only once internally
    if (noConnection !== this._noConnectionModel.get()) {
      this._noConnectionModel.set(noConnection);
    }
  }
}
