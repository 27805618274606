import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InsuranceContractDto } from 'src/app/shared/models/insurance-contract/insurance-contract-dto.model';

import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'insurance-contract-list-item',
  templateUrl: 'insurance-contract-list-item.html',
  styleUrls: ['./../autocomplete-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceContractListItem implements IListView {
  @Input() item: InsuranceContractDto;
  @Output() clicked: EventEmitter<InsuranceContractDto> = new EventEmitter<InsuranceContractDto>();

  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
