import { Component, Input } from '@angular/core';

@Component({
  selector: 'itl-desktop-card',
  templateUrl: './desktop-card.component.html',
  styleUrls: ['./desktop-card.component.scss'],
})
export class DesktopCardComponent {
  @Input() header: string;
  @Input() iconName: string;
}
