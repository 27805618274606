import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, debounceTime, fromEvent } from 'rxjs';
import { StatusBarService } from '../../services/statusbar.service';

@Component({
  selector: 'itl-wizard-page',
  templateUrl: './wizard-page.component.html',
  styleUrls: ['./wizard-page.component.scss'],
})
export class WizardPageComponent implements OnInit, OnDestroy {
  @Input() public pageTitleTransPath: string;
  @Input() public sectionTitleTransPath: string;
  @Input() public sectionIcon: string;
  @Input() public subHeader: string;
  @Input() public currentPageId: number;
  @Input() public allPageCount: number;
  @Input() public pageIsValid = true;
  @Input() public isSinglePage = false;
  @Input() public allowNext = true;
  @Input() public showOnlyCancel = false;
  @Input() public showOnlyDone = false;
  @Input() public doneIcon = 'alberta-save';
  @Input() public cancelIcon = 'alberta-cancel';
  @Input() public sectionIsInvalid = false;
  @Input() public sectionHiddenInNavigation = false;
  @Output() public doneClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() public cancelClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() public sectionChangeClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() public loadPreviousClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() loadNextClicked: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('wizardContent', { static: true })
  private _wizardContent;
  private _resizeSubscription: Subscription;

  constructor(public translate: TranslateService, private _statusBarService: StatusBarService) {}

  ngOnInit() {
    this._statusBarService.setColorFromCssVariable('--alberta-color-primary-variant');
    if (!this._resizeSubscription) {
      this._resizeSubscription = fromEvent(window, 'resize')
        .pipe(debounceTime(33))
        .subscribe(this.scrollToSelectedElement);
    }
  }

  ngOnDestroy() {
    this._statusBarService.setColorFromCssVariable('--alberta-color-primary');
    this._resizeSubscription.unsubscribe();
  }

  private scrollToSelectedElement() {
    const activeElement = document.activeElement;
    if (!activeElement) {
      return;
    }
    const { top, left, bottom, right } = activeElement.getBoundingClientRect();
    if (top < 0 || left < 0 || bottom > window.innerHeight || right > window.innerWidth) {
      document.activeElement.scrollIntoView();
    }
  }

  public done() {
    if (!this.pageIsValid) {
      return;
    }
    this.doneClicked.emit();
  }
  public cancel() {
    this.cancelClicked.emit();
  }
  public changeSection() {
    this.sectionChangeClicked.emit();
  }
  public loadPrevious() {
    if (this.currentPageId !== 0) {
      this.loadPreviousClicked.emit();
      this._wizardContent.nativeElement.scrollTop = 0;
    }
  }
  public loadNext() {
    if (this.currentPageId !== this.allPageCount && this.allowNext) {
      this.loadNextClicked.emit();
      this._wizardContent.nativeElement.scrollTop = 0;
    }
  }
}
