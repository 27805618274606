import { ServiceLocation } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'serviceLocationPipe',
})
export class ServiceLocationPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}

  transform(item: ServiceLocation): string {
    return this._translate.instant(`serviceEntity.locationTypes.${item}`);
  }
}
