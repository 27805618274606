<div class="image-item">
  <div class="image-item--icon">
    <ion-icon [name]="icon ? icon : 'alberta-picture'"></ion-icon>
  </div>
  <div class="image-item--content">
    <div [ngClass]="required ? 'text-container required-star' : 'text-container'">
      <div class="text">{{ title }} <span *ngIf="required">*</span></div>
      <div class="description" *ngIf="description">{{ description }}</div>
    </div>
    <div class="image-wrapper">
      <ng-content></ng-content>
    </div>
    <itl-button (click)="addImageButtonClicked.emit()" class="addImageButton" *ngIf="showUploadButton">
      <span *ngIf="buttonText"> {{ buttonText }}</span>
      <span *ngIf="!buttonText"> {{ 'audit.node.image.add_photo' | translate }} </span>
    </itl-button>
  </div>
</div>
