<multi-autocomplete-desktop-search-view
  #desktopAutocomplete
  [titel]="title"
  [placeholder]="placeholder"
  [itemsProvider]="itemsProvider"
  [(control)]="control"
  [(selectedItems)]="selectedItems"
  [toSearchTerm]="toSearchTerm.bind(this)"
  [filterItems]="filterItems.bind(this)"
  [type]="type"
  [desktopResultsAreaHeight]="desktopResultsAreaHeight"
  [showChips]="showChips"
  [showAllOnStart]="showAllOnStart"
  [icon]="icon"
  [required]="required"
  [disabled]="disabled"
>
</multi-autocomplete-desktop-search-view>
<itl-error-display *ngIf="control" [control]="control"></itl-error-display>
<div class="resultslist">
  <div class="resultHeader" [ngClass]="{ 'resultHeader--disabled': disabled }" *ngIf="selectedItems.length">
    {{ 'multiautocomplete.selected' | translate }} {{ selectedItems.length }} {{ 'multiautocomplete.of' | translate }}
    {{ countOfAllItems }}
  </div>
  <ion-content #ionContent>
    <ion-list #selectedList>
      <ion-reorder-group [disabled]="!showReorder || disabled" (ionItemReorder)="reorderItems($event)">
        <ion-item *ngFor="let item of selectedItems; let i = index" class="displayItem">
          <ion-reorder slot="start"> </ion-reorder>
          <div class="item-content-wrapper" [ngClass]="{ 'item-content-wrapper--disabled': disabled }">
            <ng-container *ngIf="!listTemplate">
              <div class="displayItemCount">{{ i + 1 }}</div>
              <div class="itemdisplaytext">{{ item.displayText }}</div>
            </ng-container>
            <ng-container
              *ngIf="listTemplate"
              [ngTemplateOutlet]="listTemplate"
              [ngTemplateOutletContext]="{ index: i, displayText: item.displayText }"
            >
            </ng-container>
            <button (click)="removeItem(item)" [ngClass]="{ 'remove-button--disabled': disabled }">
              <ion-icon name="alberta-delete"></ion-icon>
            </button>
          </div>
        </ion-item>
      </ion-reorder-group>
    </ion-list>
  </ion-content>
</div>
