import { IAppointment, IModel } from '@alberta/konexi-shared';
import { take } from 'rxjs/operators';
import { AppointmentModelName } from 'src/app/shared/models/model-names';

import { IStateChangeListener } from '../contracts/state/state-change-listener';
import { StateExtensionAction } from '../contracts/state/state-extension-action';
import { filterTodayAppointments } from '../filter/dashboard-appointment-filter';
import { BaseStateExtension } from './state-extension';

export abstract class DashboardStateExtension<T extends IModel> extends BaseStateExtension<T>
  implements IStateChangeListener {
  protected abstract filter(appointments, item: IModel): boolean;
  protected abstract onCreated(appointment: IAppointment): Promise<void>;

  public async onChanged(action: StateExtensionAction, appointment: IAppointment): Promise<void> {
    switch (action) {
      case StateExtensionAction.create:
        await this.onCreated(appointment);
        break;
    }
  }

  public async canBeStored(item: T): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let result = false;

      this.stateRegistry
        .select<IAppointment[], T>(AppointmentModelName, 'dashboard')
        .pipe(take(1))
        .subscribe({
          next: appointments => {
            result = this.filter(filterTodayAppointments(appointments), item);
          },
          complete: () => resolve(result),
          error: () => resolve(false),
        });
    });
  }
}
