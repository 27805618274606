import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { DataRemovalToken, IDataRemoval } from './contracts/data/data-removal';

@Injectable({ providedIn: 'root' })
export class DataRemovalRegistryService {
  private _interval: NodeJS.Timeout;
  private INTERVAL_IN_MS = 60000;
  constructor(@Inject(DataRemovalToken) private dataRemovers: IDataRemoval[], private _platform: Platform) {}

  public remove(): void {
    if (!this._platform.is('hybrid')) {
      return;
    }

    global.clearInterval(this._interval);

    this._interval = global.setInterval(async () => {
      await Promise.all(this.dataRemovers.map(remover => remover.clean()));
      this.remove();
    }, this.INTERVAL_IN_MS);
  }
}
