<table cellspacing="0" role="grid" class="table-wrapper">
  <thead>
    <tr class="header">
      <th
        *ngFor="let column of columns"
        class="text-nowrap"
        [style.width.%]="column.albertaTableColumn.width"
        [class.hidden]="!showHeaders"
      >
        <ng-container *ngTemplateOutlet="column.templateRef"></ng-container>
      </th>
    </tr>
  </thead>
  <tbody [ngClass]="showHeaders ? 'withHeaders' : 'withoutHeaders'">
    <ng-container *ngIf="!!data && !!rowTemplate">
      <ng-container *ngFor="let item of data">
        <ng-container *ngTemplateOutlet="rowTemplate; context: { $implicit: item }"></ng-container>
      </ng-container>
    </ng-container>
  </tbody>
</table>
