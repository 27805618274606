import type { IModel } from '@alberta/konexi-shared';
import type { Query } from '@feathersjs/feathers';
import type { IQuery } from 'src/app/shared/services/contracts/query/query';

import { DbQueryStrategy } from './db-query-strategy';
import { SearchOptions } from './search-options';
import type { AggregateStage } from './aggregate-stage.interface';

export class LocalQueryStrategy<T extends IModel & {}> extends DbQueryStrategy<T> {
  public getAll(): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      const items = [];
      this.storage.getAll().subscribe(
        item => items.push(item),
        error => reject(error),
        () => resolve(items)
      );
    });
  }

  public getItems(keys: string[]): Promise<any[]> {
    return this.storage.getItems(keys);
  }

  public async get(id: string): Promise<T> {
    try {
      const result = await this.storage.get(id);

      return result;
    } catch (e) {
      return void 0;
    }
  }

  public async search(query: IQuery): Promise<T[]> {
    await this.ready;

    if (!query || !query.query || query.query.length === 0) {
      return [];
    }

    this.splitTooLongQuery(query);

    const indexResults = [];

    do {
      const searchOptions = SearchOptions.create(query, this.table, this.plugin);
      const results = await this.feathersAppProvider.app
        .service('search')
        .find(this.getParamsWithAuth(this.user, { query: { searchOptions } }));
      indexResults.push(...(results || []));
    } while (query.isIn && query.queryList && query.queryList.length);

    if (!indexResults || !indexResults.length) {
      return [];
    }

    const result = await this.searchInternal(indexResults);

    return result || [];
  }

  public async searchInternal(ids: string[]): Promise<T[]> {
    const items = await this.storage.getItems(ids);
    if (!this.withArchive.includes(this.table)) {
      return items.filter(item => !item.archived || item.archived == null);
    }
    return items;
  }

  public searchInternalByQuery(query: Query, aggregate: AggregateStage[], ignoreRegionIds: boolean): Promise<T[]> {
    throw new Error('Method not implemented.');
  }

  public raw({ aggregation, query }: { aggregation: any[]; query: any }): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
