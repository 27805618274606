import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'itl-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterBarComponent {
  @Input() icon;
  @Input() zIndex = 100;
  @Input() isSticky = true;
  @Output()
  clicked: EventEmitter<null> = new EventEmitter<null>();
  itemClicked() {
    this.clicked.emit();
  }
}
