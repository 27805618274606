import { get, isEqual } from 'lodash';
import moment from 'moment';
import { IChangeTrack } from '../contracts/tracking/change-track';
import { dateFormat, dateFormatWithoutTime } from '../date/format';
import { map } from '../mapper/mapper';
import { BaseViewModel } from '../viewmodel/base-view-model';
import { Change } from './change';
import { ChangeTrackCollection } from './change-track-collection';
import { ChangeTracker } from './change-tracker';
import { Changes } from './changes';
import { createHandler, propertyChanged } from './handlers';
import { proxyToRaw, rawToProxy } from './internals';
import { ignoreMetadataKey, removeTimeMetadataKey } from './tracking-keys';
//prettier-ignore
// this is needed for storybook to work
import "reflect-metadata";

export const trackingMetadataKey = 'trackableProperty';
type PropertyFunction<T> = () => T;

export type MomentInput = string | Date | number;

export function trackInternal(target: any): any {
  const trackedProps: string[] = [];

  target.changeTracker = new ChangeTracker();

  for (const prop in target) {
    if (canSkip(prop, target)) {
      continue;
    }

    buildTrack(prop);
  }

  const observable = new Proxy(target, createHandler(trackedProps));

  rawToProxy.set(target, observable);
  proxyToRaw.set(observable, target);

  return observable;

  function buildTrack(prop: string) {
    const metadata = Reflect.getMetadata(trackingMetadataKey, target, prop);
    if (Array.isArray(target[prop])) {
      target[prop] = target[prop].map((element, index) => {
        if (typeof element !== 'object' || element == null) {
          return element;
        }
        const element1 = metadata ? map(element, metadata.factory()) : element;

        return trackInternal(element1);
      });
      trackedProps.push(prop);

      propertyChanged.bind(target)(prop, target[prop]);
    } else if (
      !moment(target[prop], dateFormat, true).isValid() &&
      !moment(target[prop], dateFormatWithoutTime, true).isValid() &&
      typeof target[prop] === 'object'
    ) {
      const element = metadata ? map(target[prop], metadata.factory()) : target[prop];

      target[prop] = trackInternal(element);
      propertyChanged.bind(target)(prop, target[prop]);

      trackedProps.push(prop);
    } else {
      if (
        !(target[prop] instanceof Date) &&
        (moment(target[prop], dateFormat, true).isValid() ||
          moment(target[prop], dateFormatWithoutTime, true).isValid())
      ) {
        target[prop] = new Date(target[prop]);
      }
      trackedProps.push(prop);
      propertyChanged.bind(target)(prop, target[prop]);
    }
  }
}

function canSkip(prop: string, target) {
  return (
    prop === 'changeTracker' ||
    prop === 'changes' ||
    Reflect.getMetadata(ignoreMetadataKey, target, prop) != null ||
    target[prop] == null
  );
}

export function tracked(metadata: { factory: PropertyFunction<any> } = { factory: () => {} }): any {
  return Reflect.metadata(trackingMetadataKey, { ...metadata });
}

export function ignore(save: boolean = false): any {
  return Reflect.metadata(ignoreMetadataKey, { save });
}

export function removeTime(): any {
  return Reflect.metadata(removeTimeMetadataKey, {});
}

export function observe(target: BaseViewModel): any {
  return trackInternal(target);
}

export function getChanges(target: BaseViewModel, dto: any): Changes {
  if (!target.changeTracker) {
    return new Changes('#', []);
  }

  const changeValues: Change[] = [];
  for (const track of getDirtyChangeTracks.bind(target)()) {
    if (track.changeTrack instanceof ChangeTrackCollection) {
      changeValues.push(new Change(track.path, track.changeTrack.originTarget, track.changeTrack.oldValue));
    } else {
      changeValues.push(new Change(track.path, track.changeTrack.newValue, track.changeTrack.oldValue));
    }
  }

  let changes = new Changes((target as any)._id, changeValues);
  replaceProxies(changes, dto);
  cleanupEqualChanges(changes);

  try {
    changes = _workaround12915(changes);
  } catch (e) {
    window.logger.error('Failed to execute workaround #12915', e);
  }
  return changes;
}

function _workaround12915(changes: any) {
  /**
   * Workaround ADO #12915
   */
  if (changes.changes) {
    changes.changes
      .filter(changeElement => changeElement.path === '#.proposedArticleLines')
      .forEach(changeElement => changeElement.oldValue?.map(oldValue => delete oldValue?.insuranceContract));
    changes.changes = changes.changes
      .filter(changeElement => changeElement.path !== '#.productGroupTree')
      .filter(changeElement => changeElement.path !== '#.searchedArticlesInProductGroups')
      .filter(changeElement => changeElement.path !== '#.searchedTherapyProductGroups');
    return changes;
  }
  return changes;
}

function replaceProxies(changes: Changes, item: any) {
  for (const change of changes.changes) {
    if (
      typeof change.newValue === 'object' ||
      moment((change.newValue as unknown) as MomentInput, dateFormat, true).isValid()
    ) {
      change.newValue = get(item, change.path.replace('#.', ''));
    }
    if (change.oldValue === undefined) {
      change.oldValue = null;
    }
    if (change.newValue === undefined) {
      change.newValue = null;
    }
  }
}

function cleanupEqualChanges(changes: Changes) {
  for (let index = changes.changes.length - 1; index >= 0; index--) {
    if (isEqual(changes.changes[index].oldValue, changes.changes[index].newValue)) {
      changes.changes.splice(index, 1);
    }
  }
}

function getDirtyChangeTracks(path: string = '#'): { path: string; changeTrack: IChangeTrack }[] {
  let dirtyChangeTracks = [...this.changeTracker]
    .filter(([_, changeTrack]) => changeTrack.isDirty)
    .map(([_, changeTrack]) => ({ path: `${path}.${changeTrack.propertyName}`, changeTrack }));

  for (const prop in this) {
    if (prop === 'changeTracker' || this[prop] == null) {
      continue;
    }

    const value = this[prop];
    if (Array.isArray(value)) {
      for (let index = 0; index <= value.length - 1; index++) {
        if (value[index].changeTracker) {
          dirtyChangeTracks = [
            ...dirtyChangeTracks,
            ...[...getDirtyChangeTracks.bind(value[index])(`${path}.${prop}[${index}]`)],
          ];
        }
      }
    } else {
      if (value.changeTracker) {
        dirtyChangeTracks = [...dirtyChangeTracks, ...[...getDirtyChangeTracks.bind(value)(`${path}.${prop}`)]];
      }
    }
  }

  this.changeTracker.listeners.forEach(listener => listener.unsubscribe());
  delete this.changeTracker;

  return dirtyChangeTracks;
}
