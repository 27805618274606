import { AggregateRoot } from '../aggregate-root';
import { IGroup } from '@alberta/konexi-shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

export class GroupDto extends AggregateRoot implements IGroup, ISearchable {
  regionId: string;
  label: string;
  rightSet: string[];
  regionLabel: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;

  get displayText(): string {
    return this.label;
  }
}
