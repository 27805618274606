import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { IListView } from '../../../contracts/list-view';
import { TemplateTagItem } from 'src/app/insurance-contract/components/insurance-contract-edit/sections/required-templates/insurance-contract-required-template-item/template-tag-item';

@Component({
  selector: 'templatetag-template-list-item',
  templateUrl: 'templatetag-template-list-item.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateTagTemplateItem implements IListView {
  @Input() item: TemplateTagItem;
  @Output() clicked: EventEmitter<ISearchable> = new EventEmitter<ISearchable>();

  triggerEvent() {
    this.clicked.emit(this.item);
  }
}
