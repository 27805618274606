import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { Deferred } from 'src/app/common/deferred/deferred';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { PatientAppUserChannelDB } from '@common/repository/databases';
import { IDatabaseService } from '@services/contracts/database/database-service';
import { DatabaseService } from '@services/database.service';
import { PatientAppUserModelName } from '../../models/model-names';
import { IPatientAppUser } from '../../models/patient-app/patient-app-user';
import { IGenericStorage } from '../contracts/database/generic-storage';

@Injectable({ providedIn: 'root' })
export class PatientAppUserSynchronizer {
  private _ready = new Deferred<void>();
  private _patientAppUserChannelDb: IGenericStorage;
  private _syncReady$ = new BehaviorSubject<void>(null);

  public get patientAppSyncReady(): Observable<void> {
    return this._syncReady$.asObservable();
  }

  constructor(
    @Inject(DatabaseService) private _databaseService: IDatabaseService,
    @Inject(Dispatcher) private _dispatcher: IDispatcher<IPatientAppUser>
  ) {
    // tslint:disable-next-line: no-floating-promises
    this._databaseService.getDatabase(PatientAppUserChannelDB).then(storage => {
      this._patientAppUserChannelDb = storage;
      this._ready.resolve();
    });
  }
  public async synchronize(patientAppUsers: IPatientAppUser[]) {
    if (!patientAppUsers?.length) {
      this._syncReady$.next();
      return;
    }

    await this._ready.promise;

    const patientAppChannelUsers = patientAppUsers
      .filter(patientAppUser => patientAppUser.channelSid)
      .map(patientAppUser => ({ ...patientAppUser, _id: patientAppUser.channelSid }));
    const sqliteBatches = this._createSqliteBatchesFromPaUsers(patientAppChannelUsers);
    await this._patientAppUserChannelDb.setItems({ items: patientAppChannelUsers, deletable: true }, sqliteBatches);
    for (const user of patientAppUsers) {
      await this._dispatcher.createState(PatientAppUserModelName, user);
    }
    await this._dispatcher.updateState(PatientAppUserModelName, patientAppUsers);

    this._syncReady$.next();
  }

  private _createSqliteBatchesFromPaUsers(patientAppChannelUsers: IPatientAppUser[]) {
    // This is a workaround for #18595:
    // Patient App video chat only works on mobile devices if patient app users are synched
    // and the virtual entity PatientAppUserChannel is created as a side effect.
    // Known limitations: No FTS table is created at the moment.
    // Should be refactored with #15425 and following stories.
    return patientAppChannelUsers.map(user => [
      `INSERT OR REPLACE INTO [${PatientAppUserChannelDB.substring(
        0,
        PatientAppUserChannelDB.length - 3
      )}] (key, value) VALUES (?1, ?2)`,
      [String(user._id), JSON.stringify(user)],
    ]);
  }
}
