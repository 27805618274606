import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { areaCodes } from 'src/app/shared/components/inputs/text/areacodes';

@Directive({
  selector: '[itlPhonePrefix]',
})
export class PhonePrefixDirective {
  public areaCodes = areaCodes;
  @Input() maskPhoneNumber: boolean;

  @HostListener('keyup', ['$event'])
  onModelChange(event) {
    const fromBackspace = event.key === 'Backspace';
    this.onInputChange(event.target.value, fromBackspace);
  }

  constructor(public model: NgControl) {}

  onInputChange(event, fromBackspace: boolean = false) {
    if (this.maskPhoneNumber) {
      let newVal;

      if (this.areaCodes.includes(event) && !fromBackspace) {
        newVal = event + ' ';
      } else {
        newVal = event;
      }

      this.model.valueAccessor.writeValue(newVal);
    }
  }
}
