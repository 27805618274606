import { IPatientNursingHomeRelation } from '@alberta/konexi-shared';

import { removeTime } from '../../../common/tracking/tracker';

export class PatientNursingHomeRelationViewModel implements IPatientNursingHomeRelation {
  nursingHomeId: string;

  subunitId: string;

  contactId: string;

  @removeTime()
  dischargeDate: Date;

  _id: string;

  timestamp: Date;

  archived: boolean;
}
