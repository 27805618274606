import { IStandardCareProposal } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { Therapies } from 'src/app/shared/models/therapy';

@Pipe({
  name: 'productGroupMngSortpipe',
  pure: true,
})
export class ProductGroupMngSortPipePipe implements PipeTransform {
  constructor() {}
  transform(items: IStandardCareProposal[]): any {
    return items.sort((a, b) => {
      if (Therapies.getTherapy(+a.therapyId).displayName > Therapies.getTherapy(+b.therapyId).displayName) {
        return 1;
      }
      if (Therapies.getTherapy(+a.therapyId).displayName < Therapies.getTherapy(+b.therapyId).displayName) {
        return -1;
      }
      if (a.therapyTypeIds[0] && b.therapyTypeIds[0]) {
        if (
          Therapies.getTherapy(+a.therapyId).therapyTypes.find(item => item.id === a.therapyTypeIds[0]).displayName >
          Therapies.getTherapy(+b.therapyId).therapyTypes.find(item => item.id === b.therapyTypeIds[0]).displayName
        ) {
          return 1;
        }
        if (
          Therapies.getTherapy(+a.therapyId).therapyTypes.find(item => item.id === a.therapyTypeIds[0]).displayName <
          Therapies.getTherapy(+b.therapyId).therapyTypes.find(item => item.id === b.therapyTypeIds[0]).displayName
        ) {
          return -1;
        }
        return 0;
      }
      return 0;
    });
  }
}
