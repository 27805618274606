import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'itl-maintenance-info-modal',
  templateUrl: './maintenance-info-modal.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceInfoModalComponent implements AfterViewInit {
  public componentProps: any;
  public title: string;
  public subTitle: string;
  constructor(
    private _auth: AuthService,
    private _changeDetectionRef: ChangeDetectorRef,
    private _translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.title = `${this._translate.instant('greeting')} ${this._auth.authentication.account.firstName},`;
    const start = new Date(this.componentProps.startDate);
    if (moment().isAfter(moment(start))) {
      this.subTitle = this._translate.instant('maintenance.infoModal.maintenanceActive');
    } else {
      this.subTitle = `${this._translate.instant('maintenance.infoModal.maintenanceStarting_1')} ${moment(start).format(
        `DD.MM [${this._translate.instant('at')}] H [${this._translate.instant('timeSuffix')}]`
      )} ${this._translate.instant('maintenance.infoModal.maintenanceStarting_2')}`;
    }
    this._changeDetectionRef.detectChanges();
  }
}
