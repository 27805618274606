import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizationPipe',
  pure: true,
})
export class CapitalizationPipe implements PipeTransform {
  constructor() {}
  transform(text: string): string {
    if (!text || text.length === 0) {
      return text;
    }
    return text[0].toUpperCase() + text.substring(1);
  }
}
