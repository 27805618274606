import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class ObservableModel<T> {
  private _data: BehaviorSubject<T>;

  data$: Observable<T>;

  constructor(initialData: any, immutable: boolean, clone?: (data: T) => T) {
    this._data = new BehaviorSubject(initialData);
    this.data$ = this._data
      .asObservable()
      .pipe(
        map((data: T) =>
          immutable
            ? clone
              ? clone(data)
              : JSON.parse(JSON.stringify(data))
            : data
        )
      );
  }

  get(): T {
    return this._data.getValue();
  }

  set(data: T) {
    this._data.next(data);
  }
}
