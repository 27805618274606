import { Injectable } from '@angular/core';
import { Directory, Filesystem, ReaddirResult } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import { extension } from 'mime-types';
import { Deferred } from 'src/app/common/deferred/deferred';

import { Logger } from '@common/logging/logger';
import { IGenericStorage } from '../contracts/database/generic-storage';
import { DatabaseService } from '../database.service';
import { Database } from './Database';
import { arrayBufferToBlob, base64ToBlob, blobToBase64 } from './attachment-helper';

@Injectable({ providedIn: 'root' })
export class BlobFileService {
  private _db: IGenericStorage;
  private _chunksMetaDb: IGenericStorage;
  private _database: Database;

  private _ready = new Deferred<void>();

  private _migrationDone = false;

  constructor(private _platform: Platform, private _logger: Logger) {}

  /**
   * gets file location for provided attachment database type with new capacitor api
   * @param database database type
   * @returns object with path and directory
   */
  private async getStorageDirectoryLocation(database: Database): Promise<{ path: string; directory: Directory }> {
    let attachmentsDirectory: string;
    if (database === Database.AttachmentToSave) {
      attachmentsDirectory = `attachments_to_save`;
    } else if (database === Database.Blob) {
      attachmentsDirectory = `attachments`;
    } else {
      throw new Error('No valid database provided.');
    }

    if (this._platform.is('ios') && this._migrationDone === false) {
      this._migrationDone = await this.migrateCordovaAttachmentsForIos(attachmentsDirectory);
    }

    try {
      await Filesystem.stat({
        path: `${attachmentsDirectory}`,
        directory: Directory.Library,
      });
    } catch (error) {
      await Filesystem.mkdir({
        path: `${attachmentsDirectory}`,
        directory: Directory.Library,
        recursive: true, // This makes sure the directory and its parent directories are created if they don't exist
      });
    }

    return {
      path: attachmentsDirectory,
      directory: Directory.Library,
    };
  }

  /**
   * migrate cordova attachments to capacitor attachments on ios nocloud path if this path existis
   * @param attachmentsDirectory attachments directory
   */
  private async migrateCordovaAttachmentsForIos(attachmentsDirectory: string): Promise<boolean> {
    let readDirResult: ReaddirResult;
    try {
      readDirResult = await Filesystem.readdir({
        path: `NoCloud/${attachmentsDirectory}`,
        directory: Directory.Library,
      });
    } catch (error) {
      console.warn('no cordova attachment path to migrate', error);
      return true;
    }

    if (readDirResult.files.length > 0) {
      console.log('migrating cordova attachments');
      for (const file of readDirResult.files) {
        const fileName: string = file.name.toString();
        console.log('migrating cordova attachment', fileName);
        try {
          const fileData = await Filesystem.readFile({
            path: `NoCloud/${attachmentsDirectory}/${fileName}`,
            directory: Directory.Library,
          });
          await Filesystem.writeFile({
            path: `${attachmentsDirectory}/${fileName}`,
            directory: Directory.Library,
            data: fileData.data,
          });
          await Filesystem.deleteFile({
            path: `NoCloud/${attachmentsDirectory}/${fileName}`,
            directory: Directory.Library,
          });
          console.log('successfull migrated cordova attachment', fileName);
        } catch (error) {
          console.error('error migrating cordova attachment', fileName, error);
          this._logger.error(`error migrating cordova attachment: Filename: ${fileName}`, error);
          // if migration fails, we don't want to try it again in this session, to avoid infinite loops
        }
      }
    }
    return true;
  }

  public async create(databaseName: string, database: Database, databaseService: DatabaseService) {
    return new BlobFileService(this._platform, this._logger).init(databaseName, database, databaseService);
  }

  /**
   * build up directory paths for kinds of blob storages
   * @param databaseName databaseName
   * @param database type of database
   * @param databaseService current database Service
   * @returns BlobFileService Instance
   */
  public async init(
    databaseName: string,
    database: Database,
    databaseService: DatabaseService
  ): Promise<BlobFileService> {
    this._db = await databaseService.getDatabase(databaseName);
    await this._platform.ready();

    if (this._platform.is('hybrid')) {
      // on capacitor we need extra directory for attachments_to_save, otherwise this._fileLocation is undefined
      const { path, directory } = await this.getStorageDirectoryLocation(database);
      console.log(`setting file location database - ${Database[database]} - path - ${path} - directory - ${directory}`);
      this._chunksMetaDb = await databaseService.getDatabase('attachmentChunksMeta');
    }

    this._database = database;

    this._ready.resolve();

    return this;
  }

  public async getFileLocation(id: string, mimeType: string): Promise<{ path: string; directory: Directory }> {
    if (!id) {
      throw new Error('No valid id provided.');
    }

    await this._ready.promise;
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return this._db.get(id);
    }

    const validId = this.extractLocalPrefix(id);

    const location = await this.getStorageDirectoryLocation(this._database);

    const path = `${location.path}/${validId}.${extension(mimeType)}`;

    return { path, directory: location.directory };
  }

  public async get(id: string) {
    if (!id) {
      throw new Error('No valid id provided.');
    }
    await this._ready.promise;
    await this._platform.ready();
    if (!this._platform.is('hybrid')) {
      return this._db.get(id);
    }

    const validId = this.extractLocalPrefix(id);

    switch (this._database) {
      case Database.AttachmentToSave: {
        const data = await this._db.get(validId);
        if (!data) {
          return null;
        }

        const mimeType = data.data.mime;

        data.data.blob = await this.loadBlob(id, mimeType);
        return data;
      }
      case Database.Blob: {
        const chunksMeta = await this._chunksMetaDb.get(validId);
        if (!chunksMeta) {
          return null;
        }
        const mimeType = chunksMeta.mime;

        return this.loadBlob(id, mimeType);
      }
    }
  }

  private async loadBlob(id: string, mimeType: string): Promise<Blob> {
    let blobResult: Blob;

    const blobLocation = await this.getFileLocation(id, mimeType);
    try {
      const fileResult = await Filesystem.readFile(blobLocation);
      // capacitor returns blob in web, in mobile string
      if (this._platform.is('hybrid') && typeof fileResult.data === 'string') {
        blobResult = base64ToBlob(fileResult.data, mimeType);
      } else if (fileResult.data instanceof Blob) {
        blobResult = fileResult.data;
      } else {
        throw new Error('No valid blob data provided.');
      }
    } catch (error) {
      console.error('error fileResult', error);
    }

    return blobResult;
  }

  public async set(id: string, data: any) {
    if (!id || !data) {
      throw new Error('No valid attachment data provided.');
    }

    await this._ready.promise;
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return this._db.set(id, data);
    }

    const validId = this.extractLocalPrefix(id);
    const location = await this.getStorageDirectoryLocation(this._database);

    switch (this._database) {
      // this case is used to save the attachment(blob) locally
      case Database.Blob: {
        await this.writeBlobToDisk(validId, location, data);
        await this._chunksMetaDb.set(validId, { mime: data.type });
        return data;
      }
      // If user is offline (or upload failed) the attachment is also saved here for queue
      // Careful! The given data property is not the same structure as in above case
      case Database.AttachmentToSave: {
        let blobData: Blob;
        if (data.data.blob instanceof Blob) {
          blobData = data.data.blob;
        } else {
          blobData = arrayBufferToBlob(data.data.blob, data.data.mime);
        }
        await this.writeBlobToDisk(validId, location, blobData);
        delete data.data.blob;
        return this._db.set(validId, data);
      }
    }
  }
  private async writeBlobToDisk(validId: string, location: { path: string; directory: Directory }, data: Blob) {
    const base64String = await blobToBase64(data);
    await Filesystem.writeFile({
      path: `${location.path}/${validId}.${extension(data.type)}`,
      data: base64String,
      directory: location.directory,
      recursive: true,
    });
  }

  public async remove(id: string): Promise<any> {
    if (!id) {
      throw new Error('No valid id provided.');
    }

    await this._ready.promise;
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return this._db.remove(id);
    }

    const validId = this.extractLocalPrefix(id);
    const { path, directory } = await this.getStorageDirectoryLocation(this._database);

    switch (this._database) {
      case Database.AttachmentToSave: {
        const data = await this._db.get(validId);
        if (!data) {
          return;
        }

        Filesystem.deleteFile({
          path: `${path}/${validId}.${extension(data.data.mime)}`,
          directory,
        });
        await this._db.remove(validId);
        return;
      }
      case Database.Blob: {
        const chunksMeta = await this._chunksMetaDb.get(validId);
        if (!chunksMeta) {
          return;
        }

        Filesystem.deleteFile({
          path: `${path}/${validId}.${extension(chunksMeta.mime)}`,
          directory,
        });
        await this._chunksMetaDb.remove(validId);
      }
    }
  }

  public async keys(): Promise<string[]> {
    await this._ready.promise;
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return this._db.keys();
    }

    switch (this._database) {
      case Database.Blob:
        return this._chunksMetaDb.keys();
      case Database.AttachmentToSave:
        return this._db.keys();
    }
  }

  public async ready(): Promise<boolean> {
    return Boolean(this._db.ready());
  }

  private extractLocalPrefix(id: string) {
    let validId = id;
    if (validId.startsWith('local|')) {
      validId = validId.substring(validId.indexOf('|') + 1, validId.length);
    }
    return validId;
  }
}
