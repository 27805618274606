import { ICareProposal } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { CareProposalChannel } from 'src/app/business/care-proposal/contracts/care-proposal-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { CareProposalModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class CareProposalSyncService extends SyncService<ICareProposal> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<ICareProposal>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(CareProposalModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }
  public canSync(channel: string): boolean {
    return (
      channel === CareProposalChannel.CREATE ||
      channel === CareProposalChannel.EDIT ||
      channel === CareProposalChannel.DELETE
    );
  }
}
