import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
@Component({
  selector: 'itl-loading-indicator',
  template: `<ion-spinner *ngIf="showSpinner" name="lines"></ion-spinner>`,
  styleUrls: ['./loading-indicator.component.scss'],
  standalone: true,
  imports: [IonicModule, NgIf],
})
export class LoadingIndicatorComponent {
  showSpinner: boolean = false;
  ngOnInit(): void {
    // Delay the display of the spinner for 1 second
    setTimeout(() => {
      this.showSpinner = true;
    }, 1000);
  }
}
