import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from '@common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from '@common/contracts/unit-of-work/unit-of-work-factory';
import { ServiceCostEstimateDB } from '@common/repository/databases';
import { UnitOfWorkFactory } from '@common/unit-of-work/unit-of-work-factory';
import { ServiceCostEstimateDto } from 'src/app/shared/models/service-cost-estimate/service-cost-estimate.model';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class ServiceCostEstimateWorkItem extends WorkItem<ServiceCostEstimateDto> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'ServiceCostEstimateWorkItem';
  }
  get database(): string {
    return ServiceCostEstimateDB;
  }
}
