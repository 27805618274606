<itl-modal-page>
  <itl-modal-page-header>
    <div id="searchHeader">
      <button id="cancelButton" (click)="closeView()">
        <ion-icon id="closeIcon" name="alberta-cancel"></ion-icon>
      </button>
      <div id="pageTitel" class="bold">{{ 'search.placeholder' | translate }}</div>
    </div>
    <div id="searchBar">
      <div class="searchIcon-wrapper">
        <ion-icon id="searchIcon" name="alberta-lens"></ion-icon>
      </div>
      <form action="." (ngSubmit)="searchValues()">
        <ion-input #searchbar id="searchInput" [placeholder]="placeholder" type="search"></ion-input>
      </form>
      <div class="clearInput" *ngIf="searchbar.value" tappable (click)="clearSearch()">
        <ion-icon id="closeIcon" name="alberta-cancel"></ion-icon>
      </div>
    </div>
  </itl-modal-page-header>
  <div class="element-container" #pickContainer></div>
  <itl-warning-item *ngIf="hasSearchedAtLeastOnce && !busy && !hasAnySearchResults">{{
    errorMessage
  }}</itl-warning-item>
  <itl-loading-indicator *ngIf="busy"></itl-loading-indicator>
</itl-modal-page>
