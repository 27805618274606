<div class="tabs-container">
  <button
    *ngFor="let option of options"
    class="tab bold"
    [ngClass]="{ 'tab--selected': option.id === value }"
    (click)="selectItem(option.id)"
  >
    {{ option.value }}
  </button>
</div>
