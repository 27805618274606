import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AlbertaIconModule } from 'src/app/shared/components/alberta-icon/alberta-icon.module';
import { AlbertaCommonComponentsModule } from '../../common/common-components.module';
import { InputTemplatesModule } from '../_templates/input-templates.module';
import { AutocompleteComponent } from './autocomplete.component';
import { AutocompleteListItemsModule } from './list-items/autocomplete-list-items.module';
import { AutocompleteMobileSearchViewComponent } from './mobile-search-view/autocomplete-mobile-search-view.component';

@NgModule({
  declarations: [AutocompleteComponent, AutocompleteMobileSearchViewComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    AlbertaCommonComponentsModule,
    AutocompleteListItemsModule,
    InputTemplatesModule,
    FormsModule,
    AlbertaIconModule,
  ],
  exports: [AutocompleteComponent],
})
export class AutocompleteModule {}
