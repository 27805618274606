import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PostalCodeDto } from 'src/app/shared/models/institution/postal-code-dto.model';

import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'postal-code-list-item',
  templateUrl: 'postal-code-list-item.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostalCodeListItem implements IListView {
  @Input() item: PostalCodeDto;
  @Output() clicked: EventEmitter<PostalCodeDto> = new EventEmitter<PostalCodeDto>();
  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
