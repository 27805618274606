import { IDeliveryAddress, IErpOrder, IErpOrderedArticleLine, ITrackingInfo } from '@alberta/konexi-shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';
import { AggregateRoot } from '../aggregate-root';

export class ErpOrderDto extends AggregateRoot implements IErpOrder, ISearchable {
  prescriptionStatus: string;
  completed: boolean;
  patientId: string;
  regionId: string;
  therapyId: number;
  salesId: string;
  erpOrderedArticleLines: IErpOrderedArticleLine[];
  annotation: string;
  deliveryAddress: IDeliveryAddress;
  outboundDate: Date;
  _id: string;
  timestamp: Date;
  archived: boolean;
  trackingLink: string;
  trackingInfo: ITrackingInfo[];

  constructor() {
    super();
    this.archived = false;
  }
  get displayText(): string {
    return this.salesId;
  }
}
