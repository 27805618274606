import { IPatient } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';

import { PatientWorkItem } from '../../business/patient/patient-work-item';
import { IWorkItem } from '../../common/contracts/work-item/work-item_T';
import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class PatientCommandHandler extends EntityCommandHandler<IPatient> {
  protected workItem: IWorkItem<IPatient>;

  constructor(patientWorkItem: PatientWorkItem) {
    super();
    this.workItem = patientWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName.includes('PatientDto');
  }
}
