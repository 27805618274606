import { IAppointment } from '@alberta/konexi-shared';
import moment from 'moment';

export function filterTodayAppointments(appointments: IAppointment[]) {
  return (
    appointments &&
    appointments.filter(
      appointment =>
        !appointment.archived && moment(new Date(appointment.startDate)).isSame(moment().startOf('day'), 'd')
    )
  );
}
