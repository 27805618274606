import {
  AfterViewChecked,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itl-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('content', { read: ViewContainerRef, static: true })
  private _content;
  private _itemFactory: ComponentFactory<any>;
  private _ngUnsubscribe = new Subject();
  private _searchObject: any;
  public showCancel = false;

  constructor(_resolver: ComponentFactoryResolver, _navParams: NavParams, private _modalCtrl: ModalController) {
    this._itemFactory = _resolver.resolveComponentFactory(_navParams.get('component'));
  }

  ngOnInit(): void {
    const searchContent = this._content.createComponent(this._itemFactory);
    searchContent.instance.searchObjectChanged.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      this._searchObject = value;
    });
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.showCancel = true;
    }, 100);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next(null);
    this._ngUnsubscribe.complete();
  }
  dismissSearchView() {
    this._modalCtrl.dismiss();
  }
  startSearch() {
    this._modalCtrl.dismiss(this._searchObject);
  }
}
