<div class="page-wrapper">
  <div class="header">{{headerTranslationString | translate}}</div>

  <div class="content">
    <p *ngFor="let paragraph of messageParagraphTranslationStrings">{{paragraph | translate}}</p>
  </div>

  <div class="button-wrapper">
    <button *ngIf="continueButtonTranslationString" (click)="continue()">
      {{ continueButtonTranslationString | translate }}
    </button>
    <button *ngIf="cancelButtonTranslationString" (click)="cancel()">
      {{ cancelButtonTranslationString | translate }}
    </button>
  </div>
</div>
