import { IPatientNote } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { PatientNotesDB } from 'src/app/common/repository/databases';
import { MessagePayloadNote } from 'src/app/shared/models/share/message-payload-note';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

import { IRouteParser } from '../contracts/route/route-parser';
import { QueryService } from '../query/query.service';

@Injectable()
export class NoteRouteParser implements IRouteParser {
  type = MessagePayloadType.Note;

  constructor(private _queryService: QueryService) {}
  async parse(payload: MessagePayloadNote): Promise<string> {
    const patientNote: IPatientNote = await this._queryService.get(payload.noteId, PatientNotesDB);

    if (!patientNote) {
      throw new Error(`No patient note found for id ${payload.noteId}`);
    }

    return `/patient/${patientNote.patientId}/notes/${patientNote._id}`;
  }
}
