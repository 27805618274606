import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttachmentComponentModel } from 'src/app/business/attachment/attachment-component-model';

import { AttachmentDto } from '../../models/attachment/attachment-dto.model';

@Injectable({ providedIn: 'root' })
export class AttachmentNotificationFilterService {
  constructor(private _attachmentComponentModel: AttachmentComponentModel) {}

  public filter(): Observable<AttachmentDto[]> {
    return this._attachmentComponentModel
      .select<AttachmentDto[]>('notification')
      .pipe(
        map(attachments =>
          attachments.sort(
            (a, b) => new Date(b.metadata.createdAt).getTime() - new Date(a.metadata.createdAt).getTime()
          )
        )
      );
  }
}
