import { IModel } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { SyncTimestampDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { SyncTimestampDto } from 'src/app/shared/models/sync-timestamp-dto';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class SyncTimestampWorkItem extends WorkItem<SyncTimestampDto> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'SyncTimestampWorkItem';
  }
  get database(): string {
    return SyncTimestampDB;
  }

  public async getInitialSyncStart(sourceName: string): Promise<Date | null> {
    const initialSyncItem = await super.get(sourceName);
    if (initialSyncItem && initialSyncItem.initialSyncStart) {
      return new Date(initialSyncItem.initialSyncStart);
    }
    return null;
  }

  public async getSyncTimestamp(sourceName: string): Promise<Date> {
    const sourceTimestampItem = await super.get(sourceName);

    if (sourceTimestampItem && sourceTimestampItem.latestTimestamp) {
      return new Date(sourceTimestampItem.latestTimestamp);
    }
    return new Date(0);
  }

  public async setSyncTimestamp(sourceName: string, payload: any, initialSyncStart: Date): Promise<void> {
    if (!payload) {
      return;
    }

    let maxTimestamp: Date;
    if (sourceName === 'attachment') {
      maxTimestamp = payload[payload.length - 1]?.uploadDate;
    } else {
      maxTimestamp = this.getLatestDate(payload);
    }

    if (!maxTimestamp) {
      return;
    }

    await super.create(new SyncTimestampDto(sourceName, maxTimestamp, initialSyncStart));
    console.log(`SYNCTIMESTAMPWORKITEM SETSYNCTIMESTAMP / Gespeichert ${sourceName} ${maxTimestamp}`);
  }

  public getLatestDate(items: IModel[]): Date {
    if (!items.length) {
      return undefined;
    }

    const sortedItems = items.sort((a, b) => {
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });

    return sortedItems[sortedItems.length - 1] && sortedItems[sortedItems.length - 1].timestamp;
  }
}
