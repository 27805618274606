import { IOrder } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { BaseComponentModel } from 'src/app/common/component-model/base-component-model';
import { ComponentModelDependencies } from 'src/app/common/component-model/component-model-dependencies';
import { ComponentModelInfo } from 'src/app/common/component-model/component-model-info';
import { OrderModelName } from 'src/app/shared/models/model-names';

@Injectable({ providedIn: 'root' })
export class OrderComponentModel extends BaseComponentModel<IOrder> {
  /**
   * ComponentModel for all order relevant actions and dependencies
   */
  constructor(
    @Inject(ComponentModelDependencies) componentModelDependencies: ComponentModelDependencies,
    @Inject(ComponentModelInfo) componentModelInfo: ComponentModelInfo,
  ) {
    super(OrderModelName, componentModelDependencies, componentModelInfo);
  }
}
