import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class XmasService {
  get isDuringXmasHolidays(): boolean {
    return this.checkIfIsDuringXmaxHolidays(new Date());
  }

  public checkIfIsDuringXmaxHolidays(currentDate: Date): boolean {
    return this.isInDecember(currentDate) || this.isInJanuar(currentDate);
  }

  private isInDecember(calcDate: Date): boolean {
    return calcDate.getMonth() === 11;
  }

  private isInJanuar(calcDate: Date): boolean {
    return calcDate.getMonth() === 0 && calcDate.getDate() < 8;
  }
}
