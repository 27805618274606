import { IPatientPayerRelation, PayerType } from '@alberta/konexi-shared';
import { removeTime } from '../../../common/tracking/tracker';

export class PatientPayerRelationViewModel implements IPatientPayerRelation {
  hasCopayment?: boolean;
  copaymentRequestedAt: Date;
  copaymentRequestedBy: string;
  @removeTime()
  copaymentFromDate: Date;
  @removeTime()
  copaymentToDate: Date;
  payerId: string;
  payerType: PayerType;
  numberOfInsured: string;
  _id: string;
  timestamp: Date;
  archived: boolean;
  noCopaymentPhoto: boolean;
  hasNoEGKImage: boolean;
  hasNoCopaymentImage: boolean;
  additionalInfo?: string;
}
