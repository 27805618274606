import { HistoryItemType } from '@alberta/konexi-shared';
import ChangeOperation from '@alberta/konexi-shared/dist/general/enum/change-operation';
import IIntegratedCareHistoryLine from '@alberta/konexi-shared/dist/product/integrated-care-history-line';
import { v4 } from 'uuid';

export class IntegratedCareHistoryLineViewModel implements IIntegratedCareHistoryLine {
  date: Date;
  userName: string;
  userId: string;
  changeOperation: ChangeOperation;
  valueBefore: string;
  valueAfter: string;
  articleId: string;
  key: string;
  _id: string;
  timestamp: Date;
  archived: boolean;

  constructor(public type: HistoryItemType) {
    this._id = v4();
    this.date = this.timestamp = new Date();
    this.archived = false;
  }
}
