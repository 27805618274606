import { IOrder } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { OrderWorkItem } from 'src/app/business/order/order-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class OrderCommandHandler extends EntityCommandHandler<IOrder> {
  protected workItem: IWorkItem<IOrder>;

  constructor(orderWorkItem: OrderWorkItem) {
    super();
    this.workItem = orderWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'OrderDto';
  }
}
