import { IPostalCode } from '@alberta/konexi-shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { AggregateRoot } from '../aggregate-root';

export class PostalCodeDto extends AggregateRoot
  implements IPostalCode, ISearchable {
  postalCode: string;
  city: string;
  _id: string;
  timestamp: Date;
  archived: boolean;

  get displayText(): string {
    return `${this.postalCode} ${this.city}`;
  }
}
