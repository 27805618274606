export class MenuItem {
  public children: MenuItem[];

  constructor(public name: string, public icon: string, public page: string = null) {}

  public addChild(menuItem: MenuItem) {
    if (!this.children) {
      this.children = [];
    }
    this.children.push(menuItem);
  }
}
