import { ICancellationToken } from '../contracts/cancellation/cancellation-token';
import { ObservableModel } from '../viewmodel/observable-model';

export class CancellationToken implements ICancellationToken {
  public promise: Promise<void>;
  public cancelled: ObservableModel<boolean>;
  public serviceRepeats?: Record<string, number> = {};
  public syncCompleted?: Record<string, boolean> = {};

  constructor() {
    this.cancelled = new ObservableModel(false, false);
  }

  public async cancel(): Promise<void> {
    this.cancelled.set(true);
    if (this.promise && this.promise.then) {
      await this.promise;
    }
  }

  public reset(): ICancellationToken {
    this.cancelled = new ObservableModel(false, false);
    this.promise = undefined;

    return this;
  }
}
