export class ColorService {
  static generateComplementaryColor(baseColor: string, index: number): string {
    // Convert hex to HSL
    const baseHSL = this.hexToHSL(baseColor);
    // Calculate hue for complementary color
    const complementaryHue = (baseHSL.h + (index * 360) / 5) % 360; // Adjust the number of colors by changing the divisor (e.g., 360 / numberOfColors)
    // Convert HSL back to hex
    return ColorService.hslToHex(complementaryHue, baseHSL.s, baseHSL.l);
  }

  static hexToRgb(hex: string): { r: number; g: number; b: number } {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }

  static hexToHSL(hex: string): { h: number; s: number; l: number } {
    // Convert hex to RGB
    const { r, g, b } = ColorService.hexToRgb(hex);
    // Normalize RGB values
    const rNorm = r / 255;
    const gNorm = g / 255;
    const bNorm = b / 255;
    // Find min and max values of RGB
    const cMax = Math.max(rNorm, gNorm, bNorm);
    const cMin = Math.min(rNorm, gNorm, bNorm);
    // Calculate delta
    const delta = cMax - cMin;
    // Initialize HSL values
    let h = 0,
      s = 0,
      l = parseFloat(((cMax + cMin) / 2).toFixed(3));
    // Calculate hue
    if (delta !== 0) {
      if (cMax === rNorm) {
        h = 60 * (((gNorm - bNorm) / delta) % 6);
      } else if (cMax === gNorm) {
        h = 60 * ((bNorm - rNorm) / delta + 2);
      } else {
        h = 60 * ((rNorm - gNorm) / delta + 4);
      }
    }
    // Calculate saturation
    if (delta !== 0) {
      s = parseFloat((delta / (1 - Math.abs(2 * l - 1))).toFixed(3));
    }
    // Convert hue to positive value
    h = parseInt(((h + 360) % 360).toString(), 10);
    return { h, s, l };
  }

  static hslToHex(h: number, s: number, l: number): string {
    // Convert HSL to RGB
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;
    let r = 0,
      g = 0,
      b = 0;
    if (h >= 0 && h < 60) {
      r = c;
      g = x;
    } else if (h >= 60 && h < 120) {
      r = x;
      g = c;
    } else if (h >= 120 && h < 180) {
      g = c;
      b = x;
    } else if (h >= 180 && h < 240) {
      g = x;
      b = c;
    } else if (h >= 240 && h < 300) {
      r = x;
      b = c;
    } else if (h >= 300 && h < 360) {
      r = c;
      b = x;
    }
    // Convert RGB to HEX
    const rHex = Math.round((r + m) * 255)
      .toString(16)
      .padStart(2, '0');
    const gHex = Math.round((g + m) * 255)
      .toString(16)
      .padStart(2, '0');
    const bHex = Math.round((b + m) * 255)
      .toString(16)
      .padStart(2, '0');
    return `#${rHex}${gHex}${bHex}`;
  }
}
