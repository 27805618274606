import { IModel } from '@alberta/konexi-shared';

import { Command } from './command';
import { ICommand } from './contracts/command';

export class EntityDeleteCommand<T extends IModel> extends Command implements ICommand {
  constructor(public entity: T, public channel: string, dtoName: string) {
    super(dtoName);
  }
}
