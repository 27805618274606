import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ISelectionItem } from 'src/app/common/contracts/components/selection-item';

@Component({
  selector: 'itl-search-modal-selection',
  templateUrl: './search-modal-selection.component.html',
  styleUrls: ['./search-modal-selection.component.scss'],
})
export class SearchModalSelectionComponent {
  @Input() value = '';
  @Input() values: ISelectionItem[] = [];
  @Input() placeholder: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  constructor(private _translate: TranslateService, private _alertController: AlertController) {}

  async openSelect() {
    const inputs = [];
    // gernerating the radio options
    this.values.forEach(value => {
      inputs.push({
        type: 'radio',
        label: value.value,
        value: value.id,
        checked: this.checkIfchecked(value.id),
      });
    });
    // create the alert
    const alert = await this._alertController.create({
      header: this.placeholder,
      inputs: inputs,
      buttons: [
        {
          text: this._translate.instant('CANCEL_BUTTON'),
        },
        {
          text: this._translate.instant('DONE_BUTTON'),
          handler: (data: any) => {
            this.value = data;
            this.valueChange.emit(this.value);
          },
        },
      ],
    });
    await alert.present();
    await alert.onDidDismiss();
  }
  private checkIfchecked(id: any): boolean {
    return id === this.value;
  }
}
