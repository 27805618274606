import { NgModule } from '@angular/core';

import { UserNamePipe } from './username.pipe';

@NgModule({
  imports: [UserNamePipe],
  declarations: [],
  exports: [UserNamePipe],
})
export class UserPipesModule {}
