import { Component, Input } from '@angular/core';

@Component({
  selector: 'itl-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss'],
})
export class RatingStarsComponent {
  @Input() public rating: number;
  @Input() public assortmentType: number;
}
