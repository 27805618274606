import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { startWith, Subscription } from 'rxjs';
import { AlbertaIconModule } from '../../alberta-icon/alberta-icon.module';
import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  template: `
    <div class="rounded-md flex flex-col items-stretch h-full">
      <div class="min-h-14 pt-5 pb-4 px-6">
        <h2 class="text-[20px]">
          {{ header }}
        </h2>
      </div>
      <div *ngIf="showSearch" class="px-4 border-t border-[var(--border-color)]">
        <input
          id="searchInput"
          type="text"
          placeholder="Suche..."
          [formControl]="searchInput"
          class="w-full p-2 border-0 outline-none"
          (keydown.enter)="handleSearchKeydownEnter()"
          (keydown.escape)="handleSearchKeydownEscape()"
        />
      </div>
      <div class="flex-1 border-y border-[var(--border-color)] py-2 px-6 overflow-auto" [formGroup]="form">
        <div formArrayName="inputs">
          <ng-container *ngIf="isCheckbox">
            <itl-checkbox
              *ngFor="let input of filteredInputs"
              [title]="input.label"
              [noIcon]="true"
              [disabled]="input.disabled"
              [formControlName]="inputs.indexOf(input)"
            ></itl-checkbox>
          </ng-container>
          <ng-container *ngIf="isRadio">
            <ion-radio-group [value]="selectedRadioInput">
              <div *ngFor="let input of filteredInputs" class="my-3">
                <ion-radio
                  [value]="input.value"
                  labelPlacement="end"
                  [disabled]="input.disabled"
                  (click)="handleRadioClicked(input)"
                  ><span class="ion-text-wrap">{{ input.label }}</span></ion-radio
                >
              </div>
            </ion-radio-group>
          </ng-container>
        </div>
      </div>
      <div class="h-14 p-2 flex justify-end buttons">
        <ion-button fill="clear" *ngFor="let button of buttons" (click)="handleButtonClicked(button)">{{
          button.text
        }}</ion-button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        font-family: var(--ion-font-family);
        --border-color: var(--ion-color-step-150, var(--ion-background-color-step-150, #d9d9d9));
      }
      itl-checkbox {
        --ion-input-bottom-padding: 0;
        --ion-input-top-padding: 0;
        --ion-input-right-padding: 0;
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, CommonModule, CheckboxComponent, AlbertaIconModule, ReactiveFormsModule],
})
export class SelectModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() header: string;
  @Input() inputs: { type: string; label: string; value: string; checked: boolean; handler?: (input: any) => void }[] =
    [];
  @Input() buttons: { text: string; handler?: (data: string[]) => void }[];
  @Input() showSearch: boolean;

  filteredInputs: { type: string; label: string; value: string; checked: boolean }[];
  searchInput = new FormControl();
  form: UntypedFormGroup;
  selectedRadioInput;

  private _subscription = new Subscription();

  get inputControls(): FormArray {
    return this.form.get('inputs') as FormArray;
  }

  get isCheckbox(): boolean {
    return this.inputs?.[0]?.type === 'checkbox';
  }

  get isRadio(): boolean {
    return this.inputs?.[0]?.type === 'radio';
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _modalController: ModalController,
    private _formBuilder: UntypedFormBuilder,
    private _platform: Platform
  ) {}

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      inputs: this._formBuilder.array(this.inputs.map(input => new FormControl(input.checked))),
    });
    this.filteredInputs = this.inputs;

    if (this.isRadio) {
      const selectedInput = this.filteredInputs.find(input => input.checked);
      this.selectedRadioInput = selectedInput?.value;
    }

    this._subscription.add(
      this.searchInput.valueChanges.pipe(startWith('')).subscribe(value => {
        this.filteredInputs = this.inputs.filter(input => input.label.toLowerCase().includes(value.toLowerCase()));
        this._changeDetectorRef.detectChanges();
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.showSearch && this._platform.is('hybrid') === false) {
      document.getElementById('searchInput').focus();
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  handleButtonClicked(button: { text: string; handler?: (value: any[]) => void }) {
    if (button.handler) {
      const selectedValues = this.inputs
        .filter((_input, index) => this.inputControls.at(index).value)
        .map(input => input.value);
      button.handler(selectedValues);
    }
    this._modalController.dismiss();
  }

  handleRadioClicked(input: { label: string; value: string; handler?: (input: any) => void }) {
    this.selectedRadioInput = input.value;
    if (input.handler) {
      input.handler(input);
    }
  }

  handleSearchKeydownEnter() {
    if (this.filteredInputs.length === 1) {
      if (this.isCheckbox) {
        const index = this.inputs.indexOf(this.filteredInputs[0]);
        const control = this.inputControls.at(index);
        control.setValue(!control.value);
      } else if (this.isRadio) {
        this.handleRadioClicked(this.filteredInputs[0]);
      }
    }
  }

  handleSearchKeydownEscape() {
    this.searchInput.setValue('');
  }
}
