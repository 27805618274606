import { Inject, Injectable } from '@angular/core';
import { AttachmentService } from '@services/attachment/attachment.service';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';

import makeDebug from '../../../../makeDebug';
import { ClientDataSyncService } from '../../services/client-data-sync.service';
import { IClientDataSyncService } from '../../services/contracts/sync/client-data-sync';
import { NetworkInfoService } from '../../services/network/network-info.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement, SyncElementResult } from '../contracts/sync/sync-element';
import { SyncStarter as Starter } from '../contracts/sync/sync-endpoints';
import { TrackerService } from '@services/tracker.service';

const debug = makeDebug('sync:sync-starter');

@Injectable()
export class SyncStarter implements ISyncElement {
  public get name(): string {
    return Starter;
  }
  private _isRunning = false;

  constructor(
    @Inject(ClientDataSyncService) private readonly _clientDataSyncService: IClientDataSyncService,
    @Inject(PlatformSyncToken) private readonly _platformSync: IPlatformSync,
    private readonly _attachmentService: AttachmentService,
    private readonly _networkInfoService: NetworkInfoService
  ) {}

  public async execute(context: ISyncContext): Promise<SyncElementResult> {
    let result: SyncElementResult = { contextId: context.id, jobDone: false };
    debug('entered execute function', context);
    await this._platformSync.ready;

    if (
      !this._platformSync.canBeSynced ||
      !context.params.isOnline ||
      this._networkInfoService.isNotConnected ||
      this._isRunning ||
      context.params.token.cancelled.get()
    ) {
      result = {
        ...result,
        canBeSynced: this._platformSync.canBeSynced,
        cancelled: context.params.token.cancelled.get(),
        isRunning: this._isRunning,
        isOnline: context.params.isOnline,
        isNotConnected: this._networkInfoService.isNotConnected,
      };
      return result;
    }

    this._isRunning = true;

    debug('calling startSync of client-data-sync-service');

    await this._clientDataSyncService
      .startSync(context.params.token)
      .catch(error => window.logger.error('Client sync failed', error));

    await this._attachmentService.init();
    this._attachmentService
      .runAttachmentQueuesProcessor()
      .catch(error => window.logger.error('SyncStarter runAttachmentQueuesProcessor', error));

    this._isRunning = false;
    result.jobDone = true;
    return result;
  }
}
