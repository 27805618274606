import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { SyncIndicatorPipesModule } from '../common/pipes/sync-indicator/sync-indicator-pipes.module';
import { SyncIndicatorPage } from './sync-indicator.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule.forChild(), SyncIndicatorPipesModule],
  declarations: [SyncIndicatorPage],
  exports: [SyncIndicatorPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SyncIndicatorPageModule {}
