import { IAttachmentType } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from '@common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from '@common/contracts/unit-of-work/unit-of-work-factory';
import { AttachmentTypeDB } from '@common/repository/databases';
import { UnitOfWorkFactory } from '@common/unit-of-work/unit-of-work-factory';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class AttachmentTypeWorkItem extends WorkItem<IAttachmentType> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'AttachmentTypeWorkItem';
  }
  get database(): string {
    return AttachmentTypeDB;
  }
}
