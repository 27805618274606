import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IonicSlides, ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'itl-lightbox',
  templateUrl: './lightbox.modal.html',
  styleUrls: ['./lightbox.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightboxModal implements OnInit {
  @ViewChild('slides', { static: true }) slides: ElementRef | undefined;

  @Input()
  public images = [];
  @Input()
  public currentIndex = 0;
  public isAndroid: boolean;
  public version: number;
  public swiperModules = [IonicSlides];

  public get isIos() {
    return this._platform.is('ios');
  }

  constructor(public _modalCtrl: ModalController, private _platform: Platform) {}

  async ngOnInit() {
    await this.slides?.nativeElement.swiper.update();
    await this.slides?.nativeElement.swiper.slideTo(this.currentIndex);
  }

  public async slideChanged() {
    const currentIndex = this.slides?.nativeElement.swiper.activeIndex;
    if (currentIndex < this.images.length) {
      this.currentIndex = currentIndex;
    }
  }
}
