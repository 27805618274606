import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { NursingServiceDto } from 'src/app/shared/models/institution/nursing-service-dto.model';

import { NursingServiceDB } from '../../common/repository/databases';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class NursingServiceWorkItem extends WorkItem<NursingServiceDto> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'NursingServiceWorkItem';
  }
  get database(): string {
    return NursingServiceDB;
  }
}
