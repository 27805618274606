import {
  ChangeType,
  IArticle,
  IDosage,
  IHistoryArticleLine,
  IOrderedArticleLine,
  IProposedArticleLine,
} from '@alberta/konexi-shared';
import { TranslateService } from '@ngx-translate/core';
import { DurationPipe } from 'src/app/common/pipes/duration-pipe';
import { Change } from 'src/app/common/tracking/change';
import { ArticleService } from 'src/app/shared/services/article.service';
import { v4 } from 'uuid';

export class HistoryArticleLineViewModel implements IHistoryArticleLine {
  date: Date;
  articleId: string;
  articleNumber: string;
  articleName: string;
  articleDescription: string;
  userName: string;
  userId: string;
  valueBefore: string;
  valueAfter: string;
  _id: string;
  timestamp: Date;
  archived: boolean;

  constructor(public type: ChangeType) {
    this._id = v4();
    this.date = this.timestamp = new Date();
    this.archived = false;
  }

  public async setPrivateSale(
    privateSaleChange: Change,
    articleLine: IProposedArticleLine | IOrderedArticleLine,
    articleProvider: ArticleService,
    translate: TranslateService
  ) {
    if (!articleLine || !articleLine.articleId) {
      return;
    }

    const article = await articleProvider.find(articleLine.articleId);
    if (!article) {
      return;
    }

    this.articleId = article._id;
    this.articleName = article.name;
    this.articleDescription = article.description;
    this.articleNumber = article.customerNumber;
    this.valueBefore = privateSaleChange.oldValue
      ? translate.instant('seriesOrder.privateSale')
      : translate.instant('seriesOrder.noPrivateSale');
    this.valueAfter = privateSaleChange.newValue
      ? translate.instant('seriesOrder.privateSale')
      : translate.instant('seriesOrder.noPrivateSale');
  }

  public async setDeliveryInfo(
    deliveryChange: Change,
    articleLine: IProposedArticleLine | IOrderedArticleLine,
    articleProvider: ArticleService,
    translate: TranslateService
  ) {
    if (!articleLine || !articleLine.articleId) {
      return;
    }

    const article = await articleProvider.find(articleLine.articleId);
    if (!article) {
      return;
    }

    this.articleId = article._id;
    this.articleName = article.name;
    this.articleDescription = article.description;
    this.articleNumber = article.customerNumber;
    this.valueBefore = deliveryChange.oldValue
      ? translate.instant('seriesOrder.notAutomaticDelivery')
      : translate.instant('seriesOrder.automaticDelivery');
    this.valueAfter = deliveryChange.newValue
      ? translate.instant('seriesOrder.notAutomaticDelivery')
      : translate.instant('seriesOrder.automaticDelivery');
  }

  public async setArticleInfo(
    translate: TranslateService,
    articleService: ArticleService,
    newDosage: IDosage,
    oldDosage: IDosage,
    articleLine: IProposedArticleLine | IOrderedArticleLine
  ) {
    if (!articleLine || !articleLine.articleId) {
      return;
    }

    const article = await articleService.find(articleLine.articleId);
    if (!article) {
      return;
    }

    this.articleId = article._id;
    this.articleName = article.name;
    this.articleDescription = article.description;
    this.articleNumber = article.customerNumber;

    this.setValueAfter(translate, article, newDosage);
    this.setValueBefore(translate, article, oldDosage);
  }

  private setValueBefore(translate: TranslateService, article: IArticle, dosage: IDosage): string {
    if (!dosage) {
      return '';
    }
    this.valueBefore = this.getDosageInformation(translate, article, dosage);
  }

  private setValueAfter(translate: TranslateService, article: IArticle, dosage: IDosage): string {
    if (!dosage) {
      return '';
    }
    this.valueAfter = this.getDosageInformation(translate, article, dosage);
  }

  private getDosageInformation(translate: TranslateService, article: IArticle, dosage: IDosage) {
    const durationAsString = new DurationPipe(translate).transform(dosage.duration, dosage.duration.amount > 1);
    const packagingUnit = this.getPackagingUnit(article, dosage.packagingId);
    return `${dosage.quantity} ${packagingUnit} für ${durationAsString}`;
  }

  private getPackagingUnit(article: IArticle, packagingId: string): string {
    if (!article) {
      return;
    }

    const packagingUnit = article.packagingUnits?.find(item => item._id === packagingId);
    return packagingUnit ? packagingUnit.unit : '';
  }
}
