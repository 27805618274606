import { Injectable } from '@angular/core';
import { IStock } from '@alberta/konexi-shared';
import { FeathersService } from '../feathers.service';

@Injectable({ providedIn: 'root' })
export class StockService {
  constructor(private _feathersService: FeathersService) {}

  public async getStock(articleIds: string[]): Promise<IStock[]> {
    const stockService = this._feathersService.getService('stock');
    const result = [].concat(await stockService.find({ query: { articleIds: articleIds } }));
    return result;
  }
}
