<div class="text-input">
  <div class="checkmark">
    <ion-icon *ngIf="value && value.length" name="alberta-save"></ion-icon>
  </div>
  <div
    class="input__wrapper"
    tappable
    (click)="openSelect()"
    [ngClass]="{ 'input__wrapper--selected': value && value.length }"
  >
    <div class="text">
      <span *ngIf="value && value.length">{{ value | selectionDisplayText: values }}</span>
      <span *ngIf="!value || value.length === 0" class="placeholder">{{ placeholder }} </span>
    </div>
    <div class="chevron-input">
      <ion-icon name="arrow-dropdown"></ion-icon>
    </div>
  </div>
</div>
<div class="input-description">
  <span *ngIf="value.length">{{ placeholder }}</span>
</div>
