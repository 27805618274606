import { IAttachmentType } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { ISyncState } from '@common/contracts/sync/sync-state';
import { AttachmentTypeService } from '@services/attachment/attachment-type.service';
import { AttachmentTypeChannel } from 'src/app/business/attachmentType/contracts/attachment-type.channel';
import { AttachmentTypeModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class AttachmentTypeSyncService extends SyncService<IAttachmentType> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IAttachmentType>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync,
    private _attachmentTypeService: AttachmentTypeService
  ) {
    super(AttachmentTypeModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public canSync(channel: string): boolean {
    return (
      channel === AttachmentTypeChannel.CREATE ||
      channel === AttachmentTypeChannel.EDIT ||
      channel === AttachmentTypeChannel.DELETE
    );
  }

  protected async afterSync(syncState: ISyncState<IAttachmentType>): Promise<void> {
    await this._attachmentTypeService.fetchTypes();
    return;
  }
}
