import { IGroup, IRegion, IRightset } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { GroupViewmodel } from 'src/app/business/group/group-view-model';
import { map } from 'src/app/common/mapper/mapper';
import { GroupDB, RegionDB, RightsetDB } from 'src/app/common/repository/databases';

import { IQueryService } from './contracts/query/query-service';
import { QueryService } from './query/query.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(@Inject(QueryService) private _queryService: IQueryService) {}

  public async getGroups(groupIds: string[]): Promise<GroupViewmodel[]> {
    const groups = await this.getGroupsById(groupIds);
    const regions = await this.getRegions(groups);
    const rightSetIds = this.getRightSetIds(groups);
    const rightSets = await this.getRightSets(rightSetIds);

    return groups
      .filter(grp => !grp.archived)
      .map(group => {
        const groupViewModel = map(group, new GroupViewmodel());
        groupViewModel.region = regions.find(region => region._id === group.regionId);
        groupViewModel.rightSetItems = rightSets.filter(rightSet => group.rightSet.includes(rightSet._id));
        groupViewModel.loadRights();

        return groupViewModel;
      })
      .sort((a, b) => {
        const aIndex = groupIds.indexOf(a._id);
        const bIndex = groupIds.indexOf(b._id);
        return aIndex > bIndex ? 1 : -1;
      });
  }

  private getRightSetIds(groups: IGroup[]): string[] {
    return [
      ...new Set(
        groups
          .filter(group => !!group.rightSet)
          .reduce((ids, group) => {
            ids.push(...group.rightSet);
            return ids;
          }, [])
      ),
    ];
  }

  private async getRightSets(rightSetIds: string[]) {
    return this._queryService.search<IRightset>({ query: rightSetIds.map(id => `_id:${id}`).join(' ') }, RightsetDB, {
      isIn: true,
    });
  }

  private async getRegions(groups: IGroup[]) {
    const regionIds = [...new Set(groups.filter(group => !!group.regionId).map(group => group.regionId))];

    return this._queryService.search<IRegion>(
      {
        query: regionIds.map(id => `_id:${id}`).join(' '),
      },
      RegionDB,
      {
        isIn: true,
      }
    );
  }

  private async getGroupsById(groupIds: string[]) {
    return this._queryService.search<IGroup>({ query: groupIds.map(group => `_id:${group}`).join(' ') }, GroupDB, {
      isIn: true,
    });
  }
}
