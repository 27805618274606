export class PatientContact {
  constructor(
    public name: string,
    public email: string,
    public fax: string,
    public postalAddress,
    public type: string,
    public institutionId: string
  ) {}
}
