import { IQuote } from '@alberta/konexi-shared';

import { AggregateRoot } from '../aggregate-root';

export class QuoteDto extends AggregateRoot implements IQuote {
  date: string;
  quoteText: string;
  author: string;
  get displayText(): string {
    return `${this.quoteText} - ${this.author}`;
  }
}
