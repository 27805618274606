import { IPlannedOrder } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { PlannedOrderChannel } from 'src/app/business/plannedOrder/contracts/planned-order-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { PlannedOrderModelName } from '../../models/model-names';
import { PlannedOrderDto } from '../../models/planned-order/planned-order-dto.model';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class PlannedOrderSyncService extends SyncService<IPlannedOrder> {
  constructor(
    @Inject(AppController) appController: IAppController,
    // @Inject(Dispatcher) dispatcher: IDispatcher<IPlannedOrder>,
    @Inject(Dispatcher) dispatcher: IDispatcher<PlannedOrderDto>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(PlannedOrderModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public canSync(channel: string): boolean {
    return (
      channel === PlannedOrderChannel.CREATE ||
      channel === PlannedOrderChannel.EDIT ||
      channel === PlannedOrderChannel.DELETE
    );
  }
}
