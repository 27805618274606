import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'group-multiselect-autocomplete',
  template:
    // tslint:disable-next-line:max-line-length
    '<multi-autocomplete-list-item [selected]="selected" [item]="item" (changed)="toggle()"> <group-list-item [item]="item" (clicked)="toggle()"></group-list-item></multi-autocomplete-list-item>',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MulitAutocompleteGroupListItem {
  @Input() item: any;
  @Input() selected: any[];
  @Output() checkedChange: EventEmitter<any> = new EventEmitter<any>();
  toggle() {
    this.checkedChange.emit(this.item);
  }
}
