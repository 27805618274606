import { IRegion } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { RegionWorkItem } from 'src/app/business/user-management/region-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class RegionsCommandHandler extends EntityCommandHandler<IRegion> {
  protected workItem: IWorkItem<IRegion>;

  constructor(regionWorkItem: RegionWorkItem) {
    super();
    this.workItem = regionWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'RegionDto';
  }
}
