import { IPrescriptionRequest } from '@alberta/konexi-shared/dist/prescription/prescription-request';
import { Inject, Injectable, NgZone } from '@angular/core';
import { PrescriptionRequestChannel } from 'src/app/business/prescription/contracts/prescription-request-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { PrescriptionRequestModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class PrescriptionRequestSyncService extends SyncService<IPrescriptionRequest> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IPrescriptionRequest>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(PrescriptionRequestModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }
  public canSync(channel: string): boolean {
    return (
      channel === PrescriptionRequestChannel.CREATE ||
      channel === PrescriptionRequestChannel.EDIT ||
      channel === PrescriptionRequestChannel.DELETE
    );
  }
}
