import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * @deprecated This component (itl-card) is widely used in the application.
 * For the new article-list-item component, we needed to create a new card component (itl-card-2)
 */
@Component({
  selector: 'itl-card',
  template: `<div>
      {{ header }}
    </div>
    <div class="bold">
      <ng-content></ng-content>
    </div> `,
  styles: [
    `
      :host {
        background: var(--card-background-color, #eeeeee);
        padding: var(--card-padding, 5px 10px);
        margin: var(--card-margin, 0px 10px 10px 0px);
        border-radius: 7px;
        font-size: var(--card-font-size, 12px);
        width: var(--card-width, auto);
        word-break: break-word;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @Input() public header: string;
}
