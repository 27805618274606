import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { StandardCareProposalDto } from 'src/app/shared/models/standard-careproposal/standard-careproposal-dto.model';

import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'standard-care-proposal-list-item',
  templateUrl: 'standard-care-proposal-list-item.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardCareProposalListItem implements IListView {
  @Input() item: StandardCareProposalDto;
  @Output() clicked: EventEmitter<StandardCareProposalDto> = new EventEmitter<StandardCareProposalDto>();
  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
