import { CaregiverType, Gender, ICareGiver, Title } from '@alberta/konexi-shared';
import { v4 } from 'uuid';

export class CaregiverViewModel implements ICareGiver {
  comment: string;
  title: Title;
  fax: string;
  gender: Gender;
  type: CaregiverType;
  // legacy isLegalCarer - now in role
  isLegalCarer: boolean;
  firstName: string;
  lastName: string;
  address: string;
  postalCodeId: string;
  phone: string;
  mobilePhone: string;
  email: string;
  customerId: string;
  _id: string;
  timestamp: Date;
  archived: boolean;
  role: string;
  postalCode: string;
  city: string;

  constructor() {
    this._id = v4();
  }
}
