import { IInsuranceContract } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { InsuranceContractWorkItem } from 'src/app/business/insurance-contract/insurance-contract-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class InsuranceContractCommandHandler extends EntityCommandHandler<IInsuranceContract> {
  protected workItem: IWorkItem<IInsuranceContract>;

  constructor(insuranceContractWorkItem: InsuranceContractWorkItem) {
    super();
    this.workItem = insuranceContractWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'InsuranceContractDto';
  }
}
