import { IInstitutionAudit } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { InstitutionAuditDB } from 'src/app/common/repository/databases';
import { QueryService } from '../services/query/query.service';

@Injectable()
export class InstitutionAuditResolver implements Resolve<IInstitutionAudit> {
  constructor(private queryService: QueryService) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<IInstitutionAudit> {
    return this.queryService.get(route.paramMap.get('institutionauditId'), InstitutionAuditDB);
  }
}
