import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, ReplaySubject } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';

import makeDebug from '../../../../makeDebug';
import { IAuthEventData } from '../../../common/contracts/authentication/auth-event-data';
import { AuthService } from '../auth.service';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { FeathersService } from '../feathers.service';
import { Chat } from './model/chat-instance';

const debug = makeDebug('services:chat:user');

@Injectable({ providedIn: 'root' })
export class ChatUserService {
  get localUserChatToken$(): Observable<string> {
    return this._localUserChatToken$.asObservable();
  }

  get localAgentChatToken$(): Observable<string> {
    return this._localAgentChatToken$.asObservable();
  }

  private _localUserIdentity$ = new ReplaySubject<string>();
  private _localUserChatToken$ = new ReplaySubject<string>();
  private _localAgentChatToken$ = new ReplaySubject<string>();

  constructor(
    private readonly _auth: AuthService,
    private readonly _platform: Platform,
    @Inject(FeathersService) private readonly _feathersApp: IFeathersAppProvider
  ) {
    this._auth.authenticatedEventPublisher.subscribe(async (authEvent: IAuthEventData) => {
      if (authEvent.isAuthenticated) {
        this.updateUserIdentity();

        if (authEvent.authOnline) {
          await this.updateAccountChatInformation();
        }
      }
    });
  }

  public getUserDisplayName() {
    return `${this._auth.authentication.account.firstName} ${this._auth.authentication.account.lastName}`;
  }

  public getUserIdentity(): Promise<string> {
    debug('get user chat identity');
    return this._localUserIdentity$.pipe(take(1)).toPromise();
  }

  public async refreshToken(chat: Chat): Promise<string> {
    const authPromise = new Promise<void>(resolve => {
      this._auth.authenticatedEventPublisher
        .pipe(takeWhile((authEvent: IAuthEventData) => !authEvent.isAuthenticated))
        .subscribe({ complete: resolve });
    });

    await authPromise;

    debug('refreshing token...');
    if (chat === Chat.PatientApp) {
      const chatToken = await this.getAgentChatToken();
      if (!chatToken) {
        throw Error('refreshing failed');
      }
      this._localAgentChatToken$.next(chatToken);
      return chatToken;
    } else {
      throw new Error(`Unknown chat type: ${chat}`);
    }
  }
  private async updateAccountChatInformation(): Promise<void> {
    try {
      if (this._auth.authentication.account) {
        const agentChatToken = await this.getAgentChatToken();

        debug('update account information', { agentChatToken });

        if (agentChatToken) {
          this._localAgentChatToken$.next(agentChatToken);
        }
      }
    } catch (error) {
      window.logger.error('[ChatUserService] updateAccountChatInformation failed', error);
    }
  }

  private async getAgentChatToken(): Promise<string> {
    if (!this._auth.authentication.account.isAgent) {
      return 'NO_AGENT';
    }

    try {
      const { token } = await this._feathersApp.app.service('agent-chat').create({
        pushChannel: this._platform.is('ios') ? 'apn' : 'fcm',
      });
      return token;
    } catch (e) {
      return null;
    }
  }

  private updateUserIdentity() {
    const _id = this._auth.authentication.account?._id;

    if (_id) {
      this._localUserIdentity$.next(_id);
    }
  }
}
