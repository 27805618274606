import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ColorService } from '../../../services/color.service';

@Component({
  selector: 'itl-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements OnInit {
  @Input() baseColor: string;
  @Input() header: string;
  @Input() message: string;

  public infoStyle: string;
  public iconStyle: string;

  constructor(private _modalController: ModalController) {}

  ngOnInit() {
    this.setStyle();
  }

  public async close() {
    await this._modalController.dismiss().catch(error => console.log(error));
  }

  private setStyle() {
    const hsl = ColorService.hexToHSL(this.baseColor);
    const hslPrefix = 'hsl(' + hsl.h + ',' + hsl.s * 100 + '%,';
    this.infoStyle = `
      background-color: ${hslPrefix}95%);
    `;
    this.iconStyle = `color: ${hslPrefix}${hsl.l * 100}%)`;
  }
}
