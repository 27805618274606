import '@progress/kendo-angular-intl/locales/de/all';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import de from '@angular/common/locales/de';
import { APP_INITIALIZER, ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { provideFirebaseApp } from '@angular/fire/app';
import { FormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BaseRouteReuseStrategy, Router } from '@angular/router';
import { Printer } from '@awesome-cordova-plugins/printer/ngx';
import { SecureStorageEcho } from '@awesome-cordova-plugins/secure-storage-echo/ngx';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { SQLite } from '@ionic-enterprise/secure-storage/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage/dist/esm';
import { MbscModule } from '@mobiscroll/angular';
import * as SentryAngular from '@sentry/angular';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import moment from 'moment';
import { MobileOAuthModule } from 'src/app/auth/mobile-oauth.module';
import { environment } from 'src/environments/environment';

import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { initializeApp } from 'firebase/app';
import { AppInitService } from './app-init.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { WorkItemModule } from './business/work-item.module';
import { CommandModule } from './commands/command.module';
import authentication from './common/authentication/authentication-account';
import { AuthenticationToken } from './common/contracts/authentication/authentication-account';
import { PlatformSyncToken } from './common/contracts/sync/platform-sync';
import platformSync from './common/sync/platform-sync';
import { FaxCreateModalModule } from './fax/components/fax-create/fax-create.module';
import { I18nModule } from './i18n/i18n.module';
import { IndexRepairStatusModule } from './index-repair-status-modal/index-repair-status.module';
import { LightboxModule } from './shared/components/common/lightbox/lightbox.module';
import { MaintenanceInfoModalModule } from './shared/components/maintenance/maintenance-info-modal/maintenance-info-modal.module';
import { MaintenanceSnackBarModule } from './shared/components/maintenance/maintenance-snack-bar/maintenance-snack-bar.module';
import { SplitPaneModule } from './shared/components/split-pane/split-pane.module';
import { ProviderToken } from './shared/services/contracts/general-entity.service';
import { FieldNurseService } from './shared/services/field-nurse.service';
import { GeneralEntityService } from './shared/services/general-entity.service';
import { InstitutionContactFunctionService } from './shared/services/institution-contact-function.service';
import providerConfig, { ProviderConfigToken } from './shared/services/provider-config';
import { SharedModule } from './shared/shared.module';
import { SyncIndicatorPageModule } from './sync-indicator/sync-indicator.module';
import { UnlockPageModule } from './unlock/unlock.module';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'b47e1416-7b33-4299-a498-7cd7b240c361',
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}

moment.updateLocale('de', {});
registerLocaleData(de, 'de-DE');

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    return new (<any>window).Hammer(element, {
      touchAction: 'auto',
      inputClass: Hammer.TouchInput,
      recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]],
    });
  }
}

export function initApp(appInitService: AppInitService) {
  return (): void => appInitService.init();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    FormsModule,
    MbscModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md',
      scrollAssist: false,
      animated: true,
      rippleEffect: false,
      hardwareBackButton: false,
    }),
    IonicStorageModule.forRoot({
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    I18nModule.forRoot(),
    provideMessaging(() => getMessaging()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    AppRoutingModule,
    SharedModule,
    CommandModule.forRoot(),
    WorkItemModule.forRoot(),
    HttpClientModule,
    UnlockPageModule,
    SplitPaneModule,
    LightboxModule,
    SyncIndicatorPageModule,
    IndexRepairStatusModule,
    FaxCreateModalModule,
    MaintenanceSnackBarModule,
    MaintenanceInfoModalModule,
    HammerModule,
    MsalModule,
    OAuthModule.forRoot(),
    MobileOAuthModule,
  ],
  providers: [
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    AppInitService,
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [AppInitService], multi: true },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [SentryAngular.TraceService], multi: true },

    Deploy,
    SQLite,
    SecureStorageEcho,
    { provide: OAuthStorage, useValue: localStorage },
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler({
        logErrors: true,
      }),
    },
    { provide: BaseRouteReuseStrategy, useClass: IonicRouteStrategy },
    // TODO: remove Token
    { provide: ProviderConfigToken, useValue: providerConfig },
    // TODO: remove Token
    { provide: ProviderToken, useClass: GeneralEntityService, multi: true },
    // TODO: remove Token
    { provide: ProviderToken, useClass: FieldNurseService, multi: true },
    { provide: ProviderToken, useClass: InstitutionContactFunctionService, multi: true },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    { provide: AuthenticationToken, useValue: authentication },
    { provide: PlatformSyncToken, useValue: platformSync },
    Printer,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    MsalService,
    MsalGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
