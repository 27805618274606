export enum MessagePayloadType {
  URI = 'URI',
  Patient = 'patient',
  Careproposal = 'careproposal',
  IntegratedCare = 'integratedcare',
  Order = 'order',
  SingleOrder = 'singleOrder',
  DirectOrder = 'directOrder',
  CrossTherapy = 'crossTherapy',
  Document = 'document',
  InstitutionDocument = 'institutionDocument',
  Institution = 'institution',
  Attachment = 'attachment',
  Note = 'note',
  ReturnDelivery = 'returnDelivery',
  Appointment = 'appointment',
  PatientAppAttachment = 'patientAppAttachment',
  IntegratedCareReturnDelivery = 'integratedCareReturnDelivery',
}
