import { IMaintenance } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { IMaintenanceService } from 'src/app/business/maintenance/contracts/maintenance';
import { MaintenanceChannel } from 'src/app/business/maintenance/contracts/maintenance-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { ISyncState } from 'src/app/common/contracts/sync/sync-state';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import makeDebug from '../../../../makeDebug';
import { MaintenanceModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { MaintenanceService } from '../maintenance/maintenance.service';
import { SyncService } from './sync.service';

const debug = makeDebug('maintenance:maintenance-sync-service');

@Injectable({ providedIn: 'root' })
export class MaintenanceSyncService extends SyncService<IMaintenance> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IMaintenance>,
    eventService: EventService<SyncProgressEvent>,
    @Inject(MaintenanceService) private _maintenanceService: IMaintenanceService,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(MaintenanceModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public canSync(channel: string): boolean {
    return (
      channel === MaintenanceChannel.CREATE ||
      channel === MaintenanceChannel.EDIT ||
      channel === MaintenanceChannel.DELETE
    );
  }

  protected async afterSync(syncState: ISyncState<IMaintenance>) {
    if (!syncState.created.length && !syncState.updated.length) {
      return;
    }

    debug('trigger new maintenance from afterSync');
    await this._maintenanceService.startWatchingMaintenance();
  }

  protected async onCreated(data: any): Promise<void> {
    await super.onCreated(data);

    debug('trigger new maintenance from onCreated');
    await this._maintenanceService.startWatchingMaintenance();
  }

  protected async onPatched(data: any): Promise<void> {
    await super.onPatched(data);

    debug('trigger new maintenance from onPatched');
    await this._maintenanceService.startWatchingMaintenance();
  }
}
