import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'reverse' })
export class ReversePipe implements PipeTransform {
  transform(value) {
    if (!value || !value.length) {
      return value;
    }

    return value.slice().reverse();
  }
}
