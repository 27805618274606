import { Injectable } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class StatusBarService {
  constructor(private _platform: Platform) {}

  public async setColorFromCssVariable(color: string): Promise<void> {
    await this.setColorFromHex(getComputedStyle(document.documentElement).getPropertyValue(color).replace(/[ ]/g, ''));
  }

  public async setColorFromHex(hexColor: string): Promise<void> {
    if (!this._platform.is('hybrid')) {
      return;
    }

    if (this._platform.is('android')) {
      await StatusBar.setOverlaysWebView({ overlay: false });
      await StatusBar.setBackgroundColor({ color: hexColor }); // currently only works on android
    }

    if (this.lightOrDark(hexColor) === 'light') {
      await StatusBar.setStyle({ style: Style.Light });
    } else {
      await StatusBar.setStyle({ style: Style.Dark });
    }
  }

  public lightOrDark(color: string): string {
    const rgb = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    /* tslint:disable:no-bitwise */
    const r = rgb >> 16;
    const g = (rgb >> 8) & 255;
    const b = rgb & 255;
    /* tslint:enable:no-bitwise */

    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    return hsp > 127.5 ? 'light' : 'dark';
  }
}
