import { Component, Input } from '@angular/core';
import { InfoBoxType } from '../info-box/info-box.component';

@Component({
  selector: 'itl-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})

// sticky toolbar component
export class ToolbarComponent {
  @Input() showInfoBox = false;
  @Input() infoBoxText = '';
  @Input() infoBoxType: InfoBoxType = InfoBoxType.Information;
  @Input() infoBoxBaseColor: string;
}
