import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { PrescriptionRequestDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { PrescriptionRequestDto } from 'src/app/shared/models/prescription/prescription-request-dto.model';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class PrescriptionRequestWorkItem extends WorkItem<PrescriptionRequestDto> {
  constructor(@Inject(UnitOfWorkFactory) _unitOfWorkFactory: IUnitOfWorkFactory) {
    super(_unitOfWorkFactory);
  }

  get name(): string {
    return 'PrescriptionRequestWorkItem';
  }
  get database(): string {
    return PrescriptionRequestDB;
  }
}
