import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'booleanYesNoPipe',
  pure: true,
})
export class BooleanYesNoPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(value: boolean): any {
    let text = this._translate.instant('NO');
    if (value) {
      text = this._translate.instant('YES');
    }
    return text;
  }
}
