import { IPatientPharmacyRelation } from '@alberta/konexi-shared';

import { removeTime } from '../../../common/tracking/tracker';

export class PatientPharmacyRelationViewModel implements IPatientPharmacyRelation {
  subunitId: string;
  contactId: string;
  _id: string;
  timestamp: Date;
  archived: boolean;
}
