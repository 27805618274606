import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';

import makeDebug from '../../../../makeDebug';
import { IPipelineContext } from '../contracts/start/pipeline-context';
import { IPipelineElement, PipelineResult } from '../contracts/start/pipeline-element';
import { PlatformSyncDetector as SyncDetector, WebSocketConnector } from '../contracts/start/pipeline-endpoints';

const debug = makeDebug('sync:platform-sync-detector');

@Injectable()
export class PlatformSyncDetector implements IPipelineElement {
  public get endpoint(): string {
    return SyncDetector;
  }

  public get parent(): string {
    return WebSocketConnector;
  }

  constructor(private _platform: Platform, @Inject(PlatformSyncToken) private _platformSync: IPlatformSync) {}

  async execute(context: IPipelineContext): Promise<PipelineResult> {
    const debugInfos: PipelineResult = { contextId: context.id, jobDone: false };
    debug('detecting platform sync status');

    const canBeSynced = localStorage.getItem('canBeSynced');
    debugInfos['canBeSyncedFromStorage'] = canBeSynced;
    await this._platform.ready();

    this._platformSync.isCordova = this._platform.is('hybrid');
    debugInfos['isCordova'] = this._platform.is('hybrid');
    this._platformSync.canBeSynced = this._platformSync.isCordova || Boolean(canBeSynced);
    debugInfos.jobDone = true;
    return debugInfos;
  }
}
