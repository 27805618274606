import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { AlbertaIconModule } from '../shared/components/alberta-icon/alberta-icon.module';
import { AlbertaInputsModule } from '../shared/components/inputs/inputs-components.module';
import { DirectivesModule } from '../shared/directives/directives.module';
import { SyncIndicatorPageModule } from '../sync-indicator/sync-indicator.module';
import { UnlockPage } from './unlock.page';

@NgModule({
  declarations: [UnlockPage],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    SyncIndicatorPageModule,
    DirectivesModule,
    TranslateModule,
    AlbertaInputsModule,
    AlbertaIconModule,
  ],
  exports: [UnlockPage],
})
export class UnlockPageModule {}
