import { ICareProposal } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { CareProposalDB } from 'src/app/common/repository/databases';
import { MessagePayloadCareproposal } from 'src/app/shared/models/share/message-payload-careproposal';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

import { IRouteParser } from '../contracts/route/route-parser';
import { QueryService } from '../query/query.service';

@Injectable()
export class CareProposalRouteParser implements IRouteParser {
  type = MessagePayloadType.Careproposal;

  constructor(private _queryService: QueryService) {}
  async parse(payload: MessagePayloadCareproposal): Promise<string> {
    const careProposal: ICareProposal = await this._queryService.get(payload.careproposalId, CareProposalDB);

    if (!careProposal) {
      throw new Error(`No careProposal found for id ${payload.careproposalId}`);
    }

    const careproposalPath = `?careproposalId=${careProposal._id}`;

    return `/patient/${careProposal.patientId}/careproposal${careproposalPath}`;
  }
}
