import { IAppointment } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
// tslint:disable-next-line: max-line-length
import {
  AuthenticationToken,
  IAuthenticationAccount,
} from 'src/app/common/contracts/authentication/authentication-account';
import { IStateChangeEmitter } from 'src/app/common/contracts/state/state-change-emitter';
import { IStateExtension } from 'src/app/common/contracts/state/state-extension';
import { StateExtensionAction } from 'src/app/common/contracts/state/state-extension-action';
import { IStateRegistry } from 'src/app/common/contracts/state/state-registry';
import { filterTodayAppointments } from 'src/app/common/filter/dashboard-appointment-filter';
import { AppointmentModelName } from 'src/app/shared/models/model-names';

@Injectable({ providedIn: 'root' })
export class DashboardAppointmentStateExtension
  implements IStateExtension<IAppointment>, IStateChangeEmitter<IAppointment>
{
  private _stateRegistry: IStateRegistry;
  private _ttl: Record<string, IAppointment> = {};
  private _changed = new Subject<{ action: StateExtensionAction; payload: IAppointment }>();
  public get name(): string {
    return AppointmentModelName;
  }
  public get changed() {
    return this._changed.asObservable();
  }

  constructor(
    @Inject(AuthenticationToken) private _authenticationAccount: IAuthenticationAccount,
    ngZone: NgZone
  ) {
    ngZone.runOutsideAngular(() => {
      setInterval(async () => {
        const keys = Object.keys(this._ttl);
        for (let index = keys.length - 1; index >= 0; index--) {
          const appointment = this._ttl[keys[index]];
          if (this.checkIfAppointmentWasYesterday(appointment.endDate)) {
            await this.removeFromState(appointment);
          }
        }
      }, 60000);
    });
  }

  setRegistry(stateRegistry: IStateRegistry): void {
    this._stateRegistry = stateRegistry;
  }

  public async afterCreate(appointments: IAppointment[]): Promise<void> {
    if (!appointments || !appointments.length) {
      return;
    }

    await this._authenticationAccount.ready;

    for (const appointment of appointments) {
      if (this._authenticationAccount.account._id !== appointment.userId || !(await this.canBeStored(appointment))) {
        continue;
      }

      await this._stateRegistry.createBySync(AppointmentModelName, 'dashboard', appointment);
      this._changed.next({ action: StateExtensionAction.create, payload: appointment });

      this._ttl[appointment._id] = appointment;
    }
  }

  public async afterUpdate(appointments: IAppointment[]): Promise<void> {
    if (!appointments || !appointments.length) {
      return;
    }

    await this._authenticationAccount.ready;

    const myAppointments = appointments.filter(
      appointment => this._authenticationAccount.account._id === appointment.userId
    );

    for (const appointment of myAppointments) {
      if (!(await this.canBeStored(appointment))) {
        await this.removeFromState(appointment);
        continue;
      }

      await this._stateRegistry.createBySync(AppointmentModelName, 'dashboard', appointment);
      await this._stateRegistry.updateBySync(AppointmentModelName, 'dashboard', [appointment]);
      this._changed.next({ action: StateExtensionAction.create, payload: appointment });

      this._ttl[appointment._id] = appointment;
    }
  }

  private async removeFromState(appointment: IAppointment): Promise<void> {
    await this._stateRegistry.removeBySync(AppointmentModelName, 'dashboard', [appointment]);
    delete this._ttl[appointment._id];
  }

  public async canBeStored(appointment: IAppointment): Promise<boolean> {
    const filteredAppointments = filterTodayAppointments([appointment]);

    return filteredAppointments && !!filteredAppointments.length;
  }

  private checkIfAppointmentWasYesterday(day: Date): boolean {
    const yesterday = moment().subtract(1, 'days').startOf('day');

    return moment(day).isSame(yesterday, 'd');
  }
}
