import { IErpTask } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { BaseComponentModel } from 'src/app/common/component-model/base-component-model';
import { ComponentModelDependencies } from 'src/app/common/component-model/component-model-dependencies';
import { ComponentModelInfo } from 'src/app/common/component-model/component-model-info';
import { ErpTaskModelName } from 'src/app/shared/models/model-names';

@Injectable({ providedIn: 'root' })
export class ErpTaskComponentModel extends BaseComponentModel<IErpTask> {
  /**
   * ComponentModel for all erp task relevant actions and dependencies
   */
  constructor(
    @Inject(ComponentModelDependencies) componentModelDependencies: ComponentModelDependencies,
    @Inject(ComponentModelInfo) componentModelInfo: ComponentModelInfo
  ) {
    super(ErpTaskModelName, componentModelDependencies, componentModelInfo);
  }
}
