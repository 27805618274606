import { ISearchable } from 'src/app/common/contracts/components/searchable';

export class FieldNurse implements ISearchable {
  constructor(
    public _id: string,
    public firstName: string,
    public lastName: string,
    public archived: boolean,
    public active: boolean
  ) {}

  get displayText(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
