import { Injectable } from '@angular/core';
import { PatientAppUserWorkItem } from 'src/app/business/patient-app-user/patient-app-user-work-item';
import { IPatientAppUser } from 'src/app/shared/models/patient-app/patient-app-user';

import { IWorkItem } from '../../common/contracts/work-item/work-item_T';
import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class PatientAppUserCommandHandler extends EntityCommandHandler<IPatientAppUser> {
  protected workItem: IWorkItem<IPatientAppUser>;

  constructor(patientAppUserWorkItem: PatientAppUserWorkItem) {
    super();
    this.workItem = patientAppUserWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName.includes('PatientAppUserDto');
  }
}
