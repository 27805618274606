import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Deferred } from 'src/app/common/deferred/deferred';
import { CodePushService } from 'src/app/shared/services/code-push/code-push.service';
import { buildNumber, latestCommit, version } from 'src/environments/build-number';
import { environment } from 'src/environments/environment';

import makeDebug from '../../../../makeDebug';

const debug = makeDebug('services:environment');

export interface BranchUrls {
  dev: string;
  staging: string;
  production: string;
  uat: string;
  mdr: string;
}

export const Branch = {
  dev: 'dev',
  staging: 'staging',
  production: 'production',
  uat: 'uat',
  mdr: 'mdr',
};

export const AlbertaUrls = new InjectionToken<BranchUrls>('alberta baseUrls', {
  providedIn: 'root',
  factory: () => environment.environmentSettings.albertaUrl as BranchUrls,
});

export const SignalingServerUrls = new InjectionToken<BranchUrls>('signaling server urls', {
  providedIn: 'root',
  factory: () => environment.environmentSettings.signalingServer as BranchUrls,
});

export const SwodocUrls = new InjectionToken<BranchUrls>('swodocUrls', {
  providedIn: 'root',
  factory: () => environment.environmentSettings.swodocUrls as BranchUrls,
});

export const EnvironmentBranch = new InjectionToken<string>('environment branch', {
  providedIn: 'root',
  factory: () => ((environment as any).branch ? (environment as any).branch : null),
});

export const WindowHost = new InjectionToken<string>('window host', {
  providedIn: 'root',
  factory: () => window.location.host.split(':')[0],
});

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private _ready = new Deferred<void>();
  public get ready(): Promise<void> {
    return this._ready.promise;
  }
  private _version: string;
  public get version() {
    return this._version;
  }

  private _buildNumber: string;
  public get buildNumber() {
    return this._buildNumber;
  }

  private _gitCommitHash: string;
  public get getCommitHash() {
    return this._gitCommitHash;
  }

  private _swodocUrl: string;
  public get swodocUrl() {
    return this._swodocUrl;
  }

  private _signalingServerUrl: string;
  public get signalingServerUrl() {
    return this._signalingServerUrl;
  }

  private _branch: string;
  public get branch() {
    return this._branch;
  }

  private _isProduction = false;
  public get isProduction() {
    return this._isProduction;
  }

  private _alberta_baseurl: string;
  public get alberta_baseurl() {
    return this._alberta_baseurl;
  }

  constructor(
    private _platform: Platform,
    @Inject(AlbertaUrls)
    private _albertaBaseUrls: BranchUrls,
    @Inject(SignalingServerUrls) private readonly _signalingServerUrls: BranchUrls,
    @Inject(SwodocUrls) private _swodocUrls: BranchUrls,
    @Inject(EnvironmentBranch) private _environmentBranch: string,
    @Inject(WindowHost) private _windowHost: string,
    private _codePushService: CodePushService,
    private _storage: Storage
  ) {}

  public async init() {
    this.initVersion();
    if (this._platform.is('hybrid')) {
      await this.initMobile();
    } else {
      await this.initWeb();
    }
    this._ready.resolve();
  }

  private initVersion() {
    this._version = version;
    this._gitCommitHash = latestCommit;
    this._buildNumber = buildNumber;
  }

  private async getDeploymentOverride() {
    if (this._environmentBranch) {
      return this._environmentBranch;
    }
    const branchFromStorage = await this._storage.get('BRANCH');
    if (branchFromStorage) {
      if (
        branchFromStorage === Branch.dev ||
        branchFromStorage === Branch.staging ||
        branchFromStorage === Branch.production
      ) {
        return branchFromStorage;
      }
    }
  }

  private async initMobile() {
    let deployment = await this.getDeploymentOverride();
    if (!deployment) {
      deployment = this._codePushService.currentDeployment
        ? this._codePushService.currentDeployment
        : Branch.production;
      if (deployment) {
        debug('deployment from codepush', deployment);
      }
    }
    await this.initAlbertaUrls(deployment);
  }

  private async initWeb() {
    const deployment = await this.getDeploymentOverride();
    await this.initAlbertaUrls(deployment);
  }

  private _setAlbertaUrlsFromBranch(branch: string) {
    switch (branch) {
      case Branch.dev:
        this._alberta_baseurl = this._albertaBaseUrls.dev;
        this._swodocUrl = this._swodocUrls.dev;
        this._signalingServerUrl = this._signalingServerUrls.dev;
        break;
      case Branch.staging:
        this._alberta_baseurl = this._albertaBaseUrls.staging;
        this._swodocUrl = this._swodocUrls.staging;
        this._signalingServerUrl = this._signalingServerUrls.staging;
        break;
      case Branch.uat:
        this._alberta_baseurl = this._albertaBaseUrls.uat;
        this._swodocUrl = this._swodocUrls.uat;
        this._signalingServerUrl = this._signalingServerUrls.uat;
        break;
      case Branch.mdr:
        this._alberta_baseurl = this._albertaBaseUrls.mdr;
        this._swodocUrl = this._swodocUrls.mdr;
        this._signalingServerUrl = this._signalingServerUrls.mdr;
        break;
      default:
        this._alberta_baseurl = this._albertaBaseUrls.production;
        this._swodocUrl = this._swodocUrls.production;
        this._signalingServerUrl = this._signalingServerUrls.production;
        this._isProduction = true;
    }
  }

  private async initAlbertaUrls(deployment: string) {
    if (this._platform.is('hybrid')) {
      this._branch = deployment ? deployment : Branch.production;
      this._setAlbertaUrlsFromBranch(this._branch);
      return;
    }
    switch (this._windowHost) {
      case 'localhost':
      case '127.0.0.1':
        this._branch = deployment ? deployment : Branch.dev;
        this._setAlbertaUrlsFromBranch(this._branch);
        break;
      case `dev.app.${environment.baseUrl}`:
        this._alberta_baseurl = this._albertaBaseUrls.dev;
        this._swodocUrl = this._swodocUrls.dev;
        this._branch = deployment ? deployment : Branch.dev;
        this._signalingServerUrl = this._signalingServerUrls.dev;
        break;
      case `staging.app.${environment.baseUrl}`:
        this._alberta_baseurl = this._albertaBaseUrls.staging;
        this._swodocUrl = this._swodocUrls.staging;
        this._branch = deployment ? deployment : Branch.staging;
        this._signalingServerUrl = this._signalingServerUrls.staging;
        break;
      case `uat.app.${environment.baseUrl}`:
        this._alberta_baseurl = this._albertaBaseUrls.uat;
        this._swodocUrl = this._swodocUrls.staging;
        this._branch = deployment ? deployment : Branch.uat;
        this._signalingServerUrl = this._signalingServerUrls.uat;
        break;
      case `mdr-uat.app.${environment.baseUrl}`:
        this._alberta_baseurl = this._albertaBaseUrls.uat;
        this._swodocUrl = this._swodocUrls.uat;
        this._branch = deployment ? deployment : Branch.uat;
        this._signalingServerUrl = this._signalingServerUrls.uat;
        break;
      case `mdr.${environment.baseUrl}`:
        this._alberta_baseurl = this._albertaBaseUrls.mdr;
        this._swodocUrl = this._swodocUrls.mdr;
        this._branch = deployment ? deployment : Branch.mdr;
        this._signalingServerUrl = this._signalingServerUrls.mdr;
        break;
      default:
        this._alberta_baseurl = this._albertaBaseUrls.production;
        this._swodocUrl = this._swodocUrls.production;
        this._signalingServerUrl = this._signalingServerUrls.production;
        this._branch = deployment ? deployment : Branch.production;
        this._isProduction = true;
    }
    debug('_alberta_baseurl', this.alberta_baseurl);
    debug('_swodocUrl', this._swodocUrl);
    debug('_branch', this._branch);
  }

  public isProductionBranch(): boolean {
    return this._branch === Branch.production || this._branch === Branch.mdr;
  }

  public isMdrBranch(): boolean {
    return this._branch === Branch.mdr || this._branch === Branch.uat;
  }
}
