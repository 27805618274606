import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { StandardCareProposalDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { StandardCareProposalDto } from 'src/app/shared/models/standard-careproposal/standard-careproposal-dto.model';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class StandardCareProposalWorkItem extends WorkItem<StandardCareProposalDto> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'StandardCareProposalWorkItem';
  }
  get database(): string {
    return StandardCareProposalDB;
  }
}
