import { ISingleOrder } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { SingleOrderWorkItem } from 'src/app/business/single-order/single-order-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class SingleOrderCommandHandler extends EntityCommandHandler<ISingleOrder> {
  protected workItem: IWorkItem<ISingleOrder>;

  constructor(orderWorkItem: SingleOrderWorkItem) {
    super();
    this.workItem = orderWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'SingleOrderDto';
  }
}
