import { ISubunit } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { assignIn } from 'lodash';
import { SubunitViewModel } from 'src/app/business/institution/view-model/subunit-view-model';
import { SubunitDB } from 'src/app/common/repository/databases';
import { QueryService } from 'src/app/shared/services/query/query.service';

@Injectable()
export class SubunitResolver implements Resolve<ISubunit> {
  constructor(private _queryService: QueryService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const subunit = await this._queryService.get<ISubunit>(route.paramMap.get('subunitId'), SubunitDB);

    return subunit != null ? assignIn(new SubunitViewModel(), subunit) : null;
  }
}
