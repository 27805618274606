<div class="wizard-page" [ngClass]="{ 'wizard-page--multiplePages': !isSinglePage }">
  <div class="wizard-page__header">
    <div class="header-area">
      <div id="navBarCancelButton" (click)="cancel()">
        <ion-icon id="closeIcon" [name]="cancelIcon" *ngIf="!showOnlyDone"></ion-icon>
      </div>
      <div class="header-area__title">
        {{ pageTitleTransPath | translate }}
      </div>
      <div #saveButtons><ng-content select="[save-buttons]"></ng-content></div>
      <!-- only display regular save button if no custom save buttons are provided -->
      <div
        data-testid="wizard-save-button"
        tappable
        class="navBarCheckButton"
        (click)="done()"
        *ngIf="!showOnlyCancel && !saveButtons?.children?.length"
        [ngClass]="{ 'navBarCheckButton--disabled': !pageIsValid }"
      >
        <ion-icon id="checkIcon" [name]="doneIcon"></ion-icon>
      </div>
    </div>
    <div class="subheader-area" *ngIf="subHeader && subHeader.length > 0">{{ subHeader }}</div>
    <div
      class="navigation-area"
      [ngClass]="{ disabled: sectionHiddenInNavigation }"
      *ngIf="!isSinglePage"
      (click)="!sectionHiddenInNavigation ? changeSection() : false"
      tappable
    >
      <div class="sectionIcon-wrapper" *ngIf="sectionIcon">
        <ion-icon id="sectionIcon" [name]="sectionIcon"></ion-icon>
        <itl-alberta-icon
          *ngIf="sectionIsInvalid"
          class="invalidSectionIcon"
          name="alberta-invalid-section"
        ></itl-alberta-icon>
      </div>
      <div id="sectionChangeText">{{ sectionTitleTransPath | translate }}</div>
      <ion-icon id="navbaricon" name="alberta-arrow-down"> </ion-icon>
    </div>
  </div>
  <div class="wizard-page__content" #wizardContent><ng-content> </ng-content></div>
</div>
<div class="footer" *ngIf="!isSinglePage">
  <div class="footerspacer">
    <ng-content select="itl-wizard-page-footer"></ng-content>
  </div>
  <div
    data-testid="previous-page-button"
    class="previousPage"
    (click)="loadPrevious()"
    [ngClass]="{ 'previousPage--disabled': currentPageId === 1 }"
  >
    <ion-icon name="alberta-arrow-left"></ion-icon>
  </div>
  <div
    data-testid="next-page-button"
    class="nextPage"
    (click)="loadNext()"
    [ngClass]="{ 'nextPage--disabled': currentPageId === allPageCount || !allowNext }"
  >
    <ion-icon name="alberta-arrow-right"></ion-icon>
  </div>
</div>
