import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IndexHealth } from '@services/contracts/database/generic-storage';
import { Subject } from 'rxjs';
import { SqliteStorage } from 'src/app/common/storage/sqlite-storage';
import { IndexRepairStatusModalComponent } from '../../../index-repair-status-modal/index-repair-status-modal-page';
import { DatabaseService } from '../../services/database.service';
import { TrackerService } from '../../services/tracker.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement, SyncElementResult } from '../contracts/sync/sync-element';
import { IndexRebuilder as Rebuilder } from '../contracts/sync/sync-endpoints';

@Injectable()
export class IndexRebuilder implements ISyncElement {
  public static isRepairing = false;
  private static _executionPromise: Promise<SyncElementResult>;

  private _minimalDialogueTimeInMs = 3000;

  public constructor(
    private _trackerService: TrackerService,
    private _modalController: ModalController,
    private _databaseService: DatabaseService
  ) {}

  public get name(): string {
    return Rebuilder;
  }

  public async execute(context: ISyncContext): Promise<SyncElementResult> {
    if (!IndexRebuilder._executionPromise) {
      IndexRebuilder._executionPromise = this.rebuildIndices(context);
    }
    return IndexRebuilder._executionPromise;
  }

  private async rebuildIndices(context: ISyncContext): Promise<SyncElementResult> {
    const result: RepairIndicesResult = { jobDone: false, contextId: context.id, timings: {}, tableAnalytics: [] };
    result.contextId = context.id;

    IndexRebuilder.isRepairing = true;
    this._trackerService.debugIndexRepairStart();
    const tableAnalytics = await this.tableAnalytics();
    const tablesToRepair = tableAnalytics.filter(table => !table.indexHealth.healthy);

    if (tablesToRepair.length === 0) {
      window.logger.info(`[index-rebuilder][execute] No indices to repair`);
      this._trackerService.trackIndexRepairStop();
      result.jobDone = true;
      result.tablesToRepairNames = [];
      IndexRebuilder.isRepairing = false;
      return result;
    } else {
      this._trackerService.debugIndexRepairTablesFound(tablesToRepair.map(table => table.tableName));
    }

    result.tableAnalytics = tableAnalytics.map(table => {
      return { tableName: table?.tableName, indexHealth: table?.indexHealth };
    });

    window.logger.info(
      `[index-rebuilder][execute] Trying to repair indices of: ${tablesToRepair.map(t => t.tableName)}`
    );

    const currentDb$ = new Subject<{ name: string; index: number }>();
    const loadingModal = await this._modalController.create({
      cssClass: 'loading-modal',
      component: IndexRepairStatusModalComponent,
      showBackdrop: true,
      backdropDismiss: false,
      componentProps: {
        dbTotal: tablesToRepair.length,
        currentDb$,
      },
    });

    const dialogueStartTime = Date.now();
    await loadingModal.present();
    for (let i = 0; i < tablesToRepair.length; i++) {
      const tableStartTime = Date.now();
      window.logger.info(`[index-rebuilder][execute] repairing ${tablesToRepair[i].tableName}`);
      currentDb$.next({ name: tablesToRepair[i].tableName, index: i + 1 });
      this._trackerService.debugIndexRepairTableStart(tablesToRepair[i].tableName);
      await tablesToRepair[i].storage.repairIndex();
      const elapsedTime = Date.now() - tableStartTime;
      result.timings[tablesToRepair[i].tableName] = elapsedTime;
      this._trackerService.debugIndexRepairTableDone(tablesToRepair[i].tableName, elapsedTime);
    }
    const dialogueRunTime = Date.now() - dialogueStartTime;
    const timeRemaining = this._minimalDialogueTimeInMs - dialogueRunTime;
    if (timeRemaining > 0) {
      await new Promise(resolve => setTimeout(resolve, timeRemaining));
    }
    currentDb$.complete();
    await loadingModal.dismiss();
    this._trackerService.trackIndexRepairDialogOpenTime(Date.now() - dialogueStartTime);
    this._trackerService.trackIndexRepairStop();
    IndexRebuilder.isRepairing = false;

    return result;
  }

  private async tableAnalytics(): Promise<{ tableName: string; storage: SqliteStorage; indexHealth: IndexHealth }[]> {
    const tables: { tableName: string; storage: SqliteStorage; indexHealth: IndexHealth }[] = [];
    const storageMetadata = await this._databaseService.databaseNames;
    for (const metadata of storageMetadata) {
      const tableName = metadata.databaseName;
      const storage = await this._databaseService.getDatabase(tableName);
      if (!storage.canRepairIndex) {
        continue;
      }
      const indexHealth = await storage.indexHealth();
      tables.push({ tableName, storage: storage as SqliteStorage, indexHealth: indexHealth });
    }
    return tables;
  }
}

type RepairIndicesResult = {
  tableAnalytics: { tableName: string; indexHealth: IndexHealth }[];
  timings: object;
} & SyncElementResult;
