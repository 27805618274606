import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'itl-index-repair-status-modal',
  templateUrl: 'index-repair-status-modal-page.html',
  styleUrls: ['index-repair-status-modal-page.scss'],
})
export class IndexRepairStatusModalComponent implements OnInit {
  @Input() dbTotal: number;
  @Input() currentDb$: Observable<{ name: string; index: number }>;
  public currentName$: Observable<string>;
  public currentIndex$: Observable<number>;
  public percentage$: Observable<string>;

  constructor(private _translate: TranslateService) {}

  public ngOnInit(): void {
    this.currentIndex$ = this.currentDb$.pipe(map(db => db.index));
    this.currentName$ = this.currentDb$.pipe(map(db => this.getDatabaseDisplayName(db.name)));
  }
  private getDatabaseDisplayName(dbName: string): string {
    const splittedName = dbName.split('.');
    return this._translate.instant(`entities.${splittedName[0]}`);
  }
}
