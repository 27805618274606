import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { PayerDto } from 'src/app/shared/models/institution/payer-dto.model';

import { PayerDB } from '../../common/repository/databases';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class PayerWorkItem extends WorkItem<PayerDto> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'PayerWorkItem';
  }
  get database(): string {
    return PayerDB;
  }
}
