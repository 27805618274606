import { IIntegratedCareReturnDelivery } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { IntegratedCareReturnDeliveryChannel } from 'src/app/business/integrated-care-return-delivery/contracts/integrated-care-return-delivery-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { IntegratedCareReturnDeliveryModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class IntegratedCareReturnDeliverySyncService extends SyncService<IIntegratedCareReturnDelivery> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IIntegratedCareReturnDelivery>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(IntegratedCareReturnDeliveryModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public async afterPatch(entity: IIntegratedCareReturnDelivery): Promise<void> {
    return this.onPatched(entity);
  }

  public canSync(channel: string): boolean {
    return (
      channel === IntegratedCareReturnDeliveryChannel.CREATE ||
      channel === IntegratedCareReturnDeliveryChannel.EDIT ||
      channel === IntegratedCareReturnDeliveryChannel.DELETE
    );
  }
}
