import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { IStorageMetadata } from 'src/app/common/contracts/database/storage-metadata';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { Deferred } from 'src/app/common/deferred/deferred';
import {
  AuditDB,
  CareProposalDB,
  OrderDB,
  PatientDB,
  PatientNotesDB,
  RegionDB,
  SyncTimestampDB,
} from 'src/app/common/repository/databases';
import { SqliteStorage } from 'src/app/common/storage/sqlite-storage';

import { StorageInformation } from '../models/storage-information';
import { IDatabaseService } from './contracts/database/database-service';
import { IGenericStorage } from './contracts/database/generic-storage';

@Injectable({ providedIn: 'root' })
export class DatabaseService implements IDatabaseService {
  private _storages: Record<string, IGenericStorage>;
  private _ready = new Deferred<void>();
  private _priorityRank = [SyncTimestampDB, PatientDB, CareProposalDB, OrderDB, AuditDB, PatientNotesDB, RegionDB];

  constructor(public translate: TranslateService, @Inject(PlatformSyncToken) private _platformSync: IPlatformSync) {}

  setStorages(storages: Record<string, IGenericStorage>): void {
    this._storages = storages;

    this._ready.resolve();
  }

  // tslint:disable-next-line:array-type
  public get databaseNames(): Promise<IStorageMetadata[]> {
    return this._ready.promise.then(() => {
      const storageKeys = Object.keys(this._storages);

      const metadata: IStorageMetadata[] = [];

      let index = 0;
      for (const key of storageKeys) {
        metadata[index++] = {
          databaseName: key,
          priority: this._priorityRank.includes(key) ? 1 : 2,
        };
      }

      return metadata;
    });
  }

  public async getDatabase(name: string): Promise<IGenericStorage> {
    await this._ready.promise;

    const storage = this._storages[name];
    if (!storage) {
      throw new Error(`No storage defined for ${name}`);
    }
    await storage.ready();
    return storage;
  }

  public async clearSingleCollection(collectionName: string): Promise<void> {
    await this._ready.promise;

    if (!collectionName || collectionName.length < 4) {
      return;
    }
    if (this._storages.hasOwnProperty(collectionName)) {
      await this._storages[collectionName].clear().catch(() => undefined);
    }
    // tslint:disable-next-line:array-type
    const promises: Promise<void>[] = [];
    promises.push(await this.clearSyncTimestamp(collectionName));

    await Promise.all(promises);
  }

  public async repairSingleIndex(collectionName: string) {
    if (!collectionName || collectionName.length < 4) {
      return;
    }

    await this._ready.promise;

    if (this._storages.hasOwnProperty(collectionName)) {
      await this._storages[collectionName].repairIndex();
    }
  }

  public async clearAll(switchingChannel: boolean = false): Promise<void> {
    await this._ready.promise;

    // tslint:disable-next-line:array-type
    const promises: Promise<void>[] = [];
    for (const name in this._storages) {
      if (this._storages.hasOwnProperty(name) && (this._storages[name].isDeletable || switchingChannel)) {
        promises.push(
          this._storages[name].clear().catch(error => window.logger.error('error during clear all', error))
        );
      }
    }

    if (this._platformSync.isCordova) {
      const syncTimestampStorage = await this.getDatabase('syncTimestamp.db');
      promises.push(syncTimestampStorage.clear().catch(error => window.logger.error('error during clear all', error)));
      const statePersister = await this.getDatabase('statePersister.db');
      promises.push(statePersister.clear().catch(error => window.logger.error('error during clear all', error)));
    }
    await Promise.all(promises);

    // after deleting all tables we want to vacuum the database to free up space
    try {
      const storageValues = Object.values(this._storages);
      if (storageValues.length > 0) {
        // the sqlite commands are available for each storage entity so we need to find one first
        await storageValues[0]?.vacuum();
      }
    } catch (error) {
      window.logger.error('error during vacuum', error);
    }
  }

  public async getStorageInformation(): Promise<StorageInformation[]> {
    try {
      await this._ready.promise;

      // tslint:disable-next-line:array-type
      const storageInformation: StorageInformation[] = [];
      for (const name in this._storages) {
        if (this._storages.hasOwnProperty(name)) {
          const length = await this._storages[name].length();
          let indexLength = null;
          if (this._storages[name] instanceof SqliteStorage) {
            indexLength = await this._storages[name].length(true);
          }
          const splittedName = name.split('.');
          const displayName = this.translate.instant(`entities.${splittedName[0]}`);
          storageInformation.push(
            new StorageInformation(
              name,
              displayName,
              this._storages[name].isDeletable,
              this._storages[name].canRepairIndex,
              length,
              0,
              indexLength
            )
          );
        }
      }
      return storageInformation.sort((a, b) => a.displayName.localeCompare(b.displayName));
    } catch (error) {
      window.logger.error('DATABASESERVICE GETSTORAGEINFORMATION', error);
    }
  }

  private async clearSyncTimestamp(collectionName: string) {
    const syncTimestampStorage = await this.getDatabase('syncTimestamp.db');
    const entityName = collectionName.substring(0, collectionName.length - 3);
    return syncTimestampStorage.removeItem(entityName);
  }
}
