export function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  if (blob && blob instanceof Blob && typeof blob.arrayBuffer === 'function') {
    return blob.arrayBuffer();
  }

  if (blob == null || !(blob instanceof Blob)) {
    return null;
  }

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      resolve(fileReader.result as ArrayBuffer);
    };
    fileReader.onerror = () => {
      reject();
    };
    fileReader.readAsArrayBuffer(blob);
  });
}

export function arrayBufferToBlob(buffer: ArrayBuffer, mimeType: string): Blob {
  return new Blob([buffer], { type: mimeType });
}

export function base64ToBlob(b64Data, contentType: string = '', sliceSize: number = 512): Blob {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = [slice.length];
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = () => {
      window.logger.info('Failed to read blob.');
      reject(reader.error);
    };
    reader.readAsDataURL(blob);
  });
}

export function getMimeTypeFromBase64String(base64String: string): string {
  if (typeof base64String !== 'string') {
    return;
  }
  const mime = base64String.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  return mime && mime.length ? mime[1] : null;
}

export function stripDataPartFromBase64String(base64String: string): string {
  return base64String.substring(base64String.indexOf(',') + 1);
}
