import { Injectable } from '@angular/core';
import { PortalNotificationWorkItem } from 'src/app/business/portal-notification/portal-notification-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class PortalNotificationCommandHandler extends EntityCommandHandler<any> {
  protected workItem: IWorkItem<any>;

  constructor(portalNotificationWorkItem: PortalNotificationWorkItem) {
    super();
    this.workItem = portalNotificationWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'PortalNotificationDto';
  }
}
