import { IInstitutionAudit, InstitutionType } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { InstitutionAuditDB } from 'src/app/common/repository/databases';
import InstitutionTypeMapping from 'src/app/institution/master/institution-documents/institution-document-create/institution-type-mapping';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';
import { MessagePayloadInstitutionDocument } from '../../models/share/message-payload-institution-document';

import { IRouteParser } from '../contracts/route/route-parser';
import { QueryService } from '../query/query.service';
import { getInstitutionPathFromType } from './route-helper';

@Injectable()
export class InstitutionDocumentRouteParser implements IRouteParser {
  type = MessagePayloadType.InstitutionDocument;

  constructor(private _queryService: QueryService) {}
  async parse(payload: MessagePayloadInstitutionDocument): Promise<string> {
    const institutionAudit: IInstitutionAudit = await this._queryService.get(payload.documentId, InstitutionAuditDB);

    if (!institutionAudit) {
      throw new Error(`No institution document found for id ${payload.documentId}`);
    }
    return `/${getInstitutionPathFromType(payload.institutionType)}/${payload.institutionId}/documents/${
      payload.documentId
    }`;
  }
}
