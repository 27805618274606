import { Component, Input } from '@angular/core';

@Component({
  selector: 'itl-detail-item-container',
  templateUrl: './detail-item-container.component.html',
  styleUrls: ['./detail-item-container.component.scss'],
})
export class DetailItemContainerComponent {
  @Input() title: string;
}
