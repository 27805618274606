import { AppointmentType, IPatient } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { HospitalDB, NursingHomeDB } from 'src/app/common/repository/databases';

@Injectable({ providedIn: 'root' })
export class InstitutionActivityHelperService {
  constructor() {}

  public getInstititionDbFromType(appointmentType: AppointmentType): string {
    switch (appointmentType) {
      case 'nursingHome':
        return NursingHomeDB;
      case 'hospital':
        return HospitalDB;
    }
  }

  public getInsititutionIdFromPatient(patient: IPatient, appointmentType: AppointmentType): string {
    switch (appointmentType) {
      case 'nursingHome':
        return patient.nursingHomeId;
      case 'hospital':
        return patient.hospital.hospitalId;
    }
  }

  public getInstitutionSearchFieldFromInstitutionType(appointmentType: AppointmentType): string {
    switch (appointmentType) {
      case 'nursingHome':
        return 'nursingHomeId';
      case 'hospital':
        return 'hospital.hospitalId';
    }
  }

  public getRouteForInstitutionType(appointmentType: AppointmentType): string {
    switch (appointmentType) {
      case 'nursingHome':
        return 'nursing-home';
      case 'hospital':
        return 'hospital';
    }
  }

  public getAppointmentTypeFromRoute(route: string): AppointmentType {
    switch (route) {
      case 'nursing-home':
        return 'nursingHome';
      case 'hospital':
        return 'hospital';
    }
  }
}
