import { IErpTask } from '@alberta/konexi-shared';
import { Injectable } from '@angular/core';
import { ErpTaskWorkItem } from 'src/app/business/erpTask/erp-task-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class ErpTaskCommandHandler extends EntityCommandHandler<IErpTask> {
  protected workItem: IWorkItem<IErpTask>;

  constructor(erpTaskWorkItem: ErpTaskWorkItem) {
    super();
    this.workItem = erpTaskWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'ErpTaskDto';
  }
}
