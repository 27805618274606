export class WizardStepItem {
  constructor(
    public nameTransPath: string,
    public viewItem: any,
    public pathToIcon?: string,
    public identifier?: any,
    public isInvalid?: boolean,
    public hiddenInNavigation?: boolean,
    public disabled?: boolean
  ) {}
}
