import { ArticleAssortment } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'articleAssortmentPipe',
  pure: true,
})
export class ArticleAssortmentPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(item: any): any {
    let result = '';
    switch (item) {
      case ArticleAssortment.Unknown:
        result = this._translate.instant('article.edit.assortmentType.unknown');
        break;
      case ArticleAssortment.Focused:
        result = this._translate.instant('article.edit.assortmentType.focused');
        break;
      case ArticleAssortment.Standard:
        result = this._translate.instant('article.edit.assortmentType.standard');
        break;
      case ArticleAssortment.Edge:
        result = this._translate.instant('article.edit.assortmentType.edge');
        break;
    }
    return result;
  }
}
