import { Pipe, PipeTransform } from '@angular/core';
import { IQuantityPerUnit } from '@alberta/konexi-shared';

@Pipe({
  name: 'filterArchivedPackingUnits',
  pure: true,
})
export class ArticleFilterArchivedPackingUnitsPipe implements PipeTransform {
  transform(packagingUnits: IQuantityPerUnit[]): IQuantityPerUnit[] {
    return packagingUnits.filter(item => !item.archived);
  }
}
