import { InjectionToken } from '@angular/core';

export interface IGeneralEntityService {
  restriction: any;
  responsibleTypes: string[];
  type: string;
  isRestricted(item: any): boolean;
  clone(): IGeneralEntityService;
  getAll(): Promise<any[]>;
  find(id: string): Promise<any>;
  query(query: string): Promise<any>;
}

export const ProviderToken = new InjectionToken<IGeneralEntityService>('ProviderToken');
